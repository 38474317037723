import configs from "configs";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout, selectToken, selectUser } from "redux-store/features/authSlice";
import UserService from "services/UserService";
import { logger } from "shared/helpers";
import styles from "./styles.module.css";
import { selectUnreadMessages, setUnreadMesssages } from "redux-store/features/unreadMessagesSlice";

function DashboardHeader({}) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const unreadMessages = useSelector(selectUnreadMessages);

  const location = useLocation().pathname;

  const logoutUser = () => {
    dispatch(logout());
  };

  const httpGetUnreadMessages = useCallback(
    async () => {
      try {
        const res = await UserService.getUnreadMessageCount(token, dispatch);

        if (res.data && res.data.success) {
          dispatch(setUnreadMesssages(res.data.data));
        }
      } catch (error) {
        console.error(error);
      }
    },
    [token, dispatch]
  )

  const [status, set_status] = useState({
    home: false,
    getQuality: false,
    submitQuality: false,
    accountProfile: false,
  });

  const changeStatus = (active) => {
    set_status((prev) => {
      const d = { ...prev };

      Object.keys(d).map((key) => {
        if (key === active) d[key] = true;
        else d[key] = false;
      });

      return d;
    });
  };

  useEffect(() => {
    logger("Location", location);
    switch (location) {
      case "/account-profile":
      case "/":
        changeStatus("accountProfile");
        break;

      case "/submit-quality-information":
      case "/add-unit":
      case "/add-category":
      case "/add-company":
      case "/add-brand":
      case "/add-model":
        changeStatus("submitQuality");
        break;

      default:
        break;
    }
  }, [location]);

  useEffect(() => {
    httpGetUnreadMessages();
  }, [])

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed">
      <div className="container">
        <Link className="navbar-brand" to={"/"} style={{ cursor: "pointer" }}>
          Qualy
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item nav-profile dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="position-relative">
                  <img
                    src={
                      user && user.photo_id_1
                        ? `${configs.appAPIAssets}${user.photo_id_1}`
                        : "/assets/images/default-avatar.jpg"
                    }
                    alt="profile"
                  />
                  <span className={`${styles.badge} position-absolute`}>
                    {unreadMessages?.length ?? 0}
                  </span>
                </div>
                {" "}
                <span>
                  {user?.first_name + " " + user?.last_name}{" "}
                  <em>{`(${user?.number})`}</em>
                </span>
              </a>
              <ul
                className="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="dropdown dropend">
                  <Link className="dropdown-item" to="/messages">
                    <i className="bi bi-chat-right-text"></i>
                    Messages
                  </Link>
                </li>

                <div className="dropdown-divider"></div>
                <a className="dropdown-item" onMouseDown={logoutUser}>
                  <i className="bi bi-box-arrow-right"></i>
                  Logout
                </a>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default DashboardHeader;
