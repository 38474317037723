import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import AdminService from "services/AdminServise";

function UserVerificationSummary() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const goBack = () => navigate(-1);

  const [data, set_data] = useState(null);
  const [loading, set_loading] = useState(false);

  const fetchData = async () => {
    set_loading(true);
    try {
      const res = await AdminService.fetchUserVerificationSummary(
        token,
        dispatch
      );

      set_data(res.data.data);
    } catch (error) {}
    set_loading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4">User Verification Summary</h2>

          <div className="infoContentCenter card mb-4">
            {loading || !data ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <div className="table-responsive">
                <table className="table table-responsive table-bordered table-dark-theme user-summary-table">
                  <tr>
                    <th>Group</th>
                    <th>Records</th>
                  </tr>
                  <tr>
                    <td>Common User Trusted:</td>
                    <td>{data.common_users_trusted}</td>
                  </tr>
                  <tr>
                    <td>Common Users on Hold:</td>
                    <td>{Number(data.common_users_onhold).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>Common Users Barred:</td>
                    <td>{Number(data.common_users_barred).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>Submitting users on Hold:</td>
                    <td>{Number(data.sub_users_onhold).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>Common User (L0)</td>
                    <td>{data.common_users_l0}</td>
                  </tr>
                  <tr>
                    <td>Common User (L1)</td>
                    <td>{data.common_users_l1}</td>
                  </tr>
                  <tr>
                    <td>Submitting User Normal</td>
                    <td>{data.sub_users_normal}</td>
                  </tr>
                  <tr>
                    <td>Submitting Level 2 Pending:</td>
                    <td>{data.sub_users_level2}</td>
                  </tr>
                  <tr>
                    <td>Submitting Level 3 Pending:</td>
                    <td>{data.sub_users_level3}</td>
                  </tr>
                  <tr>
                    <td>Commercial Users:</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Approved Professional Commitment:</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Professional Applications Pending:</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Company Rep:</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Company Rep Pending:</td>
                    <td>-</td>
                  </tr>
                </table>
              </div>
            )}
          </div>

          <div className="mt-3 text-center">
            <a onClick={goBack} className="btn btn-black me-2">
              Back
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserVerificationSummary;
