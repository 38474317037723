import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./InstructionRecord.module.css";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import ModelInstructionService from "../../../services/ModelInstructionService";
import { useEffect } from "react";
import {
  formatMMDDYYYY,
  useForm,
  useShortDisplay,
} from "../../../shared/helpers";
import LoadIndicator from "views/components/LoadIndicator";
import BrandService from "services/BrandService";
import CategoryService from "services/CategoryService";
import { logger } from "shared/helpers";
import ErrorsComponent from "views/components/Errors";
import { MAX_FILE_SIZE } from "shared/constants";
import {
  add,
  remove,
  update,
} from "redux-store/features/modelInstructionSlice";
import DescModal from "views/components/DescModal";
import WarningModal from "./WarningModal";
import Countdown from "views/components/countdown";

function InstructionRecord() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const instructionService = new ModelInstructionService(dispatch, token);

  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedInstructionId, setSelectedInstructionId] = useState("");
  const [selectedInstruction, setSelectedInstruction] = useState(null);

  const { form, err, set_err, handleServerError, onChange } = useForm({
    instruction_id: "Null",
    brand: null,
    category: null,
    name: null,
    application: null,
    date_created: null,
  });
  useShortDisplay(() => set_err([]), 5000, err.length > 0);

  const [fetchingBrands, setFetchingBrands] = useState(false);
  const fetchBrands = async () => {
    setFetchingBrands(true);
    try {
      const res = await BrandService.fetchAll(token, dispatch);

      setBrands(res.data.data);
    } catch (error) {
      logger("Error", error);
    }
    setFetchingBrands(false);
  };

  const [fetchingCategories, setFetchingCategories] = useState(false);
  const fetchCategories = async () => {
    setFetchingCategories(true);
    try {
      const res = await CategoryService.fetchAllCategoryChildren(
        token,
        dispatch,
        "All"
      );

      setCategories(res.data.data);
    } catch (error) {
      logger("Error", error);
    }
    setFetchingCategories(false);
  };

  const unlockInstruction = async () => {
    try {
      logger("Unlock instructions");
      await instructionService.unlock();
    } catch (error) {}
  };

  const [fromRef, setFromRef] = useState("");
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const id = search.get("id");
    const ref = search.get("ref");

    setSelectedInstructionId(id);
    setFromRef(ref);

    fetchBrands();
    fetchCategories();

    return () => {
      unlockInstruction();
    };
  }, []);

  const setInstructionData = (data) => {
    setSelectedInstruction((prev) => {
      if (prev) return { ...prev, ...data };

      return data;
    });

    onChange("instruction_id", data.instruction_id);
    onChange("brand", data.brand);
    onChange("category", data.category);
    onChange("name", data.name);
    onChange("application", data.application);
    onChange("date_created", data.created_at);
  };

  const goBack = () => {
    if (fromRef === "brand_model") {
      window.close();
    } else {
      navigate(-1);
    }
  };

  const [fetching, setFetching] = useState(false);
  const getModelInstruction = async (id) => {
    setFetching(true);
    try {
      const res = await instructionService.view(id);

      setInstructionData(res.data.data);
    } catch (error) {
      handleServerError(error);
      console.log("Error", error);
    }
    setFetching(false);
  };

  useEffect(() => {
    if (selectedInstructionId) {
      getModelInstruction(selectedInstructionId);
    }
  }, [selectedInstructionId]);

  const [saving, setSaving] = useState(false);

  const updateInstruction = async (data) => {
    if (saving) return;

    setSaving(true);

    try {
      const res = await instructionService.update(data);

      setInstructionData(res.data.data);

      dispatch(update(res.data.data));

      setSelectedFile(null);
    } catch (error) {
      handleServerError(error);
    }

    setSaving(false);
  };

  const { state } = useLocation();

  const addInstruction = async (data) => {
    if (saving) return;

    setSaving(true);

    try {
      const res = await instructionService.add(data);

      setInstructionData(res.data.data);

      dispatch(add(res.data.data));

      setSelectedFile(null);

      if (
        (state && state.from === "brand_model") ||
        fromRef === "brand_model"
      ) {
        goBack();
      }
    } catch (error) {
      handleServerError(error);
    }

    setSaving(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = new FormData();

    if (form.brand) payload.append("brand", form.brand);

    if (form.category) payload.append("category", form.category);

    if (form.name !== selectedInstruction?.name)
      payload.append("name", form.name);

    if (selectedFile) payload.append("file", selectedFile);

    payload.append("application", form.application);

    if (selectedInstructionId) {
      payload.append("instruction_id", selectedInstructionId);

      updateInstruction(payload);
    } else {
      addInstruction(payload);
    }
  };

  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    if (deleting || !selectedInstructionId) return;

    setDeleting(true);

    try {
      await instructionService.delete(selectedInstructionId);

      navigate(-1);
      dispatch(remove(selectedInstructionId));
    } catch (e) {
      logger("Error deleting instruction", e);
    }

    setDeleting(false);
  };

  const [isUsesOpen, setIsUsesOpen] = useState(false);
  const openUsesModal = () => setIsUsesOpen(true);
  const closeUsesModal = () => setIsUsesOpen(false);

  const [isWarningOpen, setIsWarningOpen] = useState(false);

  const onCloseWarningModal = (overwrite = false) => {
    console.log("Overwrite", overwrite ? "Yes" : "No");
    if (!overwrite) setSelectedFile(null);

    setIsWarningOpen(false);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const onFileChange = (event) => {
    const file = event.target.files[0];

    const ext = file.name.split(".").pop();

    const allowedExtensions = ["pdf", "jpeg", "jpg", "png"];

    if (!allowedExtensions.includes(ext.toLowerCase()))
      return alert("Invalid file type, Select PDF, JPEG, JPG or PNG");

    if (ext.toLowerCase() === "pdf" && file.size > MAX_FILE_SIZE)
      return alert(`Pdf size cannot be more than ${MAX_FILE_SIZE} B.`);
    else if (file.size > MAX_FILE_SIZE)
      return alert(`Image cannot be greater than ${MAX_FILE_SIZE} B.`);

    if (selectedInstruction && selectedInstruction.file) {
      setIsWarningOpen(true);
    }

    setSelectedFile(file);
  };

  const user = useSelector(selectUser);

  return (
    <>
      <WarningModal show={isWarningOpen} handleClose={onCloseWarningModal} />

      <DescModal
        show={isUsesOpen}
        handleClose={closeUsesModal}
        children={
          <div>
            <h5>Instruction Uses</h5>

            {selectedInstruction && selectedInstruction.uses && (
              <ul>
                {selectedInstruction.uses.map((item) => (
                  <li key={"instruction-uses-available-" + item.brand_model_id}>
                    <p>
                      {item.brand_model_id}, {item.brand}, {item.model_name_des}
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        }
      />

      <div id="main-container">
        <div className={styles.wrapper}>
          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/Archive/ArrowLeft.png" alt="" />
            <span>Go Back</span>
          </div>

          {fetching ? (
            <LoadIndicator />
          ) : (
            <>
              <div className={`${styles.heading} mb-4`}>
                <h2>Model Instruction Record</h2>

                <p className={styles.uses} onClick={openUsesModal}>
                  Uses: {selectedInstruction?.uses.length || 0}
                </p>

                {selectedInstruction &&
                selectedInstruction.locked_by &&
                user ? (
                  selectedInstruction.locked_by == user.user_id ? (
                    <div className={styles.alNum}>
                      <i
                        className="bi bi-unlock-fill"
                        style={{ color: "green" }}
                      ></i>
                      <Countdown
                        initialValue={selectedInstruction.seconds_left}
                        onFinish={() => navigate(-1)}
                      />
                    </div>
                  ) : (
                    <div className={styles.alNum}>
                      <i
                        className="bi bi-lock-fill"
                        style={{ color: "red" }}
                      ></i>
                    </div>
                  )
                ) : (
                  <div className={styles.alNum}>
                    <i
                      className="bi bi-lock-fill"
                      style={{ color: "green" }}
                    ></i>
                  </div>
                )}

                <div className={styles.instId}>
                  <h4>Instruction ID: {selectedInstructionId || "Null"}</h4>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                {/* a row withoiut button*/}
                <div className={styles.row}>
                  <div className={styles.rowLabel}>
                    <img src="/assets/Archive/UserCircle.png" alt="" />
                    <span>Owner</span>
                  </div>

                  <div className={styles.rowValue}>
                    <span>Null (Admin)</span>
                  </div>
                </div>

                {fetchingBrands ? (
                  <LoadIndicator />
                ) : (
                  <div className={styles.row}>
                    <div className={styles.rowLabel}>
                      <img src="/assets/Archive/AppStoreLogo.png" alt="" />
                      <span>Brand</span>
                    </div>

                    <div className={styles.rowValue}>
                      <select
                        className="form-select"
                        value={form.brand}
                        onChange={(e) =>
                          onChange("brand", e.target.value || null)
                        }
                      >
                        <option value={""}>Null</option>

                        {brands.map((e) => (
                          <option
                            key={`brand-option-${e.brand_id}`}
                            value={e.brand_id}
                          >
                            {e.brand}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                {fetchingCategories ? (
                  <LoadIndicator />
                ) : (
                  <div className={styles.row}>
                    <div className={styles.rowLabel}>
                      <img src="/assets/Archive/List.png" alt="" />
                      <span>Category</span>
                    </div>

                    <div className={styles.rowValue}>
                      <select
                        className="form-select"
                        value={form.category}
                        onChange={(e) =>
                          onChange("category", e.target.value || null)
                        }
                      >
                        <option value={""}>Null</option>

                        {categories.map((e) => {
                          if (e.category_id !== 1) {
                            return (
                              <option
                                key={`category-option-${e.category_id}`}
                                value={e.category_id}
                              >
                                {e.name}
                              </option>
                            );
                          }
                        })}
                      </select>
                    </div>
                  </div>
                )}

                <div className={styles.row}>
                  <div className={styles.rowLabel}>
                    <img src="/assets/Archive/IdentificationCard.png" alt="" />
                    <span>Name</span>
                  </div>

                  <div className={styles.rowValue}>
                    <input
                      className="form-control"
                      maxLength={30}
                      value={form.name}
                      onChange={(e) => onChange("name", e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.rowLabel}>
                    <img src="/assets/Archive/app.png" alt="" />
                    <span>Application</span>
                  </div>

                  <div className={styles.rowValue}>
                    <textarea
                      name="application"
                      id="application"
                      className="textarea-white"
                      value={form.application || ""}
                      maxLength={100}
                      onChange={(e) => onChange("application", e.target.value)}
                      style={{
                        backgroundColor: "white",
                        width: "100%",
                        padding: 8,
                      }}
                    />
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.rowLabel}>
                    <img src="/assets/Archive/CalendarCheck.png" alt="" />
                    <span>Date Created</span>
                  </div>

                  <div className={styles.rowValue}>
                    <span>
                      {selectedInstruction
                        ? formatMMDDYYYY(selectedInstruction.created_at)
                        : "Null"}
                    </span>
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.rowLabel}>
                    <img src="/assets/Archive/CalendarCheck.png" alt="" />
                    <span>Date Updated</span>
                  </div>

                  <div className={styles.rowValue}>
                    <span>
                      {selectedInstruction
                        ? formatMMDDYYYY(selectedInstruction.updated_at)
                        : "Null"}
                    </span>
                  </div>
                </div>

                {/* a row with button*/}
                <div className={styles.row}>
                  <div className={styles.rowLabel}>
                    <img src="/assets/Archive/file.png" alt="" />
                    <span>File</span>
                  </div>

                  <div className={styles.rowValue}>
                    {selectedFile ? (
                      selectedFile.name
                    ) : selectedInstruction && selectedInstruction.file ? (
                      <a target={"_blank"} href={selectedInstruction.file}>
                        {selectedInstruction.file}
                      </a>
                    ) : (
                      "Null"
                    )}
                  </div>

                  <div>
                    <input
                      type="file"
                      id="modelInstructionFile"
                      onChange={onFileChange}
                      accept=".png, .jpg, .jpeg, application/pdf, application/vnd.ms-excel.doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv, .txt"
                      max={10}
                      value={""}
                      hidden
                    />
                    <label htmlFor="modelInstructionFile">
                      <img src="/assets/Archive/UploadSimple.png" alt="" />
                      <span>Upload file</span>
                    </label>
                  </div>
                </div>

                {err.length > 0 && (
                  <div className="mt-4">
                    <ErrorsComponent errors={err} />
                  </div>
                )}

                <div className={`${styles.btns} mt-4`}>
                  <button type="submit" className={`${styles.submit}`}>
                    {saving ? <LoadIndicator /> : "Submit"}
                  </button>

                  {selectedInstruction &&
                    selectedInstruction.uses &&
                    selectedInstruction.uses.length === 0 && (
                      <button
                        className={styles.deleteBtn}
                        onClick={handleDelete}
                        disabled={deleting}
                      >
                        {deleting ? (
                          <LoadIndicator />
                        ) : (
                          <img src="/assets/Archive/DeleteBtn.png" alt="" />
                        )}
                      </button>
                    )}
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default InstructionRecord;
