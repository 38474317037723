import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import SWVersionService from "services/SoftwareVersionServices";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";
import { handleServerError } from "views/database-records-management/brand-management/helpers";

const ConfirmDelete = ({ show, handleClose, versionId }) => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const swVersionService = new SWVersionService(dispatch, token);

  const [errors, setErrors] = useState([]);

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (!versionId) return;

    setDeleting(true);
    try {
      await swVersionService.delete(versionId);

      handleClose(true);
    } catch (error) {
      handleServerError(error, setErrors);
    }
    setDeleting(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} keyboard={false} centered>
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        Confirm Delete
      </Modal.Header>

      <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
        <div>Do you want to delete this resource?</div>

        {errors.length > 0 && (
          <div className="mt-4">
            <ErrorsComponent errors={errors} />
          </div>
        )}
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button onClick={() => handleClose()} variant="secondary">
          No
        </Button>

        <Button onClick={handleDelete}>
          {deleting ? <LoadIndicator /> : "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDelete;
