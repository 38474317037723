import { useSelector } from "react-redux";
import { selectBrandModelManagementData } from "redux-store/features/brandModelManagement";
import { brand_model_status, formatEnum } from "shared/b_m_helper";
import { formatDDMMYYY } from "shared/helpers";

const ViewBrandModel = ({ openDescriptionModal, uses = [], openUsesModal }) => {
  const { selectedBrandModel, mode } = useSelector(
    selectBrandModelManagementData
  );

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
        <h3>Brand Model</h3>
      </div>

      <div className="row">
        <div className="col-xl-8">
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Brand_Model</label>
            </div>

            <div className="col-md-9">
              <p>{selectedBrandModel?.brand_model_id || "Null"}</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Uses</label>
            </div>

            <div className="col-md-9">
              <div className="row align-items-center">
                <p>
                  {uses.length}{" "}
                  <a onClick={openUsesModal} className="infolink">
                    List uses
                  </a>
                </p>
              </div>
            </div>
          </div>

          {/** Brand Information */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Brand ID</label>
            </div>
            <div className="col-md-9">
              <div className="d-flex align-items-center">
                {selectedBrandModel?.brand_id || "Null"}
              </div>
            </div>
          </div>

          <div className="ms-4">
            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Brand</label>
              </div>
              <div className="col-md-9">
                {selectedBrandModel?.brand || "Null"}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Brand Owner</label>
              </div>
              <div className="col-md-9">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    {selectedBrandModel?.company_name || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Brand+Company same</label>
              </div>
              <div className="col-md-9">
                {Boolean(+selectedBrandModel?.as_company) ? "Yes" : "No"}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Created By</label>
              </div>

              <div className="col-md-9">
                <p>{selectedBrandModel?.created_by_number || "Null"}</p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Status</label>
              </div>

              <div className="col-md-9">
                <p>{selectedBrandModel?.brand_status || "Null"}</p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Verification Date</label>
              </div>

              <div className="col-md-9">
                <p>
                  {selectedBrandModel?.brand_verified_date
                    ? formatDDMMYYY(selectedBrandModel.brand_verified_date)
                    : "Null"}
                </p>
              </div>
            </div>
          </div>

          {/** Model Information */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Model ID</label>
            </div>
            <div className="col-md-9">
              {selectedBrandModel?.model_id || "Null"}
            </div>
          </div>

          <div className="ms-4">
            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">
                  Model Code{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(
                        'The coded model name, less used in marketing, more for manufacturing and servicing, do not use this field for generic items. If generic is set to yes, this field is automatically set to "Generic".'
                      )
                    }
                  ></i>
                </label>
              </div>
              <div className="col-md-9">
                {selectedBrandModel?.model_code || "Null"}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">
                  Model Name{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(
                        "The more familiar textual, used more in Marketing"
                      )
                    }
                  ></i>
                </label>
              </div>
              <div className="col-md-9">
                {selectedBrandModel?.model_name_des || "Null"}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Model Notes</label>
              </div>
              <div className="col-md-9">
                {selectedBrandModel?.model_notes || "Null"}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Generic Attributes ID</label>
              </div>
              <div className="col-md-9">
                {selectedBrandModel?.generic_attributes || "Null"}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">
                  Generic Model{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      openDescriptionModal(
                        "Set as generic if the same item is provided by most companies in the category without a company specific model or service title, for example 'Plumbing Repair' is a generic service. When set to yes, if no record is available, show message \"create new generic attributes record?\" and show attibutes editable as a new window."
                      )
                    }
                  ></i>
                </label>
              </div>
              <div className="col-md-9">
                {Boolean(selectedBrandModel?.generic_model) ? "Yes" : "No"}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Generic Owner</label>
              </div>
              <div className="col-md-9">
                {selectedBrandModel?.model_owner || "Null"}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Generic Status</label>
              </div>
              <div className="col-md-9">
                {selectedBrandModel?.generic_status
                  ? formatEnum(selectedBrandModel?.generic_status)
                  : "Null"}
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Attributes</label>
            </div>
            <div className="col-md-9">
              {selectedBrandModel?.attributes || "Null"}
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Created By (Brand_Model)</label>
            </div>
            <div className="col-md-9">
              <p>{selectedBrandModel?.brand_model_created_by || "Null"}</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Created Date (Brand_Model)</label>
            </div>
            <div className="col-md-9">
              <p>
                {selectedBrandModel?.created_at
                  ? formatDDMMYYY(selectedBrandModel?.created_at)
                  : "Null"}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Status (Brand_Model)</label>
            </div>
            <div className="col-md-9">
              <p>
                {selectedBrandModel?.status
                  ? brand_model_status[selectedBrandModel.status]
                  : "Null"}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Verified Date</label>
            </div>
            <div className="col-md-9">
              <p>
                {selectedBrandModel?.verified_date
                  ? formatDDMMYYY(selectedBrandModel?.verified_date)
                  : "-"}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Owner</label>
            </div>
            <div className="col-md-9">
              <p>{selectedBrandModel?.owner || "Null"}</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Data Sales (N)</label>
            </div>
            <div className="col-md-9">
              <p>{selectedBrandModel?.data_sales || "0"}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewBrandModel;
