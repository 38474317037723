import React from "react";

function ErrorsComponent({ errors, variant = "alert-danger" }) {
  if (errors instanceof Array) {
    return errors.map((err, idx) => (
      <div className={`alert ${variant}`} key={"error-" + idx}>
        {typeof err === "string" ? err : "Something went wrong"}
      </div>
    ));
  } else {
    return (
      <div className={`alert ${variant}`}>
        {typeof errors === "string" ? errors : "Something went wrong"}
      </div>
    );
  }
}

export default ErrorsComponent;
