import React from "react";
import { formatDDMMYYYYDash } from "shared/helpers";
import { formatEnum } from "./helpers";

function ViewCompany({ selectedCompany }) {
  return (
    <>
      {/** COMPANY ID */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Company ID</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.company_id || "Null"}</p>
        </div>
      </div>

      {/** COMPANY NAME */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Company Name</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.company_name || "Null"}</p>
        </div>
      </div>

      {/** COUNTRY */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Country</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.country || "Null"}</p>
        </div>
      </div>

      {/** ADDRESS LINE 1 */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Address Line 1</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.address_line1 || "Null"}</p>
        </div>
      </div>

      {/** TOWN */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Town</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.town || "Null"}</p>
        </div>
      </div>

      {/** REGION */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Region</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.region || "Null"}</p>
        </div>
      </div>

      {/** POSTAL REF */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Postal Ref</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.postal_ref || "Null"}</p>
        </div>
      </div>

      {/** TYPE */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Type</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.type || "Null"}</p>
        </div>
      </div>

      {/** PUBLIC LOCATION */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Public Location</label>
        </div>

        <div className="col-md-9">
          <p>
            {selectedCompany?.public_location
              ? Boolean(selectedCompany.public_location)
                ? "Yes"
                : "No"
              : "Null"}
          </p>
        </div>
      </div>

      {/** STATUS */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Status</label>
        </div>

        <div className="col-md-9">
          <p>
            {selectedCompany?.status
              ? formatEnum(selectedCompany.status)
              : "Null"}
          </p>
        </div>
      </div>

      {/** STATUS CHANGED */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Status Changed</label>
        </div>

        <div className="col-md-9">
          <p>
            {selectedCompany?.status_changed
              ? formatDDMMYYYYDash(selectedCompany.status_changed)
              : "Null"}
          </p>
        </div>
      </div>

      {/** CONTACT NUMBER */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Contact Number</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.contact_number || "Null"}</p>
        </div>
      </div>

      {/** CONTACT EMAIL */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Contact Email</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.contact_email || "Null"}</p>
        </div>
      </div>

      {/** WEBSITE */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Website</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.website || "Null"}</p>
        </div>
      </div>

      {/** START DATE */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Start Date</label>
        </div>

        <div className="col-md-9">
          <p>
            {selectedCompany?.start_date
              ? formatDDMMYYYYDash(selectedCompany.start_date)
              : "Null"}
          </p>
        </div>
      </div>

      {/** COMPANY REP */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Company Rep</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.company_rep || "Null"}</p>
        </div>
      </div>

      {/** CREATED BY */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Created By</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.created_by || "Null"}</p>
        </div>
      </div>

      {/** NOTES */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Notes</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.notes || "Null"}</p>
        </div>
      </div>

      {/** VERIFICATION STATUS */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Verification Status</label>
        </div>

        <div className="col-md-9">
          <p>
            {selectedCompany?.verification_status
              ? formatEnum(selectedCompany.verification_status)
              : "Null"}
          </p>
        </div>
      </div>

      {/** PROFESSIONAL ID */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Professional ID</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.professional_id || "Null"}</p>
        </div>
      </div>

      {/** VERIFIED DATE */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Verified Date</label>
        </div>

        <div className="col-md-9">
          <p>
            {selectedCompany?.verified_date
              ? formatDDMMYYYYDash(selectedCompany.verified_date)
              : "Null"}
          </p>
        </div>
      </div>

      {/** PROFESSIONAL SALES */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Professional Sales</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.professional_sales || "Null"}</p>
        </div>
      </div>
    </>
  );
}

export default ViewCompany;
