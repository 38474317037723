import "./styles.css";
import { useMessageTypeManagement } from "hooks/useMessageTypeManagement";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "redux-store/features/authSlice";
import { selectMessageTypes } from "redux-store/features/messageTypeManagementSlice";
import SingleSupportAllocation from "./SingleSupportAllocation";
import SingleMessageView from "views/components/SingleMessageView";
import { useLocation, useNavigate } from "react-router-dom";

const SupportAllocation = () => {
  const messagetypeController = useMessageTypeManagement();
  const navigate = useNavigate();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("message");

  const user = useSelector(selectUser);
  const messageTypes = useSelector(selectMessageTypes);

  const [message, setMessage] = useState(null);

  const cleanUp = () => {
    setMessage(null);
  };

  useEffect(() => {
    if (param) {
      setMessage(Number(param));
    } else {
      setMessage(null);
    }
  }, [param]);

  useEffect(() => {
    return cleanUp;
  }, []);

  useEffect(() => {
    if (messageTypes.length === 0) {
      messagetypeController.httpGetMessageTypes();
    }
  }, [messageTypes.length]);

  return (
    <div id="main-container" className="qualy-support-allocation">
      <div className="container">
        {!message && (
          <div className="row">
            <div className="col-12 col-lg-9">
              <div className="row mb-4 justify-content-center">
                <div className="col-sm-6">
                  <div className="infomore">
                    User Admin level:{" "}
                    {user && user.admin && user.admin.permission}
                  </div>
                </div>
                <div className="col-sm-6"></div>
              </div>

              <h2 className="mb-4">Support Allocation</h2>

              {messageTypes.map((messageType) => {
                if (!Boolean(messageType.allocation)) return null;

                return (
                  <SingleSupportAllocation
                    key={`support-allocation-${messageType.type_id}`}
                    messageType={messageType}
                  />
                );
              })}
            </div>
          </div>
        )}

        {message && (
          <SingleMessageView
            messageId={message}
            handleClose={() => navigate(-1)}
          />
        )}
      </div>
    </div>
  );
};

export default SupportAllocation;
