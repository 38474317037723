import { useSelector } from "react-redux";

export const ADMIN_LEVELS = {
    A1: "A1",
    A5: "A5",
    A10: "A10",
    "00": "00"
}

export default function RequireAdminLevel({level = null, children}) {
    const user = useSelector((state) => state.auth.user);

    if (!level) {
        return children;
    }

    if (level && user && user.admin) {
        const levelPosition = Object.keys(ADMIN_LEVELS).indexOf(level);
        const permissionPosition = Object.keys(ADMIN_LEVELS).indexOf(user.admin.permission);

        if (permissionPosition <= levelPosition) {
            return children;
        }
    }

    return null;
}