import { axiosClient } from "shared/axiosInstance";

class UserService {
  constructor() {}

  static updateVerificationMethods(data, token, dispatch) {
    return axiosClient(dispatch, token).post(
      "/users/settings/verification_methods",
      data
    );
  }

  static fetchVerifyList(dispatch, token) {
    return axiosClient(dispatch, token).get("/users/settings/verify_list");
  }

  static submitVerificationDocument(data, token, dispatch) {
    return axiosClient(dispatch, token).post(
      "/users/settings/user_verification_doc",
      data
    );
  }

  static isVerificationMethodSubmitted(token, dispatch) {
    return axiosClient(dispatch).get(
      "/users/settings/verification_methods_status",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  static getEnabledVeriMethods(token, dispatch) {
    return axiosClient(dispatch, token).get(
      "/users/settings/verification_methods"
    );
  }

  static fetchSubmittingUserDetails(token) {
    return axiosClient().get("/users/submitting_role_details", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static fetchRolesAndStatus(token, dispatch) {
    return axiosClient(dispatch, token).get("/users/roles");
  }

  static updateProfilePic(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/users/settings/avatar", data);
  }

  static findUserByNumber(token, dispatch, user_number) {
    return axiosClient(dispatch, token).get(
      "/users?user_number=" + user_number
    );
  }

  static bypassUser(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/users/bypass", data);
  }

  static fetchUserByUserNo(token, dispatch, userNo) {
    return axiosClient(dispatch, token).get(`/users?user_number=${userNo}`)
  }

  static getUnreadMessageCount(token, dispatch) {
    return axiosClient(dispatch, token).get('/messages/unread');
  }
}

export default UserService;
