const { axiosClient } = require("shared/axiosInstance");

class RealtimeNotificationService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async toggleSiteAvailability() {
    return this.client.post("/realtime-notification/site-availability");
  }
}

export default RealtimeNotificationService;
