export const modes = {
  view: "view",
  edit: "edit",
  new_brand: "new_brand",
  new_model: "new_model",
  new_brand_model: "new_brand_model",
};

export const tabs = {
  brand_model: "brand_model",
  attributes: "attributes",
};

export const brand_model_status = {
  awaiting_verification: "Awaiting Verification",
  queried: "Queried",
  verified: "Verified",
};

export const brandModelSpecific = [
  "created_by",
  "created_at",
  "verified_date",
  "status",
  "owner",
  "data_sales",
];

export const formatEnum = (string = "") => {
  while (string.includes("_")) string = string.replace("_", " ");

  return `${string[0].toUpperCase()}${string.substring(1).toLowerCase()}`;
};
