import { useBrandModelMgmt } from "hooks/useBrandModelMgmt";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "redux-store/features/authSlice";
import { selectBrandModelManagementData } from "redux-store/features/brandModelManagement";
import { useMultiSelect } from "shared/useMultiSelect";
import AutocompleteV2 from "views/components/AutocompleteV2";
import LoadIndicator from "views/components/LoadIndicator";
import MultiStepCategorySelect from "views/components/MultiStepCategorySelect";
import { modes, tabs } from "../../../shared/b_m_helper";
import LockTimer from "views/components/LockTimer";
import { useDescriptionModal, useShortDisplay } from "shared/helpers";
import DescModal from "views/components/DescModal";
import ViewBrandModel from "./ViewBrandModel";
import { useEffect, useMemo, useRef, useState } from "react";
import ViewAttributes from "./ViewAttributes";
import ErrorsComponent from "views/components/Errors";
import EditBrandModel from "./EditBrandModel";
import { fromBrandModel } from "redux-store/features/companyManagementSlice";
import NewCompany from "./CreateNewCompany";
import ModelMismatchModal from "./ModelMismatchWarning";
import EditAttribute from "./EditAttributes";
import SelectSerialInstruction from "./SelectSerialInstruction";
import SelectSoftwareApplication from "./SelectSoftwareApplication";
import WarningModal from "views/components/WarningModal";
import { useNavigate } from "react-router-dom";
import QueryModal from "./QueryModal";
import DeleteModal from "./DeleteModal";
import DeleteMessageModal from "./DeleteMessageModal";

const getMaxNumber = (numbers = []) => {
  if (!Array.isArray(numbers) || numbers.length === 0) {
    return 0;
  }

  let max = Number(numbers[0]);

  numbers.forEach((e) => {
    e = Number(e);
    if (e > max) max = e;
  });

  return max;
};

const BrandModelManagementPage = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const {
    models,
    brands,
    idSearch,
    searching,
    uses,
    loading,
    deleting,
    errors,
    brandUses,
    modelUses,
    modeSwitch,
    showModelMismatchModal,
    selectedCategoryPrior,
    genericModelCategory,
    attributeEditMultiSelect,
    modeRef,
    handleIdSearchChange,
    handleSearchCategoryChange,
    handleSearchBrandChange,
    handleSearchModelChange,
    handleGetBrandModelById,
    switchActiveTab,
    handleCancel,
    handleOpenSerialInstruction,
    handleNewClick,
    handleEditClick,
    clearErrors,
    handleNewBrandClick,
    handleNewModelClick,
    onBrandIdChange,
    onModelIdChange,
    handleEditFormChange,
    closeMismatchModal,
    fetchCurrentCategoryTree,
    ...otherProps
  } = useBrandModelMgmt();

  const isBlurIgnored = useRef(false);
  const setIsBlurIgnored = (val) => (isBlurIgnored.current = val);

  useEffect(() => {
    setIsBlurIgnored(false);
    const id = new URLSearchParams(window.location.search).get("id");
    const tab = new URLSearchParams(window.location.search).get("tab");

    if (tab) {
      switchActiveTab(tab);
    }

    if (id) {
      handleGetBrandModelById(+id);
    }

    return () => {
      if (modeRef.current === modes.edit && !isBlurIgnored.current) {
        otherProps.httpUnlockBrandModel();
      }
    };
  }, []);

  useShortDisplay(clearErrors, 10000, errors.length > 0);
  const errorRef = useRef(null);
  useEffect(() => {
    if (errors.length > 0) {
      errorRef.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [errors]);

  const {
    dropdownMatrix,
    setCategoyInput,
    categoryInput,
    setDropdownMatrix,
    reset: resetCategorySearch,
  } = useMultiSelect({
    preSelected: null,
    setValue: handleSearchCategoryChange,
  });

  const {
    mode,
    unverifiedBrandModels,
    selectedCategory,
    selectedBrand,
    selectedBrandModel,
    activeTab,
    attributesMode,
  } = useSelector(selectBrandModelManagementData);

  const {
    showDescriptionModal,
    closeDescriptionModal,
    description,
    openDescriptionModal,
  } = useDescriptionModal();

  const [isUsesOpen, setIsUsesOpen] = useState(false);
  const openUsesModal = () => setIsUsesOpen(true);
  const closeUsesModal = () => setIsUsesOpen(false);

  const displayUses = () => {
    return (
      <div>
        <h5>Brand_Model Uses</h5>

        <ul>
          {uses.map((item) => (
            <li key={"brand-model-uses-available-" + item.unit_id}>
              <p>{item.unit_id}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const createDisabled = useMemo(() => {
    return (
      mode != modes.view ||
      attributesMode != modes.view ||
      mode === modes.new_brand_model
    );
  }, [mode, attributesMode]);

  const editDisabled = useMemo(() => {
    return (
      mode === modes.edit ||
      mode === modes.new_brand ||
      mode === modes.new_model ||
      !selectedBrandModel ||
      Object.keys(selectedBrandModel).length <= 2 ||
      attributesMode === modes.edit ||
      mode === modes.new_brand_model ||
      loading ||
      (selectedBrandModel.locked_by &&
        selectedBrandModel.locked_by != user.user_id)
    );
  }, [mode, loading, selectedBrandModel, attributesMode]);

  const deleteDisabled = useMemo(() => {
    return (
      deleting ||
      !selectedBrandModel ||
      Object.keys(selectedBrandModel).length <= 2 ||
      mode === modes.new_brand ||
      mode === modes.new_model ||
      mode === modes.new_brand_model
    );
  }, [deleting, selectedBrandModel, mode]);

  const queryDisabled = useMemo(() => {
    return (
      deleting ||
      !selectedBrandModel ||
      Object.keys(selectedBrandModel).length <= 2 ||
      mode === modes.new_brand ||
      mode === modes.new_model ||
      mode === modes.new_brand_model ||
      selectedBrandModel.status == "queried"
    );
  }, [deleting, selectedBrandModel, mode]);

  const [showNewCompany, setShowNewCompany] = useState(false);
  const setUpForNewCompany = () => {
    dispatch(fromBrandModel(true));
    setShowNewCompany(true);
  };

  const handleCloseNewCompany = (company) => {
    setShowNewCompany(false);
    if (!company) return;
    handleEditFormChange("company_id")({
      target: { value: company.company_id },
    });
    handleEditFormChange("company_name")({
      target: { value: company.company_name },
    });
    modeSwitch.goToEditMode();
  };

  const [showSelectSerialInstruction, setShowSelectSerialInstruction] =
    useState(null);
  const openSelectSerialInstruction = () => {
    const name = attributeEditMultiSelect.categoryInput[
      getMaxNumber(Object.keys(attributeEditMultiSelect.categoryInput))
    ]
      ?.replace("(p)", "")
      .trim();

    setShowSelectSerialInstruction({
      brand: selectedBrandModel?.brand_id || null,
      brand_name: selectedBrandModel?.brand || "",
      category: name || null,
      from: "brand_model",
    });
  };
  const closeSelectSerialInstruction = () =>
    setShowSelectSerialInstruction(null);
  const onSerialInstructionSelected = (instruction) => {
    handleEditFormChange("serial_instructions")({
      target: { value: instruction },
    });

    closeSelectSerialInstruction();
  };

  const [showSelectSoftwareApplication, setShowSelectSoftwareApplication] =
    useState(null);
  const openSelectSoftwareApplication = () => {
    setShowSelectSoftwareApplication({
      for: "brand_model_attributes",
      brand: selectedBrandModel?.brand_id || null,
      end_category: selectedBrandModel?.end_category || null,
    });
  };
  const closeSelectSoftwareApplication = () =>
    setShowSelectSoftwareApplication(null);
  const onSoftwareApplicationSelected = (application) => {
    handleEditFormChange("sw_application")({
      target: { value: application.sw_application_id },
    });
    handleEditFormChange("sw_application_name")({
      target: { value: application.name },
    });
    closeSelectSoftwareApplication();
  };

  const [openWarning, setOpenWarning] = useState(false);
  const [warning, setWarning] = useState("");
  const [warningFunc, setWarningFunc] = useState("companyIsSet");

  const handleOkFunctions = {
    companyIsSet: () => {
      setOpenWarning(false);
    },
  };

  const handleOpenWarning = (msg, func) => {
    setWarning(msg);
    setWarningFunc(func);
    setOpenWarning(true);
  };

  const handleCancelWarning = () => {
    setOpenWarning(false);
  };

  const handleSubmit = () => {
    if (
      selectedBrandModel &&
      (selectedBrandModel.company_id === "none" ||
        !selectedBrandModel.company_id)
    ) {
      handleOpenWarning("Brand owner must be set", "companyIsSet");
      return;
    }

    if (
      selectedBrandModel &&
      selectedBrandModel.company_verification_status === "Not Verified"
    ) {
      handleOpenWarning("Company must be verified separately ", "companyIsSet");
      return;
    }

    switch (mode) {
      case modes.new_brand_model:
        return otherProps.httpNewBrandModel();

      case modes.new_brand:
        return otherProps.httpAddBrandToDb();

      case modes.new_model:
        return otherProps.httpAddModelToDb();

      default:
        return otherProps.httpUpdateBrandModel();
    }
  };

  const goToBrandModelUnits = () =>
    selectedBrandModel &&
    navigate(
      `/database-records-management/brand-model/units?id=${selectedBrandModel.brand_model_id}`
    );

  const [showQueryModal, setShowQueryModal] = useState(false);
  const handleCloseQueryModal = () => setShowQueryModal(false);
  const openQueryModal = () => setShowQueryModal(true);

  const [showDelete, setShowDelete] = useState(false);
  const onDeleteClick = () => setShowDelete(true);

  const [isDeleteMsgOpen, setIsDeleteMsgOpen] = useState(false);
  const [continueDeleteData, setContinueDeleteData] = useState(null);
  const openDeleteMessagePopup = (data) => {
    setContinueDeleteData(data);
    setIsDeleteMsgOpen(true);
  };

  const closeDeleteMessagePopop = (message) => {
    if (continueDeleteData) {
      const data = { ...continueDeleteData, message };
      otherProps.httpDeleteBrandModel(data, openDeleteMessagePopup);
    }

    setIsDeleteMsgOpen(false);
  };

  return (
    <>
      <DeleteMessageModal
        show={isDeleteMsgOpen}
        handleClose={closeDeleteMessagePopop}
      />

      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        deleteBrandModel={(data) =>
          otherProps.httpDeleteBrandModel(data, openDeleteMessagePopup)
        }
      />

      <QueryModal show={showQueryModal} handleClose={handleCloseQueryModal} />

      <DescModal
        show={isUsesOpen}
        handleClose={closeUsesModal}
        children={displayUses()}
      />

      <DescModal
        show={showDescriptionModal}
        handleClose={closeDescriptionModal}
        text={description}
      />

      <NewCompany
        show={showNewCompany}
        handleClose={() => setShowNewCompany(false)}
        closeModal={handleCloseNewCompany}
      />

      {useMemo(
        () => (
          <SelectSerialInstruction
            contextData={showSelectSerialInstruction}
            handleClose={closeSelectSerialInstruction}
            onSelect={onSerialInstructionSelected}
          />
        ),
        [showSelectSerialInstruction]
      )}

      {useMemo(
        () => (
          <SelectSoftwareApplication
            contextData={showSelectSoftwareApplication}
            handleClose={closeSelectSoftwareApplication}
            onSelect={onSoftwareApplicationSelected}
          />
        ),
        [showSelectSoftwareApplication]
      )}

      <ModelMismatchModal
        show={showModelMismatchModal}
        handleClose={closeMismatchModal}
        selectedCategoryName={selectedCategoryPrior?.name}
        genericCategoryName={genericModelCategory?.name}
      />

      <WarningModal
        show={openWarning}
        text={warning}
        handleOk={handleOkFunctions[warningFunc]}
        handleClose={handleCancelWarning}
        hideCancel
      />

      <div className="brand-model-mgmt-page" id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4">Professional Brand Model Management</h2>

          <div className="infoContentCenter card mb-4">
            {/** Category Multi Step Dropdown */}
            <MultiStepCategorySelect
              id={"b_m_mgmt-head-category-select"}
              categoryInput={categoryInput}
              dropdownMatrix={dropdownMatrix}
              setCategoyInput={setCategoyInput}
              setDropdownMatrix={setDropdownMatrix}
              setValue={handleSearchCategoryChange}
            />

            {/** Unverified Brand Model */}
            <div className="row mt-4">
              <div className="col-md-4">
                <label className="form-label">
                  Unverified Brand_Model records
                </label>
              </div>
              <div className="col-md-8">
                <p>{unverifiedBrandModels?.length || 0}</p>
              </div>
            </div>

            <AutocompleteV2
              suggestions={unverifiedBrandModels}
              placeholder="Select an unverified brand model"
              labels={["brand", "model_name_des"]}
              primaryKey={"brand_model_id"}
              disabled={
                selectedCategory && Object.keys(selectedCategory).length === 0
              }
              setValue={(brand_model) =>
                handleGetBrandModelById(brand_model.brand_model_id)
              }
            />

            {/** Brand & Model Search */}
            <div className="row mt-4">
              <div className="col-6 col-xm-12">
                <label className="form-label">Category Search</label>
                {/** SELECT BRAND */}
                <AutocompleteV2
                  suggestions={brands}
                  placeholder="Please select brand"
                  labels={["brand", "company_name"]}
                  primaryKey={"brand_id"}
                  setValue={handleSearchBrandChange}
                />
              </div>

              <div className="col-6 col-xm-12">
                <label className="form-label" style={{ color: "transparent" }}>
                  -
                </label>
                {/** SELECT MODEL */}
                <AutocompleteV2
                  suggestions={models}
                  placeholder="Please select model"
                  disabled={
                    !selectedBrand ||
                    !selectedCategory ||
                    Object.keys(selectedCategory).length === 0 ||
                    Object.keys(selectedBrand).length === 0
                  }
                  labels={["model_name_des", "model_code"]}
                  primaryKey={"model_id"}
                  setValue={handleSearchModelChange}
                />
              </div>
            </div>

            {/** Find B_M by ID */}
            <div className="row mt-4">
              <div className="col-6 col-xm-12">
                <label className="form-label">Find record by id</label>

                <input
                  type="text"
                  placeholder="Search by id"
                  value={idSearch}
                  className="form-control"
                  onChange={handleIdSearchChange}
                />
              </div>
              <div className="col-3 col-xm-12" style={{ marginTop: "auto" }}>
                <button
                  className="btn btn-yellow ms-3"
                  onClick={() => handleGetBrandModelById(idSearch)}
                  disabled={searching || !idSearch}
                >
                  {searching ? <LoadIndicator size="sm" /> : "Search"}
                </button>
              </div>
            </div>

            {/** TABS */}
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeTab === tabs.brand_model ? "active" : undefined
                  }`}
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#BrandModel"
                  type="button"
                  role="tab"
                  aria-controls="home-tab"
                  aria-selected="true"
                  onClick={() => switchActiveTab(tabs.brand_model)}
                >
                  Brand_Model {selectedBrandModel?.brand_model_id || "Null"}
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeTab === tabs.attributes ? "active" : undefined
                  }`}
                  id="attributes-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Attributes"
                  type="button"
                  role="tab"
                  aria-controls="attributes-tab"
                  aria-selected="false"
                  onClick={() => switchActiveTab(tabs.attributes)}
                >
                  Attributes {selectedBrandModel?.attributes || "Null"}
                </button>
              </li>
            </ul>

            <div className="tab-content" id="myTabContent">
              {selectedBrandModel?.brand_model_id && (
                <div className="row mt-4">
                  <div className="col-md-8">
                    <LockTimer
                      data={selectedBrandModel}
                      onComplete={handleCancel}
                    />
                  </div>
                </div>
              )}

              {activeTab === tabs.brand_model && (
                <div
                  className={`tab-pane fade ${
                    activeTab === tabs.brand_model ? "show active" : undefined
                  }`}
                  id="BrandModel"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {mode === modes.view && (
                    <ViewBrandModel
                      openDescriptionModal={openDescriptionModal}
                      uses={uses}
                      openUsesModal={openUsesModal}
                    />
                  )}

                  {mode !== modes.view && (
                    <EditBrandModel
                      uses={uses}
                      openUsesModal={openUsesModal}
                      brandUses={brandUses}
                      modelUses={modelUses}
                      handleNewBrandClick={handleNewBrandClick}
                      handleNewModelClick={handleNewModelClick}
                      modeSwitch={modeSwitch}
                      onBrandIdChange={onBrandIdChange}
                      handleEditFormChange={handleEditFormChange}
                      setUpForNewCompany={setUpForNewCompany}
                      onModelIdChange={onModelIdChange}
                      openDescriptionModal={openDescriptionModal}
                    />
                  )}
                </div>
              )}

              {activeTab === tabs.attributes && (
                <div
                  className={`tab-pane fade ${
                    activeTab === tabs.attributes ? "show active" : undefined
                  }`}
                  id="Attributes"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {attributesMode === modes.view && (
                    <ViewAttributes
                      handleOpenSerialInstruction={handleOpenSerialInstruction}
                    />
                  )}

                  {attributesMode !== modes.view && (
                    <EditAttribute
                      attributeEditMultiSelect={attributeEditMultiSelect}
                      handleEditFormChange={handleEditFormChange}
                      fetchCurrentCategoryTree={fetchCurrentCategoryTree}
                      handleOpenSerialInstruction={handleOpenSerialInstruction}
                      openSelectSerialInstruction={openSelectSerialInstruction}
                      openSelectSoftwareApplication={
                        openSelectSoftwareApplication
                      }
                      onAttributeChange={otherProps.onAttributeChange}
                    />
                  )}
                </div>
              )}
            </div>

            <div className="btn-group-child mb-5">
              <button
                type="button"
                className="btn btn-yellow me-3"
                disabled={createDisabled}
                onClick={handleNewClick}
              >
                <i className="bi bi-plus-circle"></i> Create New
              </button>

              <button
                type="button"
                className="btn btn-yellow me-3"
                disabled={editDisabled}
                onClick={handleEditClick}
              >
                <i className="bi bi-pencil"></i> Edit
              </button>

              <button
                type="button"
                className="btn btn-yellow me-3"
                disabled={deleteDisabled}
                onClick={onDeleteClick}
              >
                <i className="bi bi-trash"></i> Delete
              </button>

              <button
                type="button"
                className="btn btn-yellow me-3"
                disabled={queryDisabled}
                onClick={openQueryModal}
              >
                Query
              </button>

              {selectedBrandModel &&
                Object.keys(selectedBrandModel).length > 0 && (
                  <button
                    type="button"
                    className="btn btn-yellow me-3"
                    onClick={goToBrandModelUnits}
                    disabled={mode === modes.new_brand_model}
                  >
                    View Unit(s)
                  </button>
                )}
            </div>

            <ErrorsComponent errors={errors} />
            <div ref={errorRef} />

            <div className="mt-4 text-center">
              <button
                type="button"
                onClick={handleCancel}
                className="btn btn-black me-2"
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-yellow"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? <LoadIndicator /> : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandModelManagementPage;
