const { axiosClient } = require("shared/axiosInstance");

class SWApplicationService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async fetchAll() {
    return this.client.get("/sw-applications");
  }

  async view(id) {
    return this.client.get(`/sw-applications/view/${id}`);
  }

  async create(data) {
    return this.client.post("/sw-applications", data);
  }

  async update(data) {
    return this.client.put("/sw-applications", data);
  }

  async unlock() {
    return this.client.post("/sw-applications/unlock");
  }

  async delete(id) {
    return this.client.delete(`/sw-applications?id=${id}`);
  }
}

export default SWApplicationService;
