import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./SoftwareApplicationManagement.module.css";
import { useSelector } from "react-redux";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import { useDispatch } from "react-redux";
import Autocomplete from "views/components/Autocomplete";
import BrandService from "services/BrandService";
import SWApplicationService from "services/SWApplicationService";
import MultiStepCategorySelect from "views/components/MultiStepCategorySelect";
import {
  initializeApplicationList,
  selectSoftwareApplicationManagementData,
} from "redux-store/features/softwareApplicationManagement";
import { updateSelectedBrandModel } from "redux-store/features/brandModelManagement";
import { tabs } from "shared/b_m_helper";
import { tabs as modelTabs } from "../model-management/helper";
import { useMultiSelect } from "shared/useMultiSelect";
import ApplicationRow from "./ApplicationRow";
import { updateSelectedAttribute } from "redux-store/features/modelManagementSlice";
import LoadIndicator from "views/components/LoadIndicator";
import { useHasFocus } from "hooks/useHasFocus";

function SoftwareApplicationManagement(props) {
  const focus = useHasFocus();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { applications, initialized } = useSelector(
    selectSoftwareApplicationManagementData
  );
  const [brands, setBrands] = useState([]);
  const [brandInput, setBrandInput] = useState("No Filter");
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const swApplicationService = new SWApplicationService(dispatch, token);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState("no-filter");
  const [textFilter, setTextFilter] = useState("");
  const [userFilter, setUserFilter] = useState("no-filter");

  const goBack = () => {
    navigate(-1);
  };

  // fake data
  const goToSARNew = () => {
    if (props.contextData) {
      window.open(
        `${window.location.origin}/database-records-management/sar?ref=new`
      );
    } else {
      navigate("/database-records-management/sar", {
        state: {
          for: "new",
          end_category: state?.end_category,
        },
      });
    }
  };

  const goToSARExisting = (item) => {
    navigate("/database-records-management/sar", {
      state: {
        for: "existing",
        item,
      },
    });
  };

  const [fetchingBrands, setFetchingBrands] = useState(false);
  const getBrands = async () => {
    setFetchingBrands(true);
    try {
      const res = await BrandService.fetchAll(token, dispatch);

      setBrands(res.data.data);
    } catch (e) {}
    setFetchingBrands(false);
  };

  const getApplications = async () => {
    try {
      const res = await swApplicationService.fetchAll();
      dispatch(initializeApplicationList(res.data.data));
    } catch (error) {}
  };

  useEffect(() => {
    if (brands.length === 0) getBrands();
    if (applications.length === 0 || !initialized) getApplications();

    return () => {
      resetList();
    };
  }, []);

  useEffect(() => {
    if (state && state.brand && brands.length > 0) {
      const brand = brands.filter((e) => e.brand_id === state.brand)[0];

      setSelectedBrand(brand || "no-filter");
      setBrandInput(brand?.brand || "No Filter");
    }
  }, [state, brands]);

  const handleRowClick = (item) => () => {
    if (
      props.contextData &&
      props.contextData.for === "brand_model_attributes"
    ) {
      props.contextData.onSelect(item);
    } else if (state?.for === "brand_model_attributes") {
      // select and go back
      dispatch(
        updateSelectedBrandModel({
          key: "sw_application",
          value: item.sw_application_id,
        })
      );

      navigate(
        "/database-records-management/brand-model?tab=" + tabs.attributes,
        { replace: true }
      );
    } else if (state?.for === "model_attributes") {
      dispatch(
        updateSelectedAttribute({
          key: "sw_application",
          value: item.sw_application_id,
        })
      );

      navigate(
        "/database-records-management/model?tab=" + modelTabs.attributes,
        { replace: true, state: { from: "select_application" } }
      );
    } else {
      goToSARExisting(item);
    }
  };

  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    setFiltered(applications);
  }, [applications]);

  const filterList = () => {
    const result = applications.filter(
      (item) =>
        (!selectedCategory ||
          selectedCategory.category_id === item.end_category) &&
        (selectedBrand === "no-filter" ||
          (!selectedBrand && !item.brand) ||
          selectedBrand.brand_id === item.brand) &&
        (!textFilter ||
          item.name?.toLowerCase()?.includes(textFilter.trim().toLowerCase()) ||
          item.application
            ?.toLowerCase()
            ?.includes(textFilter.trim().toLowerCase())) &&
        (userFilter === "no-filter" ||
          (!userFilter && !item.owner) ||
          (userFilter === "you" && item.owner == user.user_id))
    );

    setFiltered(result);
  };

  useEffect(() => {
    filterList();
  }, [applications, selectedCategory, selectedBrand, textFilter, userFilter]);

  const {
    dropdownMatrix,
    setCategoyInput,
    categoryInput,
    setDropdownMatrix,
    reset,
  } = useMultiSelect({
    preSelected: state?.end_category,
    setValue: setSelectedCategory,
    noFilter: true,
  });

  const resetList = () => {
    reset();
    setBrandInput("No Filter");
    setTextFilter("");
    setSelectedBrand("no-filter");
    setUserFilter("no-filter");
    setSelectedCategory(null);
  };

  const handleCategoryChange = (category) => {
    if (category.category_id === "none") {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(category);
    }
  };

  const [refetching, setRefetching] = useState(false);
  const refetchList = async () => {
    if (refetching) return;

    setRefetching(true);

    await getApplications();

    setRefetching(false);
  };

  useEffect(() => {
    if (focus) {
      refetchList();
    }
  }, [focus]);

  return (
    <>
      <div
        id="main-container"
        style={{ padding: props.contextData ? 0 : undefined }}
      >
        <div className="container">
          <div className="row mb-4 justify-content-center userLevel-Admin">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          {!Boolean(props.contextData) && (
            <div className={`${styles.arrow} mb-4`} onClick={goBack}>
              <img src="/assets/Archive/ArrowLeft.png" alt="" />
              <span>Go Back</span>
            </div>
          )}

          <h2 className="mb-4 userLevel-Title">
            Software application management
          </h2>

          <div className={`${styles.filterContainer} mb-4`}>
            <p className={styles.filter}>Filter List</p>

            <div className="row mb-2">
              <div className="col-md-2 mb-2 mb-md-0">
                <div className={styles.topLabel}>
                  <span>End Category:</span>
                </div>
              </div>

              <div className="col-md-5">
                <MultiStepCategorySelect
                  id="s-a-m-category-select"
                  // preSelected={state?.end_category}
                  setValue={handleCategoryChange}
                  dropdownMatrix={dropdownMatrix}
                  setCategoyInput={setCategoyInput}
                  categoryInput={categoryInput}
                  setDropdownMatrix={setDropdownMatrix}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-2 mb-2 mb-md-0">
                <div className={styles.topLabel}>
                  <span>Text Filter:</span>
                </div>
              </div>

              <div className="col-md-5">
                <input
                  type={"text"}
                  className="form-control"
                  placeholder="Search"
                  value={textFilter}
                  onChange={(e) => setTextFilter(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-2 mb-2 mb-md-0">
                <div className={styles.topLabel}>
                  <span>Created By:</span>
                </div>
              </div>

              <div className="col-md-5">
                <select
                  className="form-select"
                  value={userFilter}
                  onChange={(e) => setUserFilter(e.target.value)}
                >
                  <option value={"you"}>You</option>
                  <option value="no-filter">No Filter</option>
                  <option value="">Null</option>
                </select>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-2 mb-2 mb-md-0">
                <div className={styles.topLabel}>
                  <span>HW Brand:</span>
                </div>
              </div>

              <div className="col-md-5">
                <Autocomplete
                  first={[
                    {
                      brand: "No Filter",
                      brand_id: "none",
                    },

                    {
                      brand: "Null",
                      brand_id: "",
                    },
                  ]}
                  suggestions={brands}
                  placeholder="Select a brand"
                  input={brandInput}
                  className="selectBrand"
                  setInput={(input) => {
                    setBrandInput(input);
                  }}
                  labelKey="brand"
                  primaryKey={"brand_id"}
                  setValue={(brand) => {
                    if (brand.brand_id === "none") {
                      setSelectedBrand("no-filter");
                      setBrandInput("No Filter");
                    } else if (brand.brand_id === "") {
                      setSelectedBrand(null);
                      setBrandInput("Null");
                    } else {
                      setSelectedBrand(brand);
                    }
                  }}
                />
              </div>
            </div>

            <span className={styles.refreshList} onClick={refetchList}>
              {refetching ? <LoadIndicator /> : "Refresh List"}
            </span>
          </div>

          <div className={`${styles.applicationListContainer} mb-4`}>
            <div className={styles.applicationListHeader}>
              <h5 className="mb-2 userLevel-Title">
                Select Software Application
              </h5>
            </div>

            <div className={`${styles.applicationList}`}>
              <header className="mb-2">
                <div>
                  <div>
                    <span>Name</span>
                  </div>
                </div>

                <div>
                  <div>
                    <span>Application</span>
                  </div>
                </div>

                <div>
                  <div>
                    <span>Creator</span>
                  </div>
                </div>

                <div>
                  <div>
                    <span>Category</span>
                  </div>
                </div>

                <div>
                  <div>
                    <span>Brand</span>
                  </div>
                </div>

                <div></div>
              </header>

              <div className="data">
                {filtered.map((item) => {
                  return (
                    <ApplicationRow
                      key={`application-${item.sw_application_id}`}
                      data={item}
                      onClick={handleRowClick(item)}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          <button onClick={goToSARNew} className={styles.new}>
            New Application
          </button>
        </div>
      </div>
    </>
  );
}

export default SoftwareApplicationManagement;
