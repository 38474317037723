import { Button, Modal } from "react-bootstrap";

const WarningModal = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      backdrop="static"
      centered
      onHide={handleClose}
      keyboard={false}
    >
      <Modal.Header style={{ borderBottom: "none" }}>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h5 className="mod-detail">
          Are you sure you want to overwrite existing file?
        </h5>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="secondary" onClick={() => handleClose(false)}>
          No
        </Button>

        <Button variant="primary" onClick={() => handleClose(true)}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WarningModal;
