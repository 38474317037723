import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import AutocompleteV2 from "views/components/AutocompleteV2";
import CategoryService from "services/CategoryService";
import SymptomService from "services/SymptomService";
import WarningModal from "views/components/WarningModal";

export const CategoryUsedInModal = ({
  show,
  handleClose,
  data = [],
  symptom = null,
}) => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const service = new SymptomService(dispatch, token);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [warningProps, setWarningProps] = useState({});

  const getCategories = async () => {
    try {
      const res = await CategoryService.getEndCategories(dispatch, token);

      setCategories(res.data.data);
    } catch (error) {}
  };

  const shouldDelete = async () =>
    new Promise((res) => {
      setIsWarningOpen(true);
      setWarningProps({
        handleClose: () => res(false),
        handleOk: () => res(true),
      });
    });

  const onDelete = (id) => async () => {
    const canDelete = await shouldDelete();
    setIsWarningOpen(false);

    if (canDelete) {
      try {
        setLoading(true);
        await service.delete_category(id);
        handleClose(true);
      } catch (error) {
        console.log("[error]", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (symptom && selectedCategory) {
        try {
          setLoading(true);
          await service.add_category({
            symptom,
            end_category: selectedCategory.category_id,
          });
          handleClose(true);
        } catch (error) {
          console.log("[error]", error);
        } finally {
          setLoading(false);
        }
      }
    },
    [selectedCategory, symptom]
  );

  useEffect(() => {
    if (show) {
      getCategories();
      setSelectedCategory(null);
    }
  }, [show]);

  const onClose = useCallback(() => {
    if (!loading) {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <WarningModal
        show={isWarningOpen}
        text={"Are you sure you want to delete this resource?"}
        negativeLabel="No"
        positiveLabel="Yes"
        {...warningProps}
      />

      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Categories</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          {/* <div className="table-responsive"> */}
          <table className="table table-responsive table-bordered table-dark-theme">
            <thead>
              <tr>
                <th>C_ID</th>
                <th colSpan={2}>Category name</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.cat_symp_id}>
                  <td>{item.end_category}</td>
                  <td colSpan={2}>{item.category_name}</td>
                  <td>
                    <button
                      className="delete"
                      onClick={onDelete(item.cat_symp_id)}
                      disabled={loading}
                    >
                      <i style={{ color: "red" }} className="bi bi-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}

              <tr>
                <td>New Category</td>

                <td colSpan={2}>
                  <form id="addCategoryToSympForm" onSubmit={handleSubmit}>
                    <AutocompleteV2
                      suggestions={categories}
                      labels={["name"]}
                      primaryKey={"category_id"}
                      value={selectedCategory?.category_id}
                      placeholder={"Select a category"}
                      sortKey={"name"}
                      setValue={setSelectedCategory}
                      required={true}
                    />
                  </form>
                </td>

                <td>
                  <button
                    type="submit"
                    form="addCategoryToSympForm"
                    className="btn btn-yellow"
                    disabled={loading}
                  >
                    Add
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          {/* </div> */}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleClose} type="button">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
