import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "redux-store/features/authSlice";
import "./index.css";

function AdminTools() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const goToUserVerificationSummary = () =>
    navigate("/user-verification-summary");

  const goToSubmittingUserVerification = () =>
    navigate("/submitting-user-verification");

  const goToAdminUserManagement = () => navigate("/admin-user-management");

  const goToDatabaseManagement = () => navigate("/database-records-management");

  const goToSecurityManagement = () => navigate("/security-key-management");

  const goToMessageTypeManagement = () => navigate("/message-type-management");

  const goToSupportAllocation = () => navigate("/support-allocation");

  const goToMessageTaxManagement = () => navigate("/tax-management");

  return (
    <>
      <div id="main-container">
        <div className="d-flex flex-column p-4">
          <div className="row mb-4 justify-content-start userLevel-Admin">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4 userLevel-Admin-Tools-Title">Admin Tools</h2>

          <div className="row admin-tool-cont">
            <div className="col-md-3 col-sm-6 admin-tool">
              <a
                className="puBox actionBox"
                onClick={goToUserVerificationSummary}
              >
                <i className="bi bi-person"></i>
                User Verification Summary
              </a>
            </div>

            <div className="col-md-3 col-sm-6 admin-tool">
              <a
                className="puBox actionBox"
                onClick={goToSubmittingUserVerification}
              >
                <i className="bi bi-person-check"></i>
                Submitting User Verification
              </a>
            </div>

            <div className="col-md-3 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToAdminUserManagement}>
                <i className="bi bi-briefcase"></i>
                Admin User Management
              </a>
            </div>

            <div className="col-md-3 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToDatabaseManagement}>
                <i className="bi bi-box"></i>
                Database Records Management
              </a>
            </div>

            <div className="col-md-3 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToSecurityManagement}>
                <i className="bi bi-box"></i>
                Security Key Management
              </a>
            </div>

            <div className="col-md-3 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToSupportAllocation}>
                <i className="bi bi-box"></i>
                Support Allocation
              </a>
            </div>

            <div className="col-md-3 col-sm-6 admin-tool">
              <a
                className="puBox actionBox"
                onClick={goToMessageTypeManagement}
              >
                <i className="bi bi-box"></i>
                Message Type Management
              </a>
            </div>

            <div className="col-md-3 col-sm-6 admin-tool">
              <a
                className="puBox actionBox"
                onClick={goToMessageTaxManagement}
              >
                <i className="bi bi-box"></i>
                Tax Management
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminTools;
