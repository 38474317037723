import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import DatabaseGlobalVariablesService from "services/DatatbaseGlobalVariablesService";
import { handleError, useForm, useShortDisplay } from "shared/helpers";
import CategoryEndStructureTable from "views/components/CategoryEndStructureTable";
import LoadIndicator from "views/components/LoadIndicator";
import ActivateCronModal from "./ActivateCronModal";
import { CRON_EXECUTE_ROUTES } from "shared/constants";

function ScheduledTasks() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  const [errors, setErrors] = useState([]);
  useShortDisplay(() => setErrors([]), 5000, errors.length === 0);

  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const service = new DatabaseGlobalVariablesService(token, dispatch);

  const [loading, setLoading] = useState(false);

  const { form, onChange } = useForm({
    new_user_inv_auto: 0,
    personal_doc_delete_auto: 0,
    new_user_t: 0,
    category_end_structure_auto: 0,
    category_end_structure_t: 0,
    personal_doc_delete_t: 0,
    item_integ_t: 0,
    item_integ_auto: 0,
    sub_user_auto: 0,
    sub_user_t: 0,
    qlist_delete_auto: 0,
    qlist_delete_t: 0,
    report_trans_auto: 0,
    report_trans_t: 0,
    use_t: 0,
    use_auto: 0,
    interest_t: 0,
    interest_auto: 0,
    qprice_t: 0,
    qprice_auto: 0,
    unreg_unit_delete_t: 0,
    unreg_unit_delete_auto: 0,
  });

  const setUpForm = (data) => {
    const formKeys = Object.keys(form);
    if (data) {
      for (const key of Object.keys(data)) {
        if (formKeys.includes(key)) {
          onChange(key, data[key]);
        }
      }
    }
  };

  const [fetchingVariables, setFetchingVariables] = useState(false);
  const getDatabaseGlobalVariables = async () => {
    try {
      setFetchingVariables(true);
      const res = await service.getDatabaseGlobalVariables();

      setUpForm(res.data.data);
    } catch (error) {
      handleError(error, setErrors);
    }
    setFetchingVariables(false);
  };

  const [updatingDbVariables, setUpdatingDbVariables] = useState(false);
  const updateSingleDbVariable = async (data) => {
    if (fetchingVariables || updatingDbVariables) return;

    setUpdatingDbVariables(true);

    try {
      const res = await service.updateDatabaseGlobalVariables(data);

      setUpForm(res.data.data);
    } catch (error) {
      handleError(error, setErrors);
    }

    setUpdatingDbVariables(false);
  };

  const updateAllGlobalVariables = async () => {
    if (fetchingVariables || updatingDbVariables) return;

    setUpdatingDbVariables(true);
    setLoading(true);

    try {
      const res = await service.updateDatabaseGlobalVariables(form);

      setUpForm(res.data.data);
    } catch (error) {
      handleError(error), setErrors;
    }

    setUpdatingDbVariables(false);
    setLoading(false);
  };

  useEffect(() => {
    getDatabaseGlobalVariables();
  }, []);

  const [isActivateCronOpen, setIsActivateCronOpen] = useState(null);
  const [activateModalRoute, setActivateModalRoute] = useState("");

  const getNoOfDays = (hours = 0, parent = "hours") => {
    if (parent === "hours") {
      if (typeof hours !== "number" || hours < 24 || isNaN(hours)) return "0";

      return Math.round(hours / 24);
    } else if (parent === "weeks") {
      const weeks = hours;
      if (typeof weeks !== "number" || weeks < 1 || isNaN(weeks)) return "0";
      return Math.round(weeks * 7);
    }
  };

  const openActivateModal = (route) => () => {
    setIsActivateCronOpen(true);
    setActivateModalRoute(route);
  };

  const [structureData, setStructureData] = useState([]);
  const handleActivateModalClose = (data = null) => {
    if (data) {
      setStructureData(data);
    }

    setIsActivateCronOpen(false);
    setActivateModalRoute("");
  };

  return (
    <>
      <ActivateCronModal
        show={isActivateCronOpen}
        handleClose={handleActivateModalClose}
        route={activateModalRoute}
      />

      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center">
            <div className="col-sm-6">
              <div className="infmore">
                User Admin Level: {user && user.admin && user.admin.permission}
              </div>
            </div>

            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4">Database Scheduled Tasks</h2>

          <div className="infoContentCenter card mb-4">
            <div className="table-responsive">
              <table className="table table-responsive table-bordered table-dark-theme user-summary-table">
                <thead>
                  <tr>
                    <th>Task</th>
                    <th>Manual/auto</th>
                    <th>Execute Period Hours (days)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Submitting user verification schedule check</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={Boolean(parseInt(form.sub_user_auto))}
                            onChange={(e) =>
                              updateSingleDbVariable({
                                sub_user_auto: e.target.checked ? 1 : 0,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>

                        {Boolean(parseInt(form.sub_user_auto)) ? null : (
                          <button
                            className="btn btn-yellow"
                            disabled={fetchingVariables}
                            onClick={openActivateModal(
                              CRON_EXECUTE_ROUTES.subUser
                            )}
                          >
                            Execute
                          </button>
                        )}
                      </div>
                    </td>

                    <td>
                      <div className="d-flex flex-row align-items-center g-4">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="24"
                          value={form.sub_user_t}
                          onChange={(e) =>
                            onChange("sub_user_t", e.target.valueAsNumber)
                          }
                          min={1}
                          required
                        />{" "}
                        ({getNoOfDays(form.sub_user_t)})
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>User response timeout check</td>
                    <td>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    </td>
                    <td>168 (7)</td>
                  </tr>

                  <tr>
                    <td>Personal document removal</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={Boolean(
                              parseInt(form.personal_doc_delete_auto)
                            )}
                            onChange={(e) =>
                              updateSingleDbVariable({
                                personal_doc_delete_auto: e.target.checked
                                  ? 1
                                  : 0,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>

                        {Boolean(
                          parseInt(form.personal_doc_delete_auto)
                        ) ? null : (
                          <button
                            className="btn btn-yellow"
                            disabled={fetchingVariables}
                            onClick={openActivateModal(
                              CRON_EXECUTE_ROUTES.personalDocDelete
                            )}
                          >
                            Execute
                          </button>
                        )}
                      </div>
                    </td>

                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        placeholder="24"
                        value={form.personal_doc_delete_t}
                        onChange={(e) =>
                          onChange(
                            "personal_doc_delete_t",
                            e.target.valueAsNumber
                          )
                        }
                        required
                      />{" "}
                      ({getNoOfDays(form.personal_doc_delete_t)})
                    </td>
                  </tr>

                  <tr>
                    <td>Item record integrity check</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={Boolean(Number(form.item_integ_auto))}
                            onChange={(e) =>
                              updateSingleDbVariable({
                                item_integ_auto: e.target.checked ? 1 : 0,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>

                        {Boolean(Number(form.item_integ_auto)) ? null : (
                          <button
                            className="btn btn-yellow"
                            disabled={fetchingVariables}
                            onClick={openActivateModal(
                              CRON_EXECUTE_ROUTES.itemIntegCheck
                            )}
                          >
                            Execute
                          </button>
                        )}
                      </div>
                    </td>

                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        placeholder="24"
                        value={form.item_integ_t}
                        onChange={(e) =>
                          onChange("item_integ_t", e.target.valueAsNumber)
                        }
                        required
                      />{" "}
                      ({getNoOfDays(form.item_integ_t)})
                    </td>
                  </tr>

                  <tr>
                    <td>Category end structure</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={Boolean(
                              parseInt(form.category_end_structure_auto)
                            )}
                            onChange={(e) =>
                              updateSingleDbVariable({
                                category_end_structure_auto: e.target.checked
                                  ? 1
                                  : 0,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>

                        {Boolean(
                          parseInt(form.category_end_structure_auto)
                        ) ? null : (
                          <button
                            className="btn btn-yellow"
                            disabled={fetchingVariables}
                            onClick={openActivateModal(
                              CRON_EXECUTE_ROUTES.categoryEndStructure
                            )}
                          >
                            Execute
                          </button>
                        )}
                      </div>
                    </td>

                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        placeholder="24"
                        value={form.category_end_structure_t}
                        onChange={(e) =>
                          onChange(
                            "category_end_structure_t",
                            e.target.valueAsNumber
                          )
                        }
                        required
                      />{" "}
                      ({getNoOfDays(form.category_end_structure_t)})
                    </td>
                  </tr>

                  <tr>
                    <td>Admin user leaving</td>
                    <td>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    </td>
                    <td>168 (7)</td>
                  </tr>

                  <tr>
                    <td>User delete</td>
                    <td>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    </td>
                    <td>168 (7)</td>
                  </tr>

                  <tr>
                    <td>New user invitation</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={Boolean(parseInt(form.new_user_inv_auto))}
                            onChange={(e) =>
                              updateSingleDbVariable({
                                new_user_inv_auto: e.target.checked ? 1 : 0,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>

                        {Boolean(parseInt(form.new_user_inv_auto)) ? null : (
                          <button
                            className="btn btn-yellow"
                            disabled={fetchingVariables}
                            onClick={openActivateModal(
                              CRON_EXECUTE_ROUTES.newUserInvite
                            )}
                          >
                            Execute
                          </button>
                        )}
                      </div>
                    </td>
                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        placeholder="24"
                        value={form.new_user_t}
                        onChange={(e) =>
                          onChange("new_user_t", e.target.valueAsNumber)
                        }
                        required
                      />{" "}
                      ({Math.round(form.new_user_t / 24)})
                    </td>
                  </tr>

                  <tr>
                    <td>Quality transaction Report Delete</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={Boolean(parseInt(form.qlist_delete_auto))}
                            onChange={(e) =>
                              updateSingleDbVariable({
                                qlist_delete_auto: e.target.checked ? 1 : 0,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>

                        {Boolean(parseInt(form.qlist_delete_auto)) ? null : (
                          <button
                            className="btn btn-yellow"
                            disabled={fetchingVariables}
                            onClick={openActivateModal(
                              CRON_EXECUTE_ROUTES.qlistDelete
                            )}
                          >
                            Execute
                          </button>
                        )}
                      </div>
                    </td>

                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        placeholder="24"
                        value={form.qlist_delete_t}
                        onChange={(e) =>
                          onChange("qlist_delete_t", e.target.valueAsNumber)
                        }
                        required
                      />{" "}
                      ({Math.round(form.qlist_delete_t / 24)})
                    </td>
                  </tr>

                  <tr>
                    <td>Report Transaction</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={Boolean(parseInt(form.report_trans_auto))}
                            onChange={(e) =>
                              updateSingleDbVariable({
                                report_trans_auto: e.target.checked ? 1 : 0,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>

                        {Boolean(parseInt(form.report_trans_auto)) ? null : (
                          <button
                            className="btn btn-yellow"
                            disabled={fetchingVariables}
                            onClick={openActivateModal(
                              CRON_EXECUTE_ROUTES.reportTrans
                            )}
                          >
                            Execute
                          </button>
                        )}
                      </div>
                    </td>

                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        placeholder="24"
                        value={form.report_trans_t}
                        onChange={(e) =>
                          onChange("report_trans_t", e.target.valueAsNumber)
                        }
                        required
                      />{" "}
                      ({Math.round(form.report_trans_t / 24)})
                    </td>
                  </tr>

                  <tr>
                    <td>Out of use delay</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={Boolean(parseInt(form.use_auto))}
                            onChange={(e) =>
                              updateSingleDbVariable({
                                use_auto: e.target.checked ? 1 : 0,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>

                        {Boolean(parseInt(form.use_auto)) ? null : (
                          <button
                            className="btn btn-yellow"
                            disabled={fetchingVariables}
                            onClick={openActivateModal(
                              CRON_EXECUTE_ROUTES.outOfUse
                            )}
                          >
                            Execute
                          </button>
                        )}
                      </div>
                    </td>

                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        placeholder="24"
                        value={form.use_t}
                        onChange={(e) =>
                          onChange("use_t", e.target.valueAsNumber)
                        }
                        required
                      />{" "}
                      ({Math.round(form.use_t / 24)})
                    </td>
                  </tr>

                  <tr>
                    <td>Sales interest</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={Boolean(parseInt(form.interest_auto))}
                            onChange={(e) =>
                              updateSingleDbVariable({
                                interest_auto: e.target.checked ? 1 : 0,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>

                        {Boolean(parseInt(form.interest_auto)) ? null : (
                          <button
                            className="btn btn-yellow"
                            disabled={fetchingVariables}
                            onClick={openActivateModal(
                              CRON_EXECUTE_ROUTES.salesInterest
                            )}
                          >
                            Execute
                          </button>
                        )}
                      </div>
                    </td>

                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        placeholder="24"
                        value={form.interest_t}
                        onChange={(e) =>
                          onChange("interest_t", e.target.valueAsNumber)
                        }
                        required
                      />{" "}
                      ({Math.round(form.interest_t / 24)})
                    </td>
                  </tr>

                  <tr>
                    <td>Unit QPricing Update</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={Boolean(parseInt(form.qprice_auto))}
                            onChange={(e) =>
                              updateSingleDbVariable({
                                qprice_auto: e.target.checked ? 1 : 0,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>

                        {Boolean(parseInt(form.qprice_auto)) ? null : (
                          <button
                            className="btn btn-yellow"
                            disabled={fetchingVariables}
                            onClick={openActivateModal(
                              CRON_EXECUTE_ROUTES.qPriceUpdate
                            )}
                          >
                            Execute
                          </button>
                        )}
                      </div>
                    </td>

                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        placeholder="24"
                        value={form.qprice_t}
                        onChange={(e) =>
                          onChange("qprice_t", e.target.valueAsNumber)
                        }
                        required
                      />{" "}
                      ({Math.round(form.qprice_t / 24)})
                    </td>
                  </tr>

                  <tr>
                    <td>Unregistered Unit Remove</td>

                    <td>
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={Boolean(
                              parseInt(form.unreg_unit_delete_auto)
                            )}
                            onChange={(e) =>
                              updateSingleDbVariable({
                                unreg_unit_delete_auto: e.target.checked
                                  ? 1
                                  : 0,
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>

                        {Boolean(
                          parseInt(form.unreg_unit_delete_auto)
                        ) ? null : (
                          <button
                            className="btn btn-yellow"
                            disabled={fetchingVariables}
                            onClick={openActivateModal(
                              CRON_EXECUTE_ROUTES.unregisteredUnitDelete
                            )}
                          >
                            Execute
                          </button>
                        )}
                      </div>
                    </td>

                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        placeholder="24"
                        value={form.unreg_unit_delete_t}
                        onChange={(e) =>
                          onChange(
                            "unreg_unit_delete_t",
                            e.target.valueAsNumber
                          )
                        }
                        required
                      />{" "}
                      ({Math.round(form.unreg_unit_delete_t / 24)})
                    </td>
                  </tr>

                  {/** */}
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-4 text-center">
            <button
              type="submit"
              form="globalVariablesForm"
              className="btn btn-yellow me-2"
              disabled={loading}
              onClick={updateAllGlobalVariables}
            >
              {loading ? <LoadIndicator /> : "Update setting"}
            </button>

            <button
              disabled={loading}
              type="button"
              className="btn btn-black"
              onClick={goBack}
            >
              Go back
            </button>
          </div>

          {structureData.length > 0 && (
            <CategoryEndStructureTable data={structureData} />
          )}
        </div>
      </div>
    </>
  );
}

export default ScheduledTasks;
