import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import {
  selectModelInstructionData,
  setInstructions,
} from "redux-store/features/modelInstructionSlice";
import {
  selectModelManagementData,
  setSelectedAttribute,
  setSelectedModel,
  switchMode,
  updateSelectedAttribute,
} from "redux-store/features/modelManagementSlice";
import {
  initializeApplicationList,
  selectSoftwareApplicationManagementData,
} from "redux-store/features/softwareApplicationManagement";
import AdminService from "services/AdminServise";
import BrandService from "services/BrandService";
import CategoryService from "services/CategoryService";
import ModelInstructionService from "services/ModelInstructionService";
import ModelService from "services/ModelService";
import SWApplicationService from "services/SWApplicationService";
import { handleError, logger, useShortDisplay } from "shared/helpers";
import DescModal from "views/components/DescModal";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";
import { formatEnum } from "../brand-management/helpers";
import EditAttribute from "./EditAttribute";
import EditModel from "./EditModel";
import { modes, tabs } from "./helper";
import ViewAttributes from "./ViewAttributes";
import ViewModel from "./ViewModel";

function ModelManagement() {
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bottomRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  useShortDisplay(() => setErrors([]), 5000, errors.length > 0);

  useEffect(() => {
    if (errors.length > 0) {
      bottomRef.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [errors]);

  const { selectedModel, selectedAttribute, mode } = useSelector(
    selectModelManagementData
  );

  const { applications } = useSelector(selectSoftwareApplicationManagementData);

  useEffect(() => {
    if (selectedAttribute?.sw_application && applications.length > 0) {
      const app = applications.filter(
        (e) => e.sw_application_id == selectedAttribute.sw_application
      )[0];

      if (app) {
        dispatch(
          updateSelectedAttribute({
            key: "sw_application_name",
            value: app.name,
          })
        );
      }
    }
  }, [applications, selectedAttribute?.sw_application]);

  const [models, setModels] = useState([]);

  const [loadingModel, setLoadingModel] = useState(false);
  const fetchModels = async () => {
    setLoadingModel(true);

    try {
      const res = await ModelService.getAllModelsNoFilter(token, dispatch);

      setModels(res.data.data);
    } catch (error) {}

    setLoadingModel(false);
  };

  const [activeTab, setActiveTab] = useState(tabs.model);
  const switchActiveTab = (tab) => setActiveTab(tab);

  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      const res = await CategoryService.getAvailableCategories(token, dispatch);

      setCategories(res.data.data);
    } catch (error) {}
  };

  const fetchAttribute = async () => {
    try {
      const res = await AdminService.getAttribute(
        token,
        dispatch,
        selectedModel.generic_attributes
      );

      dispatch(setSelectedAttribute(res.data.data));
    } catch (error) {}
  };

  const [isAttributeUsed, setIsAttributeUsed] = useState(false);
  const fetchAttributeIsUsedInBrandModel = async () => {
    try {
      const res = await BrandService.getBrandModelRecordsByAttribute(
        token,
        dispatch,
        selectedAttribute.brand_model_attribute_id
      );

      setIsAttributeUsed(res.data.data?.length > 0);
    } catch (e) {
      logger("Error", e);
      setIsAttributeUsed(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (selectedAttribute && mounted) {
      fetchAttributeIsUsedInBrandModel();
    }
    return () => {
      mounted = false;
    };
  }, [selectedAttribute?.brand_model_attribute_id]);

  const { state } = useLocation();

  useEffect(() => {
    console.log(state);
    if (
      selectedModel &&
      selectedModel.generic_attributes &&
      (!state || state.from !== "select_application")
    ) {
      console.log("Fetch attributes");
      fetchAttribute();
    }

    if (state?.from === "select_application") {
      window.history.replaceState({}, document.title);
    }
  }, [selectedModel?.generic_attributes, state]);

  const [brandModels, setBrandModels] = useState([]);
  const [open_brand_models, set_open_brand_modelss] = useState(false);

  const closeBrandModels = () => {
    set_open_brand_modelss(false);
  };

  const openBrandModels = () => {
    set_open_brand_modelss(true);
  };

  const fetchModelBrandModels = async () => {
    try {
      const res = await BrandService.getBrandModelRecordsByModel(
        token,
        dispatch,
        selectedModel.model_id
      );

      setBrandModels(res.data.data);
    } catch (e) {}
  };

  useEffect(() => {
    let mounted = true;
    if (selectedModel && mounted) {
      // POPULATE USES FOR MODEL
      fetchModelBrandModels();
    }
    return () => {
      mounted = false;
    };
  }, [selectedModel?.model_id]);

  const onNewClick = () => {
    dispatch(switchMode(modes.new));
  };

  const onEditClicked = () => {
    dispatch(switchMode(modes.edit));
  };

  const [deleting, setDeleting] = useState(false);
  const onDeleteClick = async () => {
    if (selectedModel) {
      setLoading(true);
      setDeleting(true);

      try {
        const res = await ModelService.deleteModel(
          token,
          dispatch,
          selectedModel.model_id
        );

        if (res.data.success) {
          dispatch(setSelectedModel({}));
          dispatch(setSelectedAttribute({}));
          dispatch(switchMode(modes.view));
        }
      } catch (error) {
        handleError(error, setErrors);
      }

      setLoading(false);
      setDeleting(false);
    }
  };

  const handleCancel = () => {
    if (mode != modes.view) dispatch(switchMode(modes.view));
    else navigate(-1);
  };

  const validateModelData = (exclude = []) => {
    const errors = [];
    const data = {};

    console.log(selectedModel);

    const required = ["model_id", "generic_model", "model_name_des"];

    required.forEach((item) => {
      if (!exclude.includes(item)) {
        if (
          typeof selectedModel[item] == "undefined" ||
          selectedModel[item] === null ||
          selectedModel[item] === ""
        ) {
          errors.push(`${formatEnum(item)} is required`);
        } else {
          data[item] = selectedModel[item];
        }
      }
    });

    if (selectedModel.model_code) data.model_code = selectedModel.model_code;

    return errors.length > 0 ? { errors } : { data };
  };

  const validateAttributeData = () => {
    const errors = [];
    const data = {};

    const required = [
      "end_category",
      "item_class",
      "serial_type",
      "personalized",
      "serviceable",
      "software",
      "prevent_use_overlap",
      "os_sw_updateable",
      "transferable",
      "private",
      "serial_instructions",
      "serial_uniqueness",
    ];

    required.forEach((item, index) => {
      if (
        typeof selectedAttribute[item] === "undefined" ||
        selectedAttribute[item] === null ||
        selectedAttribute[item] === ""
      ) {
        if (index > 2) {
          data[item] = 0;
        } else errors.push(`${formatEnum(item)} is required`);
      } else {
        data[item] = selectedAttribute[item];
      }
    });

    if (
      selectedAttribute.sw_application &&
      typeof selectedAttribute.sw_application === "number" &&
      selectedAttribute.sw_application > 0
    ) {
      data.sw_application = selectedAttribute.sw_application;
    }

    return errors.length > 0 ? { errors } : { data };
  };

  const handleUpdateModel = async () => {
    if (selectedModel) {
      let payload = new FormData();

      const { errors, data } = validateModelData();

      if (errors) return setErrors(errors);

      for (const key of Object.keys(data)) {
        payload.append(key, data[key]);
      }

      payload.append("model_notes", selectedModel.model_notes);
      payload.append("generic_attributes", selectedModel.generic_attributes);

      if (data.generic_model == 1) {
        const { errors, data } = validateAttributeData();

        if (errors) return setErrors(errors);

        for (const key of Object.keys(data)) {
          payload.append(key, data[key]);
        }
      }

      setLoading(true);

      try {
        const res = await ModelService.updateModel(token, dispatch, payload);

        if (res.data.success) {
          dispatch(setSelectedModel(res.data.data));
          dispatch(switchMode(modes.view));
          fetchModels();
        }
      } catch (error) {
        logger("Error", error);
        handleError(error, setErrors);
      }

      setLoading(false);
    }
  };

  const handleNewModel = async () => {
    let payload = new FormData();

    const { errors, data } = validateModelData(["model_id"]);

    if (errors) return setErrors(errors);

    for (const key of Object.keys(data)) {
      payload.append(key, data[key]);
    }

    payload.append("model_notes", selectedModel.model_notes);
    payload.append("generic_attributes", selectedModel.generic_attributes);

    if (data.generic_model == 1) {
      const { errors, data } = validateAttributeData();

      if (errors) return setErrors(errors);

      for (const key of Object.keys(data)) {
        payload.append(key, data[key]);
      }
    }

    setLoading(true);

    try {
      const res = await ModelService.newModelEntry(token, dispatch, payload);

      if (res.data.success) {
        dispatch(setSelectedModel(res.data.data));
        dispatch(setSelectedAttribute(res.data.attribute));
        dispatch(switchMode(modes.view));
      }
    } catch (error) {
      logger("Error", error);
      handleError(error, setErrors);
    }

    setLoading(false);
  };

  const handleSubmit = () => {
    switch (mode) {
      case modes.edit:
        handleUpdateModel();
        break;

      case modes.new:
        handleNewModel();
        break;

      default:
        break;
    }
  };

  const { initialized } = useSelector(selectModelInstructionData);
  const instructionService = new ModelInstructionService(dispatch, token);
  const fetchInstructions = async () => {
    try {
      const res = await instructionService.fetchAll();

      dispatch(setInstructions(res.data.data));
    } catch (error) {
      logger("Error ", error);
    }
  };

  const { initialized: applicationsInitialized } = useSelector(
    selectSoftwareApplicationManagementData
  );
  const applicationService = new SWApplicationService(dispatch, token);
  const fetchApplications = async () => {
    try {
      const res = await applicationService.fetchAll();

      dispatch(initializeApplicationList(res.data.data));
    } catch (error) {
      logger("Error ", error);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchModels();
      fetchCategories();
    }

    if (!initialized) fetchInstructions();

    if (!applicationsInitialized) fetchApplications();

    const tab = new URLSearchParams(window.location.search).get("tab");

    if (tab) {
      switchActiveTab(tab);
    }

    return () => {
      mounted = false;
      dispatch(setSelectedModel(null));
    };
  }, []);

  return (
    <>
      <DescModal
        show={open_brand_models}
        handleClose={closeBrandModels}
        children={
          <div>
            <h5>Brand Models</h5>

            <ul>
              {brandModels.map((item) => (
                <li key={"brand-models-available-sd-" + item.brand_model_id}>
                  <p>
                    {item.brand_model_id} {item.brand}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        }
      />

      <div id="main-container">
        <div className="container">
          {/** USER ADMIN LEVEL */}
          <div className="row mb-4 justify-content-center">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4">Professional Model Management</h2>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Select Model ID:</label>
            </div>

            <div className="col-md-9">
              <select
                className="form-select"
                value={selectedModel?.model_id || ""}
                defaultValue=""
                onChange={(e) => {
                  const model = models.filter(
                    (item) => item.model_id == e.target.value
                  )[0];

                  dispatch(setSelectedModel(model));
                }}
                disabled={loadingModel || mode != modes.view}
              >
                <option disabled value="">
                  Select model ID
                </option>
                {models.map((model) => (
                  <option
                    key={"model-id-" + model.model_id}
                    value={model.model_id}
                  >
                    {model.model_id}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Select Model:</label>
            </div>

            <div className="col-md-9">
              <select
                className="form-select"
                value={selectedModel?.model_id || ""}
                defaultValue=""
                onChange={(e) => {
                  const model = models.filter(
                    (item) => item.model_id == e.target.value
                  )[0];

                  dispatch(setSelectedModel(model));
                }}
                disabled={loadingModel || mode != modes.view}
              >
                <option disabled value="">
                  Select model
                </option>
                {models.map((model) => (
                  <option
                    key={"model-" + model.model_id}
                    value={model.model_id}
                  >
                    {model.model_name_des}, {model.model_code}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Uses:</label>
            </div>

            <div className="col-md-9">
              <div className="row align-items-center">
                <p>
                  {brandModels.length}{" "}
                  <a onClick={openBrandModels} className="infolink">
                    See details
                  </a>
                </p>
              </div>
            </div>
          </div>

          {/** */}
          <div className="infoContentCenter card mb-4">
            {/** TABS */}
            <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeTab === tabs.model ? "active" : undefined
                  }`}
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Model"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={() => switchActiveTab(tabs.model)}
                >
                  Model {selectedModel?.model_id || "Null"}
                </button>
              </li>
              {selectedModel?.generic_model != 0 && (
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === tabs.attributes ? "active" : undefined
                    }`}
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Attributes"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    onClick={() => switchActiveTab(tabs.attributes)}
                  >
                    Attributes {selectedModel?.generic_attributes || "Null"}
                  </button>
                </li>
              )}
            </ul>

            {/** BODY */}
            <div className="tab-content" id="myTabContent">
              {activeTab === tabs.model && (
                <div
                  className={`tab-pane fade ${
                    activeTab === tabs.model ? "show active" : undefined
                  }`}
                  id="Model"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {mode === modes.view && <ViewModel />}
                  {mode != modes.view && (
                    <EditModel
                      isModelUsedInBrandModel={brandModels.length > 0}
                      isAttributeUsed={isAttributeUsed}
                    />
                  )}
                </div>
              )}

              {activeTab === tabs.attributes && (
                <div
                  className={`tab-pane fade ${
                    activeTab === tabs.attributes ? "show active" : undefined
                  }`}
                  id="Attributes"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {mode === modes.view && <ViewAttributes />}
                  {mode != modes.view && (
                    <EditAttribute categories={categories} />
                  )}
                </div>
              )}
            </div>

            <div ref={bottomRef} />
            {errors.length > 0 && <ErrorsComponent errors={errors} />}

            {/** ACTIONS */}
            <div className="btn-group-child mb-5">
              <button
                type="button"
                className="btn btn-yellow me-3"
                disabled={mode != modes.view || loading}
                onClick={onNewClick}
              >
                <i className="bi bi-plus-circle"></i> Create New
              </button>

              <button
                type="button"
                className="btn btn-yellow me-3"
                disabled={mode != modes.view || !selectedModel || loading}
                onClick={onEditClicked}
              >
                <i className="bi bi-pencil"></i> Edit
              </button>

              <button
                type="button"
                className="btn btn-yellow me-3"
                disabled={
                  !selectedModel ||
                  mode != modes.view ||
                  loading ||
                  brandModels.length > 0 ||
                  (selectedModel.generic_model == 1 && isAttributeUsed) ||
                  deleting
                }
                onClick={onDeleteClick}
              >
                <i className="bi bi-trash"></i>{" "}
                {deleting ? <LoadIndicator /> : "Delete"}
              </button>
            </div>
          </div>

          <div className="mt-4 text-center">
            <button
              onClick={handleCancel}
              disabled={loading}
              className="btn btn-black me-2"
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-yellow"
              disabled={mode === modes.view || loading}
              onClick={handleSubmit}
            >
              {loading ? <LoadIndicator /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModelManagement;
