import { createSlice } from "@reduxjs/toolkit";
// import { logger } from "shared/helpers";
import { modes, tabs } from "shared/b_m_helper";

const initialState = {
  mode: modes.view,
  attributesMode: modes.view,
  activeTab: tabs.brand_model,
  categories: [],
  unverifiedBrandModels: [],
  selectedCategory: {},
  selectedBrandModel: {},
  selectedBrand: {},
  selectedModel: {},
  brandModelForm: {},
  prefilledEndCategory: [],
};

export const brandModelManagement = createSlice({
  name: "brandModelManagement",
  initialState,
  reducers: {
    setSelectedBrand: (state, action) => {
      state.selectedBrand = action.payload;
    },

    clearSelectedBrand: (state) => {
      state.selectedBrand = {};
    },

    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },

    clearSelectedModel: (state) => {
      state.selectedModel = {};
    },

    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },

    clearSelectedCategory: (state) => {
      state.selectedCategory = {};
    },

    setSelectedBrandModel: (state, action) => {
      state.selectedBrandModel = action.payload;
    },

    clearSelectedBrandModel: (state) => {
      state.selectedBrandModel = {};
    },

    setCategories: (state, action) => {
      state.categories = action.payload;
    },

    clearCategories: (state) => {
      state.categories = [];
    },

    setBrandModelForm: (state, action) => {
      state.brandModelForm = {
        ...state.brandModelForm,
        [action.payload.key]: action.payload.value,
      };
    },

    clearBrandModelForm: (state) => {
      state.brandModelForm = {};
    },

    setUnverifiedBrandModel: (state, action) => {
      state.unverifiedBrandModels = action.payload;
    },

    clearUnverifiedBrandModel: (state) => {
      state.unverifiedBrandModels = [];
    },

    switchAttributesMode: (state, action) => {
      state.attributesMode = action.payload;

      if (action.payload === modes.new_brand_model) {
        state.selectedBrandModel = {
          attributes: null,
          brand_model_id: null,
          brand: "",
          company_id: "none",
          company_name: "",
          brand_created_by: "",
          model_code: "",
          model_name_des: "",
          model_notes: "",
          generic_model: 0,
          generic_attributes: "",
          os_sw_updateable: 0,
          personalized: 0,
          serviceable: 0,
          software: 0,
          prevent_use_overlap: 0,
          transferable: 0,
          private: 0,
          item_class: "Multiple use product",
          serial_type: "None",
          serial_instructions: null,
          serial_uniqueness: null,
        };
      }
    },

    switchTab: (state, action) => {
      state.activeTab = action.payload;
    },

    switchMode: (state, action) => {
      state.mode = action.payload;
      if (action.payload === modes.new_brand) {
        state.selectedBrandModel = {
          ...state.selectedBrandModel,
          brand: "",
          company_id: "none",
          company_name: "",
          brand_created_by: "",
        };
      } else if (action.payload === modes.new_model) {
        state.selectedBrandModel = {
          ...state.selectedBrandModel,
          model_code: "",
          model_name_des: "",
          model_notes: "",
          generic_model: 0,
          generic_attributes: "",
          model_owner: "",
        };
      } else if (action.payload === modes.new_brand_model) {
        state.selectedBrandModel = {
          brand_model_id: null,
          attributes: null,
          brand: "",
          company_id: "none",
          company_name: "",
          brand_created_by: "",
          model_code: "",
          model_name_des: "",
          model_notes: "",
          generic_model: 0,
          generic_attributes: "",
          os_sw_updateable: 0,
          personalized: 0,
          serviceable: 0,
          software: 0,
          prevent_use_overlap: 0,
          transferable: 0,
          private: 0,
          item_class: "Multiple use product",
          serial_type: "None",
          serial_instructions: null,
          serial_uniqueness: null,
          status: null,
        };
      }
    },

    updateSelectedBrandModel: (state, action) => {
      state.selectedBrandModel = {
        ...state.selectedBrandModel,
        [action.payload.key]: action.payload.value,
      };
    },

    updateSelectedBrandModelV2: (state, action) => {
      state.selectedBrandModel = {
        ...state.selectedBrandModel,
        ...action.payload,
      };
    },

    prefillEndCategory: (state, action) => {
      state.prefilledEndCategory = action.payload;
    },

    addToPrefillCategory: (state, action) => {
      state.prefilledEndCategory = [
        ...state.prefilledEndCategory,
        action.payload,
      ];
    },

    clearPrefilledEndCategory: (state) => {
      state.prefilledEndCategory = [];
    },
  },
});

export const {
  setSelectedCategory,
  clearSelectedCategory,
  setCategories,
  clearCategories,
  setBrandModelForm,
  clearBrandModelForm,
  setUnverifiedBrandModel,
  clearUnverifiedBrandModel,
  setSelectedBrandModel,
  clearSelectedBrandModel,
  setSelectedBrand,
  clearSelectedBrand,
  setSelectedModel,
  clearSelectedModel,
  switchMode,
  updateSelectedBrandModel,
  updateSelectedBrandModelV2,
  switchAttributesMode,
  switchTab,
  prefillEndCategory,
  addToPrefillCategory,
  clearPrefilledEndCategory,
} = brandModelManagement.actions;

export const selectBrandModelManagementData = (state) => ({
  ...state.brandModelManagement,
});

export default brandModelManagement.reducer;
