import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import {
  selectBrandModelManagementData,
  setSelectedBrandModel,
} from "redux-store/features/brandModelManagement";
import AdminService from "services/AdminServise";
import { handleError, useShortDisplay } from "shared/helpers";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";

function QueryModal({ show = false, handleClose = () => {} }) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  useShortDisplay(() => setErrors([]), 5000, errors.length > 0);

  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const { selectedBrandModel } = useSelector(selectBrandModelManagementData);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedBrandModel) {
      return setErrors(["No brand model selected"]);
    }

    setLoading(true);

    try {
      const reason = e.target.elements.reason.value;

      const data = {
        brand_model_id: selectedBrandModel.brand_model_id,
        reason,
      };

      const res = await AdminService.queryBrandModel(token, dispatch, data);

      dispatch(setSelectedBrandModel(res.data.data));
      // dispatch(addMail({ data: res.data.mail, type: "sent" }));

      handleClose(true);
    } catch (error) {
      handleError(error, setErrors);
    }

    setLoading(false);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>Query</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
        <div className="container px-4">
          <div className="row mb-4">
            <div className="col-md-12">
              <form id="reasonForm" onSubmit={handleSubmit}>
                <textarea
                  name="reason"
                  id="reason"
                  placeholder="Reason for query..."
                  required
                  className="textarea-white"
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    padding: 8,
                  }}
                  maxLength="255"
                />
              </form>
            </div>
          </div>

          <ErrorsComponent errors={errors} />
        </div>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>

        <Button
          type="submit"
          form="reasonForm"
          variant="primary"
          disabled={loading}
        >
          {loading ? <LoadIndicator /> : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default QueryModal;
