import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth from "./auth";
import Dashboard from "./dashboard";
//import Client from './client';
import Logout from "./logout";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route index element={<Auth />} /> */}
        <Route path="auth/*" element={<Auth />} />
        <Route path="signout" element={<Logout />} />
        <Route path="/*" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
