import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCategoryManagementData } from "redux-store/features/categoryManagement";

const ViewCategory = ({ uses = [], openUses = () => {} }) => {
  const { categoryManagementForm: form } = useSelector(
    selectCategoryManagementData
  );

  useEffect(() => {
    console.log(form?.parent_category);
  }, [form?.parent_category]);

  return (
    <div className="fadeIn">
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Category ID</label>
        </div>

        <div className="col-md-9">{form?.category_id || "Null"}</div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Uses</label>
        </div>

        <div className="col-md-9">
          <div className="row align-items-center">
            <p>
              {uses.length}{" "}
              <a onClick={openUses} className="infolink">
                List uses
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Parent</label>
        </div>

        <div className="col-md-9">{form?.parent_category_name || "Null"}</div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Public Name</label>
        </div>

        <div className="col-md-9">
          <p>{form?.name || "Null"}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Public Description</label>
        </div>

        <div className="col-md-9">
          <p>{form?.description || "Null"}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Minimum Age</label>
        </div>

        <div className="col-md-9">
          <p>{form?.age_restriction || "Null"}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Allow End category</label>
        </div>

        <div className="col-md-9">
          <p>{form?.allow_end_category === 1 ? "Yes" : "No"}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">End category</label>
        </div>

        <div className="col-md-9">
          <p>{form.end === 1 ? "Yes" : "No"}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Verified Date</label>
        </div>

        <div className="col-md-9">
          <p>{form.verified_date ? form.verified_date : "-"}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Owner</label>
        </div>

        <div className="col-md-9">
          <p>{form.professional_id || "-"}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Data Sales (N)</label>
        </div>

        <div className="col-md-9">
          <p>{form.professional_sales?.toString() || "Null"}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Technical Note</label>
        </div>

        <div className="col-md-9">
          <p>{form.technical_notes || "Null"}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewCategory;
