import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken } from "redux-store/features/authSlice";
import {
  clearSelectedBrandModel,
  selectBrandModelManagementData,
  setSelectedBrand,
  setSelectedBrandModel,
  setSelectedCategory,
  setSelectedModel,
  setUnverifiedBrandModel,
  switchAttributesMode,
  switchMode,
  switchTab,
  updateSelectedBrandModel,
  updateSelectedBrandModelV2,
} from "redux-store/features/brandModelManagement";
import {
  selectModelInstructionData,
  setInstructions,
} from "redux-store/features/modelInstructionSlice";
import AdminService from "services/AdminServise";
import AssetService from "services/AssetService";
import BrandService from "services/BrandService";
import ModelInstructionService from "services/ModelInstructionService";
import { brandModelSpecific, modes } from "shared/b_m_helper";
import { handleError, logger } from "shared/helpers";
import { useCompany } from "./useCompany";
import ModelService from "services/ModelService";
import CategoryService from "services/CategoryService";
import { useMultiSelect } from "shared/useMultiSelect";

export const useBrandModelMgmt = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const instructionService = new ModelInstructionService(dispatch, token);
  const assetService = new AssetService(dispatch, token);

  const goBack = () => navigate(-1);

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [idSearch, setIdSearch] = useState("");
  const [searching, setSearching] = useState(false);

  const [uses, setUses] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandUses, setBrandUses] = useState([]);
  const [models, setModels] = useState([]);
  const [modelUses, setModelUses] = useState([]);

  const {
    mode,
    selectedCategory,
    selectedBrand,
    selectedBrandModel,
    attributesMode,
    selectedModel,
  } = useSelector(selectBrandModelManagementData);
  const { initialized: instructionsInit } = useSelector(
    selectModelInstructionData
  );
  const { all: companies } = useCompany();

  const httpGetBrandUses = async () => {
    const brandUsesData =
      (
        await BrandService.getBrandModelBrandUses(token, dispatch, {
          brand_model: selectedBrandModel.brand_model_id,
          brand: selectedBrandModel.brand_id,
        })
      ).data?.data || [];

    setBrandUses(brandUsesData);
  };

  const httpGetModelUses = async () => {
    try {
      const modelUsesData =
        (
          await ModelService.getBrandModelModelUses(token, dispatch, {
            brand_model: selectedBrandModel.brand_model_id,
            model: selectedBrandModel.model_id,
          })
        ).data?.data || 0;

      setModelUses(modelUsesData);
    } catch (error) {}
  };

  const httpViewBrandModel = async (id) => {
    try {
      const res = await AdminService.viewBrandModel(token, dispatch, id);

      const brandModel = res.data.data;

      dispatch(
        updateSelectedBrandModel({
          key: "seconds_left",
          value: brandModel.seconds_left,
        })
      );
      dispatch(
        updateSelectedBrandModel({
          key: "locked_by",
          value: brandModel.locked_by,
        })
      );
      dispatch(
        updateSelectedBrandModel({
          key: "locked_on",
          value: brandModel.locked_on,
        })
      );
    } catch (error) {}
  };

  const httpUnlockBrandModel = async () => {
    try {
      await AdminService.unlockBrandModel(token, dispatch);
      dispatch(updateSelectedBrandModel({ key: "locked_on", value: null }));
      dispatch(updateSelectedBrandModel({ key: "locked_by", value: null }));
      dispatch(updateSelectedBrandModel({ key: "locked_by_2", value: null }));
    } catch (error) {}
  };

  const httpGetInstructions = async () => {
    try {
      const res = await instructionService.fetchAll();

      dispatch(setInstructions(res.data.data));
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const httpGetUnverifiedBrandModels = async () => {
    try {
      const res = await AdminService.fetchUnverifiedBrandModel(
        token,
        dispatch,
        selectedCategory.category_id
      );

      if (res.data.success) {
        // Perform action
        dispatch(setUnverifiedBrandModel(res.data.data));
      }
    } catch (e) {}
  };

  const httpGetBrandModelById = async (id) => {
    setSearching(true);

    try {
      const { data } = await AdminService.fetchBrandModelById(
        token,
        dispatch,
        id
      );

      if (!data.data) {
        setErrors(["No record found!"]);
      } else {
        setErrors([]);
        setIdSearch("");
        dispatch(setSelectedBrandModel(data.data));
        dispatch(switchMode(modes.view));
        dispatch(switchAttributesMode(modes.view));
        // fetchCurrentCategoryTree(data.data.end_category);
      }
    } catch (error) {
      handleError(error, setErrors);
    }

    setSearching(false);
  };

  const httpGetBrands = async () => {
    try {
      const res = await BrandService.getBrandsForCategory(
        token,
        dispatch,
        selectedCategory.category_id
      );

      setBrands(res.data.data);
    } catch (e) {}
  };

  const httpGetModels = async () => {
    try {
      const res = await BrandService.getAllModels(
        token,
        dispatch,
        selectedBrand.brand_id,
        selectedCategory.category_id
      );

      setModels(res.data.data);
    } catch (e) {}
  };

  const httpGetBMUses = async () => {
    try {
      const res = await AdminService.fetchBrandModelUses(
        token,
        dispatch,
        selectedBrandModel.brand_model_id
      );

      setUses(res.data.data);
    } catch (e) {
      console.log("Error fetching uses", e);
    }
  };

  const handleSearchCategoryChange = (category) => {
    if (!category) return;

    dispatch(setSelectedCategory(category));
  };

  const handleSearchBrandChange = (brand) => {
    dispatch(setSelectedBrand(brand));
    dispatch(setSelectedModel({}));
    // TODO: clear model select input
  };

  const handleSearchModelChange = (model) => dispatch(setSelectedModel(model));

  const httpGetBrandModel = async () => {
    try {
      const res = await AdminService.fetchBrandModelByBrandAndModel(
        token,
        dispatch,
        selectedBrand.brand_id,
        selectedModel.model_id
      );

      dispatch(setSelectedBrandModel(res.data.data));
      dispatch(switchMode(modes.view));
      dispatch(switchAttributesMode(modes.view));
    } catch (e) {}
  };

  const handleGetBrandModelById = (id) => {
    if ((!selectedBrandModel || !selectedBrandModel.brand_model_id) && !id)
      return;

    attributeEditMultiSelect.userInteraction.current = false;
    httpGetBrandModelById(id || selectedBrandModel.brand_model_id);
  };

  const handleIdSearchChange = (e) => setIdSearch(e.target.value);

  const switchActiveTab = (tab) => dispatch(switchTab(tab));

  const handleOpenSerialInstruction = async (location) => {
    if (!location) return;
    try {
      const res = await assetService.getAssetUrl(location);

      window.open(res.data.data, "_blank");
    } catch (error) {}
  };

  const handleNewClick = () => {
    dispatch(switchMode(modes.new_brand_model));
    dispatch(switchAttributesMode(modes.new_brand_model));
    dispatch(setSelectedBrand({}));
    dispatch(setSelectedModel({}));
    // TODO: reset model & brand input
  };

  const handleEditClick = () => {
    httpViewBrandModel(selectedBrandModel?.brand_model_id);
    dispatch(switchMode(modes.edit));
    dispatch(switchAttributesMode(modes.edit));
  };

  const handleNewBrandClick = () => {
    if (mode === modes.new_brand_model) {
      dispatch(updateSelectedBrandModel({ key: "brand_id", value: null }));
    } else dispatch(switchMode(modes.new_brand));
  };

  const handleNewModelClick = () => {
    if (mode === modes.new_model) return;

    if (mode === modes.new_brand_model) {
      dispatch(updateSelectedBrandModel({ key: "model_id", value: null }));
    } else {
      dispatch(switchMode(modes.new_model));
    }
  };

  const handleEditFormChange = (key) => (e) => {
    dispatch(updateSelectedBrandModel({ key, value: e.target.value }));
  };

  const onBrandIdChange = async (value) => {
    try {
      // fetch brand by id
      const res = await BrandService.fetchById(token, dispatch, value);
      const brand = res.data.data;

      // update selected bramd model fields
      if (res.data.success && res.data.data) {
        dispatch(
          updateSelectedBrandModel({
            key: "as_company",
            value: brand.as_company,
          })
        );

        dispatch(
          updateSelectedBrandModel({
            key: "brand_owner",
            value: brand.brand_owner,
          })
        );

        dispatch(
          updateSelectedBrandModel({
            key: "brand",
            value: brand.brand,
          })
        );

        dispatch(
          updateSelectedBrandModel({
            key: "company_name",
            value: brand.company_name,
          })
        );

        if (!brand.company_id) {
          brand.company_id = "none";
        }

        dispatch(
          updateSelectedBrandModel({
            key: "company_id",
            value: brand.company_id,
          })
        );

        dispatch(
          updateSelectedBrandModel({
            key: "created_by_number",
            value: brand.created_by_number,
          })
        );

        dispatch(
          updateSelectedBrandModel({
            key: "brand_status",
            value: brand.status,
          })
        );

        dispatch(
          updateSelectedBrandModel({
            key: "brand_verified_date",
            value: brand.verified_date,
          })
        );

        if (mode === modes.new_brand_model) {
          dispatch(
            updateSelectedBrandModel({
              key: "brand_id",
              value: res.data.data.brand_id,
            })
          );
        }
      }
    } catch (error) {}
  };

  const changeAttribute = async (value) => {
    if (value) {
      for (const key of Object.keys(value)) {
        if (
          typeof selectedBrandModel[key] !== "undefined" &&
          !brandModelSpecific.includes(key)
        ) {
          dispatch(updateSelectedBrandModel({ key, value: value[key] }));
        }
      }
    }

    if (attributesMode === modes.new_brand_model) {
      if (value)
        dispatch(
          updateSelectedBrandModel({
            key: "brand_model_attribute_id",
            value: value.brand_model_attribute_id,
          })
        );
      else
        dispatch(
          updateSelectedBrandModel({
            key: "brand_model_attribute_id",
            value: undefined,
          })
        );
    }
  };

  const onAttributeChange = async (value) => {
    if (value === "new") {
      handleEditFormChange("brand_model_attribute_id")({
        target: { value: null },
      });

      dispatch(
        updateSelectedBrandModelV2({
          attributes: "",
          end_category: "",
          item_class: "",
          os_sw_updateable: "",
          sw_application: null,
          serial_type: "",
          serial_instructions: null,
          personalized: null,
          serial_uniqueness: null,
          serviceable: null,
          software: null,
          prevent_use_overlap: null,
          transferable: 0,
          private: null,
        })
      );

      // attributeEditMultiSelect.fill([]);

      return;
    }

    try {
      // fetch brand by id
      const res = await AdminService.getAttribute(token, dispatch, value);

      // update selected bramd model fields
      if (res.data.success && res.data.data) {
        handleEditFormChange("attributes")({ target: { value } });

        // attributeEditMultiSelect.fill(res.data.data.prefilled);

        for (const key of Object.keys(res.data.data)) {
          if (
            // typeof selectedBrandModel[key] !==  "undefined" &&
            ![...brandModelSpecific, "prefilled"].includes(key)
          ) {
            handleEditFormChange(key)({
              target: { value: res.data.data[key] },
            });
          }
        }
      }

      if (attributesMode === modes.new_brand_model) {
        handleEditFormChange("brand_model_attribute_id")({
          target: { value: res.data.data.brand_model_attribute_id },
        });
      }
    } catch (e) {}
  };

  const attributeEditMultiSelect = useMultiSelect({
    value: selectedBrandModel?.end_category || "",
    setValue: (value) => {
      dispatch(
        updateSelectedBrandModel({
          key: "end_category",
          value: value.category_id,
        })
      );
    },
  });

  const [showModelMismatchModal, setShowModelMismatchModal] = useState(false);
  const [modelData, setModelData] = useState(null);
  const [genericModelCategory, setGenericModelCategory] = useState(null);
  const [selectedCategoryPrior, setSelectedCategoryPrior] = useState(null);
  const [selectedAttributePrior, setSelectedAttributePrior] = useState(null);

  const openMismatchModal = async (generic_category, data, attribute) => {
    if (!generic_category || !selectedBrandModel) return;

    setModelData(data);
    setSelectedAttributePrior(attribute);

    const x = (
      await CategoryService.getCategory(token, dispatch, generic_category)
    )?.data.data;

    if (!x) return;

    setShowModelMismatchModal(true);

    setGenericModelCategory(x);

    const y = (
      await CategoryService.getCategory(
        token,
        dispatch,
        selectedBrandModel.end_category
      )
    )?.data.data;

    setSelectedCategoryPrior(y);
  };

  const closeMismatchModal = (override = false) => {
    if (override === true) {
      // change category selection and set model
      if (modelData) {
        for (const key of Object.keys(modelData)) {
          if (!brandModelSpecific.includes(key)) {
            dispatch(updateSelectedBrandModel({ key, value: modelData[key] }));
          }
        }
      }

      // handleSearchCategoryChange(genericModelCategory);
      attributeEditMultiSelect.userInteraction.current = false;
      dispatch(
        updateSelectedBrandModel({
          key: "end_category",
          value: genericModelCategory.category_id,
        })
      );

      changeAttribute(selectedAttributePrior);

      if (mode === modes.new_brand_model) {
        dispatch(
          updateSelectedBrandModel({
            key: "model_id",
            value: modelData.model_id,
          })
        );
      }
    } else {
      // setModelText(selectedBrandModel.model_name_des);
    }

    setGenericModelCategory(null);
    setSelectedCategoryPrior(null);
    setModelData(null);
    setSelectedAttributePrior(null);
    setShowModelMismatchModal(false);
  };

  const fetchCurrentCategoryTree = async (id) => {
    try {
      // const res = await CategoryService.getParentTree(token, dispatch, id);
      // const prefill = [...res.data.data, id];
      // attributeEditMultiSelect.fill(prefill);
    } catch (e) {}
  };

  const onModelIdChange = async (value) => {
    if (!selectedBrandModel) return;

    try {
      // fetch model by id
      const res = await ModelService.fetchById(token, dispatch, value);

      const model = res.data?.data;

      // update selected brand model fields
      if (Boolean(model)) {
        let attribute = (
          await AdminService.getAttribute(
            token,
            dispatch,
            selectedBrandModel.attributes
          )
        )?.data?.data;

        if (model.generic_model === 1) {
          handleEditFormChange("attributes")({
            target: { value: model.generic_attributes },
          });

          attribute = (
            await AdminService.getAttribute(
              token,
              dispatch,
              model.generic_attributes
            )
          )?.data?.data;

          if (
            attribute &&
            selectedBrandModel.end_category !== attribute.end_category
          ) {
            // open model to show generic model is not of same category as selected brand model
            openMismatchModal(attribute.end_category, res.data.data, attribute);
            return;
          } else {
            changeAttribute(attribute);

            for (const key of Object.keys(model)) {
              if (
                typeof selectedBrandModel[key] !== "undefined" &&
                !brandModelSpecific.includes(key)
              )
                dispatch(updateSelectedBrandModel({ key, value: model[key] }));
            }

            if (mode === modes.new_brand_model) {
              dispatch(
                updateSelectedBrandModel({
                  key: "model_id",
                  value: model.model_id,
                })
              );
            }
          }
        } else if (
          selectedBrandModel.generic_model == 1 &&
          model.generic_model == 0
        ) {
          changeAttribute(attribute);

          for (const key of Object.keys(model)) {
            if (!brandModelSpecific.includes(key))
              dispatch(updateSelectedBrandModel({ key, value: model[key] }));
          }

          if (mode === modes.new_brand_model) {
            dispatch(
              updateSelectedBrandModel({
                key: "model_id",
                value: model.model_id,
              })
            );
          }
        } else {
          for (const key of Object.keys(model)) {
            if (!brandModelSpecific.includes(key))
              dispatch(updateSelectedBrandModel({ key, value: model[key] }));
          }

          dispatch(
            updateSelectedBrandModel({
              key: "model_id",
              value: model.model_id,
            })
          );
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const clearErrors = () => setErrors([]);
  const goToEditMode = () => dispatch(switchMode(modes.edit));
  const goToNewBrandModelMode = () =>
    dispatch(switchMode(modes.new_brand_model));

  useEffect(() => {
    if (selectedCategory && Object.keys(selectedCategory).length > 0) {
      httpGetUnverifiedBrandModels();

      if (selectedCategory.category_id) {
        httpGetBrands();
      }
    }
  }, [selectedCategory?.category_id]);

  useEffect(() => {
    if (selectedBrand && Object.keys(selectedBrand).length > 0) {
      httpGetModels();
    }
  }, [selectedBrand?.brand_id]);

  const modeRef = useRef(mode);
  useEffect(() => {
    modeRef.current = mode;
  }, [mode]);

  useEffect(() => {
    if (selectedBrandModel && selectedBrandModel.brand_model_id) {
      httpGetBMUses();
    } else {
      setUses([]);
    }
  }, [selectedBrandModel?.brand_model_id]);

  const attributesModeRef = useRef(attributesMode);
  useEffect(() => {
    attributesModeRef.current = attributesMode;
  }, [attributesMode]);

  useEffect(() => {
    if (!instructionsInit) httpGetInstructions();
  }, [instructionsInit]);

  useEffect(() => {
    if (
      selectedBrand &&
      selectedModel &&
      Object.keys(selectedBrand).length > 0 &&
      Object.keys(selectedModel).length > 0
    ) {
      httpGetBrandModel();
    }
  }, [selectedBrand?.brand_id, selectedModel?.model_id]);

  useEffect(() => {
    if (selectedBrandModel?.brand_id && selectedBrandModel?.brand_model_id) {
      httpGetBrandUses();
    }
  }, [selectedBrandModel?.brand_id]);

  useEffect(() => {
    if (selectedBrandModel?.model_id && selectedBrandModel?.brand_model_id) {
      httpGetModelUses();
    }
  }, [selectedBrandModel?.model_id]);

  useEffect(() => {
    if (selectedBrandModel) {
      if (
        !selectedBrandModel.brand_owner ||
        selectedBrandModel.brand_owner === "none"
      ) {
        dispatch(
          updateSelectedBrandModel({
            key: "as_company",
            value: 0,
          })
        );
      }

      const company = companies.filter(
        (e) => e.company_id == selectedBrandModel.brand_owner
      )[0];

      if (!company) {
        dispatch(
          updateSelectedBrandModel({
            key: "as_company",
            value: 0,
          })
        );
      } else if (
        selectedBrandModel.brand &&
        company.company_name !== selectedBrandModel.brand
      ) {
        dispatch(
          updateSelectedBrandModel({
            key: "as_company",
            value: 0,
          })
        );
      }
    }
  }, [selectedBrandModel?.brand_owner, selectedBrandModel?.brand]);

  useEffect(() => {
    if (mode === modes.view) {
      httpUnlockBrandModel();
    }
  }, [mode]);

  // useEffect(() => {
  //   if (
  //     selectedBrandModel &&
  //     mode !== modes.view &&
  //     !selectedBrandModel.locked_on &&
  //     selectedBrandModel.brand_model_id
  //   ) {
  //     httpViewBrandModel(selectedBrandModel.brand_model_id);
  //   }
  // }, [mode, selectedBrandModel?.brand_model_id]);

  const handleCancel = () => {
    if (
      attributesModeRef.current === modes.edit ||
      modeRef.current === modes.edit
    ) {
      console.log("SWITCH TO VIEW");
      dispatch(switchMode(modes.view));
      dispatch(switchAttributesMode(modes.view));
      handleGetBrandModelById();
      return;
    }

    if (
      attributesModeRef.current === modes.new_brand_model ||
      modeRef.current === modes.new_brand_model
    ) {
      dispatch(switchMode(modes.view));
      dispatch(switchAttributesMode(modes.view));
      dispatch(setSelectedBrandModel({}));
      return;
    }

    switch (modeRef.current) {
      case modes.new_brand:
      case modes.new_model:
        dispatch(switchMode(modes.edit));
        // get existing brand and repopulate fields
        break;

      default:
        goBack();
        break;
    }
  };

  const httpNewBrandModel = async () => {
    setLoading(true);

    try {
      const payload = {};

      if (selectedBrandModel) {
        for (const key of Object.keys(selectedBrandModel)) {
          if (
            ((key === "model_id" || key === "brand_id") &&
              selectedBrandModel[key] === null) ||
            selectedBrandModel[key] === ""
          )
            continue;

          payload[key] = selectedBrandModel[key];
        }
      }

      const res = await AdminService.createBrandModel(token, dispatch, payload);

      dispatch(setSelectedBrandModel(res.data.data));

      dispatch(switchMode(modes.view));
      dispatch(switchAttributesMode(modes.view));
    } catch (error) {
      handleError(error, setErrors);
    }

    setLoading(false);
  };

  const httpAddBrandToDb = async () => {
    setLoading(true);

    try {
      const data = {
        brand: selectedBrandModel.brand,
      };

      if (selectedBrandModel.company_id != "none") {
        data.company = selectedBrandModel.company_id;
      }

      const res = await BrandService.addBrandToDb(token, dispatch, data);

      handleEditFormChange("brand_id")({
        target: { value: res.data.data.brand_id },
      });

      handleEditFormChange("brand_created_by")({
        target: { value: res.data.data.created_by },
      });

      dispatch(switchMode(modes.edit));
      dispatch(switchAttributesMode(modes.edit));

      httpGetModels();
    } catch (error) {}

    setLoading(false);
  };

  const httpAddModelToDb = async () => {
    setLoading(true);

    try {
      const data = {
        generic_model: selectedBrandModel.generic_model,
        brand: selectedBrandModel.brand_id,
        category: selectedCategory.category_id,
        model_name_des: selectedBrandModel.model_name_des,
        model_code: selectedBrandModel.model_code,
        model_notes: selectedBrandModel.model_notes,
      };

      const res = await ModelService.addModelOnly(token, dispatch, data);

      if (res.data.success) {
        dispatch(
          updateSelectedBrandModel({
            key: "model_id",
            value: res.data.data.model_id,
          })
        );
      }

      dispatch(switchMode(modes.edit));
      dispatch(switchAttributesMode(modes.edit));
    } catch (error) {}

    setLoading(false);
  };

  const httpUpdateBrandModel = async () => {
    setLoading(true);

    try {
      const res = await AdminService.updateBrandModel(
        token,
        dispatch,
        selectedBrandModel
      );

      dispatch(setSelectedBrandModel(res.data.data));

      dispatch(switchMode(modes.view));
      dispatch(switchAttributesMode(modes.view));
    } catch (error) {
      handleError(error, setErrors);
    }

    setLoading(false);
  };

  const httpDeleteBrandModel = async (data, openDeleteMessagePopup) => {
    setDeleting(true);
    try {
      await AdminService.deleteBrandModel(token, dispatch, data);

      dispatch(clearSelectedBrandModel());
      dispatch(switchAttributesMode(modes.view));
      dispatch(switchMode(modes.view));
    } catch (error) {
      if (error.response?.data?.code === 111) {
        openDeleteMessagePopup(data);
        setDeleting(false);
        return;
      }
      handleError(error, setErrors);
      setDeleting(false);
    }
  };

  return {
    searching,
    brands,
    models,
    idSearch,
    uses,
    errors,
    loading,
    deleting,
    showModelMismatchModal,
    selectedCategoryPrior,
    genericModelCategory,
    brandUses,
    modelUses,
    attributeEditMultiSelect,
    modeRef,
    handleSearchCategoryChange,
    handleSearchBrandChange,
    handleSearchModelChange,
    handleIdSearchChange,
    handleEditFormChange,
    handleGetBrandModelById,
    switchActiveTab,
    handleCancel,
    handleOpenSerialInstruction,
    handleNewClick,
    handleEditClick,
    handleNewBrandClick,
    handleNewModelClick,
    clearErrors,
    onBrandIdChange,
    onModelIdChange,
    closeMismatchModal,
    fetchCurrentCategoryTree,
    httpUnlockBrandModel,
    httpNewBrandModel,
    httpAddBrandToDb,
    httpAddModelToDb,
    httpUpdateBrandModel,
    onAttributeChange,
    httpDeleteBrandModel,
    modeSwitch: {
      goToEditMode,
      goToNewBrandModelMode,
    },
  };
};
