import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import { selectCategoryManagementData } from "redux-store/features/categoryManagement";
import CategoryService from "services/CategoryService";
import { logger, useForm } from "shared/helpers";
import LoadIndicator from "views/components/LoadIndicator";

function EditAttribute() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const { categoryManagementForm } = useSelector(selectCategoryManagementData);

  const goBack = () => navigate(-1);

  const { form, onChange } = useForm({
    use_units: "Operational time",
    public_use: 0,
    repairable: 0,
    fixed_location: 0,
  });

  const [fetchingAttribute, setFetchingAttribute] = useState(false);
  const getAttributeForCategory = async () => {
    setFetchingAttribute(true);
    try {
      const res = await CategoryService.getCategoryAttribute(
        token,
        dispatch,
        categoryManagementForm.category_id
      );

      logger("ATTRIBUTES", res.data);

      if (res.data.data && typeof res.data.data === "object") {
        for (const key of Object.keys(res.data.data))
          onChange(key, res.data.data[key]);
      }
    } catch (e) {}
    setFetchingAttribute(false);
  };
  useEffect(() => {
    if (categoryManagementForm.category_id) getAttributeForCategory();
  }, [categoryManagementForm]);

  const [saving, setSaving] = useState(false);
  const saveAttribute = async () => {
    setSaving(true);
    try {
      const res = await CategoryService.saveCategoryAttribute(token, dispatch, {
        ...form,
        category: categoryManagementForm.category_id,
      });

      if (res.status === 200 || res.status === 201) goBack();
    } catch (e) {}
    setSaving(false);
  };

  return (
    <>
      <div id="main-container">
        <div className="container">
          {fetchingAttribute ? (
            <LoadIndicator size="lg" />
          ) : (
            <>
              <div className="row mb-4 justify-content-center">
                <div className="col-sm-6">
                  <div className="infomore">
                    User Admin level:{" "}
                    {user && user.admin && user.admin.permission}
                  </div>
                </div>
                <div className="col-sm-6"></div>
              </div>

              <h2 className="mb-4">End Category Attributes</h2>

              <div className="infoContentCenter card mb-4">
                <div className="row">
                  <div className="col-xl-8">
                    {/** */}

                    <div className="row mb-4">
                      <div className="col-md-3">
                        <label className="form-label">End Category</label>
                      </div>
                      <div className="col-md-9">
                        <p>{categoryManagementForm?.category?.name}</p>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-md-3">
                        <label className="form-label">Repairable</label>
                      </div>
                      <div className="col-md-9">
                        <select
                          className="form-select"
                          value={form.repairable || 0}
                          onChange={(e) =>
                            onChange("repairable", e.target.value)
                          }
                        >
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-md-3">
                        <label className="form-label">Fixed Location</label>
                      </div>
                      <div className="col-md-9">
                        <select
                          className="form-select"
                          value={form.fixed_location || 0}
                          onChange={(e) =>
                            onChange("fixed_location", e.target.value)
                          }
                        >
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-md-3">
                        <label className="form-label">Public Use</label>
                      </div>
                      <div className="col-md-9">
                        <select
                          className="form-select"
                          value={form.public_use}
                          onChange={(e) =>
                            onChange("public_use", e.target.value)
                          }
                        >
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-md-3">
                        <label className="form-label">Use Units</label>
                      </div>
                      <div className="col-md-9">
                        <select
                          className="form-select"
                          value={form.use_units}
                          onChange={(e) =>
                            onChange("use_units", e.target.value)
                          }
                        >
                          <option value={"Operational time"}>
                            Operational time
                          </option>
                          <option value={"Age"}>Age</option>
                          <option value={"Distance"}>Distance</option>
                          <option value={"Cycles"}>Cycles</option>
                          <option value={"Uses"}>Uses</option>
                        </select>
                      </div>
                    </div>
                    {/** */}
                  </div>
                </div>
              </div>

              <div className="mt-4 text-center">
                <a onClick={goBack} className="btn btn-black me-2">
                  Cancel
                </a>
                <button
                  type="button"
                  className="btn btn-yellow"
                  onClick={saveAttribute}
                >
                  {saving ? <LoadIndicator /> : "Submit & Verify"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default EditAttribute;
