import React from "react";
import { useSelector } from "react-redux";
import { selectModelManagementData } from "redux-store/features/modelManagementSlice";
import { formatEnum } from "../brand-management/helpers";

function ViewModel() {
  const { selectedModel } = useSelector(selectModelManagementData);

  return (
    <div className="row">
      <div className="col-xl-8">
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Model ID</label>
          </div>
          <div className="col-md-9">
            <p>{selectedModel?.model_id || "Null"}</p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Generic Model</label>
          </div>
          <div className="col-md-9">
            {!selectedModel || selectedModel.generic_model == null
              ? "Null"
              : Boolean(selectedModel?.generic_model)
              ? "Yes"
              : "No"}
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Generic Attributes</label>
          </div>
          <div className="col-md-9">
            {selectedModel?.generic_attributes || "Null"}
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Model Code</label>
          </div>
          <div className="col-md-9">{selectedModel?.model_code || "Null"}</div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Generic Status</label>
          </div>
          <div className="col-md-9">
            {selectedModel?.generic_status
              ? formatEnum(selectedModel?.generic_status)
              : "Null"}
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Model Name Description</label>
          </div>
          <div className="col-md-9">
            {selectedModel?.model_name_des || "Null"}
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Model Notes</label>
          </div>
          <div className="col-md-9">{selectedModel?.model_notes || "Null"}</div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Generic Owner</label>
          </div>
          <div className="col-md-9">
            {selectedModel?.generic_owner || "Null"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewModel;
