import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedSymptom: null,
  selectedAberration: null,
  mode: "view",
};

export const symptomRecord = createSlice({
  name: "symptom_record",
  initialState,
  reducers: {
    setSelectedSymptom: (state, action) => {
      state.selectedSymptom = action.payload || null;
    },
    setSelectedAberration: (state, action) => {
      state.selectedAberration = action.payload || null;
    },
    setSymptomRecordMode: (state, action) => {
      state.mode = action.payload;
    },
  },
});

export const {
  setSelectedSymptom,
  setSymptomRecordMode,
  setSelectedAberration,
} = symptomRecord.actions;

export const selectSymptomRecordData = (state) => ({
  ...state.symptom_record,
});

export default symptomRecord.reducer;
