import React, { useEffect } from "react";
import styles from "./QualityRecord.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDDMMYYY } from "shared/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUnitQualityRecordData,
  setQualityRecord,
} from "redux-store/features/unitQualityRecordSlice";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const QualityRecord = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => navigate(-1);

  const { state } = useLocation();
  const { selectedQualityRecord } = useSelector(selectUnitQualityRecordData);

  useEffect(() => {
    if (state && state.data) {
      const data = JSON.parse(state.data);

      dispatch(setQualityRecord(data));
    }
  }, [state]);

  const messageOwner = () => {
    if (!selectedQualityRecord) return;

    navigate("/messages", {
      state: {
        prefilledMailData: {
          subject: "Quailty Record Query",
          to: selectedQualityRecord.owner_email,
          text: `Record: ${selectedQualityRecord.quality_id}, ${selectedQualityRecord.description}`,
        },
        destination: "compose",
      },
    });
  };

  return (
    <>
      <div id="main-container">
        <div className="container">
          <h2 className="mb-4 userLevel-Title">Quality Record</h2>

          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span className="">Quality and Symptom Management</span>
          </div>

          {/** SYMPTOM FILTER */}
          <div className="mb-2">
            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Quality ID</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.quality_id || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>User's Description</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.description || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Symptom ID</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.symptom || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Aberration</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.aberration || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Symptom</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.symptom_description || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Severity</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.severity || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>First Seen</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.date_seen
                      ? formatDDMMYYY(selectedQualityRecord.date_seen)
                      : "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Use of Seen Time</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.use_amount}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Repeatability</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.repeatability || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>SW Version</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <OverlayTrigger
                      key={"qr-sw-version"}
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-qr-sw-version">
                          {selectedQualityRecord?.sw_version_build_code || ""}
                        </Tooltip>
                      }
                    >
                      <span>
                        {selectedQualityRecord?.sw_version_name || "Null"}
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Repaired Date</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.repaired
                      ? formatDDMMYYY(selectedQualityRecord.repaired)
                      : "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Status</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.status || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Evidence</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord
                      ? selectedQualityRecord.evidence
                        ? "Yes"
                        : "No"
                      : "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Owner</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.owner_random_id || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Assistor</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord?.assistor_random_id || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Entry Date</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord
                      ? formatDDMMYYY(selectedQualityRecord.created_at)
                      : "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Sales</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedQualityRecord
                      ? selectedQualityRecord["sales(n)"]
                      : "Null"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.actions} d-flex flex-row`}>
            <button className={styles.cancel} onClick={goBack}>
              Back
            </button>

            <button
              className={styles.btn}
              onClick={messageOwner}
              disabled={
                !selectedQualityRecord || !selectedQualityRecord.owner_email
              }
            >
              Message Owner
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QualityRecord;
