import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { handleError } from "shared/helpers";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";

function AddUser({ show, handleClose, caseId, addUser }) {
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState("");
  const [errors, setErrors] = useState(false);

  const cleanUp = () => {
    setSaving(false);
    setErrors(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isNaN(user)) return setErrors(["Inavlid user id"]);
    if (!caseId || !user) return;

    setErrors([]);
    setSaving(true);

    try {
      const res = await addUser(caseId, user);
      handleClose(res);
    } catch (error) {
      handleError(error, setErrors);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    return cleanUp;
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          <form id="addUserForm" onSubmit={handleSubmit}>
            <div className="px-4">
              <input
                type="text"
                value={user}
                className="form-control"
                onChange={(e) => setUser(e.target.value)}
                required
              />
            </div>

            {errors.length > 0 && (
              <div className="px-4 pt-4">
                <ErrorsComponent errors={errors} />
              </div>
            )}
          </form>
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "none" }}>
          <button className="btn btn-black me-2" onClick={handleClose}>
            Cancel
          </button>

          <button className="btn btn-yellow" type="submit" form="addUserForm">
            {saving ? <LoadIndicator /> : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddUser;
