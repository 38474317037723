import { formatDDMMYYY } from "shared/helpers";
import styles from "./QualityRecordManagement.module.css";

const AberrationTable = ({ data = [], onRowClick }) => {
  return (
    <div className={styles.aberrationListContainer}>
      <div className={`${styles.aberrationList}`}>
        <header className="mb-2">
          <div>
            <div>
              <span>Aberration</span>
            </div>
          </div>

          <div>
            <div>
              <span>Symptom</span>
            </div>
          </div>

          <div>
            <div>
              <span>Severity</span>
            </div>
          </div>

          <div>
            <div>
              <span>Date seen</span>
            </div>
          </div>

          <div>
            <div>
              <span>Repaired</span>
            </div>
          </div>

          <div>
            <div>
              <span>Owner</span>
            </div>
          </div>
        </header>

        {data.map((item) => (
          <div
            key={item.quality_id + "quality"}
            className={`${styles.aberrationRow} mb-2`}
            onClick={() => onRowClick(item)}
          >
            <div className={styles.aberrationRowData}>
              <div className={styles.aberrationRowDataCol}>
                <div>
                  <span>{item.aberration}</span>
                </div>
              </div>

              <div className={styles.aberrationRowDataCol}>
                <div>
                  <span>{item.symptom_description}</span>
                </div>
              </div>

              <div className={styles.aberrationRowDataCol}>
                <div>
                  <span>{item.severity}</span>
                </div>
              </div>

              <div className={styles.aberrationRowDataCol}>
                <div>
                  <span>{formatDDMMYYY(item.date_seen)}</span>
                </div>
              </div>

              <div className={styles.aberrationRowDataCol}>
                <div>
                  <span>
                    {item.repaired ? formatDDMMYYY(item.repaired) : "Null"}
                  </span>
                </div>
              </div>

              <div className={styles.aberrationRowDataCol}>
                <div>
                  <span>{item.owner_random_id || "Null"}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AberrationTable;
