import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCategoryManagementData,
  setCategoryManagementForm,
} from "redux-store/features/categoryManagement";
import { modes } from "../helper";

function EditCategory() {
  const dispatch = useDispatch();

  const { categoryManagementForm: form, mode } = useSelector(
    selectCategoryManagementData
  );

  const onChange = (key, value) => {
    dispatch(setCategoryManagementForm({ key, value }));
  };

  useEffect(() => {
    if (mode === modes.new) {
      onChange("parent_category_name", form?.name);
      onChange("parent_category", {
        name: form.name,
        category_id: form.category_id,
      });
      onChange("name", "");
      onChange("description", "");
      onChange("age_restriction", 16);
      onChange("allow_end_category", 0);
      onChange("end", 0);
      onChange("category_id", null);
      onChange("technical_notes", null);
    }
  }, [mode]);

  return (
    <div className="fadeIn">
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Category ID</label>
        </div>

        <div className="col-md-9">{form?.category_id || "Null"}</div>
      </div>

      {/** Parent */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Parent</label>
        </div>

        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="publicName"
            placeholder="Parent category name"
            name="publicName"
            value={
              form.parent_category_name || form.parent_category?.name || ""
            }
            onChange={(e) => onChange("parent_category_name", e.target.value)}
            required
            readOnly
          />
        </div>
      </div>

      {/** Public name */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Public Name</label>
        </div>
        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="publicName"
            placeholder="Basic Mobile Phone"
            name="publicName"
            value={form.name || ""}
            onChange={(e) => onChange("name", e.target.value)}
            required
          />
        </div>
      </div>

      {/** Public description */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Public Description</label>
        </div>
        <div className="col-md-9">
          <textarea
            className="form-control"
            id="publicDescription"
            placeholder="Mobile phone mainly with voice and text functions, no internet or web based applications."
            value={form.description || ""}
            onChange={(e) => onChange("description", e.target.value)}
          ></textarea>
        </div>
      </div>

      {/** Minimum Age */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Minimum Age</label>
        </div>
        <div className="col-md-9">
          <input
            type="number"
            className="form-control"
            id="age"
            placeholder="16"
            name="age"
            value={form.age_restriction || ""}
            onChange={(e) => onChange("age_restriction", e.target.value)}
            min={16}
            required
          />
        </div>
      </div>

      {/** Allow end category */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Allow End category</label>
        </div>

        <div className="col-md-9">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              required
              id="flexCheckChecked"
              checked={form.allow_end_category === 1 ? true : false}
              onChange={(e) =>
                onChange("allow_end_category", e.target.checked ? 1 : 0)
              }
            />
          </div>
        </div>
      </div>

      {/** End category */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">End category</label>
        </div>
        <div className="col-md-9">
          <select
            className="form-select"
            value={form.end}
            onChange={(e) => onChange("end", e.target.value)}
            required
          >
            <option value={1}>Yes </option>
            <option value={0}>No</option>
          </select>
        </div>
      </div>

      {/** Verified date */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Verified Date</label>
        </div>
        <div className="col-md-9">
          <p>{form.verified_date ? form.verified_date : "-"}</p>
        </div>
      </div>

      {/** Owner */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Owner</label>
        </div>
        <div className="col-md-9">
          <p>{form.professional_id || "-"}</p>
        </div>
      </div>

      {/** Data sales */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Data Sales (N)</label>
        </div>
        <div className="col-md-9">
          <p>{form.professional_sales?.toString()}</p>
        </div>
      </div>

      {/** Technical notes */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Technical Note</label>
        </div>
        <div className="col-md-9">
          <textarea
            className="textarea-white"
            style={{ backgroundColor: "white", width: "100%" }}
            maxLength="255"
            value={form.technical_notes || ""}
            onChange={(e) => onChange("technical_notes", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default EditCategory;
