const { axiosClient } = require("shared/axiosInstance");

class AberrationService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async fetchAll() {
    return this.client.get("/aberrations");
  }

  async insert(data) {
    return this.client.post("/aberrations", data);
  }

  async update(data) {
    return this.client.put("/aberrations", data);
  }

  async getAberrationUseCount(aberration_id) {
    return this.client.get(
      "/aberrations/quality-record-count?aberration_id=" + aberration_id
    );
  }

  async remove(id) {
    return this.client.delete("/aberrations?aberration_id=" + id);
  }
}

export default AberrationService;
