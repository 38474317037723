import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import CronService from "services/CronService";
import { CRON_EXECUTE_ROUTES } from "shared/constants";
import { formatEnum, handleError, useShortDisplay } from "shared/helpers";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";

const ActivateCronModal = ({ show, handleClose, route }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  useShortDisplay(() => setErrors([]), 5000, errors.length > 0);

  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const service = new CronService(token, dispatch);

  const handleExecute = async () => {
    if (loading) return;

    setLoading(true);

    try {
      const res = await service.execute(route);
      console.log("Res", res.data.data);

      if (route === CRON_EXECUTE_ROUTES.categoryEndStructure)
        handleClose(res.data.data || []);
      else handleClose();
    } catch (error) {
      handleError(error, setErrors);
    }

    setLoading(false);
  };

  return (
    <Modal
      show={Boolean(show && route)}
      backdrop="static"
      centered
      onHide={handleClose}
      keyboard={false}
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h5 className="mod-detail">Initiate {formatEnum(route)} task now</h5>

        {errors.length > 0 && <ErrorsComponent errors={errors} />}
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>

        <Button variant="primary" disabled={loading} onClick={handleExecute}>
          {loading ? <LoadIndicator /> : "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ActivateCronModal;
