const { axiosClient } = require("shared/axiosInstance");

class SymptomService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async fetchAll() {
    return this.client.get("/symptoms");
  }

  async insert(data) {
    return this.client.post("/symptoms", data);
  }

  async get_quality_records(id) {
    return this.client.get("/symptoms/quality-records?symptom_id=" + id);
  }

  async get_category_symptom_records(id) {
    return this.client.get(
      "/symptoms/category-symptom-records?symptom_id=" + id
    );
  }

  async approve(data) {
    return this.client.post("/symptoms/approve", data);
  }

  async add_category(data) {
    return this.client.post("/symptoms/add-category", data);
  }

  async delete_category(cat_symp) {
    return this.client.delete(`/symptoms/category?cat_symp=${cat_symp}`);
  }

  async unlock_symptoms() {
    return this.client.post("/symptoms/unlock");
  }

  async view(id) {
    return this.client.get("/symptoms/view?id=" + id);
  }

  async change_aberration(data) {
    return this.client.post("/symptoms/change-aberration", data);
  }

  async reject_symptom(data) {
    return this.client.post("/symptoms/reject", data);
  }

  async delete_symptom(data) {
    return this.client.post("/symptoms/remove", data);
  }

  async send_bar_message(data) {
    return this.client.post("/symptoms/reject/message", data);
  }
}

export default SymptomService;
