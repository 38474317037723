import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import {
  addCase,
  setBugTypes,
  setCases,
  updateCase,
} from "redux-store/features/messageCasesSlice";
import MessageCaseService from "services/MessageService";
import { handleError, logger } from "shared/helpers";

export const useMessageCases = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);

  const service = new MessageCaseService(token, dispatch);

  const httpGetBugTypes = useCallback(async () => {
    try {
      const res = await service.get_bug_types();

      if (res.data && Array.isArray(res.data)) {
        dispatch(setBugTypes(res.data));
      }
    } catch (error) {
      logger(error);
    }
  }, [service, dispatch]);

  const httpCreateCase = useCallback(
    async (data, setErrors) => {
      try {
        const res = await service.create_message_case(data);

        if (res.data) {
          dispatch(addCase(res.data));
        }

        return { newCase: res.data };
      } catch (error) {
        handleError(error, setErrors);
        return { newCase: null };
      }
    },
    [service, dispatch]
  );

  const httpUpdateCase = useCallback(
    async (id, data) => {
      try {
        const res = await service.update_message_case(id, data);

        if (res.data) {
          dispatch(updateCase(res.data));
        }
      } catch (error) {
        logger(error);
      }
    },
    [service, dispatch]
  );

  const httpGetCase = useCallback(
    async (id) => {
      try {
        const res = await service.get_case_by_id(id);

        return res.data;
      } catch (error) {
        logger(error);
      }
    },
    [service]
  );

  const httpGetCases = useCallback(async () => {
    try {
      const res = await service.get_user_cases();

      dispatch(setCases(res.data || []));
    } catch (error) {
      logger(error);
    }
  }, [service, dispatch]);

  const httpGetCaseMessages = useCallback(
    async (case_id) => {
      try {
        const res = await service.get_case_messages(case_id);
        return res.data || [];
      } catch (error) {
        logger(error);
      }

      return [];
    },
    [service]
  );

  const httpCreateCaseMessage = useCallback(
    async (case_id, message, files = [], to = null) => {
      try {
        const payload = new FormData();

        payload.append("case_id", case_id);
        payload.append("message", message);
        
        if (to)
          payload.append("to", to);

        if (files.length > 0) {
          files.forEach((file) => payload.append("files[]", file));
        }

        const res = await service.create_case_message(payload);

        if (res?.data?.data) {
          return res.data.data;
        }
      } catch (error) {
        logger("[error]", error);
      }

      return null;
    },
    [service, dispatch]
  );

  const httpGetCaseUsers = useCallback(
    async (case_id) => {
      try {
        const res = await service.get_case_users(case_id);
        return res.data || [];
      } catch (error) {
        logger(error);
      }

      return [];
    },
    [service]
  );

  const httpCloseCase = useCallback(
    async (case_id) => {
      try {
        const res = await service.close_case(case_id);

        if (res && res.data) {
          dispatch(updateCase(res.data));
        }
      } catch (error) {}
    },
    [service, dispatch]
  );

  const httpAddCaseUser = useCallback(
    async (case_id, user_id) => {
      try {
        const res = await service.create_case_user(case_id, user_id);

        if (res && res.data) {
          return res.data;
        }
      } catch (error) {
        throw error;
      }

      return null;
    },
    [service, dispatch]
  );

  const httpGetTypeByID = useCallback(
    async (type_id) => {
      try {
        const res = await service.get_bug_type(type_id);

        if (res && res.data) {
          return res.data;
        }
      } catch (error) {
        throw error;
      }
      return null;
    },
    [service]
  );

  return {
    httpGetBugTypes,
    httpCreateCase,
    httpUpdateCase,
    httpGetCase,
    httpGetCases,
    httpGetCaseMessages,
    httpGetCaseUsers,
    httpCreateCaseMessage,
    httpCloseCase,
    httpAddCaseUser,
    httpGetTypeByID,
  };
};
