import { createSlice } from "@reduxjs/toolkit";
import { modes } from "views/database-records-management/category-management-new/helper";

const initialState = {
  unverifiedCategories: [],
  categories: [],
  selectedCategory: {},
  prefilledParentCategory: [],
  categoryManagementForm: { end: 0 },
  mode: modes.view,
};

export const categoryManagement = createSlice({
  name: "categoryManagement",
  initialState,
  reducers: {
    setSelecedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },

    clearSelectedCategory: (state, action) => {
      state.selectedCategory = {};
    },

    changeSelectedCategory: (state, action) => {
      state.selectedCategory = {
        ...state.selectedCategory,
        [action.payload.key]: action.payload.value,
      };
    },

    setUnverifiedCategory: (state, action) => {
      state.unverifiedCategories = action.payload;
    },

    clearUnverifiedCategory: (state) => {
      state.unverifiedCategories = [];
    },

    setCategories: (state, action) => {
      state.categories = action.payload;
    },

    clearCategories: (state) => {
      state.categories = [];
    },

    prefillParentCategory: (state, action) => {
      state.prefilledParentCategory = action.payload;
    },

    addToPrefill: (state, action) => {
      state.prefilledParentCategory = [
        ...state.prefilledParentCategory,
        action.payload,
      ];
    },

    clearPrefillParentCategory: (state) => {
      state.prefilledParentCategory = [];
    },

    setCategoryManagementForm: (state, action) => {
      state.categoryManagementForm = {
        ...state.categoryManagementForm,
        [action.payload.key]: action.payload.value,
      };
    },

    clearCategoryManagementForm: (state) => {
      state.categoryManagementForm = {};
    },

    switchMode: (state, action) => {
      state.mode = action.payload;

      if (action.payload === modes.new) {
        state.categoryManagementForm = {
          ...state.categoryManagementForm,
          category_id: null,
        };
      }
    },
  },
});

export const {
  setUnverifiedCategory,
  clearUnverifiedCategory,
  setCategories,
  clearCategories,
  prefillParentCategory,
  addToPrefill,
  clearPrefillParentCategory,
  setCategoryManagementForm,
  clearCategoryManagementForm,
  setSelecedCategory,
  clearSelectedCategory,
  changeSelectedCategory,
  switchMode,
} = categoryManagement.actions;

export const selectCategoryManagementData = (state) => ({
  ...state.categoryManagement,
});

export const selectSelectedCategory = (state) =>
  state.categoryManagement.selectedCategory;

export default categoryManagement.reducer;
