import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken } from "redux-store/features/authSlice";
import { removeSymptom } from "redux-store/features/symptomManagementSlice";
import { selectSymptomRecordData } from "redux-store/features/symptomRecordSlice";
import SymptomService from "services/SymptomService";
import { useForm, useShortDisplay } from "shared/helpers";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";

const DeleteModal = ({
  show,
  handleClose = () => {},
  categoryRecords = [],
}) => {
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { selectedSymptom } = useSelector(selectSymptomRecordData);
  const [deleteAllRecords, setDeleteAlRecords] = useState(false);
  const [categoriesToDelete, setCategoriesToDelete] = useState([]);
  const service = new SymptomService(dispatch, token);

  // // //
  const { err, handleServerError, set_err } = useForm();
  useShortDisplay(() => set_err([]), 5000, err.length > 0);
  // // //

  const updateOptions = (id) => {
    if (categoriesToDelete.includes(id)) {
      setCategoriesToDelete((prev) => prev.filter((e) => e !== id));
    } else {
      setCategoriesToDelete((prev) => [...prev, id]);
    }
  };

  // // //

  useEffect(() => {
    if (!selectedSymptom) {
      handleClose();
    }

    return handleClose;
  }, [selectedSymptom]);

  useEffect(() => {
    if (deleteAllRecords) setCategoriesToDelete([]);
  }, [deleteAllRecords]);

  // // //

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    try {
      let payload = {};
      if (deleteAllRecords) {
        payload = { symptom_id: selectedSymptom };
      } else {
        if (categoriesToDelete.length === 0) {
          set_err(["Please select at least one category"]);
          return;
        }

        payload = { ids: categoriesToDelete };
      }

      const res = await service.delete_symptom(payload);

      if (res.data.success && deleteAllRecords) {
        dispatch(removeSymptom(selectedSymptom));
      }

      handleClose();
      navigate(-1);
    } catch (error) {
      handleServerError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      // backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
        <form
          id="deleteForm"
          className="container px-4"
          onSubmit={handleSubmit}
        >
          {!deleteAllRecords && categoryRecords.length > 0 && (
            <>
              <div className="row mb-2">
                <div className="col-md-3">C_ID</div>
                <div className="col-md-6">Category Name</div>
                <div className="col-md-3 text-end">Select</div>
              </div>

              <div className="mb-4">
                {categoryRecords.map((item) => (
                  <div
                    className="row mb-2"
                    key={"cat_symp_" + item.cat_symp_id}
                  >
                    <div className="col-md-3">{item.cat_symp_id}</div>
                    <div className="col-md-6">{item.category_name}</div>
                    <div className="col-md-3 text-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked"
                        checked={categoriesToDelete.includes(item.cat_symp_id)}
                        onChange={() => updateOptions(item.cat_symp_id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          <div className="row mb-2">
            <div className="col-md-9">Delete from selected categories</div>

            <div className="col-md-3 text-end">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                checked={!deleteAllRecords}
                onChange={() => {
                  if (deleteAllRecords) {
                    setDeleteAlRecords(false);
                  }
                }}
              />
            </div>
          </div>
          {/** */}
          <div className="row mb-2">
            <div className="col-md-9">Delete symptom and all category uses</div>

            <div className="col-md-3 text-end">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                checked={deleteAllRecords}
                onChange={() => {
                  if (!deleteAllRecords) {
                    setDeleteAlRecords(true);
                  }
                }}
              />
            </div>
          </div>
        </form>

        {err.length > 0 && <ErrorsComponent errors={err} />}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          disabled={loading}
          type="submit"
          form="deleteForm"
        >
          {loading ? <LoadIndicator /> : "Yes"}
        </Button>

        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
