import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import SoftwareApplicationManagement from "../software/SoftwareApplicationManagement";

function SelectSoftwareApplication({ contextData, handleClose, onSelect }) {
  const data = useMemo(
    () => (contextData ? { ...contextData, handleClose, onSelect } : null),
    [contextData, onSelect, handleClose]
  );

  return (
    <Modal
      show={Boolean(contextData)}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      fullscreen
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ borderBottom: "none", backgroundColor: "black" }}
      ></Modal.Header>

      <Modal.Body style={{ backgroundColor: "black" }}>
        <SoftwareApplicationManagement contextData={data} />
      </Modal.Body>
    </Modal>
  );
}

export default SelectSoftwareApplication;
