import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import BrandService from "services/BrandService";
import { formatDDMMYYY } from "shared/helpers";
import LoadIndicator from "views/components/LoadIndicator";
import styles from "./BrandModelUnits.module.css";
import MessageModal from "./MessageModal";
import FileDisplayThumb from "views/components/FileDisplay";

const BrandModelUnits = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const [brandModelId, setBrandModelId] = useState(null);
  const [attribute, setAttribute] = useState(null);
  const [units, setUnits] = useState([]);

  const [fetching, setFetching] = useState(false);
  const fetchUnits = async (id) => {
    setFetching(true);
    try {
      const res = await BrandService.getBrandModelUnits(token, dispatch, id);

      setUnits(res.data.data);
    } catch (error) {}
    setFetching(false);
  };

  const fetchAttribute = async (id) => {
    try {
      const res = await BrandService.getBrandModelAttribute(
        token,
        dispatch,
        id
      );
      setAttribute(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get("id");

    setBrandModelId(id);

    if (!id) {
      navigate(-1);
    } else {
      fetchAttribute(id);
    }
  }, []);

  useEffect(() => {
    if (brandModelId && attribute) {
      fetchUnits(brandModelId);
    }
  }, [brandModelId, attribute]);

  const [selectedUintId, setSelectedUnitId] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const openMessageModal = (id) => {
    setSelectedUnitId(id);
    setIsMessageModalOpen(true);
  };

  const closeMessageModal = () => {
    setSelectedUnitId(null);
    setIsMessageModalOpen(false);
  };

  return (
    <>
      <MessageModal
        open={isMessageModalOpen}
        handleClose={closeMessageModal}
        unitId={selectedUintId}
      />

      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>
          {/** */}

          <h2 className="mb-4">Brand_Model Units</h2>
          {/** */}

          <div className="row =">
            <div className="col-md-3">
              <label className="form-label">Brand_Model ID:</label>
            </div>

            <div className="col-md-9">{brandModelId}</div>
          </div>

          <div className={`${styles.tableWrapper} mt-4`}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>
                    <div className={styles.tableHead}>
                      <span>Unit ID</span>
                    </div>
                  </th>
                  {attribute && attribute.serial_type !== "None" && (
                    <th>
                      <div className={styles.tableHead}>
                        <span>{attribute.serial_type}</span>
                      </div>
                    </th>
                  )}
                  <th>
                    <div className={styles.tableHead}>
                      <span>Image</span>
                    </div>
                  </th>
                  <th>
                    <div className={styles.tableHead}>
                      <span>Added</span>
                    </div>
                  </th>
                  <th>
                    <div
                      className={
                        styles.tableHead + " " + styles.tableHeadLastChild
                      }
                    ></div>
                  </th>
                </tr>
              </thead>

              {fetching ? (
                <LoadIndicator />
              ) : (
                <tbody>
                  {units.map((unit) => {
                    return (
                      <tr
                        key={`unit-${unit.unit_id}`}
                        className={styles.tableChild}
                      >
                        <td>{unit.unit_id}</td>
                        {attribute && attribute.serial_type !== "None" && (
                          <td>{unit.unique_identifier}</td>
                        )}
                        <td>
                          {unit.rating_label ? (
                            <FileDisplayThumb
                              location={encodeURI(unit.rating_label)}
                            />
                          ) : (
                            "Null"
                          )}
                        </td>
                        <td>{formatDDMMYYY(unit.created_at)}</td>
                        <td>
                          <button
                            onClick={() => openMessageModal(unit.unit_id)}
                          >
                            <i className={"far fa-envelope fa-fw"}></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandModelUnits;
