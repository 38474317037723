import { axiosClient } from "shared/axiosInstance";

class CategoryService {
  static createChildCategory(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/category/child", data);
  }

  static getAvailableCategories(token, dispatch) {
    return axiosClient(dispatch, token).get("/category");
  }

  static unlock(token, dispatch) {
    return axiosClient(dispatch, token).post("/category/unlock");
  }

  static view(token, dispatch, id) {
    return axiosClient(dispatch, token).get(`/category/view/${id}`);
  }

  static getEndCategories(dispatch, token) {
    return axiosClient(dispatch, token).get("/category/end");
  }

  static changeStructure(token, dispatch, data) {
    return axiosClient(dispatch, token).patch("/category/structure", data);
  }

  static addCategory(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/category", data);
  }

  static getParentTree(token, dispatch, category) {
    return axiosClient(dispatch, token).get(
      "/category/parent-tree?category=" + category
    );
  }

  static getCategoryAttribute(token, dispatch, category) {
    return axiosClient(dispatch, token).get(
      "/category/attribute?category=" + category
    );
  }

  static saveCategoryAttribute(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/category/attribute", data);
  }

  static submitAndVerifyCategory(token, dispatch, data) {
    return axiosClient(dispatch, token).patch("/category", data);
  }

  static deleteCategory(token, dispatch, category) {
    return axiosClient(dispatch, token).delete(
      "/category?category=" + category
    );
  }

  static getCategory(token, dispatch, id) {
    if (!id) return;
    return axiosClient(dispatch, token).get(
      `/category/single?category_id=${id}`
    );
  }

  static fetchUses(token, dispatch, id) {
    return axiosClient(dispatch, token).get("/category/uses?category_id=" + id);
  }

  static fetchAll(token, dispatch) {
    return axiosClient(dispatch, token).get("category/list");
  }

  static fetchAllCategoryChildren(token, dispatch, category) {
    return axiosClient(dispatch, token).get(
      "category/list-children?category=" + category
    );
  }
}

export default CategoryService;
