import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const DeleteMessageModal = ({ show, handleClose }) => {
  const [text, setText] = useState("");

  const handleSubmit = (e) => {
    e?.preventDefault();
    handleClose(text);
    setText("");
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>Delete Message</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
        <div className="container px-4">
          <div className="row mb-4">
            <div className="col-md-12">
              <form id="reasonForm" onSubmit={handleSubmit}>
                <textarea
                  name="reason"
                  id="reason"
                  placeholder="Reason for deletion..."
                  required
                  className="textarea-white"
                  value={text || ""}
                  minLength={10}
                  onChange={(e) => setText(e.target.value)}
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    padding: 8,
                  }}
                  maxLength="255"
                />
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button type="submit" form="reasonForm" variant="primary">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteMessageModal;
