import React from "react";
import { formatDDMMYYY } from "shared/helpers";

function ViewBrand({ selectedBrand, companies }) {
  const getBrandOwnwer = () => {
    const company = companies.filter(
      (item) => item.company_id == selectedBrand?.brand_owner
    )[0];

    if (company) {
      return company.company_name;
    }

    return "Null";
  };

  return (
    <>
      {/** BRAND ID */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">ID</label>
        </div>

        <div className="col-md-9">
          <p>{selectedBrand?.brand_id || "Null"}</p>
        </div>
      </div>

      {/** BRAND NAME */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Brand Name</label>
        </div>

        <div className="col-md-9">
          <p>{selectedBrand?.brand || "Null"}</p>
        </div>
      </div>

      {/** BRAND OWNER */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Brand Owner</label>
        </div>

        <div className="col-md-9">
          <p>{getBrandOwnwer()}</p>
        </div>
      </div>

      {/** AS COMPANY */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">As Company</label>
        </div>

        <div className="col-md-9">
          <p>
            {selectedBrand !== null
              ? Boolean(selectedBrand.as_company)
                ? "Yes"
                : "No"
              : "Null"}
          </p>
        </div>
      </div>

      {/** CREATED BY */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Created By</label>
        </div>

        <div className="col-md-9">
          <p>{selectedBrand?.created_by?.toString() || "Null"}</p>
        </div>
      </div>

      {/** STATUS */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Status</label>
        </div>

        <div className="col-md-9">
          <p>{selectedBrand?.status || "Null"}</p>
        </div>
      </div>

      {/** VERIFIED DATE */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Verification Date</label>
        </div>

        <div className="col-md-9">
          <p>
            {selectedBrand?.verified_date
              ? formatDDMMYYY(selectedBrand.verified_date)
              : "Null"}
          </p>
        </div>
      </div>
    </>
  );
}

export default ViewBrand;
