import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  instructions: [],
  initialized: false,
  from: null,
  contextData: null,
};

export const modelInstruction = createSlice({
  name: "model_instruction",
  initialState,
  reducers: {
    setInstructions: (state, action) => {
      state.instructions = action.payload;
      state.initialized = true;
    },

    add: (state, action) => {
      state.instructions = [...state.instructions, action.payload];
    },

    remove: (state, action) => {
      state.instructions = state.instructions.filter(
        (e) => e.instruction_id != action.payload
      );
    },

    update: (state, action) => {
      state.instructions = state.instructions.map((item) => {
        if (item.instruction_id == action.payload.instruction_id) {
          return { ...item, ...action.payload };
        }

        return item;
      });
    },

    setFrom: (state, action) => {
      state.from = action.payload;
    },

    setContextData: (state, action) => {
      state.contextData = action.payload;
    },
  },
});

export const { setInstructions, add, setContextData, setFrom, update, remove } =
  modelInstruction.actions;

export const selectModelInstructionData = (state) => ({
  ...state.model_instruction,
});

export default modelInstruction.reducer;
