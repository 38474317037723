import AppRoutes from "./routes";
import "./App.css";
import "animate.css";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return <AppRoutes />;
}

export default App;
