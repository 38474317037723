import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import {
  fromBrandModel,
  selectCompanyManagementData,
  setSelectedCompany,
  switchMode,
} from "redux-store/features/companyManagementSlice";
import CompanyService from "services/CompanyService";
import { useShortDisplay } from "shared/helpers";
import ActionModal from "views/components/ActionModal";
import Autocomplete from "views/components/Autocomplete";
import DescModal from "views/components/DescModal";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";
import EditCompany from "./edit";
import { handleServerError, modes } from "./helpers";
import ViewCompany from "./view";

const active1 = ["Limited", "Not for profit", "Charity"];

function CompanyManagement({ closeModal }) {
  const navigate = useNavigate();
  const goBack = () => {
    dispatch(switchMode(modes.view));
    dispatch(setSelectedCompany(null));
    navigate(-1);
  };

  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const {
    selectedCompany,
    mode,
    fromBrandModel: from,
  } = useSelector(selectCompanyManagementData);

  const [search, setSearch] = useState("");
  const [companies, setCompanies] = useState([]);

  const [fetchingCompanies, setFetchingCompanies] = useState(false);
  const fetchCompanies = async () => {
    setFetchingCompanies(true);
    try {
      const res = await CompanyService.getAvailableCompanies(token, dispatch);

      setCompanies(res.data.data);
    } catch (error) {}
    setFetchingCompanies(false);
  };

  useEffect(() => {
    fetchCompanies();

    return () => {
      dispatch(fromBrandModel(false));
      dispatch(setSelectedCompany(null));
    };
  }, []);

  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get("id");

    if (id && companies.length > 0) {
      const company = companies.filter((e) => e.company_id === +id)[0];

      if (company) {
        dispatch(setSelectedCompany(company));
      }
    }
  }, [companies]);

  useEffect(() => {
    if (from) {
      dispatch(switchMode(modes.new));
    }
  }, [from]);

  const [brands, setBrands] = useState([]);
  useEffect(() => {
    const fetchBrands = async () => {
      const res = await CompanyService.fetchBrands(
        token,
        dispatch,
        selectedCompany.company_id
      );

      setBrands(res.data.data);
    };

    if (selectedCompany && mode != modes.new) {
      fetchBrands();
    }
  }, [selectedCompany?.company_id]);

  const [open_brands, set_open_brands] = useState(false);

  const closeBrands = () => {
    set_open_brands(false);
  };

  const openBrands = () => {
    set_open_brands(true);
  };

  const setupEditMode = () => {
    dispatch(switchMode(modes.edit));
  };

  const setupNewMode = () => {
    dispatch(switchMode(modes.new));
  };

  const [errors, setErrors] = useState([]);

  const [loading, setLoading] = useState(false);
  const updateCompany = async () => {
    setErrors([]);
    setLoading(true);

    const err = validateForm();

    if (err) return setErrors([err]);

    try {
      const res = await CompanyService.updateCompany(
        token,
        dispatch,
        selectedCompany
      );

      dispatch(setSelectedCompany(res.data.data));
      dispatch(switchMode(modes.view));
    } catch (error) {
      handleServerError(error, setErrors);
    }
    setLoading(false);
  };

  const validateForm = () => {
    if ([...active1, "Unlimited"].includes(selectedCompany.type)) {
      if (!selectedCompany.town) {
        return "Town is required.";
      }
    }

    if (selectedCompany.type == "unlimited") {
      if (
        !selectedCompany.contact_number &&
        !selectedCompany.contact_email &&
        !selectedCompany.website
      ) {
        return "At least one of Conntact number, Contact email or website is required.";
      }

      if (
        !selectedCompany.address_line1 &&
        !selectedCompany.town &&
        !selectedCompany.postal_ref
      ) {
        return "At least one of address line 1, town or postal ref is required.";
      }
    }

    return null;
  };

  const createCompany = async () => {
    setErrors([]);
    setLoading(true);

    const err = validateForm();

    if (err) return setErrors([err]);

    try {
      const res = await CompanyService.addCompanyToDb(
        token,
        dispatch,
        selectedCompany
      );

      if (closeModal) {
        closeModal(res.data.data);
      } else {
        dispatch(setSelectedCompany(res.data.data));
        dispatch(switchMode(modes.view));

        setCompanies((prev) => [...prev, res.data.data]);
      }
    } catch (error) {
      handleServerError(error, setErrors);
    }
    setLoading(false);
  };

  const bottomRef = useRef(null);
  const [deleting, setDeleting] = useState(false);
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (success) {
      bottomRef.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [success]);

  useShortDisplay(() => setSuccess(""), 6000, success.length > 0);

  const deleteCompany = async () => {
    setErrors([]);
    setLoading(true);
    setDeleting(true);
    try {
      const res = await CompanyService.deleteCompany(
        token,
        dispatch,
        selectedCompany.company_id
      );

      setSuccess(`Company ${selectedCompany.company_name} deleted`);

      dispatch(setSelectedCompany(null));
      dispatch(switchMode(modes.view));
      setSearch("");
      fetchCompanies();
    } catch (error) {
      handleServerError(error, setErrors);
    }
    setLoading(false);
    setDeleting(false);
  };

  const onSubmit = (e) => {
    e?.preventDefault();

    switch (mode) {
      case modes.edit:
        updateCompany();
        break;

      case modes.new:
        createCompany();
        break;

      default:
        updateCompany();
        break;
    }
  };

  const [showActionModal, setShowActionModal] = useState(false);
  const [actionModalText, setActionModalText] = useState(false);
  const [buttonTitle, setButtonTitle] = useState({
    cancel: "Cancel",
    ok: "Ok",
  });
  const [action, setAction] = useState({ cancel: "", ok: "" });
  const buttonActions = {
    deleteCompany: () => {
      setShowActionModal(false);
      deleteCompany();
    }, // TODO: Go to internal messaging
    closeActionModal: () => setShowActionModal(false),
  };

  const openActionModal = (text, buttonTitle, action) => {
    setActionModalText(text);
    setButtonTitle(buttonTitle);
    setAction(action);
    setShowActionModal(true);
  };

  return (
    <>
      <ActionModal
        show={showActionModal}
        text={actionModalText}
        buttonTitle={buttonTitle}
        action={action}
        buttonActions={buttonActions}
      />

      <DescModal
        show={open_brands}
        handleClose={closeBrands}
        children={
          <div>
            <h5>Brands</h5>

            <ul>
              {brands.map((item) => (
                <li key={"brands-available-" + item.brand_id}>
                  <p>{item.brand}</p>
                </li>
              ))}
            </ul>
          </div>
        }
      />

      <div id="main-container" style={{ padding: closeModal ? 0 : undefined }}>
        {fetchingCompanies ? (
          <div className="container">
            <LoadIndicator size="lg" />
          </div>
        ) : (
          <div className="container">
            <div className="row mb-4 justify-content-center">
              <div className="col-sm-6">
                <div className="infomore">
                  User Admin level:{" "}
                  {user && user.admin && user.admin.permission}
                </div>
              </div>
              <div className="col-sm-6"></div>
            </div>

            <h2 className="mb-4">Professional Company Management</h2>

            <div className="infoContentCenter card mb-4">
              <div className="row">
                {/** */}
                <div className="col-xl-8">
                  {mode != modes.new && (
                    <>
                      {/** COMPANY SEARCH */}
                      <div className="row mb-4">
                        <div className="col-md-3">
                          <label className="form-label">Select Company:</label>
                        </div>

                        <div className="col-md-9">
                          <Autocomplete
                            input={search}
                            setInput={setSearch}
                            suggestions={companies}
                            placeholder="Samsung"
                            primaryKey="company_id"
                            labelKey="company_name"
                            setValue={(company) =>
                              dispatch(setSelectedCompany(company))
                            }
                          />
                        </div>
                      </div>

                      {/** USES AND BRAND LIST */}
                      <div className="row mb-4">
                        <div className="col-md-3">
                          <label className="form-label">Uses:</label>
                        </div>

                        <div className="col-md-9">
                          <div className="row align-items-center">
                            <p>
                              {brands.length}{" "}
                              <a onClick={openBrands} className="infolink">
                                List brands
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="mb-4">
                    <ErrorsComponent errors={errors} />
                  </div>

                  {mode === modes.view && (
                    <ViewCompany selectedCompany={selectedCompany} />
                  )}

                  {(mode === modes.edit || mode === modes.new) && (
                    <EditCompany
                      selectedCompany={selectedCompany}
                      onSubmit={onSubmit}
                      mode={mode}
                    />
                  )}

                  <div className="btn-group-child mb-5">
                    <button
                      type="button"
                      className="btn btn-yellow me-3"
                      disabled={
                        mode != modes.view || loading || selectedCompany
                      }
                      onClick={setupNewMode}
                    >
                      <i className="bi bi-plus-circle"></i> New Company
                    </button>

                    <button
                      type="button"
                      className="btn btn-yellow me-3"
                      disabled={
                        mode === modes.edit ||
                        mode === modes.new ||
                        !selectedCompany ||
                        loading
                      }
                      onClick={setupEditMode}
                    >
                      <i className="bi bi-pencil"></i> Edit
                    </button>

                    <button
                      type="button"
                      className="btn btn-yellow me-3"
                      disabled={
                        mode != modes.view ||
                        !selectedCompany ||
                        brands.length > 0 ||
                        loading
                      }
                      onClick={() =>
                        openActionModal(
                          "Confirm Delete?",
                          { cancel: "Cancel", ok: "Ok" },
                          { cancel: "closeActionModal", ok: "deleteCompany" }
                        )
                      }
                    >
                      <i className="bi bi-trash"></i>
                      {deleting ? <LoadIndicator /> : "Delete"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div ref={bottomRef} />

            {success && (
              <ErrorsComponent errors={success} variant="alert-success" />
            )}

            <div className="mt-4 text-center">
              <button
                className="btn btn-black me-2"
                onClick={closeModal || goBack}
              >
                Cancel
              </button>

              <button
                form="company-edit-form"
                type="submits"
                className="btn btn-yellow"
                disabled={loading}
              >
                {loading ? <LoadIndicator /> : "Submit"}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CompanyManagement;
