import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectAberrationManagementData } from "redux-store/features/aberrationManagementSlice";
import { selectToken } from "redux-store/features/authSlice";
import AberrationService from "services/AberrationService";
import { handleError } from "shared/helpers";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";

const DeleteAberration = ({ show = false, handleClose = () => {} }) => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const service = new AberrationService(dispatch, token);
  const [errors, setErrors] = useState([]);

  const { selectedAberration } = useSelector(selectAberrationManagementData);

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      setErrors([]);
      await service.remove(selectedAberration);

      handleClose(true);
    } catch (error) {
      console.log(error);
      handleError(error, setErrors);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      // onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
        <h5 className="mod-detail">Delete this record?</h5>

        {errors.length > 0 && (
          <div className="mt-3">
            <ErrorsComponent 
              errors={errors}
            />
          </div>
        )}

      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="primary" disabled={loading} onClick={handleDelete}>
          {loading ? <LoadIndicator /> : "Yes"}
        </Button>

        <Button variant="secondary" onClick={() => handleClose(false)}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAberration;
