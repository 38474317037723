import { createSlice } from "@reduxjs/toolkit";
import { logger } from "shared/helpers";

const initialState = {
  pendingUnits: [],
};

export const unitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    setPendingUnits: (state, action) => {
      state.pendingUnits = action.payload;
    },

    editPendingUnit: (state, action) => {
      const result = state.pendingUnits.map((unit) => {
        if (unit.unit_id === action.payload.unit_id)
          return { ...unit, ...action.payload };

        return unit;
      });

      logger("Edit Pending Unit Result", result);

      state.pendingUnits = result;
    },
  },
});

export const { setPendingUnits, editPendingUnit } = unitsSlice.actions;

export const selectUnitsData = (state) => ({ ...state.units });

export default unitsSlice.reducer;
