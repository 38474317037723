import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
//import AuthLayout from "components/layouts/auth";

import AdminTools from "views/admin-tools";
import AdminUserManagement from "views/admin-user-management";
import SubmittingUserVerification from "views/submitting-user-verification";
import UserVerificationSummary from "views/user-verification-summary";
import DatabaseManagementRoutes from "./database-management";
import MessagingRoutes from "./MessagingRoutes";
import SecurityKeyManagement from "views/security-key-management";
import Layout from "views/components/Layout";
import MessageTypeManagement from "views/message-type-management";
import SupportAllocation from "views/support-allocation";
import TaxManagement from "views/tax-management";

const Dashboard = ({}) => {
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) navigate("/auth");
  }, [token, user]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<AdminTools />} />
        <Route path="tools" element={<AdminTools />} />
        <Route
          path="user-verification-summary"
          element={<UserVerificationSummary />}
        />
        <Route
          path="submitting-user-verification"
          element={<SubmittingUserVerification />}
        />
        <Route path="admin-user-management" element={<AdminUserManagement />} />
        <Route
          path="database-records-management/*"
          element={<DatabaseManagementRoutes />}
        />
        <Route path="messages/*" element={<MessagingRoutes />} />

        <Route
          path="security-key-management"
          element={<SecurityKeyManagement />}
        />

        <Route
          path="message-type-management"
          element={<MessageTypeManagement />}
        />

        <Route path="support-allocation" element={<SupportAllocation />} />

        <Route path="tax-management" element={<TaxManagement />} />
      </Route>
    </Routes>
  );
};

export default Dashboard;
