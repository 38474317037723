import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import {
  updateMessageType,
  setMessageTypes,
  addMessageType,
} from "redux-store/features/messageTypeManagementSlice";
import MessageCaseService from "services/MessageService";

export const useMessageTypeManagement = () => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const service = new MessageCaseService(token, dispatch);

  const httpGetMessageTypes = useCallback(async () => {
    try {
      const res = await service.get_all_bug_types();
      dispatch(setMessageTypes(res.data || []));
    } catch (error) {}
  }, [service, dispatch]);

  const httpUpdateMessageType = useCallback(
    async (data) => {
      try {
        const res = await service.update_bug_type(data);

        if (res && res.data) {
          dispatch(updateMessageType(res.data));
        }
      } catch (error) {
        throw error;
      }
    },
    [service, dispatch]
  );

  const httpCreateMessageType = useCallback(
    async (data) => {
      try {
        const res = await service.create_bug_type(data);

        if (res && res.data) {
          dispatch(addMessageType(res.data));
        }

        return res?.data?.type_id;
      } catch (error) {
        throw error;
      }
    },
    [service, dispatch]
  );

  const httpGetCasesByType = useCallback(
    async (type_id) => {
      try {
        const res = await service.get_cases_by_type(type_id);

        if (res && res.data) {
          return res.data;
        }
      } catch (error) {
        console.log(error);
      }

      return [];
    },
    [service]
  );

  const httpAcceptCase = useCallback(
    async (case_id) => {
      try {
        const res = await service.accept_case(case_id);

        if (res && res.data) {
          return res.data;
        }
      } catch (error) {
        throw error;
      }

      return null;
    },
    [service]
  );

  const httpDropCase = useCallback(
    async (case_id) => {
      try {
        const res = await service.drop_case(case_id);

        if (res && res.data) {
          return res.data;
        }
      } catch (error) {
        throw error;
      }

      return null;
    },
    [service]
  );

  const httpGetMessageById = useCallback(
    async (message_id) => {
      try {
        const res = await service.get_message_by_id(message_id);

        if (res && res.data) {
          return res.data;
        }
      } catch (error) {
        throw error;
      }

      return null;
    },
    [service]
  );

  return {
    httpGetMessageTypes,
    httpUpdateMessageType,
    httpCreateMessageType,
    httpGetCasesByType,
    httpAcceptCase,
    httpDropCase,
    httpGetMessageById,
  };
};
