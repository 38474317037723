import { useCallback } from "react";
import styles from "./styles.module.css";
import Pagination from "react-bootstrap/Pagination";
import { DOTS } from "shared/constants";

const CardTable = ({
  id,
  columns,
  data = [],
  customClassName = "",
  pagination = null,
  meta = null,
  goToPage = () => {},
}) => {
  const renderTd = useCallback((column, rowItem, rowItemIndex) => {
    if (
      column.componentType === "Select" &&
      Array.isArray(column.componentOptions)
    ) {
      return (
        <select
          className="form-select border-0 bg-transparent"
          value={rowItem[column.key]}
          defaultValue=""
          onChange={(e) => column.onSelect(rowItemIndex, e.target.value)}
          disabled={column.disabled}
          style={{ color: "#D5D6D6" }}
        >
          {column.componentOptions.map((optionData, idx) => (
            <option key={`option-data-${id}-${idx}`} value={optionData.val}>
              {optionData.label}
            </option>
          ))}
        </select>
      );
    }

    if (column.componentType === "Input") {
      return (
        <input
          className="form-control border-0 bg-transparent"
          value={rowItem[column.key]}
          onChange={(e) => column.onChange(rowItemIndex, e.target.value)}
          disabled={column.disabled}
          style={{ color: "#D5D6D6" }}
        />
      );
    }

    if (column.componentType === "Switch") {
      return (
        <label className="switch">
          <input
            type="checkbox"
            checked={Boolean(parseInt(rowItem[column.key]))}
            onChange={() => column.onChange(rowItemIndex)}
            disabled={column.disabled}
          />
          <span className="slider round"></span>
        </label>
      );
    }

    return (
      <div>
        {column.getData ? column.getData(rowItem) : rowItem[column.key]}
      </div>
    );
  }, []);

  if (columns.length === 0) {
    return null;
  }

  return (
    <>
      <div className={styles.cardTableContainer}>
        <table className={`${styles.table} ${customClassName}`}>
          <thead>
            <tr>
              {columns.map((column, idx) => (
                <th
                  key={`${id}-th-${idx}`}
                  className={styles.th}
                  colSpan={column.colSpan}
                >
                  <div>{column.title}</div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data.map((item, idx) => (
              <tr className={styles.tr} key={`${id}-tr-${idx}`}>
                {columns.map((column, colIdx) => (
                  <td
                    className={styles.td}
                    key={`${id}-td-${idx}-${colIdx}`}
                    colSpan={column.colSpan}
                  >
                    {renderTd(column, item, idx)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {Array.isArray(pagination) && meta && (
        <Pagination>
          <Pagination.Prev
            disabled={meta?.page === 1}
            onClick={() => goToPage(Number(meta.page) - 1)}
          />
          {pagination.map((item) => {
            if (item === DOTS) {
              return <Pagination.Ellipsis />;
            }

            return (
              <Pagination.Item
                onClick={() => goToPage(Number(item))}
                active={Number(meta?.page) === Number(item)}
              >
                {item}
              </Pagination.Item>
            );
          })}
          <Pagination.Next
            disabled={meta?.hasNext === false}
            onClick={goToPage}
          />
        </Pagination>
      )}
    </>
  );
};

export default CardTable;
