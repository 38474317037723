import { useBrands } from "hooks/useBrands";
import { useCompany } from "hooks/useCompany";
import { useModels } from "hooks/useModels";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectBrandModelManagementData } from "redux-store/features/brandModelManagement";
import { brand_model_status, formatEnum, modes } from "shared/b_m_helper";
import { formatDDMMYYY } from "shared/helpers";
import Autocomplete from "views/components/Autocomplete";
import WarningModal from "views/components/WarningModal";

const EditBrandModel = (props) => {
  const {
    uses,
    openUsesModal,
    brandUses = [],
    modelUses = [],
    handleNewBrandClick,
    handleNewModelClick,
    modeSwitch,
  } = props;

  const {
    selectedBrandModel,
    selectedBrand,
    selectedCategory,
    mode,
    categories,
    attributesMode,
  } = useSelector(selectBrandModelManagementData);

  const [brandText, setBrandText] = useState("");
  const [modelText, setModelText] = useState("");

  const { all: brands } = useBrands();
  const { all: companies, refresh: refreshCompanyList } = useCompany();
  const { all: allModels, refresh } = useModels();

  useEffect(() => {
    if (mode === modes.edit) {
      refresh();
    }
  }, [mode]);

  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [warning, setWarning] = useState("");

  const openWarning = (text) => {
    setWarning(text);
    setIsOpenWarning(true);
  };

  const handleOk = () => {
    props.handleEditFormChange("model_code")({ target: { value: "GENERIC" } });
    setIsOpenWarning(false);
  };

  const handleCancelWarning = () => {
    props.handleEditFormChange("generic_model")({ target: { value: 0 } });
    setIsOpenWarning(false);
  };

  const handleBrandSelected = (brand) => {
    if (!brand.brand_id) {
      handleNewBrandClick();
    } else {
      if (selectedBrandModel.brand_model_id) {
        if (mode != modes.edit) modeSwitch.goToEditMode();
      } else {
        if (mode != modes.new_brand_model) modeSwitch.goToNewBrandModelMode();
      }
      props.onBrandIdChange(brand.brand_id);
    }
  };

  const handleModelSelected = ({ model_id }) => {
    if (!model_id) {
      handleNewModelClick();
      setModelText("New Model");
      props.handleEditFormChange("model_code")({ target: { value: "" } });
      props.handleEditFormChange("generic_model")({ target: { value: 0 } });
      props.handleEditFormChange("model_name_des")({ target: { value: "" } });
      props.handleEditFormChange("generic_attributes")({
        target: { value: null },
      });
      props.handleEditFormChange("model_notes")({
        target: { value: "" },
      });
      props.handleEditFormChange("model_owner")({
        target: { value: "" },
      });
    } else {
      if (selectedBrandModel.brand_model_id) {
        if (mode != modes.edit) modeSwitch.goToEditMode();
      } else {
        if (mode != modes.new_brand_model) modeSwitch.goToNewBrandModelMode();
      }
      props.onModelIdChange(model_id);
    }
  };

  const BE = useMemo(() => {
    const brandHasBeenUsed = brandUses > 0;
    const brandModelVerified = selectedBrandModel?.status == "verified";

    if (!selectedBrandModel?.brand_id) {
      return true;
    }

    return (
      !brandHasBeenUsed &&
      !(brandModelVerified || mode == modes.new_brand_model)
    );
  }, [selectedBrandModel?.status, brandUses, selectedBrandModel?.brand_id]);

  const ME = useMemo(() => {
    const modelHasBeenUsed = modelUses > 0;
    const brandModelVerified = selectedBrandModel?.status == "verified";

    if (!selectedBrandModel?.model_id) {
      return true;
    }

    return (
      !modelHasBeenUsed &&
      !(brandModelVerified || mode === modes.new_brand_model)
    );
  }, [selectedBrandModel?.status, modelUses, selectedBrandModel?.model_id]);

  const handleCompanySelected = (e) => {
    props.handleEditFormChange("company_id")(e);
    props.handleEditFormChange("brand_owner")(e);
  };

  useEffect(() => {
    if (selectedBrandModel?.company_id) refreshCompanyList();
  }, [selectedBrandModel?.company_id]);

  const isAsCompanyDisabled = useMemo(() => {
    if (selectedBrandModel) {
      if (
        !selectedBrandModel.brand_owner ||
        selectedBrandModel.brand_owner === "none"
      ) {
        return true;
      }

      const company = companies.filter(
        (e) => e.company_id == selectedBrandModel.brand_owner
      )[0];

      if (!company) {
        return true;
      }

      if (
        selectedBrandModel.brand &&
        company.company_name !== selectedBrandModel.brand
      ) {
        return true;
      }
    }

    return false;
  }, [selectedBrandModel?.brand_owner, selectedBrandModel?.brand]);

  return (
    <>
      <WarningModal
        show={isOpenWarning}
        text={warning}
        handleOk={handleOk}
        handleClose={handleCancelWarning}
      />

      <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
        <h3>Brand Model</h3>
      </div>

      <div className="row">
        <div className="col-xl-9">
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Brand_Model ID</label>
            </div>
            <div className="col-md-9">
              <p>{selectedBrandModel?.brand_model_id || "Null"}</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Uses</label>
            </div>

            <div className="col-md-9">
              <div className="row align-items-center">
                <p>
                  {uses.length}{" "}
                  <a onClick={openUsesModal} className="infolink">
                    List uses
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Brand ID</label>
            </div>

            <div className="col-md-9">
              <div className="d-flex align-items-center">
                <div style={{ flex: 1 }}>
                  <Autocomplete
                    suggestions={brands}
                    required={true}
                    placeholder="Select brand"
                    primaryKey="brand_id"
                    labelKey="brand"
                    first={
                      !brandUses.length ||
                      selectedBrandModel?.status !== "verified"
                        ? [{ brand_id: "", brand: "New Brand" }]
                        : undefined
                    }
                    input={brandText}
                    addKeyToLabel
                    setInput={setBrandText}
                    setValue={handleBrandSelected}
                    initialValue={selectedBrandModel?.brand_id}
                    disabled={selectedBrandModel?.status == "verified"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ms-4">
            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Brand</label>
              </div>
              <div className="col-md-9">
                <input
                  type="text"
                  className="form-control"
                  id="brandName"
                  placeholder="Apple"
                  name="brandName"
                  disabled={!BE}
                  value={selectedBrandModel?.brand || ""}
                  onChange={props.handleEditFormChange("brand")}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Brand Owner</label>
              </div>

              <div className="col-md-9">
                <div className="d-flex align-items-center">
                  <select
                    className="form-select me-3"
                    required
                    value={selectedBrandModel?.company_id || ""}
                    defaultValue=""
                    disabled={!BE}
                    onChange={handleCompanySelected}
                  >
                    <option disabled value={""}>
                      Select brand owner
                    </option>

                    {[
                      ...companies,
                      { company_id: "none", company_name: "None" },
                    ].map((item) => (
                      <option
                        value={item.company_id}
                        key={`${item.company_id}-company-option-edit`}
                      >
                        {item.company_name}
                      </option>
                    ))}
                  </select>

                  {BE && (
                    <button
                      className="btn btn-yellow"
                      onClick={props.setUpForNewCompany}
                      style={{ minWidth: 200 }}
                      disabled={
                        selectedBrandModel &&
                        Object.keys(selectedBrandModel).length <= 2
                      }
                    >
                      New Company
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Brand+Company same</label>
              </div>

              <div className="col-md-9">
                <select
                  className="form-select"
                  value={Boolean(+selectedBrandModel?.as_company) || 0}
                  onChange={props.handleEditFormChange("as_company")}
                  disabled={isAsCompanyDisabled || !BE}
                >
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Created By</label>
              </div>

              <div className="col-md-9">
                <p>{selectedBrandModel?.created_by_number || "Null"}</p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Status</label>
              </div>

              <div className="col-md-9">
                <p>{selectedBrandModel?.brand_status || "Null"}</p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Verification Date</label>
              </div>

              <div className="col-md-9">
                <p>
                  {selectedBrandModel?.brand_verified_date
                    ? formatDDMMYYY(selectedBrandModel.brand_verified_date)
                    : "Null"}
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Model ID</label>
            </div>

            <div className="col-md-9">
              <div className="d-flex align-items-center">
                <div style={{ flex: 1 }}>
                  <Autocomplete
                    suggestions={allModels}
                    primaryKey="model_id"
                    labelKey="model_name_des"
                    labelKey2="model_code"
                    input={modelText}
                    setInput={setModelText}
                    initialValue={selectedBrandModel?.model_id}
                    placeholder="Select model"
                    first={[{ model_id: "", model_name_des: "New Model" }]}
                    setValue={handleModelSelected}
                    required={true}
                    disabled={
                      selectedBrandModel?.model_id === null ||
                      selectedBrandModel.status == "verified"
                    }
                    addKeyToLabel
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mx-4">
            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">
                  Model Code{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      props.openDescriptionModal(
                        "The more familiar textual, used more in Marketing"
                      )
                    }
                  ></i>
                </label>
              </div>

              <div className="col-md-9">
                <input
                  type="text"
                  className="form-control"
                  id="modelCode"
                  placeholder="Apple"
                  name="modelCode"
                  value={selectedBrandModel?.model_code || ""}
                  disabled={selectedBrandModel?.generic_model == 1 || !ME}
                  onChange={props.handleEditFormChange("model_code")}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">
                  Model Name{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      props.openDescriptionModal(
                        "Set as generic if the same item is provided by most companies in the category without a company specific model or service title, for example 'Plumbing Repair' is a generic service. When set to yes, if no record is available, show message \"create new generic attributes record?\" and show attibutes editable as a new window."
                      )
                    }
                  ></i>
                </label>
              </div>

              <div className="col-md-9">
                <input
                  type="text"
                  className="form-control"
                  id="modelNameDes"
                  placeholder="Apple"
                  name="modelNameDes"
                  value={selectedBrandModel?.model_name_des || ""}
                  disabled={!ME}
                  onChange={props.handleEditFormChange("model_name_des")}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Model Notes</label>
              </div>

              <div className="col-md-9">
                <textarea
                  className="form-control"
                  value={selectedBrandModel?.model_notes || ""}
                  onChange={props.handleEditFormChange("model_notes")}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Generic Attributes ID</label>
              </div>

              <div className="col-md-9">
                {!Boolean(selectedBrandModel?.generic_model)
                  ? "Null"
                  : selectedBrandModel?.generic_attributes || "Null"}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">
                  Generic Model{" "}
                  <i
                    className="bi bi-info-circle"
                    onClick={() =>
                      props.openDescriptionModal(
                        "Set as generic if the same item is provided by most companies in the category without a company specific model or service title, for example 'Plumbing Repair' is a generic service. When set to yes, if no record is available, show message \"create new generic attributes record?\" and show attibutes editable as a new window."
                      )
                    }
                  ></i>
                </label>
              </div>

              <div className="col-md-9">
                <select
                  onChange={(e) => {
                    props.handleEditFormChange("generic_model")(e);
                    if (e.target.value == 1) {
                      openWarning("Model code will be deleted");
                    }
                  }}
                  value={selectedBrandModel?.generic_model}
                  className="form-select"
                  disabled={!ME}
                >
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Generic Owner</label>
              </div>

              <div className="col-md-9">
                {!Boolean(selectedBrandModel?.generic_model)
                  ? "Null"
                  : selectedBrandModel?.model_owner || "Null"}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Generic Status</label>
              </div>
              <div className="col-md-9">
                {!Boolean(selectedBrandModel?.generic_model)
                  ? "Null"
                  : selectedBrandModel?.generic_status
                  ? formatEnum(selectedBrandModel?.generic_status)
                  : "Null"}
              </div>
            </div>

            {/** END */}
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Attributes</label>
            </div>
            <div className="col-md-9">
              {selectedBrandModel?.attributes || "Null"}
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Created By (Brand_Model)</label>
            </div>
            <div className="col-md-9">
              <p>{selectedBrandModel?.brand_model_created_by || "Null"}</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Created Date (Brand_Model)</label>
            </div>
            <div className="col-md-9">
              <p>
                {selectedBrandModel?.created_at
                  ? formatDDMMYYY(selectedBrandModel?.created_at)
                  : "Null"}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Status (Brand_Model)</label>
            </div>
            <div className="col-md-9">
              <p>{brand_model_status[selectedBrandModel?.status] || "Null"}</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Verified Date</label>
            </div>
            <div className="col-md-9">
              <p>
                {selectedBrandModel?.verified_date
                  ? formatDDMMYYY(selectedBrandModel?.verified_date)
                  : "-"}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Owner</label>
            </div>
            <div className="col-md-9">
              <p>{selectedBrandModel?.owner || "Null"}</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Data Sales (N)</label>
            </div>
            <div className="col-md-9">
              <p>{selectedBrandModel?.data_sales || "0"}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBrandModel;
