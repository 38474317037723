import { axiosClient } from "shared/axiosInstance";

class CompanyService {
  static addCompanyToDb(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/company", data);
  }

  static getAvailableCompanies(token, dispatch) {
    return axiosClient(dispatch, token).get("/company");
  }

  static fetchBrands(token, dispatch, company) {
    return axiosClient(dispatch, token).get(
      "/company/brands?company=" + company
    );
  }

  static updateCompany(token, dispatch, company) {
    return axiosClient(dispatch, token).patch("/company", company);
  }

  static deleteCompany(token, dispatch, company) {
    return axiosClient(dispatch, token).delete(`/company?company=${company}`);
  }
}

export default CompanyService;
