const { axiosClient } = require("shared/axiosInstance");

class SWVersionService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async fetchAll() {
    return this.client.get("/sw-versions");
  }

  async fetchByApplication(id) {
    return this.client.get(`/sw-versions/by-application?application=${id}`);
  }

  async view(id) {
    return this.client.get(`/sw-versions/view/${id}`);
  }

  async get(id) {
    return this.client.get(`/sw-versions?id=${id}`);
  }

  async create(data) {
    return this.client.post("/sw-versions", { ...data, from: "admin" });
  }

  async update(data) {
    return this.client.put("/sw-versions", data);
  }

  async unlock() {
    return this.client.post("/sw-versions/unlock");
  }

  async delete(id) {
    return this.client.delete(`/sw-versions?id=${id}`);
  }

  async getCreatorMail(id) {
    return this.client.get(`/sw-versions/creator?id=${id}`);
  }
}

export default SWVersionService;
