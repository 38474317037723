import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import {
  selectAdminUserManagementData,
  setAdminUserManagementData,
  updateAdminUserManagementData,
} from "redux-store/features/adminUserManagement";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import AdminService from "services/AdminServise";
import { formatDDMMYYY, formatYYYYMMDD, logger, useForm } from "shared/helpers";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";

function AdminUserManagement() {
  const user = useSelector(selectUser);

  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const data = useSelector(selectAdminUserManagementData);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const {
    form,
    onChange,
    err: errors,
    set_err: setErrors,
    handleServerError,
  } = useForm({ permission: "A1" });
  const navigate = useNavigate();

  useEffect(() => {
    if (data.permission && data.permission != form.permission)
      onChange("permission", data.permission);

    if (data.finish_date) onChange("finish_date", data.finish_date);
  }, [data]);

  const goBack = () => navigate(-1);

  const findAdminUser = async () => {
    setErrors([]);
    setSuccess("");
    if (!query) return;
    setLoading(true);
    try {
      const res = await AdminService.fetchUserByRandomId(
        token,
        dispatch,
        query
      );

      dispatch(setAdminUserManagementData(res.data.data));
    } catch (e) {}
    setLoading(false);
  };

  const [changing, setChanging] = useState(false);
  const [success, setSuccess] = useState("");

  const changePermission = async () => {
    setErrors([]);
    setSuccess("");
    if (!data.user_id) return;

    setChanging(true);
    try {
      const res = await AdminService.changeAdminUserPermission(
        token,
        dispatch,
        {
          user_id: data.user_id,
          permission: form.edit_permission === "" ? null : form.edit_permission,
          finish_date: form.finish_date,
        }
      );

      logger("Update result", res.data);

      if (res.status === 200) {
        onChange("edit_permission", "");
        dispatch(
          updateAdminUserManagementData({
            key: "permission",
            value: res.data.data?.permission,
          })
        );
        dispatch(
          updateAdminUserManagementData({
            key: "start_date",
            value: res.data.data?.date,
          })
        );
      }

      setSuccess(res.data.message);
    } catch (e) {
      handleServerError(e);
    }
    setChanging(false);
  };

  const createAdmin = async () => {
    setErrors([]);
    setSuccess("");
    setChanging(true);

    try {
      const res = await AdminService.createAdmin(token, dispatch, {
        user_id: data.user_id,
        permission: form.edit_permission,
        finish_date: form.finish_date,
      });

      if (res.status === 201) {
        dispatch(updateAdminUserManagementData({ key: "admin", value: true }));
        dispatch(
          updateAdminUserManagementData({
            key: "permission",
            value: res.data.data?.permission,
          })
        );
        dispatch(
          updateAdminUserManagementData({
            key: "start_date",
            value: res.data.data?.date,
          })
        );
        onChange("edit_permission", "");
      }

      setSuccess(res.data.message);
    } catch (e) {
      handleServerError(e);
    }

    setChanging(false);
  };

  const onSubmit = () => {
    if (!data) return;

    if (data.admin) {
      changePermission();
    } else {
      createAdmin();
    }
  };

  const transition = useTransition(success, {
    from: { x: -100, y: 200, opacity: 0 },
    enter: { x: 0, y: 0, opacity: 1 },
    leave: {},
  });

  const transition2 = useTransition(errors.length > 0, {
    from: { x: -100, y: 200, opacity: 0 },
    enter: { x: 0, y: 0, opacity: 1 },
    leave: {},
  });

  return (
    <>
      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4">Admin User Management</h2>

          <div className="infoContentCenter card mb-4">
            <div className="row">
              <div className="col-lg-8">
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">User Number:</label>
                  </div>
                  <div className="col-md-9">
                    <div className="d-flex ">
                      <input
                        type="text"
                        className="form-control me-3"
                        placeholder="User Number"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-yellow"
                        id="basic-addon2"
                        onClick={findAdminUser}
                      >
                        {loading ? <LoadIndicator /> : "Find"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">User Name:</label>
                  </div>
                  <div className="col-md-9">
                    <p>{data.name || "No record"}</p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">User Address:</label>
                  </div>
                  <div className="col-md-9">
                    <p>{data.address || "No record"}</p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">Admin Start Date:</label>
                  </div>
                  <div className="col-md-9">
                    <p>
                      {data.start_date
                        ? formatDDMMYYY(data.start_date)
                        : "No Admin Record"}
                    </p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">Admin Permission:</label>
                  </div>
                  <div className="col-md-9">
                    <p>{data.permission || "No Admin Record"}</p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">New Permission:</label>
                  </div>
                  <div className="col-md-9">
                    <select
                      className="form-select"
                      value={form.edit_permission || ""}
                      defaultValue=""
                      disabled={!data.user_id}
                      onChange={(e) =>
                        onChange("edit_permission", e.target.value)
                      }
                    >
                      <option disabled value="">
                        Select permission
                      </option>
                      <option value={"A1"}>A1</option>
                      <option value={"A5"}>A5</option>
                      <option value={"A10"}>A10</option>
                    </select>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">Admin Finish Date:</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Finish Date"
                      disabled={!data.user_id}
                      value={
                        form.finish_date ? formatYYYYMMDD(form.finish_date) : ""
                      }
                      onChange={(e) =>
                        onChange(
                          "finish_date",
                          e.target.valueAsDate.toISOString()
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 text-center">
            {transition2(
              (styles, item) =>
                item && (
                  <animated.div style={styles}>
                    <ErrorsComponent errors={errors} />
                  </animated.div>
                )
            )}

            {transition(
              (styles, item) =>
                item && (
                  <animated.div className="alert alert-success" style={styles}>
                    {success}
                  </animated.div>
                )
            )}

            <a onClick={goBack} className="btn btn-black me-2">
              Back
            </a>
            <button
              type="button"
              className="btn btn-yellow"
              disabled={changing || !data.user_id}
              onClick={onSubmit}
            >
              {changing ? <LoadIndicator /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminUserManagement;
