import { axiosClient } from "shared/axiosInstance";

class AdminService {
  static createAdmin(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/admin", data);
  }

  static deleteUser(token, dispatch, user_id) {
    return axiosClient(dispatch, token).delete(
      "/admin/users?user_id=" + user_id
    );
  }

  static fetchUserVerificationSummary(token, dispatch) {
    return axiosClient(dispatch, token).get(
      "/users/admin/verification-summary"
    );
  }

  static fetchSubmittingUserVerification(token, dispatch) {
    return axiosClient(dispatch, token).get(
      "/users/admin/submitting-user-verification"
    );
  }

  static unlockHistory(token, dispatch) {
    return axiosClient(dispatch, token).post("/verification_history/unlock");
  }

  static requestImprovement(token, dispatch, data) {
    return axiosClient(dispatch, token).post(
      "/users/admin/improvement-request",
      data
    );
  }

  static acceptSubmittingVerification(token, dispatch, data) {
    return axiosClient(dispatch, token).post(
      "/users/admin/acceptVerification",
      data
    );
  }

  static rejectSubmittingVerification(token, dispatch, data) {
    return axiosClient(dispatch, token).post(
      "/users/admin/rejectVerification",
      data
    );
  }

  static fetchUserByRandomId(token, dispatch, number) {
    return axiosClient(dispatch, token).get("/users?user_number=" + number);
  }

  static changeAdminUserPermission(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/users/change_permission", data);
  }

  static getUnverifiedCategories(token, dispatch) {
    return axiosClient(dispatch, token).get("/category/unverified");
  }

  static fetchUnverifiedBrandModel(token, dispatch, category) {
    return axiosClient(dispatch, token).get(
      "/brand_model/unverified?category=" + category
    );
  }

  static fetchBrandModelByBrandAndModel(token, dispatch, brand, model) {
    return axiosClient(dispatch, token).get(
      `/brand_model/single?brand=${brand}&model=${model}`
    );
  }

  static fetchBrandModelById(token, dispatch, id) {
    if (!id) return;

    return axiosClient(dispatch, token).get("/brand_model/single/by-id/" + id);
  }

  static unlockBrandModel(token, dispatch) {
    return axiosClient(dispatch, token).post("/brand_model/unlock");
  }

  static viewBrandModel(token, dispatch, id) {
    return axiosClient(dispatch, token).get(`/brand_model/view?id=${id}`);
  }

  static updateBrandModel(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/brand_model/update", data);
  }

  static fetchBrandModelUses(token, dispatch, id) {
    return axiosClient(dispatch, token).get(
      `/brand_model/uses?brand_model_id=${id}`
    );
  }

  static createBrandModel(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/brand_model/new", data);
  }

  static deleteBrandModel(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/brand_model/delete", data);
  }

  static queryBrandModel(tokn, dispatch, data) {
    return axiosClient(dispatch, tokn).post("/brand_model/query", data);
  }

  static getAllAttributes(token, dispatch) {
    return axiosClient(dispatch, token).get("/brand_model_attributes");
  }

  static getAllAttributesForBrandModel(
    token,
    dispatch,
    brand_model,
    must_include
  ) {
    let url = "/brand_model/attributes-dropdown";

    if (brand_model) url += `?brand_model=${brand_model}`;

    if (must_include) {
      if (url.includes("?")) url += `&include=${must_include}`;
      else url += `?include=${must_include}`;
    }

    return axiosClient(dispatch, token).get(url);
  }

  static getAttribute(token, dispatch, id) {
    if (!id) return;
    return axiosClient(dispatch, token).get("/brand_model_attributes?id=" + id);
  }

  static getE4ForModel(token, dispatch, model_id, brand_model_id) {
    if (!model_id || !brand_model_id) return;

    return axiosClient(dispatch, token).get(
      "/model/model_attributes_used?model=" +
        model_id +
        "&brand_model=" +
        brand_model_id
    );
  }
}

export default AdminService;
