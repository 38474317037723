import React, { useEffect } from "react";
import styles from "./QualityRecordManagement.module.css";
import AberrationTable from "./AberrationTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { useForm } from "shared/helpers";
import UnitService from "services/UnitService";
import {
  selectUnitQualityManagementData,
  setQualities,
  setSelectedUnit,
} from "redux-store/features/unitQualityManagementSlice";
import LoadIndicator from "views/components/LoadIndicator";

const UnitQualityRecordManagement = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const { form, onChange } = useForm();

  const { selectedUnit, qualities } = useSelector(
    selectUnitQualityManagementData
  );

  const fetchQualities = async () => {
    if (!selectedUnit) return;

    try {
      const res = await UnitService.getUnitQualities(
        token,
        dispatch,
        selectedUnit.unit_id
      );

      dispatch(setQualities(res.data.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchQualities();
  }, [selectedUnit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.loading || !form.unit_id) return;

    onChange("loading", true);

    try {
      const res = await UnitService.getUnit(token, dispatch, form.unit_id);

      if (res.data.data) {
        dispatch(setSelectedUnit(res.data.data));
      }
    } catch (error) {
    } finally {
      onChange("loading", false);
    }
  };

  const getSerialNo = () => {
    if (!selectedUnit) return "Null";

    if (selectedUnit.serial_type && selectedUnit.unique_identifier) {
      return `${selectedUnit.serial_type}#${selectedUnit.unique_identifier}`;
    }

    return "Null";
  };

  const onRowClick = (data) => {
    navigate("/database-records-management/qs-management/unit-quality-record", {
      state: {
        data: JSON.stringify(data),
      },
    });
  };

  return (
    <>
      <div id="main-container">
        <div className="container">
          <h2 className="mb-4 userLevel-Title">
            Unit Quality Record Management
          </h2>

          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span className="">Quality and Symptom management</span>
          </div>

          <div className="mb-4">
            <form className="row mb-3" onSubmit={handleSubmit}>
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Quality Unit ID</span>
                    </div>
                  </div>

                  <div
                    className={`${styles.topLabelVal} ${styles.customLabelVal} col`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      value={form.unit_id || ""}
                      onChange={(e) => onChange("unit_id", e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                disabled={form.loading}
                className={styles.btn}
              >
                {form.loading ? <LoadIndicator /> : "Find"}
              </button>
            </form>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Serial no</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {getSerialNo()}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Item Class</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedUnit?.item_class || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>End Category</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedUnit?.category_name || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Model </span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedUnit?.model_name_des || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Current Controller </span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedUnit?.controller_random_id || "Not Found"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: 50 }} />

          {/** ENTER SYMPTOM IN YOUR WORD */}
          <div className="mb-4">
            <p className={`${styles.filter} mb-4 `}>Quality History</p>

            <AberrationTable data={qualities} onRowClick={onRowClick} />
          </div>
        </div>
      </div>
    </>
  );
};

export default UnitQualityRecordManagement;
