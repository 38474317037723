const { axiosClient } = require("shared/axiosInstance");

class TaxService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async fetchCountries(page) {
    return this.client.get("/country/all");
  }

  async fetchCurrencies() {
    return this.client.get("/currency/all");
  }

  async updateCountryData(data) {
    return this.client.post('/country/update', data);
  };
}

export default TaxService;
