import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import {
  initializeModelList,
  selectModelsData,
} from "redux-store/features/modelsSlice";
import ModelService from "services/ModelService";
import { logger } from "shared/helpers";

export const useModels = () => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { model_list, initialized_model_list } = useSelector(selectModelsData);

  const fetchModelList = async () => {
    setLoading(true);
    try {
      const res = await ModelService.getModels(token, dispatch);

      if (res.data.data) {
        dispatch(initializeModelList(res.data.data));
      }
    } catch (error) {
      logger("[ERROR]", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!initialized_model_list && token) {
      fetchModelList();
    }
  }, [initialized_model_list, token]);

  const refresh = () => {
    if (token) {
      fetchModelList();
    }
  };

  return { all: model_list, refresh, loading };
};
