import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { selectToken } from "redux-store/features/authSlice";
//import AuthLayout from "components/layouts/auth";

import Signin from "views/auth/signin";
//import ResetPassword from "views/auth/reset-password";
//import ForgotPassword from "views/auth/forgot-password";

const Auth = (props) => {
  const token = useSelector(selectToken);

  const navigate = useNavigate();

  useEffect(() => {
    if (token) navigate("/");
  }, [token]);
  return (
    <Routes>
      <Route index element={<Signin />} />
      <Route path="signin" element={<Signin />} />
    </Routes>
  );
};

export default Auth;
