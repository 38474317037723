const { axiosClient } = require("shared/axiosInstance");

class ModelInstructionService {
  constructor(dispatch, token) {
    this.dispatch = dispatch;
    this.token = token;
  }

  async fetchAll() {
    return axiosClient(this.dispatch, this.token).get("/model-instruction");
  }

  async view(id) {
    return axiosClient(this.dispatch, this.token).get(
      "/model-instruction/view?instruction_id=" + id
    );
  }

  async add(data) {
    return axiosClient(this.dispatch, this.token).post(
      "/model-instruction",
      data
    );
  }

  async update(data) {
    return axiosClient(this.dispatch, this.token).post(
      "/model-instruction/update",
      data
    );
  }

  async unlock() {
    return axiosClient(this.dispatch, this.token).post(
      "/model-instruction/unlock"
    );
  }

  async delete(id) {
    return axiosClient(this.dispatch, this.token).delete(
      "/model-instruction?instruction_id=" + id
    );
  }
}

export default ModelInstructionService;
