import React, { useEffect, useState } from "react";
import styles from "./AberrationSelect.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  initializeAberrationList,
  selectAberrationManagementData,
} from "redux-store/features/aberrationManagementSlice";
import { selectToken } from "redux-store/features/authSlice";
import AberrationService from "services/AberrationService";
import { useForm } from "shared/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import SymptomService from "services/SymptomService";
import { updateSymptom } from "redux-store/features/symptomManagementSlice";

const AberrationSelect = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const aberrationService = new AberrationService(dispatch, token);
  const symptomService = new SymptomService(dispatch, token);
  const { state } = useLocation();

  const navigate = useNavigate();
  const goBack = () => {
    if (state?.from === "change_aberration") {
      navigate("/database-records-management/qs-management/symptom-record", {
        state: state?.from === "change_aberration" ? state : undefined,
        replace: true,
      });
    } else {
      navigate(-1);
    }
  };

  const { initialized, aberrations } = useSelector(
    selectAberrationManagementData
  );
  const fetchAberrations = async () => {
    try {
      const res = await aberrationService.fetchAll();

      dispatch(initializeAberrationList(res.data.data || []));
    } catch (error) {}
  };
  useEffect(() => {
    if (!initialized) fetchAberrations();
  }, [initialized]);

  const { form, onChange } = useForm({
    text: "",
    restriction: "",
  });

  useEffect(() => {
    if (state) {
      if (state.from === "change_aberration") {
        const { restriction } = JSON.parse(state.data);
        onChange("restriction", restriction);
      }
    }
  }, [state]);

  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    const result = aberrations.filter((item) => {
      return (
        (!form.text ||
          item.aberration.toLowerCase().includes(form.text.toLowerCase())) &&
          (form.restriction || '') == item.restriction
        );
      });

    setFiltered(result);
  }, [form]);

  useEffect(() => {
    setFiltered(aberrations);
  }, [aberrations]);

  const handleClick = async (id) => {
    if (state?.from === "new_symptom") {
      if (!state.category) return;

      navigate("/database-records-management/qs-management/symptom-record", {
        state: {
          mode: "new",
          aberration: id,
          category: state.category,
        },
        replace: true,
      });
    } else if (state?.from === "change_aberration") {
      const { symptom } = JSON.parse(state.data);

      try {
        const res = await symptomService.change_aberration({
          symptom_id: symptom,
          aberration_id: id,
        });

        dispatch(updateSymptom(res.data.data));

        if (state?.from === "change_aberration") {
          navigate(
            "/database-records-management/qs-management/symptom-record",
            {
              state: state?.from === "change_aberration" ? state : undefined,
              replace: true,
            }
          );
        } else {
          navigate(-1);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div id="main-container">
        <div className="container">
          <h2 className="mb-4 userLevel-Title">ABERRATION SELECT</h2>

          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span className="">Symptom Management</span>
          </div>

          {/** SYMPTOM FILTER */}
          <div className="mb-4">
            <p className={styles.filter}>Filters:</p>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Text</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <input
                      type="text"
                      className="form-control"
                      value={form.text || ""}
                      onChange={(e) => onChange("text", e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Restriction</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <select
                      className="form-select w-full"
                      value={form.restriction || ""}
                      disabled={state?.from !== "new_symptom"}
                      onChange={(e) => onChange("restriction", e.target.value)}
                    >
                      <option value="">Null</option>
                      <option value="Products">Products</option>
                      <option value="Services">Services</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/** ENTER SYMPTOM IN YOUR WORD */}
          <div className="mb-4">
            <p className={`${styles.filter} mb-4 `}>
              Select Aberration from search results below:
            </p>

            <div className="mb-3 row">
              <div className={`${styles.tableHead} col-1`}>
                <div className={styles.th}>Id</div>
              </div>

              <div className={`${styles.tableHead} col-2`}>
                <div className={styles.th}>Aberration</div>
              </div>
              <div className={`${styles.tableHead} col-7`}>
                <div className={styles.th}>Description</div>
              </div>
              <div className={`${styles.tableHead} col-2`}>
                <div className={styles.th}>Restriction</div>
              </div>
            </div>

            {filtered.map((aberration) => (
              <div
                className={`${styles.rowCard} d-flex mb-2 row `}
                onClick={() => handleClick(aberration.aberration_id)}
              >
                {/* Id */}
                <div className={`${styles.val} col-1`}>
                  <div>{aberration.aberration_id}</div>
                </div>

                <div className={`${styles.val} col-2`}>
                  <div className={styles.spacer}></div>
                  {aberration.aberration}
                </div>

                <div className={`${styles.val} col-7`}>
                  <div className={styles.spacer}></div>
                  {aberration.description || "-"}
                </div>

                <div className={`${styles.val} col-2`}>
                  <div className={styles.spacer}></div>
                  {aberration.restriction || 'Null'}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AberrationSelect;
