import configs from "configs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken } from "redux-store/features/authSlice";
import {
  selectModelInstructionData,
  setContextData,
  setFrom,
} from "redux-store/features/modelInstructionSlice";
import {
  prefillEndCategory,
  selectModelManagementData,
  setSelectedAttribute,
  updateSelectedAttribute,
  updateSelectedModel,
} from "redux-store/features/modelManagementSlice";
import AdminService from "services/AdminServise";
import CategoryService from "services/CategoryService";
import { setUpCategoryIfPrefilledExists } from "shared/helpers";
import MultiStepCategorySelect from "views/components/MultiStepCategorySelect";
import { modes } from "./helper";

function EditAttribute({ categories }) {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const navigate = useNavigate();

  const { selectedAttribute, prefilledEndCategory, mode, selectedModel } =
    useSelector(selectModelManagementData);

  const onChange = ({ key, value }) =>
    dispatch(updateSelectedAttribute({ key, value }));

  const onAttributeChange = async (value) => {
    try {
      // fetch brand by id
      const res = await AdminService.getAttribute(token, dispatch, value);

      // update selected bramd model fields
      if (res.data.success && res.data.data) {
        dispatch(updateSelectedModel({ key: "generic_attributes", value }));

        dispatch(setSelectedAttribute({ ...res.data.data }));
      }
    } catch (e) {}
  };

  useEffect(() => {
    let mounted = true;
    if (selectedAttribute && mounted) {
      fetchCurrentCategoryTree();
    }
    return () => {
      mounted = false;
    };
  }, [selectedAttribute?.brand_model_attribute_id]);

  const [dropdownMatrixEnd, setDropdownMatrixEnd] = useState([]);
  const [categoryEndInput, setCategoryEndInput] = useState("");
  useEffect(() => {
    let mounted = true;

    if (categories.length > 0 && prefilledEndCategory.length > 0 && mounted) {
      setUpCategoryIfPrefilledExists(
        prefilledEndCategory,
        setDropdownMatrixEnd,
        (category) =>
          onChange({
            key: "end_category",
            value: category.category_id,
          }),
        setCategoryEndInput,
        categories
      );
    } else setDropdownMatrixEnd([categories]);

    return () => {
      mounted = false;
    };
  }, [prefilledEndCategory]);

  const fetchCurrentCategoryTree = async () => {
    try {
      const res = await CategoryService.getParentTree(
        token,
        dispatch,
        selectedAttribute.end_category
      );

      dispatch(
        prefillEndCategory([...res.data.data, selectedAttribute?.end_category])
      );
    } catch (e) {}
  };

  const [allAttributes, setAllAttributes] = useState([]);
  const fetchAllAtributes = async () => {
    try {
      const res = await AdminService.getAllAttributes(token, dispatch);

      setAllAttributes(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    let mounted = true;

    if (mode != modes.view && mounted) {
      fetchCurrentCategoryTree();
      fetchAllAtributes();
    }

    return () => {
      mounted = false;
    };
  }, [mode]);

  const [selectedInstruction, setSelectedInstruction] = useState(null);
  const { instructions } = useSelector(selectModelInstructionData);

  useEffect(() => {
    if (selectedAttribute.serial_instructions && instructions.length > 0) {
      const x = instructions.filter(
        (e) => e.instruction_id == selectedAttribute.serial_instructions
      )[0];

      setSelectedInstruction(x);
    } else {
      setSelectedInstruction(null);
    }
  }, [instructions, selectedAttribute]);

  const goToSelectSerialInstructions = () => {
    dispatch(setFrom("model"));
    dispatch(
      setContextData({
        brand: null,
        category: selectedAttribute?.end_category || null,
      })
    );

    navigate("/database-records-management/serial-instruction");
  };

  const onCategoryChange = (category) =>
    onChange({
      key: "end_category",
      value: category.category_id,
    });

  const goToSAM = () =>
    navigate("/database-records-management/sam", {
      state: {
        for: "model_attributes",
        brand: null,
        end_category: selectedAttribute?.end_category || null,
      },
    });

  return (
    <div className="row">
      <div className="col-xl-8">
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Generic Attributes ID</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              value={selectedModel?.generic_attributes || ""}
              defaultValue=""
              placeholder="Select Attribute"
              onChange={(e) => onAttributeChange(e.target.value)}
              required
            >
              {[
                <option value="" disabled>
                  Select Attribute
                </option>,
                ...allAttributes.map((item) => (
                  <option value={item.brand_model_attribute_id}>
                    {item.brand_model_attribute_id}
                  </option>
                )),
              ]}
            </select>
          </div>
        </div>

        {/** END CATEGORY */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">End Category</label>
          </div>

          <div className="col-md-9">
            <MultiStepCategorySelect
              categoryInput={categoryEndInput}
              setCategoyInput={setCategoryEndInput}
              dropdownMatrix={dropdownMatrixEnd}
              setDropdownMatrix={setDropdownMatrixEnd}
              setValue={onCategoryChange}
              id="model-attributes__end-category"
            />

            {dropdownMatrixEnd.length === 0 && (
              <div>Please select end category</div>
            )}
          </div>
        </div>

        {/** ITEM CLASS */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Item Class</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              value={selectedAttribute?.item_class || ""}
              defaultValue=""
              onChange={(e) =>
                onChange({ key: "item_class", value: e.target.value })
              }
            >
              <option value="" disabled>
                Select Item Class
              </option>
              <option value={"Multiple use product"}>
                Multiple use product
              </option>
              <option value={"Single use product"}>Single use product</option>
              <option value={"Continuous service"}>Continuous service</option>
              <option value={"One-off service"}>One-off service</option>
            </select>
          </div>
        </div>

        {/** OS SW Upgradeable */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">OS SW Upgradeable</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedAttribute?.os_sw_updateable === null
                  ? ""
                  : +selectedAttribute.os_sw_updateable
              }
              defaultValue=""
              onChange={(e) =>
                onChange({ key: "os_sw_updateable", value: +e.target.value })
              }
            >
              <option disabled value={""}>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        {/** SW APPLICATION */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Software Application</label>
          </div>

          <div className="col-md-9">
            {selectedAttribute.sw_application_name || "Null"}
            {selectedAttribute?.os_sw_updateable ? (
              <button
                style={{
                  marginLeft: "10px",
                }}
                className="btn btn-yellow"
                onClick={goToSAM}
              >
                Manage
              </button>
            ) : null}
          </div>
        </div>

        {/** SERIAL TYPE */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Serial Type</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              required
              value={selectedAttribute?.serial_type || ""}
              defaultValue=""
              onChange={(e) =>
                onChange({ key: "serial_type", value: e.target.value })
              }
            >
              <option value="" disabled>
                Select Serial Type
              </option>
              <option value={"Serial number"}>Serial Number</option>
              <option value={"Batch number"}>Batch Number</option>
              <option value={"Chassis number"}>Chassis Number</option>
              <option value={"Account number"}>Account Number</option>
              <option value={"Case number"}>Case Number</option>
              <option value={"Transaction number"}>Transaction Number</option>
              <option value={"Job number"}>Job Number</option>
              <option value={"Location gps"}>Location GPS</option>
              <option value={"Location url"}>Location URL</option>
              <option value={"None"}>None</option>
            </select>
          </div>
        </div>

        {/** SERIAL INSTRUCTIONS */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Serial Instructions</label>
          </div>

          <div className="col-md-9">
            <div className="d-flex align-items-center">
              <div className="me-2">
                <a
                  target={"_blank"}
                  href={
                    selectedInstruction?.file
                      ? `${configs.appAPIAssets}${selectedInstruction.file}`
                      : null
                  }
                >
                  {selectedInstruction?.name || "Null"}
                </a>
              </div>

              <button
                className="btn btn-yellow"
                onClick={goToSelectSerialInstructions}
              >
                Select
              </button>
            </div>
          </div>
        </div>

        {/** PERSONALIZED */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label>Personalized</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedAttribute?.personalized === null
                  ? ""
                  : selectedAttribute?.personalized
              }
              defaultValue=""
              onChange={(e) =>
                onChange({ key: "personalized", value: +e.target.value })
              }
            >
              <option value={""} disabled>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        {/** MODEL GROUP */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Model Group</label>
          </div>
          <div className="col-md-9">
            <p>Null</p>
          </div>
        </div>

        {/** SERIAL UNIQUENESS */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Serial Uniqueness</label>
          </div>
          <div className="col-md-9">
            <select
              className="form-select"
              value={selectedAttribute?.serial_uniqueness || ""}
              onChange={(e) =>
                onChange({ key: "serial_uniqueness", value: e.target.value })
              }
              defaultValue=""
              required
            >
              <option value={""} disabled>
                Select
              </option>
              <option value={"brand"}>Brand</option>
              <option value={"model"}>Model</option>
              <option value={"batch"}>Batch</option>
              <option value={"none"}>None</option>
            </select>
          </div>
        </div>

        {/** SERVICEABLE */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Serviceable</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedAttribute?.serviceable === null
                  ? ""
                  : selectedAttribute?.serviceable
              }
              defaultValue=""
              onChange={(e) =>
                onChange({ key: "serviceable", value: +e.target.value })
              }
            >
              <option value={""} disabled>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        {/** SOFTWARE */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Software</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedAttribute?.software === null
                  ? ""
                  : selectedAttribute?.software
              }
              defaultValue=""
              onChange={(e) =>
                onChange({ key: "software", value: e.target.value })
              }
            >
              <option value={""} disabled>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        {/** PREVENT USE OVERLAP */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Prevent Use Overlap</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedAttribute?.prevent_use_overlap === null
                  ? ""
                  : selectedAttribute?.prevent_use_overlap
              }
              defaultValue=""
              onChange={(e) =>
                onChange({
                  key: "prevent_use_overlap",
                  value: +e.target.value,
                })
              }
            >
              <option value={""} disabled>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        {/** TRANSFERABLE */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Transferable</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedAttribute?.transferable === null
                  ? ""
                  : selectedAttribute?.transferable
              }
              defaultValue=""
              onChange={(e) =>
                onChange({ key: "transferable", value: +e.target.value })
              }
            >
              <option value={""} disabled>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        {/** PRIVATE */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Private</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedAttribute?.private === null
                  ? ""
                  : selectedAttribute?.private
              }
              defaultValue=""
              onChange={(e) =>
                onChange({ key: "private", value: +e.target.value })
              }
            >
              <option value={""} disabled>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAttribute;
