const { axiosClient } = require("shared/axiosInstance");

class UnverifiedRecordService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async fetchSummary() {
    return this.client.get("/unverified-records-summary");
  }

  async fetchSummaryList(type) {
    return this.client.get("/unverified-records-summary/list?type=" + type);
  }
}

export default UnverifiedRecordService;
