import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "redux-store/features/authSlice";
import {
  setContextData,
  setFrom,
} from "redux-store/features/modelInstructionSlice";
import "./index.css";

function DatabaseRecordsManagement() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const goToCategoryManagement = () =>
    navigate("/database-records-management/category");

  const goToBrandModelManagement = () =>
    navigate("/database-records-management/brand-model");

  const goToBypassManagement = () =>
    navigate("/database-records-management/bypass");

  const goToCompanyManagement = () =>
    navigate("/database-records-management/company");

  const goToBrandManagement = () =>
    navigate("/database-records-management/brand");

  const goToModelManagement = () =>
    navigate("/database-records-management/model");

  const goToGlobalVariables = () =>
    navigate("/database-records-management/global-variables");

  const goToScheduledTasks = () =>
    navigate("/database-records-management/scheduled-tasks");

  const goToModelInstructions = () => {
    dispatch(setFrom("menu"));
    dispatch(setContextData(null));
    navigate("/database-records-management/serial-instruction");
  };

  const goToSWManagement = () => navigate("/database-records-management/sam");

  const goToUnverifiedRecordSummary = () =>
    navigate("/database-records-management/unverified-record-summary");

  const goToQsManagement = () =>
    navigate("/database-records-management/qs-management");

  return (
    <>
      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center userLevel-Admin">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4 userLevel-Title">Database Record Management</h2>

          <div className="row admin-tool-cont">
            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToCategoryManagement}>
                <i className="bi bi-columns-gap"></i>
                Category Management
              </a>
            </div>

            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToBrandModelManagement}>
                <i className="bi bi-layers"></i>
                Brand Model Management
              </a>
            </div>

            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToCompanyManagement}>
                <i className="bi bi-pie-chart"></i>
                Company Management
              </a>
            </div>

            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToModelManagement}>
                <i className="bi bi-box"></i>
                Model Management
              </a>
            </div>

            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToBrandManagement}>
                <i className="bi bi-box"></i>
                Brand Management
              </a>
            </div>

            {user && user.admin && user.admin.permission === "A1" && (
              <div className="col-md-4 col-sm-6 admin-tool">
                <a className="puBox actionBox" onClick={goToBypassManagement}>
                  <i className="bi bi-key"></i>
                  Bypass
                </a>
              </div>
            )}

            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToGlobalVariables}>
                <i className="bi bi-activity"></i>
                Database Global Variables
              </a>
            </div>

            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToScheduledTasks}>
                <i className="bi bi-wrench-adjustable"></i>
                Database Scheduled Tasks
              </a>
            </div>

            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToModelInstructions}>
                <i className="bi bi-box"></i>
                Model Instructions Management
              </a>
            </div>

            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToSWManagement}>
                <i className="bi bi-box"></i>
                SW Management
              </a>
            </div>

            <div className="col-md-4 col-sm-6 admin-tool">
              <a
                className="puBox actionBox"
                onClick={goToUnverifiedRecordSummary}
              >
                <i className="bi bi-box"></i>
                Unverified Record Summary
              </a>
            </div>

            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToQsManagement}>
                <i className="bi bi-box"></i>
                Quality and Symptom Management
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DatabaseRecordsManagement;
