import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ActionTile from "./ActionTile";
import { views } from "./helper";
import "./index.css";
import { useMessageCases } from "hooks/useMessageCases";
import LoadIndicator from "views/components/LoadIndicator";
import { useSelector } from "react-redux";
import {
  selectMessageCases,
  selectMessageCasesBugTypes,
} from "redux-store/features/messageCasesSlice";
import { formatDDMMYYYYDash } from "shared/helpers";

function Messages() {
  const controller = useMessageCases();
  const navigate = useNavigate();

  const [view, setView] = useState(null);
  const [loading, setLoading] = useState(false);

  const cases = useSelector(selectMessageCases);
  const bugTypes = useSelector(selectMessageCasesBugTypes);

  const openSupporterCases = useMemo(() => {
    return cases.filter((e) => !Boolean(e.closed) && Boolean(e.supporter));
  }, [cases]);

  const openRequestCases = useMemo(() => {
    return cases.filter((e) => !Boolean(e.closed) && !Boolean(e.supporter));
  }, [cases]);

  const closedCases = useMemo(() => {
    return cases.filter((e) => Boolean(e.closed));
  }, [cases]);

  const currentCases = useMemo(() => {
    switch (view) {
      case views.open_supporter:
        return openSupporterCases;

      case views.open_request:
        return openRequestCases;

      case views.closed:
        return closedCases;

      default:
        return [];
    }
  }, [view, openSupporterCases, openRequestCases, closedCases]);

  const getTypeName = useCallback(
    (type_id) => {
      const type = bugTypes.filter((e) => e.type_id === type_id)[0];

      return type?.name;
    },
    [bugTypes.length]
  );

  const changeView = (view) => setView(view);

  const handleCaseClick = useCallback(
    (case_id) => () => {
      navigate(`/messages/case/${case_id}`);
    },
    []
  );

  const cleanUp = () => {
    setView(null);
    setLoading(false);
  };

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);

    const caseType = search.get("case");

    if (caseType) setView(caseType);

    const fetchCases = async () => {
      setLoading(true);
      await controller.httpGetCases();
      setLoading(false);
    };

    fetchCases();

    return cleanUp;
  }, []);

  useEffect(() => {
    if (bugTypes.length === 0) {
      controller.httpGetBugTypes();
    }
  }, [bugTypes.length]);

  return (
    <div id="main-container" className="messaging-main-container">
      <div className="container">
        <div className="row gx-4">
          {/** Left panel */}
          <div className="col-12 col-md-4 col-xl-3">
            <div className="messaging-messages-action pb-5 fadeIn">
              <h5 className="mx-2 py-3">Messages</h5>
              <Link className="btn btn-yellow mb-4 ms-2" to="/messages/new">
                New Case
              </Link>

              {openSupporterCases && openSupporterCases.length > 0 && (
                <ActionTile
                  label={"Open Supporter Cases"}
                  value={views.open_supporter}
                  changeView={changeView}
                  selected={view === views.open_supporter}
                />
              )}

              {openRequestCases && openRequestCases.length > 0 && (
                <ActionTile
                  label={"Open Request Cases"}
                  value={views.open_request}
                  selected={view === views.open_request}
                  changeView={changeView}
                />
              )}

              {closedCases && closedCases.length > 0 && (
                <ActionTile
                  label={"Closed Cases"}
                  value={views.closed}
                  selected={view === views.closed}
                  changeView={changeView}
                />
              )}
            </div>
          </div>

          {/** Right panel */}
          {view && (
            <div className="col-12 col-md-8 col-xl-9">
              <div className="messaging-messages-container fadeIn">
                <div className="top">
                  <h5>Open Cases</h5>
                </div>

                {loading && (
                  <div className="mx-4">
                    <LoadIndicator />
                  </div>
                )}

                {!loading && view && (
                  <div className="table-responsive m-4">
                    <table className="table table-responsive table-bordered table-dark-theme">
                      <thead>
                        <tr>
                          <th>Case</th>

                          <th>Type</th>

                          <th>Summary</th>

                          <th>Updated</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentCases.map((e) => (
                          <tr
                            key={`message-case-${e.id}`}
                            className={`case-row ${e.hasUnread ? 'hasUnread' : ''}`}
                            onClick={handleCaseClick(e.id)}
                          >
                            <td>{e.id}</td>

                            <td>{getTypeName(e.type) || "-"}</td>

                            <td>{e.title}</td>

                            <td>{formatDDMMYYYYDash(e.updated_at)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Messages;
