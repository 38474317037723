import React from "react";
import { useDispatch } from "react-redux";
import { updateSelectedCompany } from "redux-store/features/companyManagementSlice";
import { COUNTRIES } from "shared/constants";
import { formatDDMMYYYYDash } from "shared/helpers";
import LoadIndicator from "views/components/LoadIndicator";
import { formatEnum, modes } from "./helpers";

const active1 = ["Limited", "Not for profit", "Charity"];

function EditCompany({ selectedCompany, onSubmit = () => {} }) {
  const dispatch = useDispatch();
  const onChange = (key, value) =>
    dispatch(updateSelectedCompany({ key, value }));

  if (!selectedCompany) return <LoadIndicator size="lg" />;

  return (
    <form id="company-edit-form" onSubmit={onSubmit}>
      {/** COMPANY ID */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Company ID</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany.company_id || "Null"}</p>
        </div>
      </div>

      {/** COMPANY NAME */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">
            Company Name <em>*</em>
          </label>
        </div>

        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="companyName"
            required
            placeholder="Samsung Inc"
            value={selectedCompany.company_name || ""}
            onChange={(e) => onChange("company_name", e.target.value)}
          />
        </div>
      </div>

      {/** COUNTRY */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">
            Country <em>*</em>
          </label>
        </div>

        <div className="col-md-9">
          <select
            value={selectedCompany.country}
            onChange={(e) => onChange("country", e.target.value)}
            className="form-select"
            required
          >
            {COUNTRIES.map((item) => (
              <option key={`country-cy ${Math.random()}`} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/** ADDRESS LINE 1 */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">
            Address Line 1{" "}
            {active1.includes(selectedCompany.type) && <em>*</em>}
            {selectedCompany.type == "unlimited" && <em>**</em>}
          </label>
        </div>

        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="addressLine1"
            value={selectedCompany.address_line1 || ""}
            onChange={(e) => onChange("address_line1", e.target.value)}
            // required={active1.includes(selectedCompany.type)}
          />
        </div>
      </div>

      {/** TOWN */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">
            Town {active1.includes(selectedCompany.type) && <em>*</em>}
            {selectedCompany.type == "unlimited" && <em>**</em>}
          </label>
        </div>

        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="town"
            value={selectedCompany.town || ""}
            onChange={(e) => onChange("town", e.target.value)}
            // required={active1.includes(selectedCompany.type)}
          />
        </div>
      </div>

      {/** REGION */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Region</label>
        </div>

        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="region"
            value={selectedCompany.region || ""}
            onChange={(e) => onChange("region", e.target.value)}
          />
        </div>
      </div>

      {/** POSTAL REF */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">
            Postal Ref {active1.includes(selectedCompany.type) && <em>*</em>}
            {selectedCompany.type === "Unlimited" && <em>**</em>}
          </label>
        </div>

        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="postalRef"
            maxLength={10}
            value={selectedCompany.postal_ref || ""}
            onChange={(e) => onChange("postal_ref", e.target.value)}
            // required={active1.includes(selectedCompany.type)}
          />
        </div>
      </div>

      {/** TYPE */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">
            Type <em>*</em>
          </label>
        </div>

        <div className="col-md-9">
          <select
            value={selectedCompany.type}
            onChange={(e) => onChange("type", e.target.value)}
            className="form-select"
            required
          >
            <option value={"Limited"}>Limited (private / public)</option>
            <option value={"Unlimited"}>
              Unlimited (sole trader / partnership)
            </option>
            <option value={"Charity"}>Charity</option>
            <option value={"Not for profit"}>
              Not for profit / community interest
            </option>
            <option value={"Government"}>Government</option>
          </select>
        </div>
      </div>

      {/** PUBLIC LOCATION */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Public Location</label>
        </div>

        <div className="col-md-9">
          <select
            value={selectedCompany.public_location}
            onChange={(e) => onChange("public_location", e.target.value)}
            className="form-select"
            required
          >
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
        </div>
      </div>

      {/** STATUS */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Status</label>
        </div>

        <div className="col-md-9">
          <select
            value={selectedCompany.status}
            onChange={(e) => onChange("status", e.target.value)}
            className="form-select"
            required
          >
            <option value={"Actively Trading"}>Actively Trading</option>
            <option value={"Financial Trouble"}>Financial Trouble</option>
            <option value={"Insolvent"}>Insolvent</option>
            <option value={"Dormant"}>Dormant</option>
            <option value={"Closed"}>Closed</option>
          </select>
        </div>
      </div>

      {/** STATUS CHANGED */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Status Changed</label>
        </div>

        <div className="col-md-9">
          <p>
            {selectedCompany?.status_changed
              ? formatDDMMYYYYDash(selectedCompany.status_changed)
              : "Null"}
          </p>
        </div>
      </div>

      {/** CONTACT NUMBER */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">
            Contact Number {selectedCompany.type === "Unlimited" && <em>**</em>}
          </label>
        </div>

        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="contactNumber"
            value={selectedCompany.contact_number || ""}
            onChange={(e) => onChange("contact_number", e.target.value)}
            maxLength={20}
          />
        </div>
      </div>

      {/** CONTACT EMAIL */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">
            Contact Email {selectedCompany.type === "Unlimited" && <em>**</em>}
          </label>
        </div>

        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="contactEmail"
            value={selectedCompany.contact_email || ""}
            onChange={(e) => onChange("contact_email", e.target.value)}
          />
        </div>
      </div>

      {/** WEBSITE */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">
            Website {selectedCompany.type === "Unlimited" && <em>**</em>}
          </label>
        </div>

        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="website"
            value={selectedCompany.website || ""}
            onChange={(e) => onChange("website", e.target.value)}
          />
        </div>
      </div>

      {/** START DATE */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">
            Start Date <em>*</em>
          </label>
        </div>

        <div className="col-md-9">
          <input
            type="date"
            className="form-control"
            id="start_date"
            name="start_date"
            value={selectedCompany.start_date || ""}
            onChange={(e) => onChange("start_date", e.target.value)}
            required
          />
        </div>
      </div>

      {/** COMPANY REP */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Company Rep</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.company_rep || "Null"}</p>
        </div>
      </div>

      {/** CREATED BY */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Created By</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.created_by || "Null"}</p>
        </div>
      </div>

      {/** NOTES */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Notes</label>
        </div>

        <div className="col-md-9">
          <textarea
            type="text"
            className="form-control"
            id="notes"
            rows="6"
            placeholder="Note"
            name="note"
            value={selectedCompany.notes || ""}
            maxLength="200"
            onChange={(e) => onChange("notes", e.target.value)}
          />
        </div>
      </div>

      {/** VERIFICATION STATUS */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Verification Status</label>
        </div>

        <div className="col-md-9">
          <p>
            {selectedCompany?.verification_status
              ? formatEnum(selectedCompany.verification_status)
              : "Null"}
          </p>
        </div>
      </div>

      {/** PROFESSIONAL ID */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Professional ID</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.professional_id || "Null"}</p>
        </div>
      </div>

      {/** VERIFIED DATE */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Verified Date</label>
        </div>

        <div className="col-md-9">
          <p>
            {selectedCompany?.verified_date
              ? formatDDMMYYYYDash(selectedCompany.verified_date)
              : "Null"}
          </p>
        </div>
      </div>

      {/** PROFESSIONAL SALES */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Professional Sales</label>
        </div>

        <div className="col-md-9">
          <p>{selectedCompany?.professional_sales || "Null"}</p>
        </div>
      </div>
    </form>
  );
}

export default EditCompany;
