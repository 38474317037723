import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import UnverifiedRecordService from "services/UnverifiedRecordService";
import LoadIndicator from "views/components/LoadIndicator";
import styles from "./styles.module.css";

const UnverifiedRecordSummary = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const goBack = () => navigate(-1);

  const service = new UnverifiedRecordService(dispatch, token);

  const [summaryData, setSummaryData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSummaryData = async () => {
    if (loading) return;

    setLoading(true);

    try {
      const res = await service.fetchSummary();

      setSummaryData(res.data.data);
    } catch (error) {}

    setLoading(false);
  };

  useEffect(() => {
    fetchSummaryData();

    return () => setSummaryData(null);
  }, []);

  return (
    <>
      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center userLevel-Admin">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/Archive/ArrowLeft.png" alt="" />
            <span>Go Back</span>
          </div>

          <h2 className="mb-4 userLevel-Title">Unverified records summary</h2>

          <div className="infoContentCenter card mb-4">
            {loading ? (
              <LoadIndicator />
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered table-responsive table-dark-theme mb-4">
                  <thead>
                    <tr>
                      <th scope="col">Record</th>
                      <th scope="col">Null Owner</th>
                      <th scope="col">Owned</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Category</td>
                      <td className={styles.dataLink}>
                        <span>
                          <a
                            target="_blank"
                            href="/database-records-management/unverified-record-summary/list?item=category"
                          >
                            {summaryData?.category}
                          </a>
                        </span>
                      </td>
                      <td>-</td>
                    </tr>

                    <tr>
                      <td>Brand_Model</td>
                      <td className={styles.dataLink}>
                        <span>
                          <a
                            target="_blank"
                            href="/database-records-management/unverified-record-summary/list?item=brand_model_null"
                          >
                            {summaryData?.brand_model_null}
                          </a>
                        </span>
                      </td>
                      <td className={styles.dataLink}>
                        <span>
                          <a
                            target="_blank"
                            href="/database-records-management/unverified-record-summary/list?item=brand_model_owned"
                          >
                            {summaryData?.brand_model_owned}
                          </a>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Company</td>
                      <td className={styles.dataLink}>
                        <span>
                          <a
                            target="_blank"
                            href="/database-records-management/unverified-record-summary/list?item=company_null"
                          >
                            {summaryData?.company_null}
                          </a>
                        </span>
                      </td>
                      <td className={styles.dataLink}>
                        <span>
                          <a
                            target="_blank"
                            href="/database-records-management/unverified-record-summary/list?item=company_owned"
                          >
                            {summaryData?.company_owned}
                          </a>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>SW Version</td>
                      <td className={styles.dataLink}>
                        <span>
                          <a
                            target="_blank"
                            href="/database-records-management/unverified-record-summary/list?item=sw_version_null"
                          >
                            {summaryData?.sw_version_null}
                          </a>
                        </span>
                      </td>
                      <td className={styles.dataLink}>
                        <span>
                          <a
                            target="_blank"
                            href="/database-records-management/unverified-record-summary/list?item=sw_version_owned"
                          >
                            {summaryData?.sw_version_owned}
                          </a>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Symptom Check</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>

                    <tr>
                      <td>Symptom Assistance</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UnverifiedRecordSummary;
