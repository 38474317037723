import React from "react";
import { Route, Routes } from "react-router-dom";
import InternalMessaging from "views/messages";
import MessageCase from "views/messages/case";
import CreateNewCase from "views/messages/compose";

function MessagingRoutes() {
  return (
    <Routes>
      <Route index element={<InternalMessaging />} />
      <Route path="new" element={<CreateNewCase />} />
      <Route path="case/:case_id" element={<MessageCase />} />
    </Routes>
  );
}

export default MessagingRoutes;
