import { axiosClient } from "shared/axiosInstance";

class CronService {
  constructor(token, dispatch) {
    this.token = token;
    this.dispatch = dispatch;
  }

  execute(route) {
    return axiosClient(this.dispatch, this.token).post(
      `/execute-cron/${route}`,
      {}
    );
  }

  executeNewUserInvite() {
    return axiosClient(this.dispatch, this.token).post(
      "/execute-cron/new-user-invite",
      {}
    );
  }

  executePersonalDocDelete() {
    return axiosClient(this.dispatch, this.token).post(
      "/execute-cron/personal-doc-delete",
      {}
    );
  }

  executeCategoryEndStructure() {
    return axiosClient(this.dispatch, this.token).post(
      "/execute-cron/category-end-structure",
      {}
    );
  }
}

export default CronService;
