import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedQualityRecord: null,
};

export const unitQualityRecord = createSlice({
  name: "unit_quality_record",
  initialState,
  reducers: {
    setQualityRecord: (state, action) => {
      state.selectedQualityRecord = action.payload || null;
    },
  },
});

export const { setQualityRecord } = unitQualityRecord.actions;

export const selectUnitQualityRecordData = (state) => ({
  ...state.unit_quality_record,
});

export default unitQualityRecord.reducer;
