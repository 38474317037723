import React from 'react';
import { Navigate } from 'react-router-dom';
import {AuthService} from 'services';


const Logout = () => {
    AuthService.signout()
        .then(response => true)
        .catch(error => false)

    localStorage.clear();

    return <Navigate to = '/auth' />
}

export default Logout;