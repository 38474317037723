import React from "react";
import { Button, Modal } from "react-bootstrap";

function DeleteSuccessModal({ show, handleClose }) {
  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={handleClose}
      keyboard={false}
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <h5>Delete</h5>
      </Modal.Header>

      <Modal.Body style={{ padding: "0 32px", paddingBottom: 16 }}>
        <h5 className="mod-detail">User deleted successfully</h5>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button onClick={handleClose} variant="primary">
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteSuccessModal;
