import { useSelector } from "react-redux";
import { selectUser } from "redux-store/features/authSlice";
import styles from "./ApplicationRow.module.css";

const ApplicationRow = ({ data, onClick }) => {
  const user = useSelector(selectUser);

  const getCreatedByName = () => {
    if (!data) return "Null";

    if (!data.owner) return "Null";

    if (data.owner === user.user_id) return "You";

    return "Others";
  };

  return (
    <div className={`${styles.applicationRow} mb-2`} onClick={onClick}>
      <div className={styles.applicationRowData}>
        <div className={styles.applicationRowDataCol}>
          <div>
            <span>{data.name}</span>
          </div>
        </div>

        <div className={styles.applicationRowDataCol}>
          <div>
            <span>{data.application}</span>
          </div>
        </div>

        <div className={styles.applicationRowDataCol}>
          <div>
            <span>{getCreatedByName()}</span>
          </div>
        </div>

        <div className={styles.applicationRowDataCol}>
          <div>
            <span>{data.end_category_name || "Null"}</span>
          </div>
        </div>

        <div className={styles.applicationRowDataCol}>
          <div>
            <span>{data.brand_name || "Null"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationRow;
