import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialized: false,
  sw_versions: [],
};

export const softwareVersionManagement = createSlice({
  name: "software_version_management",
  initialState,
  reducers: {
    initializeSWVersionList: (state, action) => {
      state.sw_versions = action.payload;
      state.initialized = true;
    },

    updateSWVersion: (state, action) => {
      state.sw_versions = state.sw_versions.map((item) => {
        if (item.sw_version_id === action.payload.sw_version_id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
    },

    addSWVersion: (state, action) => {
      state.sw_versions = [...state.sw_versions, action.payload];
    },

    removeSWVersion: (state, action) => {
      state.sw_versions = state.sw_versions.filter(
        (item) => item.sw_version_id !== action.payload.sw_version_id
      );
    },
  },
});

export const {
  initializeSWVersionList,
  addSWVersion,
  updateSWVersion,
  removeSWVersion,
} = softwareVersionManagement.actions;

export const selectSoftwareVersionManagementData = (state) => ({
  ...state.software_version_management,
});

export default softwareVersionManagement.reducer;
