import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import SymptomService from "services/SymptomService";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";
import { handleServerError } from "views/database-records-management/company-management/helpers";

export const SendBarMessageModal = ({ show, handleClose, initMessage, affectedIds = [] }) => {
    const token = useSelector(selectToken);
    const dispatch = useDispatch();
    const service = new SymptomService(dispatch, token);

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [message, setMessage] = useState('')

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault()

        if (loading) return;

        setLoading(true);

        try {
            const payload = {
                message,
                affectedIds
            }

            await service.send_bar_message(payload)
            handleClose()
        } catch (error) {
            handleServerError(error, setErrors)
        }

        setLoading(false);
    }, [message, affectedIds, handleClose, setErrors, loading])

    useEffect(() => {
        if (initMessage) setMessage(initMessage.trim())
    }, [initMessage])

    return (
        <Modal
            show={show}
            onHide={handleClose}
            // backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
                <form
                    id="barMessageForm"
                    className="container px-4"
                    onSubmit={handleSubmit}
                >
                    <h5>Complete the notification below to the user(s) affected by this deletion</h5>

                    <textarea 
                        rows="5"
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    />
                </form>

                {errors.length > 0 && (
                    <div className="mt-4 px-4">
                        <ErrorsComponent errors={errors} />
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="primary"
                    disabled={loading || !message}
                    type="submit"
                    form="barMessageForm"
                >
                    {loading ? <LoadIndicator /> : "Submit"}
                </Button>

                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}