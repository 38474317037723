import { createSlice } from "@reduxjs/toolkit";
import { modes } from "views/database-records-management/model-management/helper";

const initialState = {
  mode: modes.view,
  selectedModel: null,
  selectedAttribute: {},
  prefilledEndCategory: [],
};

export const modelManagement = createSlice({
  name: "model_management",
  initialState,
  reducers: {
    switchMode: (state, action) => {
      state.mode = action.payload;

      if (action.payload === modes.new) {
        state.selectedModel = {
          model_id: null,
          model_name_des: "",
          model_code: "",
          generic_model: 0,
          generic_attributes: null,
          model_notes: "",
        };
        state.selectedAttribute = {
          brand_model_attributes_id: null,
          end_category: null,
          serial_type: "",
          item_class: "",
          serial_instructions: null,
          personalized: null,
          serviceable: null,
          software: null,
          prevent_use_overlap: null,
          transferable: 0,
          private: null,
          os_sw_updateable: 0,
        };
      }
    },

    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },

    updateSelectedModel: (state, action) => {
      state.selectedModel = {
        ...state.selectedModel,
        [action.payload.key]: action.payload.value,
      };
    },

    setSelectedAttribute: (state, action) => {
      if (state.selectedAttribute.serial_instructions) {
        action.payload.serial_instructions =
          state.selectedAttribute.serial_instructions;
      }

      if (action.payload == null) state.selectedAttribute = {};
      else state.selectedAttribute = action.payload;
    },

    updateSelectedAttribute: (state, action) => {
      console.log("[UPDATE_ATTRIBUTE]", action.payload);

      state.selectedAttribute = {
        ...state.selectedAttribute,
        [action.payload.key]: action.payload.value,
      };
    },

    prefillEndCategory: (state, action) => {
      state.prefilledEndCategory = action.payload;
    },

    addToPrefillCategory: (state, action) => {
      state.prefilledEndCategory = [
        ...state.prefilledEndCategory,
        action.payload,
      ];
    },

    clearPrefilledEndCategory: (state) => {
      state.prefilledEndCategory = [];
    },
  },
});

export const {
  setSelectedModel,
  switchMode,
  updateSelectedModel,
  setSelectedAttribute,
  updateSelectedAttribute,
  prefillEndCategory,
  addToPrefillCategory,
  clearPrefilledEndCategory,
} = modelManagement.actions;

export const selectModelManagementData = (state) => ({
  ...state.model_management,
});

export default modelManagement.reducer;
