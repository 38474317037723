import React, { useEffect, useState } from "react";
import "./styles.css";

function Autocomplete({
  suggestions,
  input,
  setInput,
  disabled,
  setValue,
  placeholder,
  labelKey,
  labelKey2,
  primaryKey,
  required,
  initialValue,
  addKeyToLabel,
  first = [],
  last,
}) {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  // const [input, setInput] = useState("");

  const onClick = () => {
    let idx = activeSuggestionIndex;

    if (first && first instanceof Array && first.length > 0) {
      idx = idx - first.length;
    }

    let currentSuggestion = filteredSuggestions[idx];

    if (!currentSuggestion) {
      setFilteredSuggestions([]);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);

      if (idx < 0) {
        // setValue(first[idx + first?.length || 0]);
        currentSuggestion = first[idx + first?.length || 0];
      } else {
        // setValue(last);
        currentSuggestion = last;
      }
      // return;
    }

    let input = currentSuggestion[labelKey];

    if (addKeyToLabel && currentSuggestion[primaryKey])
      input = currentSuggestion[primaryKey] + " - " + input;

    if (labelKey2 && currentSuggestion[labelKey2])
      input = input + " - " + currentSuggestion[labelKey2];

    if (
      primaryKey === "category_id" &&
      !currentSuggestion["verified_date"] &&
      currentSuggestion[labelKey] !== "All"
    )
      input = input + " (p)";

    setInput(input);
    setValue(currentSuggestion);
    setFilteredSuggestions([]);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };

  const onKeyDown = (e) => {
    if (Number(e.keyCode) === 13) {
      e.preventDefault();
      onClick(e);
    } else if (Number(e.keyCode) == "38") {
      if (activeSuggestionIndex > 0)
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
    } else if (Number(e.keyCode) == "40") {
      if (activeSuggestionIndex < filteredSuggestions.length)
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  const onHover = (index) => {
    setActiveSuggestionIndex(index);
  };

  const [initialIsSet, setInitialIsSet] = useState(false);
  useEffect(() => {
    if (initialValue && suggestions.length > 0 && !initialIsSet) {
      const selected = suggestions.filter(
        (item) => item[primaryKey] === initialValue
      )[0];

      if (selected) {
        let input = selected[labelKey];

        if (addKeyToLabel) input = selected[primaryKey] + " - " + input;

        if (labelKey2 && selected[labelKey2])
          input = input + " - " + selected[labelKey2];

        setInput(input);
        setInitialIsSet(true);
      }
    }
  }, [initialValue, suggestions, initialIsSet]);

  function sortList(suggestionA, suggestionB) {
    if (suggestionA[labelKey] < suggestionB[labelKey]) {
      return -1;
    }

    if (suggestionA[labelKey] > suggestionB[labelKey]) {
      return 1;
    }

    return 0;
  }

  const onChange = (query = "") => {
    if (!query) {
      setInput("");
      setFilteredSuggestions([...suggestions].sort(sortList));
      setActiveSuggestionIndex(0);
      setShowSuggestions(true);
      return;
    }

    if (!initialIsSet && query) return setInitialIsSet(true);

    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter((suggestion) => {
      let input = suggestion[labelKey];

      if (addKeyToLabel) input = suggestion[primaryKey] + " - " + input;

      if (labelKey2 && suggestion[labelKey2])
        input = input + " - " + suggestion[labelKey2];

      if (
        primaryKey === "category_id" &&
        !suggestion.verified_date &&
        suggestion[labelKey] !== "All"
      )
        input = input + " (p)";

      return input?.toLowerCase()?.includes(query.toLowerCase());
    });

    setInput(query);
    setFilteredSuggestions([...unLinked].sort(sortList));
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const toggleShowSuggestions = () => {
    if (disabled) {
      if (showSuggestions) {
        setShowSuggestions(false);
      }

      return;
    }

    if (!showSuggestions) {
      setFilteredSuggestions([...suggestions].sort(sortList));
    } else {
      setFilteredSuggestions([]);
    }

    setActiveSuggestionIndex(0);
    setShowSuggestions((prev) => !prev);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        className={`input-wrapper form-control ${
          disabled ? "input-wrapper-disabled" : ""
        }`}
      >
        <input
          type="text"
          onChange={(e) => onChange(e.target.value)}
          value={input || ""}
          disabled={disabled}
          className="form-control autocomplete"
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onBlur={() => {
            if (showSuggestions) {
              setFilteredSuggestions([]);
              setActiveSuggestionIndex(0);
              setShowSuggestions(false);
            }
          }}
          onFocus={() => {
            if (filteredSuggestions.length === 0) {
              setFilteredSuggestions([...suggestions].sort(sortList));
              setActiveSuggestionIndex(0);
              setShowSuggestions(true);
            }
          }}
          required={typeof required === "undefined" ? true : required}
        />
        <i
          className="bi bi-caret-down-fill"
          onClick={toggleShowSuggestions}
        ></i>
      </div>
      {showSuggestions ? (
        <SuggestionsListComponent
          filteredSuggestions={filteredSuggestions}
          labelKey={labelKey}
          labelKey2={labelKey2}
          onHover={onHover}
          activeSuggestionIndex={activeSuggestionIndex}
          primaryKey={primaryKey}
          onClick={onClick}
          last={last}
          first={first}
          addKeyToLabel={addKeyToLabel}
        />
      ) : null}
    </div>
  );
}

const SuggestionsListComponent = ({
  filteredSuggestions = [],
  labelKey,
  labelKey2,
  onHover,
  activeSuggestionIndex,
  primaryKey,
  last,
  first,
  onClick,
  addKeyToLabel,
}) => {
  return (
    <ul className="suggestions">
      {[...first, ...filteredSuggestions, last].map((suggestion, index) => {
        if (!suggestion) return;

        let input = suggestion[labelKey];

        if (addKeyToLabel && suggestion[primaryKey] !== "")
          input = suggestion[primaryKey] + " - " + input;

        if (labelKey2 && suggestion[labelKey2])
          input = input + " - " + suggestion[labelKey2];

        if (
          primaryKey === "category_id" &&
          !suggestion.verified_date &&
          suggestion[labelKey] !== "All"
        )
          input = input + " (p)";

        return (
          <li
            className={
              index === activeSuggestionIndex ? "suggestion-active" : ""
            }
            key={suggestion[primaryKey]}
            style={{ padding: 4 }}
            onMouseDown={onClick}
            onMouseEnter={() => onHover(index)}
          >
            {input}
          </li>
        );
      })}
    </ul>
  );
};

export default Autocomplete;
