import { useEffect, useState } from "react";
import { logout } from "redux-store/features/authSlice";

export const handle401 = (dispatch) => dispatch(logout());

export const useForm = (initialState = {}) => {
  const [form, set_form] = useState({ ...initialState });
  const [err, set_err] = useState([]);

  const onChange = (name, value) =>
    set_form((prev) => ({ ...prev, [name]: value }));

  const validate = (keys) => {
    set_err([]);

    for (const key of keys) {
      if (typeof form[key] === "undefined" || form[key] === null) {
        set_err([key + " is required."]);
        return false;
      }
    }

    return true;
  };

  const reset = () => set_form(initialState);

  const handleServerError = (error) => {
    const msg = error.response?.data?.message;
    if (msg) {
      if (msg instanceof String || typeof msg === "string") set_err([msg]);
      else {
        const arr = [];
        Object.keys(msg).map((key) => {
          for (const emsg of msg[key]) arr.push(emsg);
        });

        set_err(arr);
      }
    } else {
      set_err(["Something went wrong"]);
    }
  };

  return { form, onChange, reset, err, validate, handleServerError, set_err, set_form };
};

export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const useShortDisplay = (off, duration = 3000, showing = false) => {
  let timer = null;
  useEffect(() => {
    if (showing) {
      timer = setTimeout(() => {
        off();
      }, duration);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [showing]);
};

export const useDescriptionModal = () => {
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [description, setDescription] = useState("");
  const openDescriptionModal = (msg) => {
    setDescription(msg);
    setShowDescriptionModal(true);
  };

  const closeDescriptionModal = () => setShowDescriptionModal(false);

  return {
    showDescriptionModal,
    description,
    openDescriptionModal,
    closeDescriptionModal,
  };
};

export const isAllPresent = (str) => {
  // Regex to check if a string
  // contains uppercase, lowercase
  // special character & numeric value
  var pattern = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
  );

  // If the string is empty
  // then print No
  if (!str || str.length === 0) {
    return false;
  }

  // Print Yes If the string matches
  // with the Regex
  if (pattern.test(str)) {
    return true;
  } else {
    return false;
  }
};

export const formatMMDDYYYY = (date_string) => {
  const date = new Date(date_string);

  if (!date) return;

  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const formatEnum = (string = "") => {
  while (string.includes("_")) string = string.replace("_", " ");

  while (string.includes("-")) string = string.replace("-", " ");

  return `${string[0]?.toUpperCase()}${string.substring(1)?.toLowerCase()}`;
};

export const formatDDMMYYYYDash = (date_string) => {
  const date = new Date(date_string);

  if (!date) return;

  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

export const logger = (msg, obj) => {
  console.log(msg);

  if (obj) console.log(obj);
};

export const handleError = (error, setErrors) => {
  const msg = error.response?.data?.message;
  if (msg) {
    if (msg instanceof String || typeof msg === "string") setErrors([msg]);
    else {
      const arr = [];
      Object.keys(msg).map((key) => {
        for (const emsg of msg[key]) arr.push(emsg);
      });

      setErrors(arr);
    }
  } else {
    setErrors(["Something went wrong, check your internet"]);
  }
};

/**
 * Paramaters
 * prefilledCategory: array desc: Array of category id on selected in ORDER
 * setDropmatrix: function
 * setCategory: function
 * setCategory: function
 * categories: array
 */
export const setUpCategoryIfPrefilledExists = (
  prefilledCategory = [],
  setDropdownMatrix = () => {},
  setCategory,
  setCategoryInput,
  categories = [],
  noFilter
) => {
  if (prefilledCategory.length === 0) return;

  let dropdownMatrix = [categories];

  let currentCategory = categories[0];
  let categoryInput = {};

  for (let i = 0; i < prefilledCategory.length; i++) {
    if (i === 0) {
      categoryInput[0] = "All";
    } else {
      dropdownMatrix.push(currentCategory.children);

      const currentPrefilledCategory = prefilledCategory[i];

      currentCategory = currentCategory.children.filter(
        (category) => category.category_id == currentPrefilledCategory
      )[0];

      if (!currentCategory) return;

      categoryInput[i] = currentCategory.name;
    }
  }

  if (noFilter) {
    dropdownMatrix[0] = [
      ...dropdownMatrix[0],
      {
        category_id: "none",
        name: "No Filter",
        verified_date: new Date().toISOString(),
        children: [],
      },
    ];
  }

  setDropdownMatrix(dropdownMatrix);
  setCategory(currentCategory);
  setCategoryInput(categoryInput);
};

export const addLeadingZeros = (num, totalLength) => {
  return String(num).padStart(totalLength, "0");
};

export const formatYYYYMMDD = (date_string) => {
  const date = new Date(date_string);

  if (!date) return;

  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const formatDDMMYYY = (date_string) => {
  const date = new Date(date_string);

  if (!date) return;

  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const monthNameAbbrMap = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export const formatDateStringToDateNameYear = (date_string, splitter = "-") => {
  const split = date_string.split(splitter);

  return `${Number(split[2])} ${monthNameAbbrMap[Number(split[1])]} ${
    split[0]
  }`;
};

export const unitStatusMap = {
  awaiting_verification: "Awaiting Verification",
};

export const getDateToUse = (date_string, time_string) => {
  if (!date_string) return null;
  const date = new Date();

  const date_split = date_string.split("-");
  const time_split = time_string?.split(":");

  const year = Number(date_split[0]);
  let month = Number(date_split[1]) - 1;
  if (month < 10) month = "0" + month;
  let day = Number(date_split[2]);
  if (day < 10) day = "0" + day;

  let hour = 0;
  let minutes = 0;

  if (time_string) {
    hour = Number(time_split[0]);
    minutes = Number(time_split[1]);
  }

  date.setSeconds(0);

  return `${year}-${month}-${day} ${hour}:${minutes}:00`;
};

export const getFileName = (file = "") => {
  const splitUrl = file.split("/");
  return splitUrl[splitUrl.length - 1].split(".")[0];
};
