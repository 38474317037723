import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import SWVersionService from "services/SoftwareVersionServices";
import { useForm } from "shared/helpers";

import styles from "./SoftwareVersionManagement.module.css";
import VersionRow from "./VersionRow";

function SoftwareVersionManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);

  const [versions, setVersions] = useState([]);

  const { form, onChange } = useForm({ name: "", build_code: "" });

  const swVersionService = new SWVersionService(dispatch, token);

  const goBack = () => navigate(-1);

  const [application, setApplication] = useState(null);

  const { state } = useLocation();

  const goToSVR = (item) => {
    if (!application) return;

    navigate("/database-records-management/svr", {
      state: { for: "existing", application, item },
    });
  };

  const handleNewVersion = (e) => {
    e.preventDefault();

    if (!application) return;

    navigate("/database-records-management/svr", {
      state: {
        for: "new",
        application,
        prefilled: form,
      },
    });
  };

  const getVersions = async (id) => {
    try {
      const res = await swVersionService.fetchByApplication(id);

      setVersions(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (state) {
      setApplication(state.application);

      if (state.application && state.application.sw_application_id) {
        getVersions(state.application.sw_application_id);
      }
    }
  }, [state]);

  return (
    <>
      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center userLevel-Admin">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/Archive/ArrowLeft.png" alt="" />
            <span>Go Back</span>
          </div>

          <h2 className="mb-4 userLevel-Title">Software version management</h2>

          <div className={`${styles.applicationContainer} mb-4`}>
            <p className={styles.filter}>Application</p>

            <div className="row mb-2">
              <div className="col-6 col-md-2">
                <div className={styles.topLabel}>
                  <span>Name:</span>
                </div>
              </div>

              <div className={`${styles.topLabelVal} col-6 col-md-5`}>
                {application?.name}
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-6 col-md-2">
                <div className={styles.topLabel}>
                  <span>Details:</span>
                </div>
              </div>

              <div className={`${styles.topLabelVal} col-6 col-md-5`}>
                {application?.application}
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-6 col-md-2">
                <div className={styles.topLabel}>
                  <span>Hw Brand:</span>
                </div>
              </div>

              <div className={`${styles.topLabelVal} col-6 col-md-5`}>
                {application?.brand_name || "Null"}
              </div>
            </div>
          </div>

          <div className={`${styles.versionListContainer} mb-4`}>
            <div className={styles.versionListHeader}>
              <h5 className="mb-2 userLevel-Title">
                Select Software Application
              </h5>
            </div>

            <div className={`${styles.versionList}`}>
              <header className="mb-2">
                <div>
                  <div>
                    <span>Date</span>
                  </div>
                </div>

                <div>
                  <div>
                    <span>Name</span>
                  </div>
                </div>

                <div>
                  <div>
                    <span>Build code</span>
                  </div>
                </div>

                <div>
                  <div>
                    <span>Status</span>
                  </div>
                </div>
              </header>

              <form id="newVersionForm" onSubmit={handleNewVersion}>
                <div className="data">
                  {[...versions, { sw_version_id: null }].map((version) => {
                    return (
                      <VersionRow
                        key={`version-${version.sw_version_id}`}
                        data={version}
                        onClick={() => goToSVR(version)}
                        form={form}
                        onChange={onChange}
                      />
                    );
                  })}
                </div>
              </form>
            </div>

            <div className={styles.footText}>
              <p>
                *enter new version in the same format as the previous versions,
                then click (Create New) to create the record in the subsequent
                screen. Check instruction link above for information on how to
                find the version in your unit.
              </p>
            </div>

            <div className={`${styles.btnContaner} mt-4`}>
              <button className={styles.cancel} onClick={goBack}>
                Cancel
              </button>

              <button
                className={styles.new}
                type="submit"
                form="newVersionForm"
              >
                Create new
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SoftwareVersionManagement;
