import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectModelInstructionData } from "redux-store/features/modelInstructionSlice";
import { selectModelManagementData } from "redux-store/features/modelManagementSlice";

function ViewAttributes() {
  const { selectedAttribute, selectedModel } = useSelector(
    selectModelManagementData
  );

  const [selectedInstruction, setSelectedInstruction] = useState(null);
  const { instructions } = useSelector(selectModelInstructionData);

  useEffect(() => {
    if (selectedAttribute.serial_instructions && instructions.length > 0) {
      const x = instructions.filter(
        (e) => e.instruction_id == selectedAttribute.serial_instructions
      )[0];

      setSelectedInstruction(x);
    } else {
      setSelectedInstruction(null);
    }
  }, [instructions, selectedAttribute]);

  return (
    <div className="row">
      <div className="col-xl-8">
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Attributes ID</label>
          </div>
          <div className="col-md-9">
            <p>{selectedModel?.generic_attributes || "Null"}</p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">End Category</label>
          </div>
          <div className="col-md-9">
            <p>{selectedAttribute?.end_category || "Null"}</p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Item Class</label>
          </div>
          <div className="col-md-9">
            <p>{selectedAttribute?.item_class || "Null"}</p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">OS SW Upgradeable</label>
          </div>
          <div className="col-md-9">
            <p>
              {selectedAttribute?.os_sw_updateable === null
                ? "Null"
                : Boolean(selectedAttribute.os_sw_updateable)
                ? "Yes"
                : "No"}
            </p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">SW Application</label>
          </div>
          <div className="col-md-9">
            <p>{selectedAttribute?.sw_application_name || "Null"}</p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Serial Type</label>
          </div>
          <div className="col-md-9">
            <p>{selectedAttribute?.serial_type || "Null"}</p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Serial Instructions</label>
          </div>
          <div className="col-md-9">
            <div className="d-flex align-items-center">
              <div className="me-2">
                <a
                  target={"_blank"}
                  href={
                    selectedInstruction?.file ? selectedInstruction.file : null
                  }
                >
                  {selectedInstruction?.name || "Null"}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label>Personalized</label>
          </div>
          <div className="col-md-9">
            <p>
              {selectedAttribute?.personalized === null
                ? "Null"
                : Boolean(selectedAttribute?.personalized)
                ? "Yes"
                : "No"}
            </p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Model Group</label>
          </div>
          <div className="col-md-9">
            <p>Null</p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Serial Uniqueness</label>
          </div>
          <div className="col-md-9">
            <p>{capitalize(selectedAttribute?.serial_uniqueness) || "Null"}</p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Serviceable</label>
          </div>
          <div className="col-md-9">
            <p>
              {selectedAttribute?.serviceable === null
                ? "Null"
                : Boolean(selectedAttribute?.serviceable)
                ? "Yes"
                : "No"}
            </p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Software</label>
          </div>
          <div className="col-md-9">
            <p>
              {selectedAttribute?.software === null
                ? "Null"
                : Boolean(selectedAttribute?.software)
                ? "Yes"
                : "No"}
            </p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Prevent Use Overlap</label>
          </div>
          <div className="col-md-9">
            <p>
              {selectedAttribute?.prevent_use_overlap === null
                ? "Null"
                : Boolean(selectedAttribute?.prevent_use_overlap)
                ? "Yes"
                : "No"}
            </p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Transferable</label>
          </div>
          <div className="col-md-9">
            <p>
              {selectedAttribute?.transferable === null
                ? "Null"
                : Boolean(selectedAttribute?.transferable)
                ? "Yes"
                : "No"}
            </p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Private</label>
          </div>
          <div className="col-md-9">
            <p>
              {selectedAttribute?.private === null
                ? "Null"
                : Boolean(selectedAttribute?.private)
                ? "Yes"
                : "No"}
            </p>
          </div>
        </div>

        {/** */}
      </div>
    </div>
  );
}

export default ViewAttributes;
