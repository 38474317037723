import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import {
  initializeCompanyList,
  selectCompanyData,
} from "redux-store/features/companySlice";
import CompanyService from "services/CompanyService";

export const useCompany = () => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { company_list, initialized_company_list } =
    useSelector(selectCompanyData);

  const fetchCompanies = async () => {
    setLoading(true);
    try {
      const res = await CompanyService.getAvailableCompanies(token, dispatch);

      dispatch(initializeCompanyList(res.data.data));
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    if (!initialized_company_list && token) {
      fetchCompanies();
    }
  }, [initialized_company_list, token]);

  const refresh = () => {
    if (token) {
      fetchCompanies();
    }
  };

  return { all: company_list, refresh, loading };
};
