import { Modal, Button } from "react-bootstrap";

const ModelMismatchModal = ({
  show = false,
  handleClose = () => {},
  selectedCategoryName = "",
  genericCategoryName = "",
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
        <h5 className="mod-detail">
          Selected Brand_Model category and Generic Model category are not the
          same, the model will be restricted to the generic model's category,
          <br />
          Selected end category: {selectedCategoryName}
          <br />
          Generic Model category: {genericCategoryName}
          <br />
          <br />
          Create a new Generic model for the selected category if you need to
          use the selected category, otherwise do you want to overwrite category
          selection?
        </h5>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="primary" onClick={() => handleClose(true)}>
          Yes
        </Button>

        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModelMismatchModal;
