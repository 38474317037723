import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

export const submittingUserVerificationSlice = createSlice({
  name: "submittingUserVerification",
  initialState,
  reducers: {
    setSubmittingUserVerificationData: (state, action) => {
      const getName = (data) => {
        let name = "";

        name += data.first_name;

        if (data.middle_name) {
          name += ` ${data.middle_name}`;
        }

        name += ` ${data.last_name}`;

        return name;
      };

      const getAddress = (data) => {
        let address = "";

        address += `${data.property_number}, `;

        address += data.road_name;

        if (data.town) {
          address += `, ${data.town}`;
        }

        if (data.state) {
          address += `, ${data.state}`;
        }

        address += `, ${data.country}.`;

        if (data.postcode) {
          address += ` ${data.postcode}`;
        }

        return address;
      };

      const getFileName = (file) => {
        console.log("File", file);

        if (!file) return "Null";

        const splitUrl = file.split("/");

        let last = splitUrl[splitUrl.length - 1];

        if (last && last.includes("?")) last = last.split("?")[0];

        console.log("Split ", last);

        return last;
      };

      state.data = {
        history_id: action.payload.history_id,
        user_number: action.payload.random_id,
        name: getName(action.payload),
        address: getAddress(action.payload),
        gender: action.payload.gender,
        date_of_birth: action.payload.date_of_birth,
        current_verification_level: action.payload.verification_level,
        selfi_picture: action.payload.selfi_photo,
        selfi_picture_name: getFileName(action.payload.selfi_photo),
        submitted_text: action.payload.verify_text,
        verification_type: action.payload.type_name,
        initiated_by: action.payload.initiated_by,
        last_check_date: action.payload.check_date
          ? action.payload.check_date
          : "Null",
        improvement_requested: action.payload.reason_for_improvement
          ? action.payload.reason_for_improvement
          : "Null",
        submitted_document: action.payload.doc_image,
        submitted_document_name: getFileName(action.payload.doc_image),
        uploaded_date: action.payload.uploaded_date,
        locked_by: action.payload.locked_by,
        seconds_left: action.payload.seconds_left,
        locked_on: action.payload.locked_on,
      };

      // logger("Set Data", data);

      // state.data = data;
    },

    clearSubmittingUserVerificationData: (state) => {
      state.data = null;
    },
  },
});

export const {
  setSubmittingUserVerificationData,
  clearSubmittingUserVerificationData,
} = submittingUserVerificationSlice.actions;

export const selectSubmittingUserVerificationData = (state) => ({
  ...state.submittingUserVerification,
});

export default submittingUserVerificationSlice.reducer;
