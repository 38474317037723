import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import {
  clearSubmittingUserVerificationData,
  selectSubmittingUserVerificationData,
  setSubmittingUserVerificationData,
} from "redux-store/features/submittingUserVerificationSlice";
import AdminService from "services/AdminServise";
import FileDisplayThumb from "views/components/FileDisplay";
import { handleError, useForm, useShortDisplay } from "shared/helpers";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";
import ImprovementRequestModal from "./ImprovementRequestModal";
import RejectionModal from "./RejectionModal";
import LockTimer from "views/components/LockTimer";

function SubmittingUserVerification() {
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const [loading, set_loading] = useState(false);
  const { data } = useSelector(selectSubmittingUserVerificationData);

  const unlock = async () => {
    try {
      await AdminService.unlockHistory(token, dispatch);
    } catch (error) {}
  };

  const getOldestUserInfo = async () => {
    set_loading(true);
    try {
      const res = await AdminService.fetchSubmittingUserVerification(
        token,
        dispatch
      );

      if (res.data.data) {
        dispatch(setSubmittingUserVerificationData(res.data.data));
      }
    } catch (error) {}
    set_loading(false);
  };

  useEffect(() => {
    getOldestUserInfo();

    return () => {
      unlock();
    };
  }, []);

  const { form, onChange, reset, err, set_err } = useForm();
  useShortDisplay(() => set_err([]), 5000, err.length > 0);

  const [isImprovementModalOpen, setIsImprovementModalOpen] = useState(false);
  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);

  const [accepting, setAccepting] = useState(false);
  const acceptSubmittingVerification = async () => {
    setAccepting(true);

    try {
      const payload = {
        history_id: data.history_id,
        a1: form.a1 || false,
        a2: form.a2 || false,
        a3: form.a3 || false,
      };

      const res = await AdminService.acceptSubmittingVerification(
        token,
        dispatch,
        payload
      );

      if (Boolean(res.data.success)) {
        reset();
        dispatch(clearSubmittingUserVerificationData());
        getOldestUserInfo();
      }
    } catch (e) {
      handleError(e, set_err);
    }

    setAccepting(false);
  };

  const [timeUp, setTimeUp] = useState(false);

  useEffect(() => {
    if (timeUp) {
      unlock();
    }
  }, [timeUp]);

  return (
    <>
      <ImprovementRequestModal
        show={isImprovementModalOpen}
        handleClose={() => setIsImprovementModalOpen(false)}
        history_id={data?.history_id}
        reset={reset}
        getOldestUserInfo={getOldestUserInfo}
      />

      <RejectionModal
        show={isRejectionModalOpen}
        handleClose={() => setIsRejectionModalOpen(false)}
        history_id={data?.history_id}
        reset={reset}
        getOldestUserInfo={getOldestUserInfo}
      />

      <div id="main-container">
        <div className="container">
          {/**  */}
          <div className="row mb-4 justify-content-center">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4">Submitting User Verification</h2>

          {data && <LockTimer data={data} onComplete={() => setTimeUp(true)} />}

          {loading ? (
            <LoadIndicator />
          ) : !data ? (
            <div>No user available</div>
          ) : (
            <div className="infoContentCenter card mb-4">
              <div className="table-responsive">
                <table className="table table-responsive table-bordered table-dark-theme user-summary-table">
                  <tr>
                    <td>User Number</td>
                    <td>{data.user_number}</td>
                  </tr>
                  <tr>
                    <td>Invited Name</td>
                    <td>{data.invited_user_name ?? '-'}</td>
                  </tr>
                  <tr>
                    <td>User Name:</td>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <td>User Address:</td>
                    <td>{data.address}</td>
                  </tr>
                  <tr>
                    <td>Gender:</td>
                    <td>{data.gender}</td>
                  </tr>
                  <tr>
                    <td>Date of Birth:</td>
                    <td>{data.date_of_birth}</td>
                  </tr>
                  <tr>
                    <td>Current Verification:</td>
                    <td>Level {data.current_verification_level}</td>
                  </tr>
                  <tr>
                    <td>Selfi Picture:</td>
                    <td>
                      {data.selfi_picture ? (
                        <>
                          <FileDisplayThumb
                            location={data.selfi_picture}
                            showSpan
                          />
                        </>
                      ) : (
                        <div>Null</div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Reverse Image Search:</td>
                    <td>
                      <a
                        className="btn btn-yellow"
                        style={{ backgroundColor: "#fc3" }}
                        target="_blank"
                        href="https://images.google.com"
                      >
                        <i className="bi bi-images"></i> Reverse Image
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Sumbitted Text:</td>
                    <td>{data.submitted_text || "Null"}</td>
                  </tr>
                  <tr>
                    <td>Verification Type:</td>
                    <td>{data.verification_type}</td>
                  </tr>
                  <tr>
                    <td>Initiated by:</td>
                    <td>{data.initiated_by}</td>
                  </tr>
                  <tr>
                    <td>Last uploaded Date:</td>
                    <td>{data.uploaded_date}</td>
                  </tr>
                  <tr>
                    <td>Last Checked Date:</td>
                    <td>{data.last_check_date}</td>
                  </tr>
                  <tr>
                    <td>Improvement Requested:</td>
                    <td>{data.improvement_requested}</td>
                  </tr>
                  <tr>
                    <td>Submitted Document:</td>
                    <td>
                      {data.submitted_document ? (
                        <FileDisplayThumb
                          location={data.submitted_document}
                          showSpan
                        />
                      ) : (
                        <div>Null</div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Selfi Photo Acceptable</td>
                    <td>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          onChange={(e) => onChange("a1", e.target.checked)}
                          checked={form.a1 || false}
                          disabled={data.current_verification_level >= 2}
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckChecked"
                        ></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Address Verification Acceptable</td>
                    <td>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckChecked"
                          onChange={(e) => onChange("a2", e.target.checked)}
                          checked={form.a2 || false}
                          disabled={data.current_verification_level >= 2}
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckChecked"
                        ></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Photo Verification Acceptable</td>
                    <td>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckChecked"
                          onChange={(e) => onChange("a3", e.target.checked)}
                          checked={form.a3 || false}
                          disabled={
                            Number(data.current_verification_level) === 3
                          }
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckChecked"
                        ></label>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          )}

          <ErrorsComponent errors={err} />

          {!loading && data && (
            <div className="mt-4 text-center">
              {data.improvement_requested === "Null" && (
                <button
                  className="btn btn-blue me-2"
                  onClick={() => setIsImprovementModalOpen(true)}
                  disabled={timeUp}
                >
                  Request Improvement
                </button>
              )}

              <button
                className="btn btn-black me-2"
                disabled={timeUp}
                onClick={() => setIsRejectionModalOpen(true)}
              >
                Reject
              </button>

              <button
                className="btn btn-yellow"
                disabled={timeUp}
                onClick={acceptSubmittingVerification}
              >
                {accepting ? <LoadIndicator /> : "Accept"}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SubmittingUserVerification;
