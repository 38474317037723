import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { selectCategoryManagementData } from "redux-store/features/categoryManagement";
import CategoryService from "services/CategoryService";
import {
  handleError,
  setUpCategoryIfPrefilledExists,
  useShortDisplay,
} from "shared/helpers";
import { useMultiSelect } from "shared/useMultiSelect";
import Autocomplete from "views/components/Autocomplete";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";
import MultiStepCategorySelect from "views/components/MultiStepCategorySelect";

const ChangeStructure = ({ open, handleClose }) => {
  const { categoryManagementForm } = useSelector(selectCategoryManagementData);

  const [parent, setParent] = useState(null);

  useEffect(() => {
    if (open && categoryManagementForm?.parent_category) {
      setParent(categoryManagementForm.parent_category);
    }

    return () => setParent(null);
  }, [open, categoryManagementForm?.parent_category]);

  const handleCategorySelect = useCallback(
    (category) => {
      setParent(category.category_id);
    },
    [setParent]
  );

  const categorySelect = useMultiSelect({
    value: parent || "",
    setValue: handleCategorySelect,
  });

  const [errors, setErrors] = useState([]);
  useShortDisplay(() => setErrors([]), 5000, errors.length > 0);

  const [success, setSuccess] = useState([]);
  useShortDisplay(() => setSuccess([]), 2500, success.length > 0);

  const [loading, setLoading] = useState(false);

  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("parent", parent);
  }, [parent]);

  const handleChangeStructure = async () => {
    setLoading(true);
    try {
      const res = await CategoryService.changeStructure(token, dispatch, {
        child_category: categoryManagementForm.category_id,
        parent_category: parent,
      });

      if (res.status === 200) {
        setSuccess([res.data.message]);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      handleError(error, setErrors);
    }
    setLoading(false);
  };

  return (
    <Modal
      show={open}
      backdrop="static"
      onHide={handleClose}
      keyboard={false}
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <h5>Change Structure</h5>
      </Modal.Header>

      <Modal.Body style={{ padding: "0 32px", paddingBottom: 16 }}>
        <label className="mb-2">Select new parent category</label>

        <MultiStepCategorySelect
          id={"change-category-structure-parent"}
          {...categorySelect}
          setValue={handleCategorySelect}
        />

        {errors.length > 0 && <ErrorsComponent errors={errors} />}

        {success.length > 0 && (
          <ErrorsComponent errors={success} variant="alert-success" />
        )}
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <button className="btn btn-black" onClick={handleClose}>
          Cancel
        </button>

        <button className="btn btn-yellow" onClick={handleChangeStructure}>
          {loading ? <LoadIndicator /> : "Submit"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeStructure;
