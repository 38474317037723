import React, { useEffect, useMemo, useState } from "react";
import styles from "./SymptomRecord.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSymptomRecordData,
  setSelectedAberration,
  setSelectedSymptom,
  setSymptomRecordMode,
} from "redux-store/features/symptomRecordSlice";
import {
  addSymptom,
  updateSymptom,
  initializeSymptomList,
  selectSymptomManagementData,
} from "redux-store/features/symptomManagementSlice";
import SymptomService from "services/SymptomService";
import { selectToken } from "redux-store/features/authSlice";
import {
  formatDDMMYYY,
  useDescriptionModal,
  useForm,
  useShortDisplay,
} from "shared/helpers";
import {
  initializeAberrationList,
  selectAberrationManagementData,
} from "redux-store/features/aberrationManagementSlice";
import AberrationService from "services/AberrationService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ErrorsComponent from "views/components/Errors";
import WarningModal from "views/components/WarningModal";
import LockTimer from "views/components/LockTimer";
import DeleteModal from "./DeleteModal";
import { SendBarMessageModal } from "./SendBarMessageModal";
import DescModal from "views/components/DescModal";
import { CategoryUsedInModal } from "./CategoryUsedInModal";
import { QualityRecordsUsedInModal } from "./QualityRecordsUsedInModal";

const SymptomRecord = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const [qualityRecords, setQualityRecords] = useState([]);
  const [categorySymptomRecords, setCategorySymptomRecords] = useState([]);

  const aberrationService = new AberrationService(dispatch, token);
  const service = new SymptomService(dispatch, token);

  const unlockSymptoms = async () => {
    try {
      await service.unlock_symptoms();
    } catch (error) {}
  };

  const viewSymptom = async (id) => {
    try {
      const res = await service.view(id);

      onChange("locked_by", res.data.data.locked_by);
      onChange("locked_on", res.data.data.locked_on);
      onChange("seconds_left", res.data.data.seconds_left);
    } catch (error) {}
  };

  const { state } = useLocation();
  const { selectedSymptom, selectedAberration, mode } = useSelector(
    selectSymptomRecordData
  );

  const { symptoms, initialized: symptomsInitialized } = useSelector(
    selectSymptomManagementData
  );

  const selectedSymptomData = useMemo(() => {
    return (
      symptoms.filter((item) => item.symptom_id === selectedSymptom)[0] || null
    );
  }, [symptoms, selectedSymptom]);

  const fetchSymptoms = async () => {
    try {
      const res = await service.fetchAll();

      dispatch(initializeSymptomList(res.data.data || []));
    } catch (error) {}
  };
  useEffect(() => {
    if (!symptomsInitialized) fetchSymptoms();
  }, [symptomsInitialized]);

  const { aberrations, initialized: aberrationsInitialized } = useSelector(
    selectAberrationManagementData
  );

  const selectedAberrationData = useMemo(() => {
    return (
      aberrations.filter(
        (item) => item.aberration_id === selectedAberration
      )[0] || null
    );
  }, [aberrations, selectedAberration]);

  const fetchAberrations = async () => {
    try {
      const res = await aberrationService.fetchAll();

      dispatch(initializeAberrationList(res.data.data || []));
    } catch (error) {}
  };
  useEffect(() => {
    if (!aberrationsInitialized) fetchAberrations();
  }, [aberrationsInitialized]);

  const [loading, setLoading] = useState(false);

  const goBack = () => navigate(-1);

  const { form, onChange, reset, err, set_err, handleServerError, set_form } =
    useForm({
      default_severity: "Minor",
    });
  useShortDisplay(() => set_err([]), 5000, err.length > 0);

  useEffect(() => {
    console.log("[STATE]", state);
    if (state) {
      if (state.mode === "new") dispatch(setSymptomRecordMode("new"));

      if (state.mode === "new") {
        reset();
      }

      if (state.from === "existing_symptom") {
        dispatch(setSelectedSymptom(state.symptom_id));
        dispatch(setSymptomRecordMode("view"));
        window.history.replaceState({}, "");
      }

      if (state.from === "change_aberration") {
        const { form, symptom, mode } = JSON.parse(state.data);
        dispatch(setSelectedSymptom(symptom));
        dispatch(setSymptomRecordMode(mode));
        set_form(form);
      }
    }
  }, [state]);

  const fetchQualityRecords = async () => {
    if (!selectedSymptom) return;

    try {
      const res = await service.get_quality_records(selectedSymptom);

      setQualityRecords(res.data.data || []);
    } catch (error) {}
  };
  const fetchCategorySymptomRecords = async () => {
    if (!selectedSymptom) return;

    try {
      const res = await service.get_category_symptom_records(selectedSymptom);

      setCategorySymptomRecords(res.data.data || []);
    } catch (error) {}
  };

  useEffect(() => {
    if (selectedSymptom) {
      fetchQualityRecords();
      fetchCategorySymptomRecords();
    }

    if (selectedSymptomData && selectedSymptomData.aberration_id) {
      dispatch(setSelectedAberration(selectedSymptomData.aberration_id));
    }
  }, [selectedSymptomData, selectedSymptom]);

  useEffect(() => {
    if (state && mode === "new") {
      if (state.aberration) {
        onChange("aberration", state.aberration);
        dispatch(setSelectedAberration(state.aberration));
      }
    }
  }, [state, mode]);

  const insertSymptomRequest = async () => {
    setLoading(true);
    try {
      const end_category = state?.category || null;

      if (!end_category) {
        return set_err(["End category is required"]);
      }
      const res = await service.insert({
        ...form,
        end_category,
        symptom_description: form.description,
      });

      if (res.data.data) {
        dispatch(addSymptom(res.data.data));
        dispatch(setSelectedSymptom(res.data.data.symptom_id));
        dispatch(setSymptomRecordMode("view"));
        reset();
      }
    } catch (error) {
      handleServerError(error);
    } finally {
      setLoading(false);
    }
  };

  const cancelUpdateRequest = () => {
    closeWarning();
    setLoading(false);
  };

  const continueUpdateRequest = async () => {
    try {
      closeWarning();

      const end_category =
        state?.category || categorySymptomRecords[0]?.end_category || null;

      if (!end_category) {
        return set_err(["End category is required"]);
      }

      let payload = mode === "view" ? { ...selectedSymptomData } : { ...form };

      if (mode === "view")
        payload.aberration = selectedSymptomData.aberration_id;

      if (mode === "edit") payload.symptom_id = selectedSymptom;

      payload = { ...payload, end_category };

      const res = await service.approve(payload);

      if (res.data.data) {
        dispatch(updateSymptom(res.data.data));
        dispatch(setSelectedSymptom(res.data.data.symptom_id));
        dispatch(setSymptomRecordMode("view"));
        reset();
      }
    } catch (error) {
      console.log(error);
      handleServerError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateSymptomRequest = async () => {
    if (!selectedSymptomData) return;

    setLoading(true);

    if (
      mode == "edit" &&
      (selectedSymptomData.description !== form.description ||
        selectedSymptomData.additional_info !== form.additional_info) &&
      (qualityRecords.length > 0 ||
        (selectedSymptomData && selectedSymptomData["sales(n)"] > 0)) &&
      selectedSymptomData.status === "Verified"
    ) {
      return openWarning({
        text: "This record is already used make minor changes only",
        handleOk: continueUpdateRequest,
        hideCancel: false,
        handleCancel: cancelUpdateRequest,
      });
    }

    await continueUpdateRequest();

    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (loading) return;

    switch (mode) {
      case "new":
        return insertSymptomRequest();

      case "view":
      case "edit":
        return updateSymptomRequest();

      default:
        break;
    }
  };

  const handleCancel = () => {
    switch (mode) {
      case "edit":
        reset();
        dispatch(setSymptomRecordMode("view"));
        break;

      default:
        goBack();
        break;
    }
  };

  const changeAberration = () => {
    if (!selectedAberrationData || !selectedSymptomData) return;

    navigate("/database-records-management/qs-management/select-aberration", {
      state: {
        data: JSON.stringify({
          symptom: selectedSymptomData.symptom_id,
          restriction: selectedAberrationData.restriction,
          form,
          mode,
        }),
        from: "change_aberration",
      },
      replace: true,
    });
  };

  const [warning, setWarning] = useState(null);
  const openWarning = (data) => setWarning(data);
  const closeWarning = () => setWarning(null);

  const goToEditMode = () => {
    if (!selectedSymptomData || loading) return;

    onChange("aberration", selectedSymptomData.aberration_id);
    onChange("description", selectedSymptomData.description);
    onChange("additional_info", selectedSymptomData.additional_info);
    onChange("default_severity", selectedSymptomData.default_severity);

    dispatch(setSymptomRecordMode("edit"));

    if (qualityRecords.length > 0 && selectedSymptomData.status === "Verified")
      openWarning({
        text: "This record is already used, changes should be minor",
        handleOk: closeWarning,
        hideCancel: true,
      });
  };

  const checkProfessionalBeforeReject = () =>
    new Promise((res, rej) => {
      if (selectedSymptomData && selectedSymptomData.professional_id) {
        openWarning({
          text: "This record is owned, do you wish to continue?",
          handleOk: res,
          handleCancel: rej,
          positiveLabel: "Yes",
          negativeLabel: "No",
        });
      } else {
        res();
      }
    });

  const warnBeforeReject = () =>
    new Promise((res, rej) => {
      openWarning({
        text: "This symptom will be barred, have you included reason in Additional Info?",
        handleOk: res,
        handleCancel: rej,
        positiveLabel: "Yes",
        negativeLabel: "No",
      });
    });

  const [initBarMessage, setInitBarMessage] = useState("");
  const [affectedIds, setAffectedIds] = useState([]);

  const rejectSymptom = async () => {
    if (!selectedSymptom) return;

    try {
      await warnBeforeReject();
      closeWarning();
      await checkProfessionalBeforeReject();
      closeWarning();

      const res = await service.reject_symptom({
        symptom_id: selectedSymptom,
        ...form,
      });

      if (res.data.data) {
        dispatch(updateSymptom(res.data.data));

        const message = `
          The symptom ${res.data.data?.description} has been determined as not allowed under Qualy's policies Explanation given;        
        `;

        setInitBarMessage(message);
        if (Array.isArray(res.data.qualityOwnerIds))
          setAffectedIds(res.data.qualityOwnerIds.map((e) => +e.owner));

        dispatch(setSymptomRecordMode("view"));
        reset();
      }
    } catch (e) {
      closeWarning();
    }
  };

  useEffect(() => {
    if (mode === "edit" && selectedSymptom) {
      viewSymptom(selectedSymptom);
    } else {
      onChange("locked_by", null);
      onChange("locked_on", null);

      unlockSymptoms();
    }
  }, [mode, selectedSymptom]);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const openDeleteModal = () => setIsDeleteOpen(true);

  const closeDeleteModal = () => setIsDeleteOpen(false);

  const {
    showDescriptionModal,
    closeDescriptionModal,
    description,
    openDescriptionModal,
  } = useDescriptionModal();

  const [isCategoryUsedInOpen, setIsCategoryUsedInOpen] = useState(false);
  const openCategoriesUsedInModal = () => {
    setIsCategoryUsedInOpen(true);
  };

  const [isQualityRecordUsedInOpen, setIsQualityRecordUsedInOpen] =
    useState(false);
  const openQualityRecordUsedInModal = () => {
    setIsQualityRecordUsedInOpen(true);
  };

  return (
    <>
      <DescModal
        show={showDescriptionModal}
        handleClose={closeDescriptionModal}
        text={description}
      />

      <SendBarMessageModal
        handleClose={() => setInitBarMessage("")}
        initMessage={initBarMessage}
        show={Boolean(initBarMessage)}
        affectedIds={affectedIds}
      />

      <CategoryUsedInModal
        show={isCategoryUsedInOpen}
        data={categorySymptomRecords}
        handleClose={(refetch = false) => {
          setIsCategoryUsedInOpen(false);
          if (refetch) {
            fetchCategorySymptomRecords();
          }
        }}
        symptom={selectedSymptomData?.symptom_id}
      />

      <QualityRecordsUsedInModal
        show={isQualityRecordUsedInOpen}
        data={qualityRecords}
        handleClose={() => setIsQualityRecordUsedInOpen(false)}
      />

      {isDeleteOpen && (
        <DeleteModal
          show={isDeleteOpen}
          handleClose={closeDeleteModal}
          categoryRecords={categorySymptomRecords}
        />
      )}

      {warning && (
        <WarningModal
          show={warning !== null}
          text={warning.text}
          hideCancel={warning.hideCancel}
          handleOk={warning.handleOk}
          handleClose={warning.handleCancel}
          positiveLabel={warning.positiveLabel || undefined}
          negativeLabel={warning.negativeLabel || undefined}
        />
      )}

      <div id="main-container">
        <div className="container">
          <h2 className="mb-4 userLevel-Title">Symptom Record</h2>

          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span className="">Symptom Management</span>
          </div>

          {/** SYMPTOM FILTER */}
          <div className="mb-2">
            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Quality records used in:</span>
                    </div>
                  </div>

                  <span
                    className={`${styles.topValueSymptom} col`}
                    onClick={openQualityRecordUsedInModal}
                  >
                    {qualityRecords.length}
                  </span>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Categories used in</span>
                    </div>
                  </div>

                  <span
                    className={`${styles.topValueSymptom} col`}
                    onClick={openCategoriesUsedInModal}
                  >
                    {categorySymptomRecords.length}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <LockTimer
            data={form}
            onComplete={() => dispatch(setSymptomRecordMode("view"))}
          />

          <div className="mb-4">
            <div className="row mb-2">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Record ID:</span>
                    </div>
                  </div>

                  <span className={`${styles.topValueSymptom} col`}>
                    {selectedSymptomData?.symptom_id || "Null"}
                  </span>
                </div>
              </div>
            </div>

            <div className={`${styles.recordContainer} mb-2`}>
              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabelSymptom}>
                        <span>Aberration ID</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      {selectedAberrationData?.aberration_id || "Null"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabelSymptom}>
                        <span>Aberration</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {selectedAberrationData?.description ||
                              "No description"}
                          </Tooltip>
                        }
                      >
                        <span>{selectedAberrationData?.aberration}</span>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabelSymptom}>
                        <span>Restriction</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      {selectedAberrationData?.restriction || "Null"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabelSymptom}>
                        <span>Aberration Guidance</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      {selectedAberrationData?.guidance || "Null"}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form id="symptomRecordForm" onSubmit={handleSubmit}>
              <div className="row mb-2">
                <div className="col-12 ">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabelSymptom}>
                        <span>Description</span>
                      </div>
                    </div>
                    <div className={`${styles.topLabelVal} col`}>
                      {mode === "view" && (
                        <span>
                          {selectedSymptomData?.description || "Null"}
                        </span>
                      )}

                      {mode !== "view" && (
                        <textarea
                          rows="2"
                          className="form-control"
                          placeholder="Battery Life .50% of Original"
                          value={form.description || ""}
                          onChange={(e) =>
                            onChange("description", e.target.value)
                          }
                          required
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 ">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabelSymptom}>
                        <span>Additional Info</span>
                      </div>
                    </div>
                    <div className={`${styles.topLabelVal} col`}>
                      {mode === "view" &&
                        (selectedSymptomData?.additional_info || "Null")}

                      {mode !== "view" && (
                        <textarea
                          rows="2"
                          className="form-control"
                          placeholder="Assumes Similar Operations and App Usage"
                          value={form.additional_info || ""}
                          onChange={(e) =>
                            onChange("additional_info", e.target.value)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabelSymptom}>
                        <span>
                          Default Severity{" "}
                          <i
                            className="bi bi-info-circle"
                            onClick={() =>
                              openDescriptionModal(
                                `Critical: The problem represents a risk of harm to life or clear fraud ;
                                Major: The problem stops you using the main functions of the product or service
                                while it is occuring for a significant period, and/or would significantly affect the
                                resale value; Minor: The product/service can continue to be used for its main
                                functions while the problem occurs, or a more severe problem only happens for a
                                short period and is an integral function of the product`
                              )
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div className={`${styles.topLabelVal} col`}>
                      {mode === "view" &&
                        (selectedSymptomData?.default_severity || "Null")}

                      {mode !== "view" && (
                        <select
                          name="severity"
                          className="form-select w-full"
                          id="severity"
                          value={form.default_severity || ""}
                          onChange={(e) =>
                            onChange("default_severity", e.target.value)
                          }
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          <option value="Minor">Minor</option>
                          <option value="Major">Major</option>
                          <option value="Critical">Critical</option>
                        </select>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabelSymptom}>
                        <span>Status</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      {selectedSymptomData?.status || "Null"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabelSymptom}>
                        <span>Professional ID</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>Null</div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabelSymptom}>
                        <span>Verified Date</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      {selectedSymptomData?.verified_date
                        ? formatDDMMYYY(selectedSymptomData.verified_date)
                        : "Null"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-md-7">
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0 col-md-auto">
                      <div className={styles.topLabelSymptom}>
                        <span>Sales (N)</span>
                      </div>
                    </div>

                    <div className={`${styles.topLabelVal} col`}>
                      {(selectedSymptomData &&
                        (selectedSymptomData["sales(n)"] || "0")) ||
                        "0"}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {err && (
            <div className="mb-2">
              <ErrorsComponent errors={err} />
            </div>
          )}

          <div className={`${styles.actions} d-flex flex-row`}>
            <button
              className={styles.edit}
              disabled={mode !== "view" || !selectedSymptom || loading}
              onClick={goToEditMode}
            >
              Edit
            </button>

            <button
              className={styles.approve}
              type="submit"
              form="symptomRecordForm"
              disabled={
                (!selectedSymptom && mode !== "new") ||
                loading ||
                !selectedAberration ||
                (!form.description && !selectedSymptomData?.description) ||
                (mode === "new" && !state?.category) ||
                (mode === "edit" && categorySymptomRecords.length === 0)
              }
            >
              Approve
            </button>

            {console.log("[selectedSymptomData]", selectedSymptomData)}
            <button
              className={styles.approve}
              disabled={
                !selectedSymptom ||
                loading ||
                mode !== "edit" ||
                selectedSymptomData?.status === "Verified"
              }
              onClick={changeAberration}
            >
              Change Aberration
            </button>

            <button
              className={styles.reject}
              disabled={
                !selectedSymptomData ||
                mode === "new" ||
                loading ||
                selectedSymptomData["sales(n)"] > 0
                // qualityRecords.length > 0
              }
              onClick={rejectSymptom}
            >
              Bar
            </button>

            <button
              className={styles.cancel}
              disabled={loading}
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              className={styles.delete}
              disabled={
                loading ||
                !selectedSymptom ||
                (selectedSymptomData && selectedSymptomData["sales(n)"] > 0) ||
                qualityRecords.length > 0
              }
              onClick={openDeleteModal}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SymptomRecord;
