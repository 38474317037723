import { axiosClient } from "../shared/axiosInstance";

class MailService {
  constructor(token, dispatch) {
    this.token = token;
    this.dispatch = dispatch;
  }

  send_mail(data) {
    return axiosClient(this.dispatch, this.token).post("/mail", data);
  }

  send_reply(data) {
    return axiosClient(this.dispatch, this.token).post("/mail/reply", data);
  }

  get_mails() {
    return axiosClient(this.dispatch, this.token).get("/mail");
  }

  delete_mail(mail_id) {
    return axiosClient(this.dispatch, this.token).delete(`/mail/${mail_id}`);
  }
}

export default MailService;
