import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "redux-store/features/authSlice";

const baseRoute = "/database-records-management/qs-management";

const QsManagement = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const goToSymptom = () => navigate(baseRoute + "/symptom");

  const goToAberration = () => navigate(baseRoute + "/aberration");

  const goToUnitQuality = () => navigate(baseRoute + "/unit-quality");

  return (
    <>
      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center userLevel-Admin">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4 userLevel-Title">
            Quality and Symptom Management
          </h2>

          <div className="row admin-tool-cont">
            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToSymptom}>
                <i className="bi bi-box"></i>
                Symptom Management
              </a>
            </div>

            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToAberration}>
                <i className="bi bi-box"></i>
                Aberration Management
              </a>
            </div>

            <div className="col-md-4 col-sm-6 admin-tool">
              <a className="puBox actionBox" onClick={goToUnitQuality}>
                <i className="bi bi-box"></i>
                Unit Quality Management
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QsManagement;
