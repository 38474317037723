import "./styles.css";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import SecurityKeyService from "services/SecurityKeyService";
import {
  initializeSecurityKeyList,
  selectSecurityKeyManagementData,
} from "redux-store/features/securityKeyManagementSlice";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import FormTable from "views/components/form-table";
import { useForm } from "shared/helpers";
import { useNavigate } from "react-router-dom";
import LoadIndicator from "views/components/LoadIndicator";
import ErrorsComponent from "views/components/Errors";
import RealtimeNotificationService from "services/RealtimeNotificationService";
import {
  selectDbVariablesData,
  setDbVariables,
  updateDbVariable,
} from "redux-store/features/dbVariableSlice";
import DbVariablesService from "services/DbVariablesService";

const columns = [
  { key: "name", text: "Name" },
  { key: "service_url", text: "Service URL", editable: true },
  { key: "key_id", text: "ID", editable: true },
  { key: "key", text: "New Key", editable: true, required: true },
  { key: "updated_at", text: "Changed", type: "date" },
];

const ITEM_KEY = "name";

const SecurityKeyManagement = () => {
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goBack = () => navigate(-1);

  const service = new SecurityKeyService(dispatch, token);
  const notificationService = new RealtimeNotificationService(dispatch, token);

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const fetchAll = async () => {
    setLoading(true);
    try {
      const res = await service.fetchAll();

      // Set List Of Keys
      dispatch(initializeSecurityKeyList(res.data.data));
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const { keys, initialized } = useSelector(selectSecurityKeyManagementData);

  useEffect(() => {
    if (!initialized) {
      fetchAll();
    }
  }, [initialized]);

  const { form, onChange, handleServerError, err, set_err } = useForm({});

  const handleChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  useEffect(() => {
    if (keys && keys.length) {
      keys.forEach((item) => {
        const values = columns
          .filter((e) => e.editable)
          .map((e) => ({ [e.key]: item[e.key] }));

        values.forEach((e) =>
          onChange(
            item[ITEM_KEY] + "__" + Object.keys(e)[0],
            e[Object.keys(e)[0]]
          )
        );
      });
    }
  }, [keys]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (saving || loading) {
      return;
    }

    set_err([]);

    setSaving(true);

    try {
      const securityKeys = {};

      Object.keys(form).map(async (formKey) => {
        const name = formKey.split("__")[0];

        if (!securityKeys[name]) {
          securityKeys[name] = {};
        }

        const key = formKey.split("__")[1];
        const keyValue = form[formKey];

        securityKeys[name].name = name;
        securityKeys[name][key] = keyValue;
      });

      const calls = Object.values(securityKeys).map(async (val) => {
        await service.update(val);
      });

      await Promise.all(calls);
    } catch (error) {
      handleServerError(error);
    }

    setSaving(false);
  };

  const handleSiteAvailabilityToggle = async () => {
    try {
      const res = await notificationService.toggleSiteAvailability();

      dispatch(updateDbVariable({ site_available: res.data.data }));
    } catch (error) {}
  };

  const { variables } = useSelector(selectDbVariablesData);

  const variableService = new DbVariablesService(token, dispatch);
  const fetchDbVariables = async () => {
    try {
      const res = await variableService.getDatabaseGlobalVariables();

      dispatch(setDbVariables(res.data.data || null));
    } catch (error) {}
  };

  useEffect(() => {
    if (!variables) {
      fetchDbVariables();
    }
  }, [variables]);

  return (
    <>
      <div id="main-container" className="qualy-security">
        <div className="container">
          <div className="row mb-4 justify-content-center">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4">Security Key Management</h2>

          {loading === 0 && (
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
            />
          )}

          {!loading && (
            <form id="securityKeysForm" onSubmit={handleSubmit}>
              <div className="mb-4">
                <div className="row mb-2">
                  <div className="col-auto">
                    <div className={"qualy-security__topLabel"}>
                      <span>Site Control</span>
                    </div>
                  </div>

                  <div className={`col-auto`}>
                    <div className="d-flex flex-column justify-content-center h-100">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={Boolean(variables?.site_available)}
                          disabled={!variables}
                          onChange={handleSiteAvailabilityToggle}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-auto">
                    <div className={"qualy-security__topLabel"}>
                      <span>Site Status</span>
                    </div>
                  </div>

                  <div className={`col-auto qualy-security__topLabelVal`}>
                    {variables
                      ? Boolean(variables.site_available)
                        ? "Active"
                        : "Showing Unavailable Message"
                      : ""}
                  </div>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <FormTable
                    data={keys}
                    columns={columns}
                    form={form}
                    onChange={handleChange}
                    itemKey={ITEM_KEY}
                  />
                </div>
              </div>
            </form>
          )}

          {err.length > 0 && <ErrorsComponent errors={err} />}

          <div className="text-center">
            <a onClick={goBack} className="btn btn-black me-2">
              Back
            </a>

            <button
              type="submit"
              form="securityKeysForm"
              className="btn btn-yellow"
              disabled={loading || saving}
            >
              {saving ? <LoadIndicator /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecurityKeyManagement;
