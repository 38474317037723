import { createSlice, current } from "@reduxjs/toolkit";
import { logger } from "shared/helpers";

const initialState = {
  prefilledCategory: [],
  prefilledCompany: null,
  prefilledBrand: null,
  prefilledModel: null,
};

export const prefillSlice = createSlice({
  name: "prefill",
  initialState,
  reducers: {
    setPrefillDirect: (state, action) => {
      state.prefilledCategory = action.payload;
    },

    setPrefillCategory: (state, action) => {
      /**
       * Expected payload:
       * current_index: integer desc: current dropdown index
       * value: integer desc: Category Identifier
       * clear_index: integer desc: Index to start clearing from
       * clear_length: integer desc: Number of Items to be cleared
       */
      const { current_index, value, clear_index, clear_length } =
        action.payload;

      // If clear index is null add value at current index
      if (!clear_index) {
        state.prefilledCategory[current_index] = value;

        logger("Prefill", current(state.prefilledCategory));
      }
      // remove all items in prefill for clear index to clear length then set value at current index
      else {
        let prefilledCopy = [...state.prefilledCategory];

        prefilledCopy.splice(clear_index, clear_length);

        prefilledCopy[current_index] = value;

        state.prefilledCategory = prefilledCopy;
      }
    },

    clearPrefillCategory: (state) => {
      state.prefilledCategory = [];
    },

    setPrefillCompany: (state, action) => {
      state.prefilledCompany = action.payload;
    },

    clearPrefillCompany: (state) => {
      state.prefilledCompany = null;
    },

    setPrefilledBrand: (state, action) => {
      state.prefilledBrand = action.payload;
    },

    clearPrefillBrand: (state) => {
      state.prefilledBrand = null;
    },

    setPrefillModel: (state, action) => {
      state.prefilledModel = action.payload;
    },

    clearPrefillModel: (state) => {
      state.prefilledModel = null;
    },

    clearAllPrefilledData: (state) => {
      state.prefilledCategory = [];
      state.prefilledCompany = null;
      state.prefilledBrand = null;
      state.prefilledModel = null;
    },
  },
});

export const {
  setPrefillDirect,
  setPrefillCategory,
  clearPrefillCategory,
  setPrefillCompany,
  clearPrefillCompany,
  setPrefilledBrand,
  clearPrefillBrand,
  setPrefillModel,
  clearPrefillModel,
  clearAllPrefilledData,
} = prefillSlice.actions;

export const selectPrefilledData = (state) => ({ ...state.prefill });

export default prefillSlice.reducer;
