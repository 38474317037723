import { capitalize } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectBrandModelManagementData } from "redux-store/features/brandModelManagement";
import { selectModelInstructionData } from "redux-store/features/modelInstructionSlice";
import LoadIndicator from "views/components/LoadIndicator";

const ViewAttributes = ({ handleOpenSerialInstruction }) => {
  const { selectedBrandModel } = useSelector(selectBrandModelManagementData);
  const { instructions } = useSelector(selectModelInstructionData);

  const selectedInstruction = useMemo(() => {
    if (selectedBrandModel?.serial_instructions && instructions.length > 0) {
      const x = instructions.filter(
        (e) => e.instruction_id == selectedBrandModel.serial_instructions
      )[0];

      return x;
    }

    return null;
  }, [instructions, selectedBrandModel?.serial_instructions]);

  if (!selectedBrandModel) return <LoadIndicator size="lg" />;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
        <h3>Attributes</h3>
      </div>

      <div className="row">
        <div className="col-xl-8">
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Attributes ID</label>
            </div>
            <div className="col-md-9">
              <p>{selectedBrandModel.attributes || "Null"}</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">End Category</label>
            </div>
            <div className="col-md-9">
              <p>{selectedBrandModel.end_category || "Null"}</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Item Class</label>
            </div>
            <div className="col-md-9">
              <p>{selectedBrandModel.item_class || "Null"}</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">OS SW Upgradeable</label>
            </div>
            <div className="col-md-9">
              <p>
                {selectedBrandModel.os_sw_updateable === null
                  ? "Null"
                  : Boolean(selectedBrandModel.os_sw_updateable)
                  ? "Yes"
                  : "No"}
              </p>
            </div>
          </div>

          {Boolean(
            selectedBrandModel && Number(selectedBrandModel.os_sw_updateable)
          ) && (
            <div className="row mb-4">
              <div className="col-md-3">
                <label className="form-label">Software Application</label>
              </div>
              <div className="col-md-9">
                <p>{selectedBrandModel.sw_application_name || "Null"}</p>
              </div>
            </div>
          )}

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Serial Type</label>
            </div>
            <div className="col-md-9">
              <p>{selectedBrandModel.serial_type || "Null"}</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Serial Instructions</label>
            </div>
            <div className="col-md-9">
              <div
                className="link"
                style={{ color: "#e6c028" }}
                onClick={() =>
                  handleOpenSerialInstruction(selectedInstruction?.file)
                }
              >
                {selectedInstruction?.name || "Null"}
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label>Personalized</label>
            </div>
            <div className="col-md-9">
              <p>
                {selectedBrandModel.personalized === null
                  ? "Null"
                  : Boolean(selectedBrandModel.personalized)
                  ? "Yes"
                  : "No"}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Model Group</label>
            </div>
            <div className="col-md-9">
              <p>Null</p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Serial Uniqueness</label>
            </div>
            <div className="col-md-9">
              <p>
                {capitalize(selectedBrandModel.serial_uniqueness) || "Null"}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Serviceable</label>
            </div>
            <div className="col-md-9">
              <p>
                {selectedBrandModel.serviceable === null
                  ? "Null"
                  : Boolean(selectedBrandModel.serviceable)
                  ? "Yes"
                  : "No"}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Software</label>
            </div>
            <div className="col-md-9">
              <p>
                {selectedBrandModel.software === null
                  ? "Null"
                  : Boolean(selectedBrandModel.software)
                  ? "Yes"
                  : "No"}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Prevent Use Overlap</label>
            </div>
            <div className="col-md-9">
              <p>
                {selectedBrandModel.prevent_use_overlap === null
                  ? "Null"
                  : Boolean(selectedBrandModel.prevent_use_overlap)
                  ? "Yes"
                  : "No"}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Transferable</label>
            </div>
            <div className="col-md-9">
              <p>
                {selectedBrandModel.transferable === null
                  ? "Null"
                  : Boolean(selectedBrandModel.transferable)
                  ? "Yes"
                  : "No"}
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Private</label>
            </div>
            <div className="col-md-9">
              <p>
                {selectedBrandModel.private === null
                  ? "Null"
                  : Boolean(selectedBrandModel.private)
                  ? "Yes"
                  : "No"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAttributes;
