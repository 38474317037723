import { createSlice } from "@reduxjs/toolkit";
import { modes } from "views/database-records-management/brand-management/helpers";

const initialState = {
  mode: modes.view,
  selectedBrand: null,
};

export const brandManagement = createSlice({
  name: "brand_management",
  initialState,
  reducers: {
    switchMode: (state, action) => {
      state.mode = action.payload;

      if (action.payload === modes.new) {
        state.selectedBrand = {
          brand_id: null,
          brand_owner: "none",
          brand: "",
          as_company: 0,
          created_by: null,
        };
      }
    },

    setSelectedBrand: (state, action) => {
      state.selectedBrand = action.payload;
    },

    updateSelectedBrand: (state, action) => {
      state.selectedBrand = {
        ...state.selectedBrand,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const { setSelectedBrand, switchMode, updateSelectedBrand } =
  brandManagement.actions;

export const selectBrandManagementData = (state) => ({
  ...state.brand_management,
});

export default brandManagement.reducer;
