import React, { useCallback, useEffect, useState } from "react";
import styles from "./SymptomManagement.module.css";
import { useNavigate } from "react-router-dom";
import MultiStepCategorySelect from "views/components/MultiStepCategorySelect";
import { useMultiSelect } from "shared/useMultiSelect";
import {
  initializeSymptomList,
  selectSymptomManagementData,
  updateFilter,
} from "redux-store/features/symptomManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  initializeAberrationList,
  selectAberrationManagementData,
} from "redux-store/features/aberrationManagementSlice";
import AberrationService from "services/AberrationService";
import { selectToken } from "redux-store/features/authSlice";
import SymptomService from "services/SymptomService";

const SymptomManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const aberrationService = new AberrationService(dispatch, token);
  const service = new SymptomService(dispatch, token);

  const goBack = () => navigate(-1);

  const {
    filter,
    initialized: symptomInitialized,
    symptoms,
  } = useSelector(selectSymptomManagementData);

  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    setFiltered(symptoms);
  }, [symptoms]);

  const { initialized, aberrations } = useSelector(
    selectAberrationManagementData
  );
  const fetchAberrations = async () => {
    try {
      const res = await aberrationService.fetchAll();

      dispatch(initializeAberrationList(res.data.data || []));
    } catch (error) {}
  };
  useEffect(() => {
    if (!initialized) fetchAberrations();
  }, [initialized]);

  const fetchSymptoms = async () => {
    try {
      const res = await service.fetchAll();

      dispatch(initializeSymptomList(res.data.data || []));
    } catch (error) {}
  };
  useEffect(() => {
    if (!symptomInitialized) fetchSymptoms();
  }, [symptomInitialized]);

  const filterSymptom = useCallback((filter) => (symptom) => {
    return (
      ( !filter.category || !filter.isCategoryEnd || (Array.isArray(symptom.cat_symps) && symptom.cat_symps.filter((e) => e.end_category == filter.category).length > 0) ) && 
      ( !filter.aberration || symptom.aberration_id == filter.aberration ) && 
      ( !filter.status || symptom.status === filter.status ) &&
      ( !filter.text || ( symptom.description && symptom.description.toLowerCase().includes(filter.text.toLowerCase()) ) ) &&
      ( !filter.symptom_id || symptom.symptom_id == filter.symptom_id)
    )
  }, []);

  useEffect(() => {
    if (!filter.category && !filter.aberration && !filter.status && !filter.text && !filter.symptom_id) {
      setFiltered(symptoms);
    } else {
      setFiltered(symptoms.filter(filterSymptom(filter)));
    }
  }, [filter, symptoms]);

  const handleCategoryChange = (category) => {
    if (category.category_id === "none" || category.category_id === 1) {
      dispatch(updateFilter({ key: "category" }));
      dispatch(updateFilter({ key: "isCategoryEnd" }));
    } else {
      dispatch(updateFilter({ key: "category", value: category.category_id }));
      dispatch(updateFilter({ key: "isCategoryEnd", value: category.end }));
    }
  };

  const {
    dropdownMatrix,
    setCategoyInput,
    categoryInput,
    setDropdownMatrix,
    userInteraction
  } = useMultiSelect({
    value: filter.category || null,
    preSelected: null,
    setValue: handleCategoryChange,
  });

  useEffect(() => {
    return () => {
    // reset();
    };
  }, []);

  const goToAberrationSelection = () => {
    if (!filter.category) return;

    navigate("/database-records-management/qs-management/select-aberration", {
      state: {
        from: "new_symptom",
        category: filter.category,
      },
    });
  };

  const handleClick = (symptom_id) => () => {
    navigate("/database-records-management/qs-management/symptom-record", {
      state: {
        from: "existing_symptom",
        symptom_id,
      },
    });
  };

  return (
    <>
      <div id="main-container">
        <div className="container">
          <h2 className="mb-4 userLevel-Title">Symptom Management </h2>

          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span className="">Quality and Symptom Management</span>
          </div>

          {/** SYMPTOM FILTER */}
          <div className="mb-4">
            <p className={styles.filter}>Filters:</p>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Category</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <MultiStepCategorySelect
                      id="symptom-management-category-select"
                      setValue={handleCategoryChange}
                      dropdownMatrix={dropdownMatrix}
                      setCategoyInput={setCategoyInput}
                      categoryInput={categoryInput}
                      setDropdownMatrix={setDropdownMatrix}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Aberration</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <select
                      value={filter.aberration || ""}
                      className="form-select w-full"
                      onChange={(e) =>
                        dispatch(
                          updateFilter({
                            key: "aberration",
                            value:
                              (e.target.value && parseInt(e.target.value)) ||
                              null,
                          })
                        )
                      }
                    >
                      <option value="" selected>
                        All
                      </option>
                      {aberrations.map((item) => (
                        <option
                          key={item.aberration_id + "aberration-symp"}
                          value={item.aberration_id}
                        >
                          {item.aberration}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Status</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <select
                      className="form-select w-full"
                      value={filter.status || ""}
                      onChange={(e) =>
                        dispatch(
                          updateFilter({
                            key: "status",
                            value: e.target.value || null,
                          })
                        )
                      }
                    >
                      <option value="">All</option>
                      <option value="Pending">Pending</option>
                      <option value="Barred">Barred</option>
                      <option value="Verified">Verified</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Text</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <input
                      type="text"
                      className={`${styles.userdescinput} form-control`}
                      value={filter.text || ""}
                      onChange={(e) =>
                        dispatch(
                          updateFilter({ key: "text", value: e.target.value })
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Symptom ID</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <input
                      type="text"
                      className={`${styles.userdescinput} form-control`}
                      value={filter.symptom_id || ""}
                      onChange={(e) =>
                        dispatch(
                          updateFilter({
                            key: "symptom_id",
                            value: e.target.value,
                          })
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <p className={styles.refresh} onClick={fetchSymptoms}>Refresh Results</p>
          </div>

          {/** ENTER SYMPTOM IN YOUR WORD */}
          <div className="mb-4">
            <p className={`${styles.filter} mb-4 `}>
              Symptom Results: {filtered.length}
            </p>

            <div className="mb-2 row">
              <div className={`${styles.tableHead} col-1`}>
                <div className={styles.th}>Id</div>
              </div>

              <div className={`${styles.tableHead} col-3`}>
                <div className={styles.th}>Aberration</div>
              </div>
              <div className={`${styles.tableHead} col-5`}>
                <div className={styles.th}>Symptom</div>
              </div>
              <div className={`${styles.tableHead} col-2`}>
                <div className={styles.th}>Status</div>
              </div>
              <div className={`${styles.tableHead} col-1`}>
                <div className={styles.th}>Cat</div>
              </div>
            </div>

            {filtered.map((item) => (
              <div
                key={`symp-management-symp-${item.symptom_id}`}
                className={`${styles.rowCard} d-flex mb-2 row `}
                onClick={handleClick(item.symptom_id)}
              >
                {/* Id */}
                <div className={`${styles.val} col-1`}>
                  <div>{item.symptom_id}</div>
                </div>
                {/* Aberration */}
                <div className={`${styles.val} col-3`}>
                  <div className={styles.spacer}></div>
                  {item.aberration}
                </div>
                {/* Symptom */}
                <div className={`${styles.val} col-5`}>
                  <div className={styles.spacer}></div>
                  {item.description}
                </div>
                {/* Status */}
                <div className={`${styles.val} col-2`}>
                  <div className={styles.spacer}></div>
                  {item.status}
                </div>
                {/* Cat */}
                <div className={`${styles.val} col-1`}>
                  <div className={styles.spacer}></div>
                  {item.cat}
                </div>
              </div>
            ))}
          </div>

          <div className={`${styles.actions} d-flex flex-row`}>
            <button className={styles.cancel} onClick={goBack}>
              Cancel
            </button>

            <button
              className={styles.btn}
              disabled={
                !filter.category ||
                filter.isCategoryEnd === null ||
                filter.isCategoryEnd === 0
              }
              onClick={goToAberrationSelection}
            >
              New Symptom
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SymptomManagement;
