import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectModelManagementData,
  updateSelectedModel,
} from "redux-store/features/modelManagementSlice";
import WarningModal from "views/components/WarningModal";
import { formatEnum } from "../brand-management/helpers";
import { modes } from "./helper";

function EditModel({ isModelUsedInBrandModel, isAttributeUsed }) {
  const { selectedModel, mode } = useSelector(selectModelManagementData);
  const dispatch = useDispatch();

  const onChange = ({ key, value }) =>
    dispatch(updateSelectedModel({ key, value }));

  const [e1, setE1] = useState(false);
  const [e2, setE2] = useState(false);
  const [e3, setE3] = useState(false);
  const [e4, setE4] = useState(false);

  const [isGenericModelDisabled, setIsGenericModelDisabled] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (selectedModel && selectedModel?.generic_model != null && mounted) {
      setIsGenericModelDisabled(
        Boolean(selectedModel?.generic_model && (e1 || e4))
      );
    }
    return () => {
      mounted = false;
    };
  }, [e1, e4, selectedModel]);

  const resetEs = () => {
    setE1(false);
    setE2(false);
    setE3(false);
    setE4(false);
  };

  const setAppropriateEs = () => {
    setE1(
      selectedModel.generic_status?.toLowerCase() === "verified" ||
        selectedModel.generic_status?.toLowerCase() == "queried"
    );

    setE2(!selectedModel.owner);

    setE3(!selectedModel.model_code);

    setE4(isModelUsedInBrandModel || isAttributeUsed);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted)
      if (selectedModel && mode === modes.edit) {
        // SET APPROPRIATE VALUES FOR THE E's
        setAppropriateEs();
      } else {
        // SET ALL E's TO `false`
        resetEs();
      }
    return () => {
      mounted = false;
    };
  }, [selectedModel]);

  const [openWarning, setOpenWarning] = useState(false);
  const [warning, setWarning] = useState("");
  const handleOk = () => {
    setOpenWarning(false);
  };
  const handleCancelWarning = () => {
    onChange({
      key: "generic_model",
      value: 1,
    });

    setOpenWarning(false);
  };

  const [openWarning2, setOpenWarning2] = useState(false);
  const [warning2, setWarning2] = useState("");

  const handleOk2 = () => {
    onChange({ key: "model_code", value: "GENERIC" });
    setOpenWarning2(false);
  };
  const handleCancelWarning2 = () => {
    onChange({
      key: "generic_model",
      value: 0,
    });

    setOpenWarning2(false);
  };

  const onGenericModelChange = (e) => {
    onChange({ key: "generic_model", value: e.target.value });

    // Check if generic model is changing to `NO`
    // if (mode === modes.edit) {
    if (Number(e.target.value) === 0) {
      if (!e2) {
        // show warning popup
        setWarning("Warning Owner will be deleted, Are you sure?");
        setOpenWarning(true);
      }
    } else if (Number(e.target.value) === 1) {
      if (!e3) {
        // show warning popup
        setWarning2("Warning Model code will be deleted");
        setOpenWarning2(true);
      } else {
        onChange({ key: "model_code", value: "GENERIC" });
      }
    }
    // }
  };

  return (
    <>
      <WarningModal
        show={openWarning}
        text={warning}
        handleOk={handleOk}
        handleClose={handleCancelWarning}
      />

      <WarningModal
        show={openWarning2}
        text={warning2}
        handleOk={handleOk2}
        handleClose={handleCancelWarning2}
      />

      <div className="row">
        <div className="col-xl-8">
          {/** MODEL ID */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Model ID</label>
            </div>

            <div className="col-md-9">
              <p>{selectedModel?.model_id || "Null"}</p>
            </div>
          </div>

          {/** GENERIC MODEL */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Generic Model</label>
            </div>

            <div className="col-md-9">
              <select
                onChange={onGenericModelChange}
                value={selectedModel?.generic_model}
                className="form-select"
                disabled={isGenericModelDisabled}
              >
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
            </div>
          </div>

          {/** GENERIC ATTRIBUTES */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Generic Attributes</label>
            </div>
            <div className="col-md-9">
              {!Boolean(selectedModel?.generic_model)
                ? "Null"
                : selectedModel?.generic_attributes || "Null"}
            </div>
          </div>

          {/** MODEL CODE */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Model Code</label>
            </div>

            <div className="col-md-9">
              <input
                type="text"
                className="form-control"
                id="modelCode"
                placeholder="GT-3435"
                name="modelCode"
                value={selectedModel?.model_code || ""}
                onChange={(e) =>
                  onChange({
                    key: "model_code",
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/** GENERIC STATUS */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Generic Status</label>
            </div>
            <div className="col-md-9">
              {!Boolean(+selectedModel?.generic_model)
                ? "Null"
                : selectedModel?.generic_status
                ? formatEnum(selectedModel?.generic_status)
                : "Null"}
            </div>
          </div>

          {/** MODEL NAME DESCRIPTION */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Model Name</label>
            </div>

            <div className="col-md-9">
              <input
                type="text"
                className="form-control"
                id="modelNameDes"
                placeholder="iPhone 8 plus"
                name="modelNameDes"
                value={selectedModel?.model_name_des || ""}
                onChange={(e) =>
                  onChange({
                    key: "model_name_des",
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/** MODEL NOTES */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Model Notes</label>
            </div>

            <div className="col-md-9">
              <textarea
                value={selectedModel?.model_notes || ""}
                onChange={(e) =>
                  onChange({
                    key: "model_notes",
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/** GENERIC OWNER */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Generic Owner</label>
            </div>

            <div className="col-md-9">
              {!Boolean(selectedModel?.generic_model)
                ? "Null"
                : selectedModel?.model_owner || "Null"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditModel;
