import React, { useEffect, useMemo, useState } from "react";
import styles from "./AberrationManagement.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import AberrationService from "services/AberrationService";
import {
  addAberration,
  initializeAberrationList,
  removeAberration,
  selectAberrationManagementData,
  setAberrationMode,
  setSelectedAberration,
  updateAberration,
} from "redux-store/features/aberrationManagementSlice";
import {
  formatDDMMYYY,
  useDescriptionModal,
  useForm,
  useShortDisplay,
} from "shared/helpers";
import ErrorsComponent from "views/components/Errors";
import DeleteAberration from "./DeleteAberration";
import AutocompleteV2 from "views/components/AutocompleteV2";
import DescModal from "views/components/DescModal";
import WarningModal from "views/components/WarningModal";

const AberrationManagement = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const service = new AberrationService(dispatch, token);

  const { initialized, aberrations, selectedAberration, mode } = useSelector(
    selectAberrationManagementData
  );

  const fetchAberrations = async () => {
    try {
      const res = await service.fetchAll();

      dispatch(initializeAberrationList(res.data.data || []));
    } catch (error) {}
  };

  useEffect(() => {
    if (!initialized) fetchAberrations();
  }, [initialized]);

  const selectedAberrationRecord = useMemo(() => {
    return (
      aberrations.filter(
        (item) => item.aberration_id === selectedAberration
      )[0] || null
    );
  }, [selectedAberration, aberrations]);

  const [loading, setLoading] = useState(false);
  const { form, onChange, reset, handleServerError, err, set_err } = useForm({
    aberration: "",
    description: "",
    guidance: "",
    restriction: "Null",
    professional_check: 0,
  });

  useShortDisplay(() => set_err([]), 5000, err.length > 0);

  useEffect(() => {
    if (mode === "new") reset();
  }, [mode]);

  const insertAberrationRequest = async () => {
    setLoading(true);

    try {
      const res = await service.insert(form);

      dispatch(addAberration(res.data.data));

      dispatch(setSelectedAberration(res.data.data?.aberration_id || null));

      dispatch(setAberrationMode("view"));

      reset();
    } catch (error) {
      handleServerError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateAberrationRequest = async () => {
    setLoading(true);

    try {
      const res = await service.update({
        ...form,
        aberration_id: selectedAberrationRecord.aberration_id,
      });

      dispatch(updateAberration(res.data.data));

      dispatch(setAberrationMode("view"));

      reset();
    } catch (error) {
      console.log(error);
      handleServerError(error);
    } finally {
      setWarning(null);
      setLoading(false);
    }
  };

  const [US, setUS] = useState(false);
  const getUsesRequest = async () => {
    try {
      const res = await service.getAberrationUseCount(
        selectedAberrationRecord.aberration_id
      );

      setUS(res.data.data && res.data.data > 0);
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (selectedAberrationRecord) getUsesRequest();
  }, [selectedAberrationRecord]);

  const [warning, setWarning] = useState(null);
  const openWarning = (data) => setWarning(data);
  const closeWarning = () => setWarning(null);

  const cancelUpdateRequest = () => {
    closeWarning();
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (loading) return;

    if (
      US &&
      selectedAberrationRecord &&
      (selectedAberrationRecord.aberration !== form.aberration ||
        selectedAberrationRecord.description !== form.description ||
        selectedAberrationRecord.guidance !== form.guidance)
    ) {
      return openWarning({
        text: "This record is already used make minor changes only",
        handleOk: updateAberrationRequest,
        hideCancel: false,
        handleCancel: cancelUpdateRequest,
      });
    }

    switch (mode) {
      case "new":
        insertAberrationRequest();
        break;

      case "edit":
        updateAberrationRequest();
        break;

      default:
        break;
    }
  };

  const handleCancel = () => {
    switch (mode) {
      case "view":
        dispatch(setSelectedAberration(null));
        break;

      case "new":
      case "edit":
        dispatch(setAberrationMode("view"));
        reset();
        break;

      default:
        break;
    }
  };

  const handleEdit = () => {
    onChange("aberration", selectedAberrationRecord.aberration);
    onChange("description", selectedAberrationRecord.description || "");
    onChange("guidance", selectedAberrationRecord.guidance);
    onChange("restriction", selectedAberrationRecord.restriction || "Null");
    onChange(
      "professional_check",
      selectedAberrationRecord.professional_check || 0
    );

    dispatch(setAberrationMode("edit"));
  };

  const [openDelete, setOpenDelete] = useState(false);

  const openDeleteModal = () => {
    setOpenDelete(true);
  };

  const closeDeleteModal = (deleted = false) => {
    if (deleted) {
      dispatch(removeAberration(selectedAberration));
      dispatch(setSelectedAberration(null));
      dispatch(setAberrationMode("view"));
      reset();
    }

    setOpenDelete(false);
  };

  useEffect(() => {
    return () => {
      reset();
      dispatch(setAberrationMode("view"));
      dispatch(setSelectedAberration(null));
    };
  }, []);

  const {
    showDescriptionModal,
    closeDescriptionModal,
    description,
    openDescriptionModal,
  } = useDescriptionModal();

  return (
    <>
      {openDelete && (
        <DeleteAberration handleClose={closeDeleteModal} show={openDelete} />
      )}

      <DescModal
        show={showDescriptionModal}
        handleClose={closeDescriptionModal}
        text={description}
      />

      {warning && (
        <WarningModal
          show={warning !== null}
          text={warning.text}
          hideCancel={warning.hideCancel}
          handleOk={warning.handleOk}
          handleClose={warning.handleCancel}
          positiveLabel={warning.positiveLabel || undefined}
          negativeLabel={warning.negativeLabel || undefined}
        />
      )}

      <div id="main-container">
        <div className="container">
          <h2 className="mb-4 userLevel-Title">Aberration Management</h2>
          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span className="">Quality and Symptom Management</span>
          </div>

          <form id="aberrationForm" className="mb-4" onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Aberration</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    <AutocompleteV2
                      labels={["aberration"]}
                      primaryKey={"aberration_id"}
                      suggestions={[
                        { aberration: "New Aberration", aberration_id: "" },
                        ...aberrations,
                      ]}
                      placeholder={"Select Aberration"}
                      value={selectedAberration}
                      setValue={(aberration) => {
                        if (aberration.aberration_id === "") {
                          dispatch(setAberrationMode("new"));
                        } else {
                          dispatch(
                            setSelectedAberration(aberration.aberration_id)
                          );
                        }
                      }}
                      disabled={mode === "edit" || mode === "new"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Record ID</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedAberrationRecord?.aberration_id || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Aberration Name *</span>
                    </div>
                  </div>
                  <div className={`${styles.topLabelVal} col`}>
                    {mode === "view"
                      ? selectedAberrationRecord?.aberration || "Null"
                      : null}

                    {mode !== "view" && (
                      <input
                        type="text"
                        maxLength={30}
                        required
                        className="form-control"
                        value={form.aberration}
                        onChange={(e) => onChange("aberration", e.target.value)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Aberration Description</span>
                    </div>
                  </div>
                  <div className={`${styles.topLabelVal} col`}>
                    {mode === "view"
                      ? selectedAberrationRecord?.description || "Null"
                      : null}

                    {mode !== "view" && (
                      <textarea
                        maxLength={100}
                        className={`${styles.userdescinput} form-control`}
                        value={form.description}
                        onChange={(e) =>
                          onChange("description", e.target.value)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Guidance *</span>
                    </div>
                  </div>
                  <div className={`${styles.topLabelVal}  col`}>
                    {mode === "view"
                      ? selectedAberrationRecord?.guidance || "Null"
                      : null}

                    {mode !== "view" && (
                      <textarea
                        maxLength={150}
                        required
                        className={`${styles.userdescinput}  form-control`}
                        value={form.guidance}
                        onChange={(e) => onChange("guidance", e.target.value)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Restricted To</span>
                    </div>
                  </div>
                  <div className={`${styles.topLabelVal} col`}>
                    {mode === "view"
                      ? selectedAberrationRecord?.restriction || "Null"
                      : null}

                    {mode !== "view" && (
                      <select
                        className={`form-select w-full ${styles.dropdownColor} ${styles.input}`}
                        value={form.restriction}
                        required={!US}
                        disabled={US}
                        onChange={(e) =>
                          onChange("restriction", e.target.value)
                        }
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        <option value="Null">Null</option>
                        <option value="Products">Products</option>
                        <option value="Services">Services</option>
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>
                        Professional Check{" "}
                        <span className={`${styles.topLabelVal} `}>
                          <i
                            className="bi bi-info-circle"
                            onClick={() =>
                              openDescriptionModal(
                                `Select "Yes" when Professional Checks are required where
                                an unqualified user cannot have suffuicient knowledge to determine they have the
                                defect, this can apply to legal failures or critical severity`
                              )
                            }
                          ></i>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className={`${styles.topLabelVal} col`}>
                    {mode === "view"
                      ? (selectedAberrationRecord &&
                        selectedAberrationRecord.professional_check === 1
                          ? "Yes"
                          : "No") || "Null"
                      : null}

                    {mode !== "view" && (
                      <select
                        className={`form-select w-full ${styles.dropdownColor}`}
                        required={!US}
                        disabled={US}
                        value={
                          form.professional_check !== null
                            ? form.professional_check
                            : ""
                        }
                        onChange={(e) =>
                          onChange(
                            "professional_check",
                            parseInt(e.target.value)
                          )
                        }
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Updated By</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedAberrationRecord?.updated_by_number || "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Updated At</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {(selectedAberrationRecord &&
                      formatDDMMYYY(selectedAberrationRecord.updated_at)) ||
                      "Null"}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-7">
                <div className="row">
                  <div className="col-12 mb-2 mb-md-0 col-md-auto">
                    <div className={styles.topLabelSymptom}>
                      <span>Created By</span>
                    </div>
                  </div>

                  <div className={`${styles.topLabelVal} col`}>
                    {selectedAberrationRecord?.created_by_number || "Null"}
                  </div>
                </div>
              </div>
            </div>
          </form>

          {err.length > 0 && <ErrorsComponent errors={err} />}

          <div className={`${styles.actions} d-flex flex-row`}>
            <button
              className={styles.requestassistance}
              disabled={!selectedAberration || loading || mode !== "view"}
              onClick={handleEdit}
            >
              Edit
            </button>

            <button
              className={styles.btn}
              disabled={mode !== "view" || loading}
              onClick={() => dispatch(setAberrationMode("new"))}
            >
              Create New
            </button>

            <button
              className={styles.btn}
              type="submit"
              form="aberrationForm"
              disabled={
                loading || (!selectedAberrationRecord && mode !== "new")
              }
            >
              Submit
            </button>

            <button
              className={styles.cancel}
              disabled={!selectedAberration || mode !== "view" || loading || US}
              onClick={openDeleteModal}
            >
              Delete
            </button>

            <button
              className={styles.cancel}
              disabled={loading}
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AberrationManagement;
