import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import AdminService from "services/AdminServise";
import UserService from "services/UserService";
import { handleError, useShortDisplay } from "shared/helpers";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";
import DeleteSuccessModal from "./DeleteSuccessModal";

function BypassManagement() {
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [alert, setAlert] = useState(false);
  const [variant, setVariant] = useState("alert-danger");
  const [alertMsg, setAlertMsg] = useState([]);
  const showAlert = (variant, msg) => {
    setVariant(variant);
    setAlertMsg(msg);
    setAlert(true);
  };

  const closeAlert = () => {
    setAlert(false);
    setAlertMsg([]);
  };

  useShortDisplay(closeAlert, 5000, alert);

  const alertTopRef = useRef(null);

  useEffect(() => {
    if (alert) {
      // Scroll to alert
      alertTopRef.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [alert]);

  const [query, setQuery] = useState("");
  const [searching, setSearching] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const goBack = () => navigate(-1);

  const findUser = async () => {
    setSearching(true);
    try {
      const res = await UserService.findUserByNumber(token, dispatch, query);

      setSelectedUser(res.data.data);

      if (res.data.data?.admin) res.data.data.admin = 1;
      else res.data.data.admin = 0;

      if (res.data.data?.submitting) res.data.data.submitting = 1;
      else res.data.data.submitting = 0;
    } catch (error) {
      const msg = error.response?.data?.message;
      let toUse = [];
      if (msg) {
        if (msg instanceof String || typeof msg === "string") toUse = [msg];
        else {
          const arr = [];
          Object.keys(msg).map((key) => {
            for (const emsg of msg[key]) arr.push(emsg);
          });

          toUse = arr;
        }
      } else {
        toUse = ["Something went wrong"];
      }

      showAlert("alert-danger", toUse);
    }
    setSearching(false);
  };

  const [bypassing, setBypassing] = useState(false);
  const bypassUser = async () => {
    setBypassing(true);
    try {
      const res = await UserService.bypassUser(token, dispatch, selectedUser);

      showAlert("alert-success", [res.data.message]);
    } catch (error) {
      const msg = error.response?.data?.message;
      let toUse = [];
      if (msg) {
        if (msg instanceof String || typeof msg === "string") toUse = [msg];
        else {
          const arr = [];
          Object.keys(msg).map((key) => {
            for (const emsg of msg[key]) arr.push(emsg);
          });

          toUse = arr;
        }
      } else {
        toUse = ["Something went wrong"];
      }

      showAlert("alert-danger", toUse);
    }
    setBypassing(false);
  };

  const [deleting, setDeleting] = useState(false);
  const [isDeleteSuccessOpen, setIsDeleteSuccessOpen] = useState(false);

  const closeDeleteSuccessModal = () => {
    setIsDeleteSuccessOpen(false);
    window.location.reload();
  };

  const deleteUser = async () => {
    setDeleting(true);

    try {
      const res = await AdminService.deleteUser(
        token,
        dispatch,
        selectedUser?.user_id
      );

      if (res.status === 200) {
        setIsDeleteSuccessOpen(true);
      }
    } catch (error) {
      handleError((errors) => showAlert("alert-danger", errors));
    }

    setDeleting(false);
  };

  useEffect(() => {
    return () => {
      setIsDeleteSuccessOpen(false);
    };
  }, []);

  return (
    <>
      <DeleteSuccessModal
        show={isDeleteSuccessOpen}
        handleClose={closeDeleteSuccessModal}
      />

      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center">
            <div className="col-sm-6">
              <div className="infmore">
                User Admin Level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4">Bypass</h2>

          <div className="infoContentCenter card mb-4">
            <div className="row">
              <div className="col-lg-8 col-sm-12">
                <div ref={alertTopRef} />
                {/** ALERT */}
                {alert && (
                  <ErrorsComponent errors={alertMsg} variant={variant} />
                )}

                {/** SEARCH */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">Enter User Number:</label>
                  </div>

                  <div className="col-md-9">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control me-3"
                        placeholder="User Number"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                      <button
                        className="btn btn-yellow ms-3"
                        onClick={findUser}
                        disabled={!query}
                      >
                        {searching ? <LoadIndicator /> : "Search"}
                      </button>
                    </div>
                  </div>
                </div>

                {/** FIRST NAME */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">First Name:</label>
                  </div>

                  <div className="col-md-9">
                    <p>{selectedUser?.first_name || "Null"}</p>
                  </div>
                </div>

                {/** LAST NAME */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">Last Name:</label>
                  </div>

                  <div className="col-md-9">
                    <p>{selectedUser?.last_name || "Null"}</p>
                  </div>
                </div>

                {/** EMAIL */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">Email:</label>
                  </div>

                  <div className="col-md-9">
                    <p>{selectedUser?.email || "Null"}</p>
                  </div>
                </div>

                {/** EMAIL VERIFICATION */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">Email Verification:</label>
                  </div>

                  <div className="col-md-9">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        disabled={!selectedUser}
                        checked={selectedUser?.email_verified === 1}
                        onChange={(e) =>
                          setSelectedUser((prev) => ({
                            ...prev,
                            email_verified: e.target.checked ? 1 : 0,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>

                {/** PHONE NUMBER */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">Phone Number:</label>
                  </div>

                  <div className="col-md-9">
                    <p>{selectedUser?.mobile || "Null"}</p>
                  </div>
                </div>

                {/** PHONE VERIFICATION */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">Phone Verification:</label>
                  </div>

                  <div className="col-md-9">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        disabled={!selectedUser}
                        checked={selectedUser?.phone_verified === 1}
                        onChange={(e) =>
                          setSelectedUser((prev) => ({
                            ...prev,
                            phone_verified: e.target.checked ? 1 : 0,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>

                {/** USER IS ADMIN */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">Admin User:</label>
                  </div>

                  <div className="col-md-9">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        disabled={!selectedUser}
                        checked={selectedUser?.admin === 1 || 0}
                        onChange={(e) =>
                          setSelectedUser((prev) => ({
                            ...prev,
                            admin: e.target.checked ? 1 : 0,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>

                {/** USER IS SUBMITTING */}
                <div className="row mb-4">
                  <div className="col-md-3">
                    <label className="form-label">Submitting User:</label>
                  </div>

                  <div className="col-md-9">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        disabled={!selectedUser}
                        checked={selectedUser?.submitting === 1 || 0}
                        onChange={(e) =>
                          setSelectedUser((prev) => ({
                            ...prev,
                            submitting: e.target.checked ? 1 : 0,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 text-center">
            <button
              onClick={deleteUser}
              className="btn btn-black me-2"
              disabled={!selectedUser || deleting || searching}
            >
              {deleting ? <LoadIndicator /> : "Delete"}
            </button>

            <button
              type="button"
              className="btn btn-yellow"
              disabled={!selectedUser || searching || deleting}
              onClick={bypassUser}
            >
              {bypassing ? <LoadIndicator /> : "Update"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BypassManagement;
