import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import MailService from "services/MailService";
import UnitService from "services/UnitService";
import LoadIndicator from "views/components/LoadIndicator";

const MessageModal = ({ unitId, open, handleClose }) => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const [fetchingStatus, setFetchingStatus] = useState(false);

  const [receiverData, setReceiverData] = useState(null);

  const fetchUnitMessageStatus = async () => {
    setFetchingStatus(true);
    try {
      const res = await UnitService.controllerUseIsCurrent(
        token,
        dispatch,
        unitId
      );

      setReceiverData(res.data.data);
    } catch (e) {}
    setFetchingStatus(false);
  };

  useEffect(() => {
    if (unitId) {
      fetchUnitMessageStatus();
    }
  }, [unitId]);

  const [sending, setSending] = useState(false);
  const [text, setText] = useState("");

  const mailService = new MailService(token, dispatch);

  const handleSend = async (e) => {
    e.preventDefault();

    if (!receiverData) return;

    setSending(true);
    try {
      await mailService.send_mail({
        subject: "Unit Message Query",
        text,
        receiver: receiverData.email,
      });

      setText("");
      handleClose();
    } catch (error) {}
    setSending(false);
  };

  return (
    <Modal show={open} onHide={handleClose} keyboard={false} centered>
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>Message Controller</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: 32, paddingTop: 0 }}>
        {fetchingStatus ? (
          <LoadIndicator />
        ) : receiverData ? (
          <form id="inputForm" onSubmit={handleSend}>
            <textarea
              required
              className="textarea-white"
              value={text || ""}
              disabled={sending}
              minLength={10}
              onChange={(e) => setText(e.target.value)}
              style={{
                backgroundColor: "white",
                width: "100%",
                padding: 8,
              }}
              maxLength={255}
            />
          </form>
        ) : (
          <h5 className="mod-detail">
            There is no current controller for this unit
          </h5>
        )}
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>

        <Button variant="primary" type="submit" form="inputForm">
          {sending ? <LoadIndicator /> : "Send"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;
