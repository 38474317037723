export const modes = {
  view: "view",
  edit: "edit",
  new: "new",
};

export const tabs = {
  model: "model",
  attributes: "attributes",
};
