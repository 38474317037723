import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_id: null,
  name: "",
  address: "",
  start_date: "",
  finish_date: "",
  permission: "",
  admin: false,
};

export const adminUserManagement = createSlice({
  name: "admin_user_management",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAdminUserManagementData: (state, action) => {
      const getName = (data) => {
        let name = "";

        name += data.first_name;

        if (data.middle_name) {
          name += ` ${data.middle_name}`;
        }

        name += ` ${data.last_name}`;

        return name;
      };

      const getAddress = (data) => {
        let address = "";

        address += data.road_name;

        if (data.town) {
          address += `, ${data.town}`;
        }

        if (data.state) {
          address += `, ${data.state}`;
        }

        address += `, ${data.country}.`;

        if (data.postcode) {
          address += ` ${data.postcode}`;
        }

        return address;
      };

      state.user_id = action.payload.user_id;
      state.name = getName(action.payload);
      state.address = getAddress(action.payload);
      state.start_date =
        action.payload.admin?.date || action.payload.admin?.created_at;
      state.finish_date = action.payload.admin?.finish_date;
      state.permission = action.payload.admin?.permission;
      state.admin = action.payload.admin ? true : false;
    },

    clearAdminUserManagementData: (state) => {
      state.user_id = null;
      state.name = "";
      state.address = "";
      state.start_date = "";
      state.finish_date = "";
      state.permission = "";
      state.admin = false;
    },

    updateAdminUserManagementData: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  setAdminUserManagementData,
  clearAdminUserManagementData,
  updateAdminUserManagementData,
} = adminUserManagement.actions;

export const selectAdminUserManagementData = (state) => ({
  ...state.admin_user_management,
});

export default adminUserManagement.reducer;
