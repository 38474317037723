import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import AssetService from "services/AssetService";
import "./styles.css";

const imageexts = ["jpeg", "jpg", "png", "svg"];

function FileDisplayThumb({ location, showSpan = false, full = false }) {
  const [ext, setExt] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const assetService = new AssetService(dispatch, token);

  const setupFileUrl = async () => {
    if (!location) return;
    try {
      const res = await assetService.getAssetUrl(location);

      setFileUrl(res.data.data);
    } catch (error) {}
  };

  const openAsset = async () => {
    if (!location) return;
    try {
      const res = await assetService.getAssetUrl(location);

      if (res.data.data) {
        window.open(res.data.data, "_blank");
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (location) {
      setupFileUrl();

      const splitUrl = location.split(".");

      let extension = splitUrl[splitUrl.length - 1]?.toLowerCase();

      if (extension && extension.includes("?")) {
        extension = extension.split("?")[0];
      }

      let fileNameSplit = splitUrl[0].split("/");
      fileNameSplit.shift();

      setFileName(fileNameSplit.join(""));

      setExt(extension);
    }
  }, [location]);

  if (!ext || !location || !fileUrl) return <div></div>;

  return imageexts.includes(ext) ? (
    <div onClick={openAsset}>
      <img
        src={fileUrl}
        className={`${!full && "img-thumbnail"} me-3 img-fluid ${
          full && "full-img-thumb"
        }`}
        alt={fileName}
        title=""
        width="60"
      />

      {showSpan && <a className="mt-2 link">{fileName}</a>}
    </div>
  ) : (
    <div onClick={openAsset}>
      <a className="link">{`${fileName}.${ext}`}</a>
    </div>
  );
}

export default FileDisplayThumb;
