import { Button } from "react-bootstrap";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectBrandModelManagementData } from "redux-store/features/brandModelManagement";

function DeleteModal({ show, handleClose, deleteBrandModel }) {
  const [options, setOptions] = useState({
    model: false,
    brand: false,
    company: false,
  });

  const { selectedBrandModel } = useSelector(selectBrandModelManagementData);

  const updateOptions = (option, value) =>
    setOptions((prev) => ({ ...prev, [option]: value }));

  const handleDeleteModalClose = () => {
    // Delete with respect to options
    if (options) {
      const data = {
        brand_model_id: selectedBrandModel.brand_model_id,
      };

      if (options.model) data.model = selectedBrandModel.model_id;
      if (options.brand) data.brand = selectedBrandModel.brand_id;
      if (options.company) data.company = selectedBrandModel.company_id;

      deleteBrandModel(data);
    }

    handleClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          <div className="container px-5">
            <div className="row mb-4">
              <div className="col-md-3">Brand_Model</div>
              <div className="col-md-9">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckChecked"
                    checked
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">Model</div>
              <div className="col-md-9">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckChecked"
                    checked={options.model}
                    onChange={(e) => updateOptions("model", e.target.checked)}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">Brand</div>
              <div className="col-md-9">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckChecked"
                    checked={options.brand}
                    onChange={(e) => updateOptions("brand", e.target.checked)}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-3">Company</div>
              <div className="col-md-9">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckChecked"
                    disabled={selectedBrandModel?.company_id == "none"}
                    checked={options.company}
                    onChange={(e) => updateOptions("company", e.target.checked)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancel
          </Button>

          <Button variant="primary" onClick={handleDeleteModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteModal;
