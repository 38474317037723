import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { clearSubmittingUserVerificationData } from "redux-store/features/submittingUserVerificationSlice";
import AdminService from "services/AdminServise";
import { handleError, useShortDisplay } from "shared/helpers";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";

function ImprovementRequestModal({
  show,
  handleClose,
  history_id,
  reset,
  getOldestUserInfo,
}) {
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  useShortDisplay(() => setErrors([]), 5000, errors.length > 0);

  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const requestImprovement = async () => {
    if (loading || !history_id) return;

    setLoading(true);

    try {
      const res = await AdminService.requestImprovement(token, dispatch, {
        reason_for_improvement: reason,
        history_id,
      });

      if (Boolean(res.data.success)) {
        reset();
        dispatch(clearSubmittingUserVerificationData());
        getOldestUserInfo();
        handleClose();
      }
    } catch (e) {
      handleError(e, setErrors);
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    requestImprovement();
  };

  useEffect(() => {
    return () => {
      setReason("");
      setLoading(false);
      setErrors([]);
    };
  }, []);

  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={handleClose}
      keyboard={false}
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <h5>Request Improvement</h5>
      </Modal.Header>

      <Modal.Body style={{ padding: "0 32px", paddingBottom: 16 }}>
        <form id="improvementForm" onSubmit={handleSubmit}>
          <textarea
            type="text"
            className="form-control"
            id="reason"
            rows="6"
            placeholder="Reason"
            value={reason || ""}
            onChange={(e) => setReason(e.target.value)}
            required
          />

          {errors.length > 0 && (
            <div className="mb-4">
              <ErrorsComponent errors={errors} />
            </div>
          )}
        </form>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "none" }}>
        <Button type="submit" form="improvementForm" variant="primary">
          {loading ? <LoadIndicator /> : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImprovementRequestModal;
