import { Button } from "react-bootstrap";
import React from "react";
import { Modal } from "react-bootstrap";

function WarningModal({
  show,
  handleClose,
  handleOk,
  text,
  hideCancel = false,
  positiveLabel = "Ok",
  negativeLabel = "Cancel",
}) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleOk}
        // backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          <h5 className="mod-detail">{text}</h5>
        </Modal.Body>

        <Modal.Footer>
          {!hideCancel && (
            <Button variant="secondary" onClick={handleClose}>
              {negativeLabel}
            </Button>
          )}

          <Button variant="primary" onClick={handleOk}>
            {positiveLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WarningModal;
