import React from "react";
import { Route, Routes } from "react-router-dom";
import DatabaseRecordsManagement from "views/database-records-management";
import BrandManagement from "views/database-records-management/brand-management";
import BrandModelManagement from "views/database-records-management/brand-model-mgmt";
import BypassManagement from "views/database-records-management/bypass";
import CategoryManagementNew from "views/database-records-management/category-management-new";
import CompanyManagement from "views/database-records-management/company-management";
import GlobalVariables from "views/database-records-management/global-variables";
import ModelManagement from "views/database-records-management/model-management";
import EditAttribute from "views/edit-attribute";
import ScheduledTasks from "views/database-records-management/scheduled-tasks";
import SerialInstruction from "views/database-records-management/serial-instructions/SerialInstruction";
import InstructionRecord from "views/database-records-management/serial-instructions/InstructionRecord";
import BrandModelUnits from "views/database-records-management/brand-model-mgmt/units";
import SoftwareApplicationManagement from "views/database-records-management/software/SoftwareApplicationManagement";
import SoftwareApplicationRecord from "views/database-records-management/software/SoftwareApplicationRecord";
import SoftwareVersionRecord from "views/database-records-management/software/SoftwareVersionRecord";
import SoftwareVersionManagement from "views/database-records-management/software/SoftwareVersionManagement";
import UnverifiedRecordSummary from "views/database-records-management/unverified-record-summary";
import RecordList from "views/database-records-management/unverified-record-summary/list";
import QsManagementRoutes from "./qs-management";

function DatabaseManagementRoutes() {
  return (
    <Routes>
      <Route index element={<DatabaseRecordsManagement />} />
      <Route path="category" element={<CategoryManagementNew />} />
      <Route path="brand-model" element={<BrandModelManagement />} />
      <Route path="brand-model/units" element={<BrandModelUnits />} />
      <Route path="serial-instruction" element={<SerialInstruction />} />
      <Route path="sam" element={<SoftwareApplicationManagement />} />
      <Route path="sar" element={<SoftwareApplicationRecord />} />
      <Route path="svr" element={<SoftwareVersionRecord />} />
      <Route path="svm" element={<SoftwareVersionManagement />} />
      <Route path="instruction-record" element={<InstructionRecord />} />
      <Route path="edit-attribute" element={<EditAttribute />} />
      <Route path="bypass" element={<BypassManagement />} />
      <Route path="company" element={<CompanyManagement />} />
      <Route path="brand" element={<BrandManagement />} />
      <Route path="model" element={<ModelManagement />} />
      <Route path="scheduled-tasks" element={<ScheduledTasks />} />
      <Route path="global-variables" element={<GlobalVariables />} />
      <Route
        path="unverified-record-summary"
        element={<UnverifiedRecordSummary />}
      />
      <Route path="unverified-record-summary/list" element={<RecordList />} />

      <Route path="qs-management/*" element={<QsManagementRoutes />} />
    </Routes>
  );
}

export default DatabaseManagementRoutes;
