import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom ;
import { selectToken, selectUser } from "redux-store/features/authSlice";
import UnverifiedRecordService from "services/UnverifiedRecordService";
import { formatDDMMYYY } from "shared/helpers";
import LoadIndicator from "views/components/LoadIndicator";
import styles from "./styles.module.css";

const RecordList = () => {
  const user = useSelector(selectUser);
  //   const navigate = useNavigate();
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  //   const goBack = () => navigate(-1);

  const service = new UnverifiedRecordService(dispatch, token);

  const [summaryData, setSummaryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSummaryDataList = async (type) => {
    if (loading) return;

    setLoading(true);

    try {
      const res = await service.fetchSummaryList(type);

      setSummaryData(res.data.data[type]);
    } catch (error) {}

    setLoading(false);
  };

  const [recordType, setRecordType] = useState(null);
  const [recordTypeRaw, setRecordTypeRaw] = useState(null);
  const [nullOwner, setNullOwner] = useState(false);

  const getRecordId = () => {
    switch (recordTypeRaw) {
      case "category":
        return "category_id";

      case "brand_model_null":
      case "brand_model_owned":
        return "brand_model_id";

      case "company_null":
      case "company_owned":
        return "company_id";

      case "sw_version_null":
      case "sw_version_owned":
        return "sw_version_id";

      default:
        break;
    }
  };

  useEffect(() => {
    const item = new URLSearchParams(window.location.search).get("item");

    if (item) {
      setRecordTypeRaw(item.toLowerCase());

      switch (item) {
        case "category":
          setRecordType("Category");
          setNullOwner(true);
          break;

        case "brand_model_null":
          setRecordType("Brand_Model");
          setNullOwner(true);
          break;

        case "brand_model_owned":
          setRecordType("Brand_Model");
          setNullOwner(false);
          break;

        case "company_null":
          setRecordType("Company");
          setNullOwner(true);
          break;

        case "company_owned":
          setRecordType("Company");
          setNullOwner(false);
          break;

        case "sw_version_null":
          setRecordType("SW Version");
          setNullOwner(true);
          break;

        case "sw_version_owned":
          setRecordType("SW Version");
          setNullOwner(false);
          break;

        default:
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (recordTypeRaw) fetchSummaryDataList(recordTypeRaw);
  }, [recordTypeRaw]);

  const handleRowClick = (item) => () => {
    switch (recordTypeRaw) {
      case "category":
        window.open(
          `/database-records-management/category?id=${item.category_id}`,
          "_blank"
        );
        break;

      case "brand_model_null":
      case "brand_model_owned":
        window.open(
          `/database-records-management/brand-model?id=${item.brand_model_id}`,
          "_blank"
        );
        break;

      case "company_null":
      case "company_owned":
        window.open(
          `/database-records-management/company?id=${item.company_id}`,
          "_blank"
        );
        break;

      case "sw_version_null":
      case "sw_version_owned":
        window.open(
          `/database-records-management/svr?id=${item.sw_version_id}`,
          "_blank"
        );
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center userLevel-Admin">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4 userLevel-Title">Record list</h2>

          <div className="infoContentCenter card mb-4">
            {recordType && (
              <h5>
                {recordType} {nullOwner ? "Null Owner" : "Owned"}
              </h5>
            )}

            {loading ? (
              <LoadIndicator />
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered table-responsive table-dark-theme mb-4">
                  <thead>
                    <tr>
                      <th scope="col">Record Id</th>
                      <th scope="col">Date Created</th>
                    </tr>
                  </thead>

                  <tbody>
                    {summaryData &&
                      summaryData instanceof Array &&
                      summaryData.map((item, idx) => {
                        return (
                          <tr
                            key={idx}
                            className={styles.recordRow}
                            onClick={handleRowClick(item)}
                          >
                            <td>{item[getRecordId()]}</td>

                            <td>{formatDDMMYYY(item.created_at)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecordList;
