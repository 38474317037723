import Autocomplete from "../Autocomplete";

const MultiStepCategorySelect = ({
  setValue,
  id,
  dropdownMatrix,
  categoryInput,
  setCategoyInput,
  setDropdownMatrix,
}) => {
  return (
    <div style={{ width: "100%" }}>
      {dropdownMatrix.map((values, dropdownIndex) => {
        if (values.length > 0)
          return (
            <Autocomplete
              key={`${id}-${dropdownIndex}`}
              suggestions={values || []}
              input={categoryInput[dropdownIndex] || ""}
              placeholder="Please select category"
              labelKey={"name"}
              primaryKey="category_id"
              setInput={(input) =>
                setCategoyInput((prev) => ({
                  ...prev,
                  [dropdownIndex]: input,
                }))
              }
              setValue={(category) => {
                const prefilled = {
                  clear_index: null,
                  clear_length: null,
                  current_index: null,
                  value: null,
                };

                if (dropdownMatrix.length - 1 > dropdownIndex) {
                  const d = [...dropdownMatrix];
                  prefilled.clear_index = dropdownIndex + 1;
                  prefilled.clear_length =
                    dropdownMatrix.length - dropdownIndex;

                  d.splice(prefilled.clear_index, prefilled.clear_length);

                  let x = prefilled.clear_index;
                  while (x < dropdownMatrix.length) {
                    setCategoyInput((prev) => ({
                      ...prev,
                      [x]: "",
                    }));
                    x++;
                  }

                  setDropdownMatrix(d);
                }

                prefilled.current_index = dropdownIndex;
                prefilled.value = category.category_id;

                if (category.children.length > 0)
                  setDropdownMatrix((prev) => [...prev, category.children]);
                setCategoyInput((prev) => ({
                  ...prev,
                  [dropdownIndex + 1]: null,
                }));

                setValue(category);
              }}
            />
          );
      })}
    </div>
  );
};

export default MultiStepCategorySelect;
