import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialized: false,
  keys: [],
};

export const securityKeyManagement = createSlice({
  name: "security_key_management",
  initialState,
  reducers: {
    initializeSecurityKeyList: (state, action) => {
      state.keys = action.payload;
      state.initialized = true;
    },

    updateSecurityKey: (state, action) => {
      state.keys = state.keys.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }

        return item;
      });
    },
  },
});

export const { initializeSecurityKeyList, updateSecurityKey } =
  securityKeyManagement.actions;

export const selectSecurityKeyManagementData = (state) => ({
  ...state.security_key_management,
});

export default securityKeyManagement.reducer;
