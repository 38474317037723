import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { selectBrandModelManagementData } from "redux-store/features/brandModelManagement";
import { selectModelInstructionData } from "redux-store/features/modelInstructionSlice";
import AdminService from "services/AdminServise";
import LoadIndicator from "views/components/LoadIndicator";
import MultiStepCategorySelect from "views/components/MultiStepCategorySelect";

const EditAttribute = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const { selectedBrandModel } = useSelector(selectBrandModelManagementData);

  const [allAttributes, setAllAttributes] = useState([]);
  const fetchAllAttributes = async (must_include = null) => {
    try {
      const res = await AdminService.getAllAttributesForBrandModel(
        token,
        dispatch,
        selectedBrandModel.brand_model_id || null,
        must_include
      );

      setAllAttributes(res.data.data);
    } catch (e) {}
  };

  useEffect(() => {
    fetchAllAttributes(selectedBrandModel.attributes);
  }, [selectedBrandModel?.brand_model_id]);

  const { categoryInput, dropdownMatrix, setCategoyInput, setDropdownMatrix } =
    props.attributeEditMultiSelect;

  const handleCategoryChange = (category) => {
    props.handleEditFormChange("end_category")({
      target: { value: category.category_id },
    });
  };

  const { instructions } = useSelector(selectModelInstructionData);

  const selectedInstruction = useMemo(() => {
    if (selectedBrandModel?.serial_instructions && instructions.length > 0) {
      const x = instructions.filter(
        (e) => e.instruction_id == selectedBrandModel.serial_instructions
      )[0];

      return x;
    }

    return null;
  }, [instructions, selectedBrandModel?.serial_instructions]);

  useEffect(() => {
    props.fetchCurrentCategoryTree(selectedBrandModel?.end_category);
  }, []);

  if (!selectedBrandModel) return <LoadIndicator size="lg" />;

  return (
    <div className="row mt-4">
      <div className="col-xl-8">
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Attributes ID</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              value={selectedBrandModel.attributes || ""}
              placeholder="Select Attribute"
              onChange={(e) => props.onAttributeChange(e.target.value)}
              disabled={selectedBrandModel.generic_model == 1}
            >
              {[
                <option value="new">New Attribute</option>,
                ...allAttributes.map((item) => (
                  <option key={`edit-attribute-bm-${item.brand_model_attribute_id}`} value={item.brand_model_attribute_id}>
                    {item.brand_model_attribute_id}
                  </option>
                )),
              ]}
            </select>
          </div>
        </div>

        {/** END CATEGORY */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">End Category</label>
          </div>

          <div className="col-md-9">
            <MultiStepCategorySelect
              id={"b_m_mgmt-head-category-select"}
              categoryInput={categoryInput}
              dropdownMatrix={dropdownMatrix}
              setCategoyInput={setCategoyInput}
              setDropdownMatrix={setDropdownMatrix}
              setValue={handleCategoryChange}
            />

            {dropdownMatrix?.length === 0 && (
              <div>Please select end category</div>
            )}
          </div>
        </div>

        {/** ITEM CLASS */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Item Class</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              value={selectedBrandModel.item_class || ""}
              onChange={props.handleEditFormChange("item_class")}
              defaultValue=""
            >
              <option value="" disabled>
                Select Item Class
              </option>
              <option value={"Multiple use product"}>
                Multiple use product
              </option>
              <option value={"Single use product"}>Single use product</option>
              <option value={"Continuous service"}>Continuous service</option>
              <option value={"One-off service"}>One-off service</option>
            </select>
          </div>
        </div>

        {/* * OS SW UNGRADEABLE */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">OS SW Upgradeable</label>
          </div>
          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedBrandModel.os_sw_updateable === null
                  ? ""
                  : +selectedBrandModel.os_sw_updateable
              }
              onChange={props.handleEditFormChange("os_sw_updateable")}
              defaultValue=""
            >
              <option disabled value={""}>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        {/** SW APPLICATION */}
        {Boolean(
          selectedBrandModel && Number(selectedBrandModel.os_sw_updateable)
        ) && (
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Software Application</label>
            </div>
            <div className="col-md-9">
              {selectedBrandModel.sw_application_name || "Null"}
              {selectedBrandModel?.os_sw_updateable ? (
                <button
                  style={{
                    marginLeft: "10px",
                  }}
                  className="btn btn-yellow"
                  onClick={props.openSelectSoftwareApplication}
                >
                  Manage
                </button>
              ) : null}
            </div>
          </div>
        )}

        {/** SERIAL TYPE */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Serial Type</label>
          </div>
          <div className="col-md-9">
            <select
              className="form-select"
              required
              value={selectedBrandModel.serial_type}
              onChange={props.handleEditFormChange("serial_type")}
            >
              <option value={""} disabled>
                Select Serial Type
              </option>
              <option value={"Serial number"}>Serial Number</option>
              <option value={"Batch number"}>Batch Number</option>
              <option value={"Chassis number"}>Chassis Number</option>
              <option value={"Account number"}>Account Number</option>
              <option value={"Case number"}>Case Number</option>
              <option value={"Transaction number"}>Transaction Number</option>
              <option value={"Job number"}>Job Number</option>
              <option value={"Location gps"}>Location GPS</option>
              <option value={"Location url"}>Location URL</option>
              <option value={"None"}>None</option>
            </select>
          </div>
        </div>

        {/** SERIAL INSTRUCTIONS */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Serial Instructions</label>
          </div>

          <div className="col-md-9">
            <div className="d-flex align-items-center">
              <div className="me-2">
                <div
                  className="link"
                  style={{ color: "#e6c028" }}
                  onClick={(e) =>
                    props.handleOpenSerialInstruction(selectedInstruction?.file)
                  }
                >
                  {selectedInstruction?.name || "Null"}
                  <button
                    style={{
                      marginLeft: "10px",
                    }}
                    className="btn btn-yellow"
                    onClick={props.openSelectSerialInstruction}
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/** PERSONALIZED */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label>Personalized</label>
          </div>

          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedBrandModel.personalized === null
                  ? ""
                  : +selectedBrandModel.personalized
              }
              onChange={props.handleEditFormChange("personalized")}
              defaultValue=""
            >
              <option disabled value={""}>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        {/** MODEL GROUP */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Model Group</label>
          </div>
          <div className="col-md-9">
            <p>Null</p>
          </div>
        </div>

        {/** SERIAL UNIQUENESS */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Serial Uniqueness</label>
          </div>
          <div className="col-md-9">
            <select
              className="form-select"
              value={selectedBrandModel.serial_uniqueness || ""}
              defaultValue=""
              onChange={props.handleEditFormChange("serial_uniqueness")}
              required
            >
              <option value={""} disabled>
                Select
              </option>
              <option value={"brand"}>Brand</option>
              <option value={"model"}>Model</option>
              <option value={"batch"}>Batch</option>
              <option value={"none"}>None</option>
            </select>
          </div>
        </div>

        {/** SERVICEABLE */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Serviceable</label>
          </div>
          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedBrandModel.serviceable === null
                  ? ""
                  : +selectedBrandModel.serviceable
              }
              defaultValue=""
              onChange={props.handleEditFormChange("serviceable")}
            >
              <option disabled value={""}>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        {/** SOFTWARE */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Software</label>
          </div>
          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedBrandModel.software === null
                  ? ""
                  : +selectedBrandModel.software
              }
              defaultValue=""
              onChange={props.handleEditFormChange("software")}
            >
              <option disabled value={""}>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        {/** PREVENT USE OVERLAP */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Prevent Use Overlap</label>
          </div>
          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedBrandModel.prevent_use_overlap === null
                  ? ""
                  : +selectedBrandModel.prevent_use_overlap
              }
              defaultValue=""
              onChange={props.handleEditFormChange("prevent_use_overlap")}
            >
              <option disabled value={""}>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        {/** TRANSFERABLE */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Transferable</label>
          </div>
          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedBrandModel.transferable === null
                  ? ""
                  : +selectedBrandModel.transferable
              }
              onChange={props.handleEditFormChange("transferable")}
            >
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        {/** PRIVATE */}
        <div className="row mb-4">
          <div className="col-md-3">
            <label className="form-label">Private</label>
          </div>
          <div className="col-md-9">
            <select
              className="form-select"
              value={
                selectedBrandModel.private === null
                  ? ""
                  : +selectedBrandModel.private
              }
              defaultValue=""
              onChange={props.handleEditFormChange("private")}
            >
              <option selected value={""} disabled>
                Select
              </option>
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAttribute;
