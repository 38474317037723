import { useMessageTypeManagement } from "hooks/useMessageTypeManagement";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUser } from "redux-store/features/authSlice";
import LoadIndicator from "views/components/LoadIndicator";

const SingleSupportAllocation = ({ messageType }) => {
  const user = useSelector(selectUser);
  const messagetypeController = useMessageTypeManagement();

  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (messageType?.type_id) {
      setLoading(true);
      messagetypeController
        .httpGetCasesByType(messageType.type_id)
        .then(setCases)
        .finally(() => setLoading(false));
    }
  }, [messageType?.type_id]);

  const handleAccept = async (case_id) => {
    if (saving) return;
    setSaving(true);

    try {
      const res = await messagetypeController.httpAcceptCase(case_id);

      if (res) {
        setCases((prev) =>
          prev.map((e) => {
            if (e.id === res.id) return { ...e, ...res };
            return e;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }

    setSaving(false);
  };

  const handleDrop = async (case_id) => {
    if (saving) return;
    setSaving(true);

    try {
      const res = await messagetypeController.httpDropCase(case_id);

      if (res) {
        setCases((prev) =>
          prev.map((e) => {
            if (e.id === res.id) return { ...e, ...res };
            return e;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }

    setSaving(false);
  };

  if (!messageType) return null;

  return (
    <>
      <div className="row mb-4">
        <div className="col-auto">
          <div className={"qualy-support-allocation__topLabel"}>
            <span>Type:</span>
          </div>
        </div>

        <div className="col qualy-support-allocation__topLabelVal">
          {messageType.name}
        </div>
      </div>

      {loading && (
        <div className="my-4">
          <LoadIndicator />
        </div>
      )}

      {!loading && cases.length === 0 && <h5 className="my-4">No requests</h5>}

      {!loading && cases.length > 0 && (
        <div className="table-responsive my-4">
          <table className="table table-responsive table-bordered table-dark-theme">
            <thead>
              <tr>
                <th>Summary</th>
                <th colSpan={3}>Message</th>
                <th>&nbsp;</th>
              </tr>
            </thead>

            <tbody>
              {cases.map((caseObj) => (
                <tr
                  key={`support-allocation-${messageType.type_id}-${caseObj.id}`}
                >
                  <td>{caseObj.title}</td>
                  <td
                    colSpan={3}
                    className="qualy-support-allocation__msg-display"
                  >
                    <p>{caseObj.oldest_message?.message || "-"}</p>
                    <span>
                      <Link
                        to={`/support-allocation?message=${
                          caseObj.oldest_message?.message_id || ""
                        }`}
                      >
                        more
                      </Link>
                    </span>
                  </td>
                  <td>
                    {!Boolean(caseObj.supporter) && (
                      <button
                        className="btn btn-green"
                        type="button"
                        disabled={saving}
                        onClick={() => handleAccept(caseObj.id)}
                      >
                        {saving ? <LoadIndicator /> : "Accept"}
                      </button>
                    )}

                    {Boolean(caseObj.supporter) &&
                      caseObj.supporter.user === user.user_id && (
                        <button
                          className="btn btn-blue"
                          type="button"
                          disabled={saving}
                          onClick={() => handleDrop(caseObj.id)}
                        >
                          {saving ? <LoadIndicator /> : "Drop"}
                        </button>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default SingleSupportAllocation;
