import LoadIndicator from "../LoadIndicator";
import styles from "./styles.module.css";

export const FullScreenLoader = ({ isOpen }) => {

    return (
        <div className={`d-flex flex-column align-items-center justify-content-center ${styles.loading_fullpage} ${isOpen ? styles.open : ""}`}>
            <LoadIndicator />
        </div>
    )
}