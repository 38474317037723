import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company_list: [],
  initialized_company_list: false,
};

export const companySlice = createSlice({
  name: "companies",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    initializeCompanyList: (state, action) => {
      state.initialized_company_list = true;
      state.company_list = action.payload;
    },
  },
});

export const { initializeCompanyList } = companySlice.actions;

export const selectCompanyData = (state) => state.companies;

export default companySlice.reducer;
