import { useMessageCases } from "hooks/useMessageCases";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addBugType,
  selectMessageCasesBugTypes,
} from "redux-store/features/messageCasesSlice";
import styles from "./style.module.css";
import AutocompleteV2 from "views/components/AutocompleteV2";
import { useForm } from "shared/helpers";
import LoadIndicator from "views/components/LoadIndicator";
import ErrorsComponent from "views/components/Errors";
import SelectUser from "./modals/SelectUser";

function CreateNewCase() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const controller = useMessageCases();

  const [openSelectUser, setOpenSelectUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);
  const form = useForm({
    type: "",
    title: "",
    message: "",
  });

  const handleChange = (name) => (e) => form.onChange(name, e.target.value);

  const bugTypes = useSelector(selectMessageCasesBugTypes);

  const { state } = useLocation();

  const selectedMessageType = useMemo(() => {
    if (!form.form.type || bugTypes.length === 0) return null;

    return (
      bugTypes.filter((type) => type.type_id === form.form.type)[0] || null
    );
  }, [form.form.type, bugTypes.length]);

  const disableSubmit = useMemo(
    () =>
      !selectedMessageType ||
      !selectedMessageType.guidance ||
      !form.form.title ||
      saving,
    [selectedMessageType?.type_id, form.form.title, saving]
  );

  const goBack = () => navigate(-1);
  const cleanUp = () => {
    form.reset();
    setErrors([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (disableSubmit) {
      return;
    }

    setErrors([]);
    setSaving(true);

    const { newCase } = await controller.httpCreateCase(
      {
        ...form.form,
        to: typeof selectedUser === "object" ? selectedUser?.user_id : null,
        all_users: typeof selectedUser === "string" && selectedUser === "All Users" ? true : false
      },
      setErrors
    );

    if (newCase)
      navigate(`/messages/case/${newCase.id}`, { replace: true });

    setSaving(false);
  };

  const handleCloseSelectUser = ({ user = null, all = false } = {}) => {
    if (user) {
      setSelectedUser(user);
    } else if (all) {
      setSelectedUser("All Users");
    }

    setOpenSelectUser(false);
  }

  useEffect(() => {
    return cleanUp;
  }, []);

  useEffect(() => {
    if (state && state.prefilled) {
      const set = (key) => form.onChange(key, state.prefilled[key]);
      Object.keys(state.prefilled).forEach(set);

      const typeExists =
        bugTypes.findIndex((e) => e.type_id === state.prefilled.type)[0] ||
        null;

      if (!typeExists) {
        controller
          .httpGetTypeByID(state.prefilled.type)
          .then((bugType) => dispatch(addBugType(bugType)))
          .catch(console.error);
      }
    }
  }, [state]);

  useEffect(() => {
    if (bugTypes.length === 0) {
      controller.httpGetBugTypes();
    }
  }, [bugTypes.length]);

  return (
    <>
      <SelectUser 
        open={openSelectUser}
        handleClose={handleCloseSelectUser}
      />

      <div id="main-container">
        <div className="container">
          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span>Go Back</span>
          </div>

          <h2 className="mb-4">New Case</h2>

          <div className="row">
            <div className="col-12 col-xl-8">
              <form className={`${styles.form} mb-4`} onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                  <div>
                    <label>Type:</label>
                  </div>

                  <AutocompleteV2
                    suggestions={bugTypes}
                    labels={["name"]}
                    value={form.form.type || ""}
                    setValue={(type) => form.onChange("type", type.type_id)}
                    sortKey="name"
                    primaryKey={"type_id"}
                    placeholder="Please select case type"
                    required
                  />
                </div>

                <div className={styles.formGroup}>
                  <div>
                    <label>Summary:</label>
                  </div>

                  <input
                    className="form-control"
                    type="text"
                    value={form.form.title || ""}
                    onChange={handleChange("title")}
                    placeholder=""
                    maxLength={40}
                    required
                  />
                </div>

                <div className={styles.formGroup}>
                  <div>
                    <label>Guidance:</label>
                  </div>

                  <p>{selectedMessageType?.guidance || "Null"}</p>
                </div>

                {selectedUser && (
                  <div className={styles.formGroup}>
                    <div>
                      <label>Selected User:</label>
                    </div>

                    <p>{typeof selectedUser === "string" ? selectedUser : selectedUser?.number}</p>
                  </div>
                )}

                <div className={styles.formGroup}>
                  <textarea
                    rows="5"
                    className="form-control"
                    value={form.form.message}
                    onChange={handleChange("message")}
                  />
                </div>

                {errors.length > 0 && <ErrorsComponent errors={errors} />}

                <div
                  className={`d-flex flex-row justify-content-center align-items-center ${styles.actions}`}
                >
                  <button
                    type="submit"
                    className="btn btn-yellow"
                    disabled={disableSubmit}
                  >
                    {saving ? <LoadIndicator /> : "Send"}
                  </button>

                  {selectedMessageType &&
                    !Boolean(selectedMessageType.allocation) &&
                    !form.form.to && (
                      <button type="button" className="btn btn-yellow" onClick={() => setOpenSelectUser(true)}>
                        Select Users
                      </button>
                    )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewCase;
