import { axiosClient } from "shared/axiosInstance";

class BrandService {
  static getBrandsForCategory(token, dispatch, category) {
    return axiosClient(dispatch, token).get(
      "/brand_model/brands?category=" + category
    );
  }

  static getBrandModelBrandUses(token, dispatch, { brand_model, brand }) {
    return axiosClient(dispatch, token).get(
      `/brand_model/brand-uses?brand_model=${brand_model}&brand=${brand}`
    );
  }

  static getAllBrands(token, dispatch) {
    return axiosClient(dispatch, token).get("/brand");
  }

  static getBrandModel(dispatch, token, id) {
    if (!id) return;

    return axiosClient(dispatch, token).get(`/brand_model/single/by-id/${id}`);
  }

  static getBrandRecords(token, dispatch) {
    return axiosClient(dispatch, token).get("/brand_model");
  }

  static getBrandModelRecordsByBrand(token, dispatch, brand) {
    return axiosClient(dispatch, token).get("/brand_model?brand=" + brand);
  }

  static getBrandModelAttribute(token, dispatch, id) {
    return axiosClient(dispatch, token).get(
      "/brand_model/attribute?brand_model_id=" + id
    );
  }

  static getBrandModelUnits(token, dispatch, id) {
    return axiosClient(dispatch, token).get(
      "/brand_model/units?brand_model_id=" + id
    );
  }

  static getBrandModelRecordsByModel(token, dispatch, model) {
    return axiosClient(dispatch, token).get("/brand_model?model=" + model);
  }

  static getBrandModelRecordsByAttribute(token, dispatch, attribute) {
    return axiosClient(dispatch, token).get(
      "/brand_model?attribute=" + attribute
    );
  }

  static addBrandToDb(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/brand", data);
  }

  static fetchById(token, dispatch, id) {
    return axiosClient(dispatch, token).get("/brand/" + id);
  }

  static fetchAll(token, dispatch) {
    return axiosClient(dispatch, token).get("/brand");
  }

  static updateBrand(token, dispatch, brand) {
    return axiosClient(dispatch, token).patch("/brand", brand);
  }

  static deleteBrand(token, dispatch, brand) {
    return axiosClient(dispatch, token).delete("/brand?brand_id=" + brand);
  }

  static getAllModels(token, dispatch, brand, category) {
    return axiosClient(dispatch, token).get(
      `/brand_model/models?brand=${brand}&category=${category}`
    );
  }
}

export default BrandService;
