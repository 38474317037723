import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import {
  clearCategoryManagementForm,
  clearPrefillParentCategory,
  clearSelectedCategory,
  prefillParentCategory,
  selectCategoryManagementData,
  setCategories,
  setCategoryManagementForm,
  setSelecedCategory,
  setUnverifiedCategory,
  switchMode,
} from "redux-store/features/categoryManagement";
import AdminService from "services/AdminServise";
import CategoryService from "services/CategoryService";
import { handleError, logger, useForm, useShortDisplay } from "shared/helpers";
import Autocomplete from "views/components/Autocomplete";
import DescModal from "views/components/DescModal";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";
import LockTimer from "views/components/LockTimer";
import EndCategoryAttributes from "./Attributes";
import ChangeStructure from "./change-structure";
import EditCategory from "./EditCategory";
import { modes, tabs } from "./helper";
import ViewCategory from "./ViewCategory";
import AutocompleteV2 from "views/components/AutocompleteV2";
import MultiStepCategorySelect from "views/components/MultiStepCategorySelect";
import { useMultiSelect } from "shared/useMultiSelect";

function CategoryManagementNew() {
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  const {
    form: lockedDetails,
    onChange: onLockedDetailsChange,
    reset: resetLockedDetails,
  } = useForm({
    locked_on: null,
    locked_by: null,
  });

  const [isNewChild, setIsNewChild] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  useShortDisplay(() => setErrors([]), 5000, errors.length > 0);

  useEffect(() => {
    if (errors.length > 0) {
      // TODO: Scroll to bottom
    }
  }, [errors]);

  const {
    unverifiedCategories,
    categories,
    categoryManagementForm: form,
    mode,
  } = useSelector(selectCategoryManagementData);

  const onChange = (key, value) => {
    dispatch(setCategoryManagementForm({ key, value }));
  };

  const [selectedUnverifiedCategory, setSelectedUnverifiedCategory] =
    useState(null);

  const [shouldChange, setShouldChange] = useState(false);
  useEffect(() => {
    if (shouldChange && form && form.category) {
      for (let key of Object.keys(form.category)) {
        dispatch(setCategoryManagementForm({ key, value: form.category[key] }));
      }

      setShouldChange(false);
    }
  }, [form?.category, shouldChange]);

  const [fetchingUnverifiedCategories, setFetchingUnverifiedCategories] =
    useState(false); // Signify when app is getting unverified categories

  const getUnverifiedCategories = async () => {
    setFetchingUnverifiedCategories(true);
    try {
      const res = await AdminService.getUnverifiedCategories(token, dispatch);

      dispatch(setUnverifiedCategory(res.data.data));
    } catch (e) {}
    setFetchingUnverifiedCategories(false);
  }; // Fetch unverified categories from server

  const [fetchingCategories, setFetchingCategories] = useState(false);
  const getCategories = async () => {
    setFetchingCategories(true);
    try {
      const res = await CategoryService.getAvailableCategories(token, dispatch);

      dispatch(setCategories(res.data.data));
    } catch (e) {}
    setFetchingCategories(false);
  };

  const reset = () => {
    dispatch(clearPrefillParentCategory());
    dispatch(clearSelectedCategory());
    dispatch(clearCategoryManagementForm());
    getCategories();
    getUnverifiedCategories();
  };

  const unlockCategory = async () => {
    try {
      await CategoryService.unlock(token, dispatch);
    } catch (error) {}
  };

  const viewCategory = async (id) => {
    try {
      const res = await CategoryService.view(token, dispatch, id);

      ["locked_on", "locked_by", "seconds_left"].forEach((key) =>
        onLockedDetailsChange(key, res.data.data[key])
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (unverifiedCategories.length === 0) getUnverifiedCategories();
    if (categories.length === 0) getCategories();

    return () => {
      reset();
      unlockCategory();
    };
  }, []);

  useEffect(() => {
    if (form && form.category_id && mode === modes.edit) {
      viewCategory(form.category_id);
    } else if (mode === modes.view) {
      unlockCategory();
    }
  }, [mode]);

  const [activeTab, setActiveTab] = useState(tabs.category);

  const [deleting, setDeleting] = useState(false);

  const updateCategoryAndAttribute = async () => {
    setLoading(true);
    try {
      let data = {
        ...form,
      };

      if (form.end == 1) {
        data = { ...data, ...attributeForm };
      }

      const res = await CategoryService.submitAndVerifyCategory(
        token,
        dispatch,
        data
      );

      if (res.data.success) {
        dispatch(switchMode(modes.view));
        onChange("verified_date", res.data.data);

        setSelectedUnverifiedCategory(null);
        onChange("unverifiedCategoryInput", "");

        dispatch(setSelecedCategory({}));

        getUnverifiedCategories();
        getCategories();
      }
    } catch (error) {
      console.log("Error: ", error);

      handleError(error, setErrors);
    }
    setLoading(false);
  };

  const createChildCategory = async () => {
    setLoading(true);
    try {
      let data = {
        ...form,
      };

      if (form.end == 1) {
        data = { ...data, ...attributeForm };
      }

      const res = await CategoryService.createChildCategory(
        token,
        dispatch,
        data
      );

      if (res.data.success) {
        dispatch(switchMode(modes.view));
        onChange("verified_date", res.data.data);
        onChange("category_id", res.data.id);
        onChange("category", { category_id: res.data.id });

        setSelectedUnverifiedCategory(null);
        onChange("unverifiedCategoryInput", "");

        dispatch(setSelecedCategory({}));

        getCategories();
      }
    } catch (error) {
      handleError(error, setErrors);
    }
    setLoading(false);
  };

  const handleSubmit = () => {
    switch (mode) {
      case modes.edit:
        updateCategoryAndAttribute();
        break;

      case modes.new:
        createChildCategory();
        break;

      default:
        updateCategoryAndAttribute();
        break;
    }
  };

  const { form: attributeForm, onChange: setAttributeForm } = useForm({
    attribute_id: null,
    use_units: "",
    public_use: "",
    repairable: "",
    fixed_location: "",
  });

  const getSelectedCategoryFromDB = async (id) => {
    try {
      const res = await CategoryService.getCategory(token, dispatch, id);

      if (res?.data.data) {
        for (const key of Object.keys(res.data.data)) {
          onChange(key, res.data.data[key]);
        }
      }

      if (res?.data.attribute) {
        Object.keys(res.data.attribute).map((key) =>
          setAttributeForm(key, res.data.attribute[key])
        );
      }
    } catch (e) {}
  };

  const handleCancel = () => {
    if (mode != modes.view) {
      dispatch(switchMode(modes.view));

      if (mode === modes.edit) {
        // get db values of category
        resetLockedDetails();
        if (form && form.category_id) {
          getSelectedCategoryFromDB(form.category_id);
        }
      }

      // if (mode === modes.new) {
      //   reset();
      // }
    } else goBack();
  };

  const deleteCategory = async () => {
    if (!form) return;

    setDeleting(true);
    logger("From", form);
    try {
      const res = await CategoryService.deleteCategory(
        token,
        dispatch,
        form.category_id
      );

      if (res.data.success) {
        // Delete was successful
        reset();
        dispatch(switchMode(modes.view));
      }
    } catch (error) {
      handleError(error, setErrors);
    }
    setDeleting(false);
  };

  const [isChangeStructureModalOpen, setIsChangeStructureModalOpen] =
    useState(false);

  const [uses, setUses] = useState([]);
  const [isUsesOpen, setIsUsesOpen] = useState(false);

  const openUsesModal = () => setIsUsesOpen(true);
  const closeUsesModal = () => setIsUsesOpen(false);

  const fetchCategoryUses = async () => {
    try {
      const res = await CategoryService.fetchUses(
        token,
        dispatch,
        form.category_id
      );

      setUses(res.data.data);
    } catch (e) {
      console.log("Error fetching uses", e);
    }
  };

  const fetchCategoryForPreSelect = async (id) => {
    try {
      const res = await CategoryService.getCategory(token, dispatch, id);

      if (!res) return;

      onChange("category", res.data.data);
      setShouldChange(true);
    } catch (e) {}
  };

  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get("id");

    if (id) {
      fetchCategoryForPreSelect(+id);
    }
  }, []);

  useEffect(() => {
    if (form && form.category_id) {
      fetchCategoryUses();
    } else {
      setUses([]);
    }
  }, [form?.category_id]);

  const clearLock = (category) => {
    onLockedDetailsChange("locked_by", category?.locked_by || null);
    onLockedDetailsChange("locked_on", category?.locked_on || null);
  };

  const handleCategorySelect = useCallback(
    async (category) => {
      await unlockCategory();
      onChange("category", category);
      setShouldChange(true);
      if (categorySelect.userInteraction.current)
        setSelectedUnverifiedCategory(null);
      clearLock(category);
    },
    [unlockCategory, onChange, setShouldChange, onLockedDetailsChange]
  );

  const categorySelect = useMultiSelect({
    // value: form.category_id || "",
    setValue: handleCategorySelect,
  });

  const handleUnverifiedCategoryChange = useCallback(
    async (category) => {
      await unlockCategory();
      clearLock(category);
      setSelectedUnverifiedCategory(category);
      dispatch(clearSelectedCategory());
      categorySelect.userInteraction.current = false;
      categorySelect.reset();
    },
    [unlockCategory, setSelectedUnverifiedCategory, onLockedDetailsChange]
  );

  const populateUnverifiedCategory = useCallback(() => {
    if (!selectedUnverifiedCategory) return;

    onChange("category", selectedUnverifiedCategory);
    setShouldChange(true);
  }, [selectedUnverifiedCategory, onChange, setShouldChange]);

  useEffect(() => {
    if (typeof form?.parent_category === "number") {
      CategoryService.getCategory(token, dispatch, form.parent_category)
        .then((res) => {
          if (res)
            onChange("parent_category_name", res.data?.data?.name || "N/A");
        })
        .catch(console.error);
    } else {
      onChange("parent_category_name", "Null");
    }
  }, [form?.parent_category]);

  useEffect(() => {
    if (mode === modes.new) {
      setAttributeForm({
        attribute_id: null,
        use_units: "",
        public_use: "",
        repairable: "",
        fixed_location: "",
      });

      onChange(
        "parent_category",
        form.category_id || form.category?.category_id
      );
      onChange("end", 0);
      onChange("allow_end_category", 0);
    }
  }, [mode]);

  return (
    <>
      <DescModal
        show={isUsesOpen}
        handleClose={closeUsesModal}
        children={
          <div>
            <h5>Category Uses</h5>

            <ul>
              {uses.map((item) => (
                <li key={"category-uses-available-" + item.brand_model_id}>
                  <p>
                    {item.brand_model_id}, {item.brand}, {item.model_name_des}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        }
      />

      <ChangeStructure
        open={isChangeStructureModalOpen}
        handleClose={() => setIsChangeStructureModalOpen(false)}
        originalParentCategory={form.parent_category}
      />

      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center userLevel-Admin">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4 userLevel-Title">
            Professional Category Management
          </h2>

          {/** Unverified Category */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Unverified End Categories:</label>
            </div>

            <div className="col-md-5">
              {fetchingUnverifiedCategories ? (
                <LoadIndicator />
              ) : (
                <p>{unverifiedCategories?.length || 0}</p>
              )}

              <div className="d-flex flex-row align-items-center">
                {fetchingUnverifiedCategories ? (
                  <LoadIndicator />
                ) : (
                  <div style={{ flex: 1 }}>
                    <AutocompleteV2
                      suggestions={unverifiedCategories}
                      placeholder={"Select an unverified category"}
                      primaryKey={"category_id"}
                      labels={["name"]}
                      value={selectedUnverifiedCategory?.category_id}
                      disabled={isNewChild}
                      setValue={handleUnverifiedCategoryChange}
                      sortKey={"name"}
                    />
                  </div>
                )}

                <button
                  className="btn btn-yellow editButton ms-2"
                  disabled={!selectedUnverifiedCategory}
                  onClick={populateUnverifiedCategory}
                >
                  <i className="bi bi-pencil mx-2"></i>
                </button>
              </div>
              {/** */}
            </div>
          </div>

          {/** Select Category */}
          <div className="row mb-4">
            <div className="col-md-3">
              <label className="form-label">Category</label>
            </div>

            <div className="col-md-5">
              <MultiStepCategorySelect
                id="category-mgmt-category"
                {...categorySelect}
                setValue={handleCategorySelect}
              />
            </div>
          </div>

          {form?.category_id && (
            <div className="row mb-4">
              <div className="col-md-8">
                <LockTimer
                  data={lockedDetails}
                  onComplete={() => dispatch(switchMode(modes.view))}
                />
              </div>
            </div>
          )}

          <div className="infoContentCenter card mb-4 fadeIn">
            {/** TABS */}
            <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="category-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Category"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={() => setActiveTab(tabs.category)}
                >
                  Category Record {form?.category_id || "Null"}
                </button>
              </li>
              {form.end == 1 && (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Attributes"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    onClick={() => setActiveTab(tabs.attributes)}
                  >
                    Attributes {attributeForm?.attribute_id || "Null"}
                  </button>
                </li>
              )}
            </ul>

            {/** BODY */}
            <div className="col-xl-8">
              <div className="tab-content" id="myTabContent">
                {/** Category tab content */}
                <div
                  className="tab-pane fade show active"
                  id="Category"
                  role="tabpanel"
                  aria-labelledby="category-tab"
                >
                  {mode === modes.view && (
                    <ViewCategory uses={uses} openUses={openUsesModal} />
                  )}

                  {mode != modes.view && <EditCategory />}

                  {/** Actions */}
                  <div className="btn-group-child mb-5">
                    <button
                      type="button"
                      className="btn btn-yellow me-3"
                      disabled={mode === modes.new}
                      onClick={() => setIsChangeStructureModalOpen(true)}
                    >
                      Change Structure
                    </button>

                    <button
                      type="button"
                      className="btn btn-yellow me-3"
                      onClick={() => dispatch(switchMode(modes.new))}
                      disabled={
                        !form?.category ||
                        mode === modes.edit ||
                        mode === modes.new
                      }
                    >
                      <i className="bi bi-plus-circle"></i> New Child
                    </button>

                    <button
                      type="button"
                      className="btn btn-yellow me-3"
                      disabled={
                        mode === modes.edit ||
                        !form?.category ||
                        mode === modes.new ||
                        Boolean(
                          lockedDetails?.locked_by &&
                            lockedDetails.locked_by != user.user_id
                        )
                      }
                      onClick={() => dispatch(switchMode(modes.edit))}
                    >
                      <i className="bi bi-pencil"></i> Edit
                    </button>

                    <button
                      type="button"
                      className="btn btn-yellow me-3"
                      disabled={
                        isNewChild || !form?.category || mode === modes.new
                      }
                      onClick={deleteCategory}
                    >
                      <i className="bi bi-trash"></i>
                      {deleting ? <LoadIndicator /> : "Delete"}
                    </button>
                  </div>
                </div>

                {/** Attributes tab content */}
                <div
                  className="tab-pane fade"
                  id="Attributes"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <EndCategoryAttributes
                    attributeForm={attributeForm}
                    setAttributeForm={setAttributeForm}
                  />
                </div>
              </div>
            </div>
          </div>

          {errors.length > 0 && (
            <div className="mb-4">
              {" "}
              <ErrorsComponent errors={errors} />{" "}
            </div>
          )}

          {/** High order actions */}
          <div className="mt-4 text-center">
            <button
              onClick={handleCancel}
              disabled={loading}
              className="btn btn-black me-2"
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-yellow"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? <LoadIndicator /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryManagementNew;
