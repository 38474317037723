import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateSelectedBrand } from "redux-store/features/brandManagementSlice";
import { formatDDMMYYY } from "shared/helpers";
import LoadIndicator from "views/components/LoadIndicator";

// const active1 = ["Limited", "Not for profit", "Charity"];

function EditBrand({ selectedBrand, onSubmit = () => {}, companies }) {
  const dispatch = useDispatch();
  const onChange = (key, value) =>
    dispatch(updateSelectedBrand({ key, value }));

  const [isAsCompanyDisabled, setIsAsCompanyDisabled] = useState(false);

  useEffect(() => {
    if (selectedBrand) {
      if (!selectedBrand.brand_owner) {
        onChange("as_company", 0);
      }
    }
  }, [selectedBrand?.brand_owner]);

  useEffect(() => {
    if (selectedBrand) {
      if (!selectedBrand.brand_owner || selectedBrand.brand_owner === "none") {
        onChange("as_company", 0);
        setIsAsCompanyDisabled(true);
        return;
      }

      const company = companies.filter(
        (e) => e.company_id == selectedBrand.brand_owner
      )[0];

      if (!company) {
        onChange("as_company", 0);
        setIsAsCompanyDisabled(true);
        return;
      }

      if (company.company_name !== selectedBrand.brand) {
        onChange("as_company", 0);
        setIsAsCompanyDisabled(true);
        return;
      }

      setIsAsCompanyDisabled(false);
    }
  }, [selectedBrand?.brand_owner, selectedBrand?.brand]);

  if (!selectedBrand) return <LoadIndicator size="lg" />;

  return (
    <form id="brand-edit-form" onSubmit={onSubmit}>
      {/** BRAND ID */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">ID</label>
        </div>

        <div className="col-md-9">
          <p>{selectedBrand?.brand_id || "Null"}</p>
        </div>
      </div>

      {/** BRAND NAME */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Brand Name</label>
        </div>

        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="brandName"
            required
            placeholder="Samsung"
            value={selectedBrand.brand || ""}
            onChange={(e) => onChange("brand", e.target.value)}
          />
        </div>
      </div>

      {/** BRAND OWNER */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Brand Owner</label>
        </div>

        <div className="col-md-9">
          <select
            value={selectedBrand.brand_owner || "none"}
            defaultValue=""
            onChange={(e) => onChange("brand_owner", e.target.value)}
            className="form-select"
            required
          >
            {[...companies, { company_id: "none", company_name: "None" }].map(
              (item) => (
                <option
                  key={`company-cy ${Math.random()}`}
                  value={item.company_id}
                >
                  {item.company_name}
                </option>
              )
            )}
          </select>
        </div>
      </div>

      {/** AS COMPANY */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">As Company</label>
        </div>

        <div className="col-md-9">
          <select
            value={selectedBrand.as_company}
            onChange={(e) => onChange("as_company", +e.target.value)}
            className="form-select"
            disabled={isAsCompanyDisabled}
            required
          >
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
        </div>
      </div>

      {/** CREATED BY */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Created By</label>
        </div>

        <div className="col-md-9">
          <p>{selectedBrand?.created_by || "Null"}</p>
        </div>
      </div>

      {/** STATUS */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Status</label>
        </div>

        <div className="col-md-9">
          <p>{selectedBrand?.status || "Null"}</p>
        </div>
      </div>

      {/** VERIFIED DATE */}
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Verification Date</label>
        </div>

        <div className="col-md-9">
          <p>
            {selectedBrand?.verified_date
              ? formatDDMMYYY(selectedBrand.verified_date)
              : "Null"}
          </p>
        </div>
      </div>
    </form>
  );
}

export default EditBrand;
