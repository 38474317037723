import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";

import authReducer from "./features/authSlice";
import prefillReducer from "./features/prefillSlice";
import unitsReducer from "./features/unitsSlice";
import submittingUserVerificationReducer from "./features/submittingUserVerificationSlice";
import adminUserManagementReducer from "./features/adminUserManagement";
import categoryManagementReducer from "./features/categoryManagement";
import brandModelManagementReducer from "./features/brandModelManagement";
import companyManagementReducer from "./features/companyManagementSlice";
import brandManagementReducer from "./features/brandManagementSlice";
import modelManagementReducer from "./features/modelManagementSlice";
import modelInstructionReducer from "./features/modelInstructionSlice";
import softwareApplicationReducer from "./features/softwareApplicationManagement";
import softwareVersionReducer from "./features/softwareVersionManagement";
import symptomManagementReducer from "./features/symptomManagementSlice";
import aberrationManagementReducer from "./features/aberrationManagementSlice";
import symptomRecordReducer from "./features/symptomRecordSlice";
import unitQualityManagementReducer from "./features/unitQualityManagementSlice";
import unitQualityRecordReducer from "./features/unitQualityRecordSlice";
import dbVariableReducer from "./features/dbVariableSlice";
import securityKeyManagementReducer from "./features/securityKeyManagementSlice";
import brandsReducer from "./features/brandsSlice";
import companySlice from "./features/companySlice";
import modelsSlice from "./features/modelsSlice";
import messageTypeManagementSlice from "./features/messageTypeManagementSlice";
import messageCasesSlice from "./features/messageCasesSlice";
import unreadMessagesSlice from "./features/unreadMessagesSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  admin_user_management: adminUserManagementReducer,
  submittingUserVerification: submittingUserVerificationReducer,
  prefill: prefillReducer,
  units: unitsReducer,
  categoryManagement: categoryManagementReducer,
  brandModelManagement: brandModelManagementReducer,
  company_management: companyManagementReducer,
  brand_management: brandManagementReducer,
  model_management: modelManagementReducer,
  messageCases: messageCasesSlice,
  model_instruction: modelInstructionReducer,
  software_application_management: softwareApplicationReducer,
  software_version_management: softwareVersionReducer,
  symptom_management: symptomManagementReducer,
  aberration_management: aberrationManagementReducer,
  symptom_record: symptomRecordReducer,
  unit_quality_management: unitQualityManagementReducer,
  unit_quality_record: unitQualityRecordReducer,
  security_key_management: securityKeyManagementReducer,
  db_variables: dbVariableReducer,
  brands: brandsReducer,
  companies: companySlice,
  models: modelsSlice,
  message_type_management: messageTypeManagementSlice,
  unreadMessages: unreadMessagesSlice
});

const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
  blacklist: [
    "admin_user_management",
    "submittingUserVerification",
    "prefill",
    "units",
    "categoryManagement",
    "brandModelManagement",
    "company_management",
    "brand_management",
    "model_management",
    "messageCases",
    "model_instruction",
    "software_application_management",
    "software_version_management",
    "symptom_management",
    "aberration_management",
    "symptom_record",
    "unit_quality_management",
    "unit_quality_record",
    "security_key_management",
    "db_variables",
    "brands",
    "models",
    "companies",
    "message_type_management",
    "unreadMessages"
  ], //blacklisting a store attribute name, will not persist that store attribute.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);
