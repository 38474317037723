import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import { selectCategoryManagementData } from "redux-store/features/categoryManagement";
import CategoryService from "services/CategoryService";
import { logger, useForm } from "shared/helpers";
import { modes } from "../helper";

const defaultData = {
  attribute_id: null,
  use_units: "",
  public_use: "",
  repairable: "",
  fixed_location: "",
};

const EndCategoryAttributes = ({
  attributeForm: form,
  setAttributeForm: onChange,
}) => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const { categoryManagementForm, mode } = useSelector(
    selectCategoryManagementData
  );

  const getAttributeForCategory = async () => {
    try {
      const res = await CategoryService.getCategoryAttribute(
        token,
        dispatch,
        categoryManagementForm.category_id
      );

      console.log("Attribites", res.data);

      if (res.data.data && typeof res.data.data === "object") {
        for (const key of Object.keys(res.data.data))
          onChange(key, res.data.data[key]);
      }
    } catch (e) {
      logger("Error", e);
    }
  };

  useEffect(() => {
    if (categoryManagementForm.category_id) getAttributeForCategory();
    else {
      for (const key of Object.keys(defaultData))
        onChange(key, defaultData[key]);
    }
  }, [categoryManagementForm?.category_id]);

  return (
    <div>
      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Attribite ID</label>
        </div>

        <div className="col-md-9">{form?.attribute_id || "Null"}</div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">End Category</label>
        </div>
        <div className="col-md-9">
          <p>
            {mode === modes.new
              ? "Will be copied from new category name"
              : categoryManagementForm?.category?.name}
          </p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Repairable</label>
        </div>
        <div className="col-md-9">
          <select
            className="form-select"
            value={form.repairable}
            defaultValue=""
            onChange={(e) => onChange("repairable", e.target.value)}
            disabled={mode === modes.view}
            required
          >
            <option disabled value={""}>
              Is category repairable?
            </option>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Fixed Location</label>
        </div>
        <div className="col-md-9">
          <select
            className="form-select"
            value={form.fixed_location}
            defaultValue=""
            onChange={(e) => onChange("fixed_location", e.target.value)}
            required
            disabled={mode === modes.view}
          >
            <option disabled value={""}>
              Is category location fixed?
            </option>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Public Use</label>
        </div>
        <div className="col-md-9">
          <select
            className="form-select"
            value={form.public_use}
            defaultValue=""
            disabled={mode === modes.view}
            onChange={(e) => onChange("public_use", e.target.value)}
            required
          >
            <option disabled value={""}>
              Is category Public use?
            </option>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-3">
          <label className="form-label">Use Units</label>
        </div>
        <div className="col-md-9">
          <select
            className="form-select"
            disabled={mode === modes.view}
            defaultValue=""
            value={form.use_units}
            onChange={(e) => onChange("use_units", e.target.value)}
            required
          >
            <option disabled value={""}>
              Select use units
            </option>
            <option value={"Operational time"}>Operational time</option>
            <option value={"Age"}>Age</option>
            <option value={"Distance"}>Distance</option>
            <option value={"Cycles"}>Cycles</option>
            <option value={"Uses"}>Uses</option>
            <option value={"Not applicable"}>Not applicable</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default EndCategoryAttributes;
