import { Route, Routes } from "react-router-dom";
import QsManagement from "views/qs-management";
import AberrationManagement from "views/qs-management/aberration-management";
import AberrationSelect from "views/qs-management/aberration-select";
import QualityRecord from "views/qs-management/quality-record";
import SymptomManagement from "views/qs-management/symptom-management";
import SymptomRecord from "views/qs-management/symptom-record";
import UnitQualityRecordManagement from "views/qs-management/unit-quality-record-management";

const QsManagementRoutes = () => {
  return (
    <Routes>
      <Route index element={<QsManagement />} />
      <Route path={"symptom"} element={<SymptomManagement />} />

      <Route path={"aberration"} element={<AberrationManagement />} />

      <Route path={"unit-quality"} element={<UnitQualityRecordManagement />} />

      <Route path="unit-quality-record" element={<QualityRecord />} />

      <Route path={"select-aberration"} element={<AberrationSelect />} />

      <Route path="symptom-record" element={<SymptomRecord />} />
    </Routes>
  );
};

export default QsManagementRoutes;
