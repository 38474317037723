import axios from "axios";
import configs from "configs";
import { logout } from "redux-store/features/authSlice";

export const axiosClient = (dispatch, token, dataType = "application/json") => {
  const headers = {
    "Content-Type": dataType,
  };

  if (token) headers.Authorization = `Bearer ${token}`;

  const axiosInstance = axios.create({
    baseURL: configs.appAPI,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401 && dispatch) {
        dispatch(logout());
      } else if (
        error.response?.status === 500 &&
        error.response?.data?.message?.toLowerCase() === "unauthenticated."
      ) {
        dispatch(logout());
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
