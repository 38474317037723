import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "redux-store/features/authSlice";
import UserService from "services/UserService";
import { handleError } from "shared/helpers";
import AutocompleteV2 from "views/components/AutocompleteV2";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";

const SelectUser = ({ open, handleClose }) => {
    const token = useSelector(selectToken);
    const dispatch = useDispatch();
    const [searching, setSearching] = useState(false);

    const [mode, setMode] = useState("");
    const [userId, setUserId] = useState("");
    const [error, setError] = useState([]);

    const httpFindUser = useCallback(
        async (number) => {
            const res = await UserService.fetchUserByUserNo(token, dispatch, number);

            if (res.data && res.data.data) {
                return res.data.data;
            }

            return null;
        },
        [token, dispatch]
    )

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSearching(true);

        try {
            setError([]);

            if (mode === "Enter User ID") {
                const user = await httpFindUser(userId);

                handleClose({ user });

                if (!user) {
                    setError("User not found!");
                }
            } else {
                handleClose({ all: true });
            }
        } catch (error) {
            handleError(error, setError);
        }

        setSearching(false);
    }

    const reset = () => {
        setMode("");
        setUserId("");
        setError([]);
        setSearching(false);
    }

    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open]);

    return (
        <Modal
            backdrop="static"
            centered
            show={open}
            onHide={() => handleClose()}
        >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
                <Modal.Title>Select User</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form id="selectUserForm" onSubmit={handleSubmit}>
                    <AutocompleteV2 
                        labels={["key"]}
                        suggestions={[{key: "All Users"}, {key: "Enter User ID"}]}
                        placeholder={"Select User"}
                        primaryKey={"key"}
                        setValue={(val) => setMode(val.key)}
                        value={mode}
                        disabledTyping
                        required
                    />

                    {
                        mode === "Enter User ID" && (
                            <input 
                                type="text" 
                                className="form-control mt-3"
                                value={userId}
                                placeholder="87666778766"
                                onChange={(e) => setUserId(e.target.value)}
                                required
                            />
                        )
                    }

                    {error.length > 0 && (
                        <div className="mt-3">
                            <ErrorsComponent 
                                errors={error}
                            />
                        </div>
                    )}
                </form>
            </Modal.Body>

            <Modal.Footer>
            <button className="btn btn-black me-2" onClick={() => handleClose()}>
                Cancel
            </button>

            <button className="btn btn-yellow" type="submit" form="selectUserForm">
                {searching ? <LoadIndicator /> : "Submit"}
            </button>
            </Modal.Footer>
        </Modal>
    )
}

export default SelectUser;