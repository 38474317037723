import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  qualities: [],
  selectedUnit: null,
};

export const unitQualityManagement = createSlice({
  name: "unit_quality_management",
  initialState,
  reducers: {
    setQualities: (state, action) => {
      state.qualities = action.payload || [];
    },

    setSelectedUnit: (state, action) => {
      state.selectedUnit = action.payload || null;
    },
  },
});

export const { setQualities, setSelectedUnit } = unitQualityManagement.actions;

export const selectUnitQualityManagementData = (state) => ({
  ...state.unit_quality_management,
});

export default unitQualityManagement.reducer;
