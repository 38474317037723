const { axiosClient } = require("shared/axiosInstance");

class SecurityKeyService {
  constructor(dispatch, token) {
    this.client = axiosClient(dispatch, token);
  }

  async fetchAll() {
    return this.client.get("/security-key");
  }

  async update(data) {
    return this.client.put("/security-key", data);
  }
}

export default SecurityKeyService;
