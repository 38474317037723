import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const QualityRecordsUsedInModal = ({
  show,
  handleClose,
  data = [],
}) => {

    const navigate = useNavigate();

    const goToQuality = (item) => () => {
        navigate("/database-records-management/qs-management/unit-quality-record", {
            state: {
                data: JSON.stringify(item),
            },
        });
    }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>Quality Records</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: 8, paddingTop: 0 }}>
          {/* <div className="table-responsive"> */}
          <table className="table table-responsive table-bordered table-dark-theme">
            <thead>
              <tr>
                <th>ID</th>
                <th>Unit ID</th>
                <th>Owner ID</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.quality_id} style={{cursor: 'pointer'}} onClick={goToQuality(item)}>
                  <td>{item.quality_id}</td>
                  <td>{item.unit}</td>
                  <td>
                    {item.owner_random_id || "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* </div> */}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleClose} type="button">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
