import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AuthenticationService from "services/AuthenticationService";
import Header from "../../components/header";
import { login } from "../../../redux-store/features/authSlice";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

function Sigin() {
  const signup = new URLSearchParams(window.location.search).get("from");
  const [form, set_form] = useState({});
  const dispatch = useDispatch();

  const [err, set_err] = useState([]);
  const [loading, set_loading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    set_err([]);
    set_loading(true);

    try {
      const res = await AuthenticationService.signin_admin(form);

      dispatch(login({ user: res.data.user, token: res.data.token }));
    } catch (error) {
      const msg = error.response?.data?.message;
      if (msg) {
        if (msg instanceof String || typeof msg === "string") set_err([msg]);
        else {
          const arr = [];
          Object.keys(msg).map((key) => {
            for (const emsg of msg[key]) arr.push(emsg);
          });

          set_err(arr);
        }
      } else {
        set_err(["Something went wrong, check your internet"]);
      }
    }

    set_loading(false);
  };

  const onChange = (name, value) =>
    set_form((prev) => ({ ...prev, [name]: value }));

  const [showPassword, setShowPassword] = useState(false);

  const toggle_password = () => setShowPassword(!showPassword);

  return (
    <>
      <Header />

      <div className="container">
        <div className="loginBg">
          <h1>Login ID</h1>

          {signup && (
            <div className="success mb-4" style={{ textAlign: "center" }}>
              Please use your current sign in details
            </div>
          )}
          <form id="loginForm" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="form-label">
                Email or User Number <em className="required">*</em>
              </label>
              <input
                type="text"
                className="form-control"
                id="user"
                name="user"
                value={form.identifier}
                onChange={(e) => onChange("identifier", e.target.value)}
                placeholder=""
                required
              />
            </div>
            <div className="mb-4">
              <label className="form-label">
                Password <em className="required">*</em>
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type={showPassword ? "text" : "password"}
                  value={form.password}
                  onChange={(e) => onChange("password", e.target.value)}
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  required
                />

                <div
                  onClick={toggle_password}
                  style={{ marginLeft: 10, cursor: "pointer" }}
                >
                  {!showPassword ? (
                    <FontAwesomeIcon icon={regular("eye")} size="lg" />
                  ) : (
                    <FontAwesomeIcon icon={regular("eye-slash")} size="lg" />
                  )}
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-yellow">
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div>

            {err.map((text) => (
              <div className="error_message">{text}</div>
            ))}
          </form>
        </div>
      </div>
    </>
  );
}

export default Sigin;
