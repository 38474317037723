import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import { updateSelectedBrandModel } from "redux-store/features/brandModelManagement";
import {
  selectModelInstructionData,
  setInstructions,
} from "redux-store/features/modelInstructionSlice";
import { updateSelectedAttribute } from "redux-store/features/modelManagementSlice";
import BrandService from "services/BrandService";
import CategoryService from "services/CategoryService";
import ModelInstructionService from "services/ModelInstructionService";
import { logger } from "shared/helpers";
import Autocomplete from "views/components/Autocomplete";

import LoadIndicator from "views/components/LoadIndicator";
import { tabs } from "shared/b_m_helper";
import styles from "./SerialInstruction.module.css";
import { useHasFocus } from "hooks/useHasFocus";

function SerialInstruction(props) {
  const focus = useHasFocus();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const instructionService = new ModelInstructionService(dispatch, token);

  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredInstructions, setFilteredInstructions] = useState([]);
  const { instructions, initialized, from, contextData } = useSelector(
    selectModelInstructionData
  );

  const goToInstructionRecord = () => {
    if (props.contextData) {
      window.open(
        `${window.location.origin}/database-records-management/instruction-record?ref=${props.contextData.from}`
      );
    } else {
      navigate("/database-records-management/instruction-record", {
        state: {
          from,
        },
      });
    }
  };

  const selectInstructionBM = (value) => {
    if (props.contextData) {
      props.contextData.onSelect(value);
    } else {
      dispatch(updateSelectedBrandModel({ key: "serial_instructions", value }));
      navigate(
        "/database-records-management/brand-model?tab=" + tabs.attributes
      );
    }
  };

  const selectInstructionM = (value) => {
    dispatch(updateSelectedAttribute({ key: "serial_instructions", value }));
    goBack();
  };

  const goToModelInstructionDetail = (id) =>
    navigate("/database-records-management/instruction-record?id=" + id);

  const handleRowClick = (id) => {
    if (from === "brand_model" || props.contextData?.from === "brand_model")
      selectInstructionBM(id);
    else if (from === "model") selectInstructionM(id);
    else goToModelInstructionDetail(id);
  };

  useEffect(() => {
    setFilteredInstructions(instructions);
  }, [instructions]);

  const [yourFile, setYourFile] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [brandInput, setBrandInput] = useState("All Brands");
  const [categoryInput, setCategoryInput] = useState("All Categories");

  useEffect(() => {
    if (instructions.length > 0) {
      const result = instructions.filter(
        (item) =>
          (!yourFile || item.owner === user.user_id) &&
          (!selectedBrand || item.brand == selectedBrand) &&
          (!selectedCategory || item.category == selectedCategory)
      );

      setFilteredInstructions(result);
    }
  }, [instructions, yourFile, selectedBrand, selectedCategory]);

  const setupDefaults = (contextData) => {
    console.log("SET UP DEFAULTS", contextData);
    setSelectedBrand(contextData.brand || "");
    setBrandInput(contextData.brand_name || "");

    if (categories.length > 0) {
      const category = categories.filter(
        (e) => e.name == contextData.category
      )[0];

      setSelectedCategory(category?.category_id || "");
      setCategoryInput(category?.name || "");
    }
  };

  useEffect(() => {
    if (from && contextData) {
      if (from === "brand_model") {
        setupDefaults(contextData);
      }
    }
  }, [from, contextData, categories, brands]);

  useEffect(() => {
    if (props.contextData) {
      if (props.contextData.from === "brand_model") {
        setupDefaults(props.contextData);
      }
    }
  }, [props.contextData, categories, brands]);

  const [fetchingBrands, setFetchingBrands] = useState(false);
  const fetchBrands = async () => {
    setFetchingBrands(true);
    try {
      const res = await BrandService.fetchAll(token, dispatch);

      setBrands(res.data.data);
    } catch (error) {
      logger("Error", error);
    }
    setFetchingBrands(false);
  };

  const [fetchingCategories, setFetchingCategories] = useState(false);
  const fetchCategories = async () => {
    setFetchingCategories(true);
    try {
      const res = await CategoryService.fetchAllCategoryChildren(
        token,
        dispatch,
        "All"
      );

      setCategories(res.data.data);
    } catch (error) {
      logger("[error]", error);
    }
    setFetchingCategories(false);
  };

  const [fetchingInstructions, setFetchingInstructions] = useState(false);
  const fetchInstructions = async () => {
    setFetchingInstructions(true);
    try {
      const res = await instructionService.fetchAll();

      dispatch(setInstructions(res.data.data));
    } catch (error) {
      logger("Error ", error);
    }
    setFetchingInstructions(false);
  };

  useEffect(() => {
    fetchBrands();
    fetchCategories();

    if (!initialized) fetchInstructions();
  }, []);

  const refresh = () => {
    setYourFile(0);
    setSelectedBrand("");
    setBrandInput("All Brands");
    setSelectedCategory("");
    setCategoryInput("All Categories");

    fetchInstructions();
  };

  useEffect(() => {
    if (focus) {
      refresh();
    }
  }, [focus]);

  return (
    <>
      <div
        id="main-container"
        style={{ padding: Boolean(props.contextData) ? 0 : undefined }}
      >
        <div className={styles.wrapper}>
          {!Boolean(props.contextData) && (
            <div className={`${styles.arrow} mb-4`} onClick={goBack}>
              <img src="/assets/Archive/ArrowLeft.png" alt="" />
              <span>Go Back</span>
            </div>
          )}

          <div className={`${styles.heading} mb-4`}>
            <h2>Instuctions Available</h2>
            <p className={styles.userAdmin}>User: Admin</p>
          </div>

          <div className={`${styles.lists} mb-4`}>
            <p className={styles.filter}>Filters</p>

            <div className={styles.selectWrapper}>
              <div className={styles.label}>
                <label>Ownership:</label>
              </div>

              <select
                className="form-select"
                value={yourFile}
                onChange={(e) => setYourFile(+e.target.value)}
              >
                <option value={1}>Your File</option>
                <option value={0}>All files</option>
              </select>
            </div>

            <div className={styles.selectWrapper}>
              <div className={styles.label}>
                <label>Brands:</label>
              </div>

              {fetchingBrands ? (
                <LoadIndicator />
              ) : (
                <div className={styles.select}>
                  <Autocomplete
                    suggestions={brands}
                    input={brandInput}
                    first={[{ brand: "All Brands", brand_id: "" }]}
                    setInput={(input) => setBrandInput(input)}
                    labelKey="brand"
                    primaryKey={"brand_id"}
                    setValue={({ brand_id }) => {
                      setSelectedBrand(brand_id);
                    }}
                  />
                </div>
              )}
            </div>

            <div className={styles.selectWrapper}>
              <div className={styles.label}>
                <label>Category:</label>
              </div>

              {fetchingCategories ? (
                <LoadIndicator />
              ) : (
                <div className={styles.select}>
                  <Autocomplete
                    suggestions={categories}
                    input={categoryInput}
                    first={[{ name: "All Categories", category_id: "" }]}
                    setInput={(input) => setCategoryInput(input)}
                    labelKey="name"
                    primaryKey={"brand_id"}
                    setValue={({ category_id }) => {
                      setSelectedCategory(category_id);
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <span className={`${styles.updateList} mb-4`} onClick={refresh}>
            Update List
          </span>

          {/*  */}
          <div className={styles.tableWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>
                    <div className={styles.tableHead}>
                      <img src="/assets/Archive/UserCircle.png" alt="" />
                      <span>Owner</span>
                    </div>
                  </th>
                  <th>
                    <div className={styles.tableHead}>
                      <img src="/assets/Archive/AppStoreLogo.png" alt="" />
                      <span>Brand</span>
                    </div>
                  </th>
                  <th>
                    <div className={styles.tableHead}>
                      <img src="/assets/Archive/List.png" alt="" />
                      <span>Category</span>
                    </div>
                  </th>
                  <th>
                    <div className={styles.tableHead}>
                      <img
                        src="/assets/Archive/IdentificationCard.png"
                        alt=""
                      />
                      <span>Name</span>
                    </div>
                  </th>
                  <th>
                    <div className={styles.tableHead}>
                      <img src="/assets/Archive/app.png" alt="" />
                      <span>Applications</span>
                    </div>
                  </th>
                  <th>
                    <div
                      className={
                        styles.tableHead + " " + styles.tableHeadLastChild
                      }
                    ></div>
                  </th>
                </tr>
              </thead>

              {fetchingInstructions ? (
                <LoadIndicator />
              ) : (
                <tbody>
                  {filteredInstructions.map((instruction) => {
                    let name = "Null";

                    if (
                      instruction.owner_first_name &&
                      instruction.owner_last_name
                    )
                      name = `${instruction.owner_first_name} ${instruction.owner_last_name}`;

                    return (
                      <tr
                        key={`instruction-${instruction.instruction_id}`}
                        className={styles.tableChild}
                        onClick={() =>
                          handleRowClick(instruction.instruction_id)
                        }
                      >
                        <td>{name}</td>
                        <td>{instruction.brand_name || "Null"}</td>
                        <td>{instruction.category_name || "Null"}</td>
                        <td>{instruction.name}</td>
                        <td>{instruction.application || "Null"}</td>
                        <td>
                          {/* <div className={styles.deleteBtn}>
                            <img src="/assets/Archive/DeleteBtn.png" alt="" />
                          </div> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>

          {/* close table  */}
          <div className={`${styles.btnContaner} mt-4`}>
            <button
              className={styles.cancel}
              onClick={props.contextData?.handleClose || goBack}
            >
              Cancel
            </button>

            <button onClick={goToInstructionRecord} className={styles.new}>
              New Instuction
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SerialInstruction;
