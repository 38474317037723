import { axiosClient } from "shared/axiosInstance";

class DatabaseGlobalVariablesService {
  constructor(token, dispatch) {
    this.token = token;
    this.dispatch = dispatch;
  }

  getDatabaseGlobalVariables() {
    return axiosClient(this.dispatch, this.token).get("/db-variables");
  }

  updateDatabaseGlobalVariables(data) {
    return axiosClient(this.dispatch, this.token).patch("/db-variables", data);
  }
}

export default DatabaseGlobalVariablesService;
