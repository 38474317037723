import "./style.css";

const CategoryEndStructureTable = ({ data }) => {
  console.log(data);
  return (
    <div className="category-end-structure-table">
      <table>
        <thead>
          <tr>
            <th>Category ID</th>
            <th>Category name</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, idx) => {
            return (
              <tr className={idx % 2 === 0 ? "active-row" : undefined}>
                <td>{item.category_id}</td>
                <td>{item.name}</td>
                <td>{item.result}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryEndStructureTable;
