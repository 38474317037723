import { axiosClient } from "shared/axiosInstance";

class UnitService {
  constructor() {}

  static quickSearch(token, dispatch) {
    return axiosClient(dispatch, token).get("/unit/quick-search");
  }

  static saveUnit(token, dispatch, data) {
    return axiosClient(dispatch, token).post("/unit", data);
  }

  static getPendingUnits(token, dispatch) {
    return axiosClient(dispatch, token).get("/unit/pending");
  }

  static getUnit(token, dispatch, id) {
    return axiosClient(dispatch, token).get(`/unit/${id}`);
  }

  static getUnitQualities(token, dispatch, id) {
    return axiosClient(dispatch, token).get("/unit/qualities?unit_id=" + id);
  }

  static addControllerUseAndPrice(token, dispatch, data) {
    return axiosClient(dispatch, token).post(
      "/unit/create_use_and_price",
      data
    );
  }

  static updateUnitRecord(token, dispatch, data) {
    return axiosClient(dispatch, token).patch("/unit", data);
  }

  static controllerUseIsCurrent(token, dispatch, id) {
    return axiosClient(dispatch, token).get(
      `/unit/controller-exists?unit_id=${id}`
    );
  }
}

export default UnitService;
