import { axiosClient } from "shared/axiosInstance";

class AuthenticationService {
  constructor() {}

  /**
   *
   */
  static signup(data) {
    return axiosClient().post("/auth/signup", data);
  }

  static verify_mail(data) {
    return axiosClient().post("/auth/confirm/email", data);
  }

  static send_user_no(data) {
    return axiosClient().post("/auth/remember/user_no", data);
  }

  static verify_phone(phone, email) {
    return axiosClient().get(`/auth/confirm/phone/${phone}/${email}`);
  }

  static verify_code(data) {
    return axiosClient().post("/auth/confirm/phone_code", data);
  }

  static complete_signup(data) {
    return axiosClient().post("/auth/update_user", data);
  }

  static is_email_verified(email) {
    return axiosClient().get(
      `/auth/confirm/email_verification_status/${email}`
    );
  }

  static signin(data) {
    return axiosClient().post("/auth/signin", data);
  }

  static signin_admin(data) {
    return axiosClient().post("/auth/admin/signin", data);
  }

  static forgot_password(identity) {
    return axiosClient().get(`/auth/reset/password/${identity}`);
  }

  static reset_password(token, password) {
    return axiosClient().put(`/auth/reset/password/${token}`, { password });
  }
}

export default AuthenticationService;
