import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import styles from "./SoftwareVersionRecord.module.css";
import { useState } from "react";
import { formatDDMMYYYYDash, useForm } from "shared/helpers";
import SWVersionService from "services/SoftwareVersionServices";
import { useSelector } from "react-redux";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import { useDispatch } from "react-redux";
import LockTimer from "views/components/LockTimer";
import {
  addSWVersion,
  updateSWVersion,
} from "redux-store/features/softwareVersionManagement";
import WarningModal from "views/components/WarningModal";
import ConfirmDelete from "./Overlay/Delete";

function SoftwareVersionRecord() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const { state } = useLocation();

  const [application, setApplication] = useState(null);
  const [version, setVersion] = useState(null);

  const swVersionService = new SWVersionService(dispatch, token);

  const {
    form,
    onChange,
    handleServerError,
    validate,
    reset: resetForm,
  } = useForm({
    name: "",
    build_code: "",
    notes: "",
    released_date: null,
    uses: 0,
  });

  const [editBtn, setEditBtn] = useState(false);
  const [showWaringModal, setShowWarningModal] = useState(false);

  const isChanged = () =>
    version &&
    (version.name !== form.name ||
      version.build_code !== form.build_code ||
      version.notes !== form.notes ||
      version.released_date !== form.released_date);

  const editHandler = () => {
    if (!editBtn) {
      setEditBtn(true);
    } else {
      if (isChanged()) {
        setShowWarningModal(true);
      } else {
        setEditBtn(false);
      }
    }
  };

  const createSWVersion = async () => {
    if (!application?.sw_application_id) return;

    try {
      const res = await swVersionService.create({
        name: form.name,
        released_date: form.released_date,
        build_code: form.build_code,
        notes: form.notes,
        application_id: application.sw_application_id,
      });

      dispatch(addSWVersion(res.data.data));

      navigate(-1);
    } catch (error) {
      handleServerError(error);
    }
  };

  const updateVersion = async () => {
    try {
      const res = await swVersionService.update(form);

      const version = res.data.data;

      dispatch(updateSWVersion(version));

      setEditBtn(false);

      setVersion(null);
      Object.keys(version).forEach((key) => onChange(key, version[key]));
    } catch (error) {
      handleServerError(error);
    }
  };

  const [saving, setSaving] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate(["build_code", "name"])) return;

    setSaving(true);
    if (state?.for === "new" || !form.sw_version_id) {
      await createSWVersion();
    } else {
      if (editBtn) updateVersion();
    }

    setSaving(false);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleDeleteClose = (deleted = false) => {
    setOpenDelete(false);
    if (deleted) goBack();
  };

  const initiateMessageCreator = async () => {
    if (!form.sw_version_id || !form.created_by) return;

    const message = `
      SW Version: ${form.name}, ${form.build_code}
    `.trim();

    navigate("/messages/new", {
      state: {
        prefilled: {
          to: form.created_by,
          title: "Entry Query",
          message,
          type: 9,
        },
      },
    });
  };

  const setupForNew = () => {
    resetForm();
    if (!editBtn) setEditBtn(true);
  };

  const unlockApplication = async () => {
    try {
      await swVersionService.unlock();
    } catch (error) {}
  };

  const viewVersion = async (id) => {
    try {
      const res = await swVersionService.view(id);

      setVersion(res.data.data);

      Object.keys(res.data.data).forEach((key) =>
        onChange(key, res.data.data[key])
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (editBtn && form.sw_version_id) {
      viewVersion(form.sw_version_id);
    } else {
      onChange("locked_by", null);
      onChange("locked_on", null);

      unlockApplication();
    }
  }, [editBtn]);

  useEffect(() => {
    if (state) {
      setEditBtn(state.for === "new");

      setApplication(state.application);

      if (state.prefilled) {
        Object.keys(state.prefilled).forEach((key) =>
          onChange(key, state.prefilled[key])
        );
      }

      if (state.item) {
        Object.keys(state.item).forEach((key) =>
          onChange(key, state.item[key])
        );
      }
    }
  }, [state]);

  const getVersionData = async (id) => {
    try {
      const res = await swVersionService.get(id);

      setApplication(res.data.application);

      if (res.data.data) {
        Object.keys(res.data.data).forEach((key) =>
          onChange(key, res.data.data[key])
        );
      }

      setEditBtn(false);
    } catch (error) {}
  };

  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get("id");

    if (id) {
      getVersionData(+id);
    }

    return () => {
      unlockApplication();
    };
  }, []);

  return (
    <>
      <WarningModal
        show={showWaringModal}
        text="Changes have not been saved, exit edit mode?"
        handleOk={() => {
          setEditBtn(false);

          if (version) {
            onChange("name", version.name);
            onChange("build_code", version.build_code);
            onChange("notes", version.notes);
            onChange("released_date", version.released_date);
          }

          setShowWarningModal(false);
        }}
        handleClose={() => setShowWarningModal(false)}
      />

      {openDelete && (
        <ConfirmDelete
          show={openDelete}
          versionId={form?.sw_version_id}
          handleClose={handleDeleteClose}
        />
      )}

      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center userLevel-Admin">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/Archive/ArrowLeft.png" alt="" />
            <span>Go Back</span>
          </div>

          <div className={`${styles.heading} mb-4`}>
            <h2 className="mb-4">Software version record</h2>

            <p className={styles.filter}>Application</p>

            <div className={styles.selectWrapper}>
              <div>
                <label>Name:</label>
              </div>

              <span>{application?.name || "Null"}</span>
            </div>

            <div className={styles.selectWrapper}>
              <div>
                <label>Details:</label>
              </div>

              <span>{application?.application || "Null"}</span>
            </div>

            <div className={styles.selectWrapper}>
              <div>
                <label>Hw Brand:</label>
              </div>

              <span>{application?.brand_name || "Null"}</span>
            </div>

            {/* <p className={styles.uses}>Model Instructions</p> */}

            {(!state || state.for === "existing") && form.sw_version_id && (
              <div className={styles.alNum}>
                <LockTimer data={form} onComplete={() => navigate(-1)} />

                <div className={styles.topBtn}>
                  <button
                    onClick={editHandler}
                    disabled={form.locked_by && form.locked_by !== user.user_id}
                  >
                    {editBtn ? "Close" : "Edit"}
                  </button>
                </div>
              </div>
            )}
          </div>
          {/*  */}

          {/* a row withoiut button*/}
          <form onSubmit={handleSubmit} id="softwareVersionRecordForm">
            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>SW Version ID</span>
              </div>

              <div className={styles.rowValue}>
                <span>{form.sw_version_id || "Null"}</span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Application ID</span>
              </div>

              <div className={styles.rowValue}>
                <span>{application?.sw_application_id || "Null"}</span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Name</span>
              </div>

              <div className={styles.rowValue}>
                {!editBtn ? (
                  <span>{form.name || "Null"}</span>
                ) : (
                  <input
                    className="form-control"
                    maxLength={30}
                    value={form.name}
                    onChange={(e) => onChange("name", e.target.value)}
                    required
                  />
                )}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Build Code</span>
              </div>

              <div className={styles.rowValue}>
                {!editBtn ? (
                  <span>{form.build_code || "Null"}</span>
                ) : (
                  <input
                    className="form-control"
                    maxLength={100}
                    value={form.build_code}
                    onChange={(e) => onChange("build_code", e.target.value)}
                    required
                  />
                )}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Release Note</span>
              </div>

              <div className={styles.rowValue}>
                {!editBtn ? (
                  <span>{form.notes || "Null"}</span>
                ) : (
                  <input
                    className="form-control"
                    maxLength={100}
                    value={form.notes}
                    onChange={(e) => onChange("notes", e.target.value)}
                  />
                )}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Release Date</span>
              </div>

              <div className={styles.rowValue}>
                {!editBtn ? (
                  <span>
                    {form.released_date
                      ? formatDDMMYYYYDash(form.released_date)
                      : "Null"}
                  </span>
                ) : (
                  <ReactDatePicker
                    className="form-control datepicker_input"
                    selected={
                      form.released_date ? new Date(form.released_date) : null
                    }
                    onChange={(date) =>
                      onChange("released_date", date.toISOString())
                    }
                    dateFormat="dd/MM/yyyy"
                    required
                    placeholderText="Select date or enter dd/MM/yyyy"
                  />
                )}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Created By </span>
              </div>

              <div className={styles.rowValue}>
                <span>{form.created_by_name || "Null"}</span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Created At</span>
              </div>

              <div className={styles.rowValue}>
                <span>
                  {form.created_at
                    ? formatDDMMYYYYDash(form.created_at)
                    : "Null"}
                </span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Approved By</span>
              </div>

              <div className={styles.rowValue}>
                <span>{form.approved_by_name || "Null"}</span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Approved At</span>
              </div>

              <div className={styles.rowValue}>
                <span>
                  {form.approved_at
                    ? formatDDMMYYYYDash(form.approved_at)
                    : "Null"}
                </span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Status</span>
              </div>

              <div className={styles.rowValue}>
                <span>{form.status || "Null"}</span>
              </div>
            </div>
          </form>

          {/* a row with button*/}
          <div className={styles.btnContainer}>
            <div className={styles.btnLeft}>
              <button
                className={styles.new}
                disabled={saving}
                onClick={setupForNew}
              >
                Create new
              </button>
            </div>

            <div className={styles.btnRight}>
              <div className={styles.rightTop}>
                <button
                  className={styles.del}
                  disabled={saving || form.uses > 0 || !form.sw_version_id}
                  onClick={() => setOpenDelete(true)}
                >
                  Delete
                </button>

                <button
                  className={styles.submit}
                  type="submit"
                  form="softwareVersionRecordForm"
                  disabled={saving || !editBtn}
                >
                  Submit
                </button>
              </div>

              <div className={styles.rightBottom}>
                {form.created_by && form.created_by !== user.user_id && (
                  <button
                    className={styles.manage}
                    disabled={saving}
                    onClick={initiateMessageCreator}
                  >
                    Message creator
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className={styles.mobileBtn}>
            <div className={styles.btnTop}>
              <button
                className={styles.del}
                disabled={saving || form.uses > 0 || !form.sw_version_id}
                onClick={() => setOpenDelete(true)}
              >
                Delete
              </button>

              <button
                className={styles.submit}
                type="submit"
                form="softwareVersionRecordForm"
                disabled={saving || !editBtn}
              >
                Submit
              </button>
            </div>

            <div className={styles.btnBottom}>
              {form.created_by && form.created_by !== user.user_id && (
                <button
                  className={styles.manage}
                  disabled={saving}
                  onClick={initiateMessageCreator}
                >
                  Message creator
                </button>
              )}

              <button
                className={styles.new}
                onClick={setupForNew}
                disabled={saving}
              >
                Create new
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SoftwareVersionRecord;
