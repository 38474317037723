export const modes = {
  view: "view",
  edit: "edit",
  new: "new",
};

export const handleServerError = (error, set_err) => {
  const msg = error.response?.data?.message;
  if (msg) {
    if (msg instanceof String || typeof msg === "string") set_err([msg]);
    else {
      const arr = [];
      Object.keys(msg).map((key) => {
        for (const emsg of msg[key]) arr.push(emsg);
      });

      set_err(arr);
    }
  } else {
    set_err(["Something went wrong"]);
  }
};

export const formatEnum = (string = "") => {
  while (string.includes("_")) string = string.replace("_", " ");

  return `${string[0].toUpperCase()}${string.substring(1).toLowerCase()}`;
};
