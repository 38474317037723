import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.css";
import CardTable from "views/components/CardTable";
import { selectToken } from "redux-store/features/authSlice";
import TaxService from "services/TaxService";
import { FullScreenLoader } from "components/FullScreenLoader";
import LoadIndicator from "views/components/LoadIndicator";
import { usePagination } from "hooks/usePagination";
import RequireAdminLevel, { ADMIN_LEVELS } from "HoC/RequireAdminLevel";

const TaxManagementMode = {
  View: "View",
  Edit: "Edit",
};

const TaxManagement = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const [loading, setLoading] = useState(false);
  const [countriesToUpdate, setCountriesToUpdate] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [mode, setMode] = useState(TaxManagementMode.View);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const taxService = new TaxService(dispatch, token);

  const fetchCountries = useCallback(async () => {
    try {
      const res = await taxService.fetchCountries();
      const result = res.data;
      setCountries(result.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchCurrencies = useCallback(async () => {
    try {
      const res = await taxService.fetchCurrencies();
      setCurrencies(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      await fetchCurrencies();
      await fetchCountries();
      setFetching(false);
    };

    fetchData();
  }, []);

  const goBack = () => navigate(-1);

  const onRegStatusChange = useCallback(
    (index) => {
      setCountries((prev) =>
        prev.map((country, countryIndex) => {
          if (countryIndex === index) {
            const updatedCountry = {
              ...country,
              registered: country.registered == 1 ? 0 : 1,
            };
            const exist = countriesToUpdate.some(
              (e) => e === country.country_id
            );
            if (!exist) {
              setCountriesToUpdate((prev) => [...prev, country.country_id]);
            }

            return updatedCountry;
          }

          return country;
        })
      );
    },
    [countries]
  );

  const onCurrencyChange = useCallback(
    (index, value) => {
      setCountries((prev) =>
        prev.map((country, countryIndex) => {
          if (countryIndex === index) {
            const updatedCountry = { ...country, currency: value };
            const exist = countriesToUpdate.some(
              (e) => e === country.country_id
            );
            if (!exist) {
              setCountriesToUpdate((prev) => [...prev, country.country_id]);
            }

            return updatedCountry;
          }

          return country;
        })
      );
    },
    [countries]
  );

  const onCountryChange = useCallback(
    (index, value) => {
      setCountries((prev) =>
        prev.map((country, countryIndex) => {
          if (countryIndex === index) {
            const updatedCountry = { ...country, name: value };
            const exist = countriesToUpdate.some(
              (e) => e === country.country_id
            );
            if (!exist) {
              setCountriesToUpdate((prev) => [...prev, country.country_id]);
            }

            return updatedCountry;
          }

          return country;
        })
      );
    },
    [countries]
  );

  const toggleMode = useCallback(() => {
    if (mode === TaxManagementMode.Edit) {
      setMode(TaxManagementMode.View);
    } else {
      setMode(TaxManagementMode.Edit);
    }
  }, [mode]);

  const columns = useMemo(
    () => [
      {
        title: "Country",
        key: "name",
        colSpan: "3",
        componentType: "Input",
        onChange: onCountryChange,
        disabled: mode === TaxManagementMode.View,
      },
      {
        title: "Reg",
        componentType: "Switch",
        onChange: onRegStatusChange,
        key: "registered",
        disabled: mode === TaxManagementMode.View,
      },
      {
        title: "Currency",
        key: "currency",
        componentType: "Select",
        componentOptions: currencies.map((e) => ({ val: e.id, label: e.iso })),
        onSelect: onCurrencyChange,
        disabled: mode === TaxManagementMode.View,
      },
      {
        title: "Sales / year",
        key: "sales",
        colSpan: "2",
      },
    ],
    [mode, currencies, onCountryChange, onRegStatusChange, onCurrencyChange]
  );

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const data = countries.filter((country) =>
        countriesToUpdate.includes(country.country_id)
      );
      await taxService.updateCountryData({ data });
      setMode(TaxManagementMode.View);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [countries, countriesToUpdate]);

  return (
    <>
      <FullScreenLoader isOpen={fetching} />

      <div id="main-container">
        <div className="container">
          <div className="row mb-1 justify-content-center">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4">Tax Management</h2>

          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/images/ArrowLeft.png" alt="" />
            <span>Admin Controls</span>
          </div>

          <div className="mb-4">
            <CardTable id="tax-management" columns={columns} data={countries} />
          </div>

          <div
            className={`mb-4 ${styles.actions} d-flex flex-row align-items-center flex-wrap`}
          >
            <button
              className={styles.button}
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? <LoadIndicator /> : "Save"}
            </button>

            <RequireAdminLevel level={ADMIN_LEVELS.A5}>
              <button className={styles.button} onClick={toggleMode}>
                {mode === TaxManagementMode.View ? "Edit" : "Cancel"}
              </button>
            </RequireAdminLevel>

            <button className={styles.button}>Sales Summary</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxManagement;
