import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import {
  selectMessageCases,
  selectMessageCasesBugTypes,
} from "redux-store/features/messageCasesSlice";
import { useEffect, useMemo, useState } from "react";
import { useMessageCases } from "hooks/useMessageCases";
import LoadIndicator from "views/components/LoadIndicator";
import { formatDDMMYYYYDash, useForm } from "shared/helpers";
import { selectUser } from "redux-store/features/authSlice";
import AttachFiles from "../AttachFiles";
import ConfirmClose from "./modals/ConfirmClose";
import SingleMessageView from "views/components/SingleMessageView";
import AddUser from "./modals/AddUser";
import { CaseMessage } from "./CaseMessage";

const MessageCase = () => {
  const navigate = useNavigate();
  const controller = useMessageCases();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const message = searchParams.get("message");

  const user = useSelector(selectUser);
  const { case_id } = useParams();
  const [editSummary, setEditSummary] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [summary, setSummary] = useState("");
  const [savingSmmary, setSavingSummary] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [openCloseConfirm, setOpenCloseConfirm] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const form = useForm({
    message: "",
    files: [],
    to: "",
  });

  const bugTypes = useSelector(selectMessageCasesBugTypes);
  const messageCases = useSelector(selectMessageCases);

  const selectedMessageType = useMemo(() => {
    if (!selectedCase?.type || bugTypes.length === 0) return null;

    return (
      bugTypes.filter((type) => type.type_id === selectedCase.type)[0] || null
    );
  }, [selectedCase?.type, bugTypes.length]);

  const goBack = () => navigate(-1);
  const cleanUp = () => {
    setSelectedCase(null);
    setEditSummary(false);
    setSavingSummary(false);
    setSummary("");
    setMessages([]);
    setUsers([]);
    setErrors([]);
    setSaving(false);
    setOpenCloseConfirm(false);
    setOpenAddUser(false);
  };

  const handleEditSummaryClick = async () => {
    if (!selectedCase) return;

    if (!editSummary) {
      setEditSummary(true);
      setSummary(selectedCase.title);
    } else {
      if (!summary) return;

      setSavingSummary(true);
      await controller.httpUpdateCase(selectedCase.id, { title: summary });
      setSavingSummary(false);
      setSummary("");
      setEditSummary(false);
    }
  };

  const getMessageFrom = (msg) => {
    if (!msg) return "-";

    const messageUser = users.filter((e) => e.user === msg.user)[0];

    if (!messageUser) return "-";

    let from = [];

    if (Boolean(messageUser.supporter)) {
      from.push("Supporter");
    } else {
      from.push("User");
    }

    if (!Boolean(messageUser.supporter)) {
      from.push(messageUser.user);
    }

    if (user && user.user_id === messageUser.user) {
      from.push("(You)");
    }

    return from.join(" ");
  };

  const getNewMessageFrom = () => {
    const messageUser = users.filter((e) => e.user == user?.user_id)[0];

    if (!messageUser) return "-";

    return Boolean(messageUser.supporter) ? "Supporter (You)" : "User (You)";
  };

  const handleCloseCase = () => setOpenCloseConfirm(true);
  const closeCloseConfirm = () => setOpenCloseConfirm(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!form.form.message || !case_id) return;

    setErrors([]);
    setSaving(true);

    try {
      const message = await controller.httpCreateCaseMessage(
        case_id,
        form.form.message,
        form.form.files,
        form.form.to
      );

      console.log("[message]", message);

      if (message) {
        setMessages((prev) => [...prev, message]);
      }
    } catch (error) {}

    setSaving(false);
    form.reset();
  };

  const shouldAllowReply = useMemo(() => {
    const messageUser = users.filter((e) => e.user == user?.user_id)[0] || null;

    return (
      messageUser &&
      !Boolean(selectedCase?.closed) &&
      (Boolean(selectedMessageType?.user_reply) ||
        Boolean(messageUser.supporter))
    );
  }, [
    user?.user_id,
    users,
    selectedMessageType?.user_reply,
    selectedCase?.closed,
  ]);

  useEffect(() => {
    return cleanUp;
  }, []);

  useEffect(() => {
    if (bugTypes.length === 0) {
      controller.httpGetBugTypes();
    }
  }, [bugTypes.length]);

  useEffect(() => {
    const setupCase = async () => {
      let messageCase = messageCases.filter((e) => e.id === +case_id)[0];

      if (!messageCase) {
        messageCase = await controller.httpGetCase(case_id);
      }

      setSelectedCase(messageCase || null);
    };
    if (case_id) {
      setupCase();
    }
  }, [case_id, messageCases]);

  useEffect(() => {
    if (selectedCase?.id) {
      controller.httpGetCaseMessages(selectedCase.id).then(setMessages);
      controller.httpGetCaseUsers(selectedCase.id).then(setUsers);
    }
  }, [selectedCase?.id]);

  const onMessageViewed = (message_id) => {
    setMessages((prev) => prev.map((message) => {
      if (message.message_id == message_id) {
        let readBy = message.read_by;

        if (!readBy) readBy = `${user.user_id}`;
        else {
          readBy += `,${user.user_id}`;
        }

        return { ...message, read_by: readBy }
      }

      return message;
    }))
  }

  return (
    <>
      <ConfirmClose
        show={openCloseConfirm}
        handleClose={closeCloseConfirm}
        caseId={case_id}
        closeCase={controller.httpCloseCase}
      />

      <AddUser
        show={openAddUser}
        handleClose={(result) => {
          setOpenAddUser(false);
          if (result?.join_id) {
            setUsers((prev) => [result, ...prev]);
          }
        }}
        addUser={controller.httpAddCaseUser}
        caseId={case_id}
      />

      <div id="main-container">
        {!message && (
          <>
            <form className="container" onSubmit={handleSubmit}>
              <div className={`${styles.arrow} mb-4`} onClick={goBack}>
                <img src="/assets/images/ArrowLeft.png" alt="" />
                <span>Go Back</span>
              </div>

              <h2 className="mb-4">Case</h2>

              {!selectedCase && <LoadIndicator size="md" />}

              {selectedCase && (
                <div className="row">
                  <div className={`${styles.caseDetails} mb-4 col-12 col-xl-8`}>
                    <div className={`${styles.group}`}>
                      <div>
                        <label>Case</label>
                      </div>

                      <p>{selectedCase.id}</p>
                    </div>

                    <div className={`${styles.group}`}>
                      <div>
                        <label>Type</label>
                      </div>

                      <p>{selectedMessageType.name}</p>
                    </div>

                    <div className={`${styles.group}`}>
                      <div>
                        <label>Summary</label>
                      </div>

                      {editSummary ? (
                        <input
                          type="text"
                          className="form-control"
                          value={summary}
                          onChange={(e) => setSummary(e.target.value)}
                        />
                      ) : (
                        <p>{selectedCase.title}</p>
                      )}

                      <span>
                        <button
                          type="button"
                          className="btn btn-yellow editButton"
                          onClick={handleEditSummaryClick}
                        >
                          {savingSmmary ? (
                            <div className="mx-2">
                              <LoadIndicator />
                            </div>
                          ) : (
                            <i className="bi bi-pencil mx-2"></i>
                          )}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {selectedCase && messages.length > 0 && (
                <div className="table-responsive my-4">
                  <table className="table table-responsive table-bordered table-dark-theme">
                    <thead>
                      <tr>
                        <th>From</th>
                        <th colSpan={3}>Message</th>
                        <th>Sent</th>
                      </tr>
                    </thead>

                    <tbody>
                      {messages.map((msg) => (
                          <CaseMessage
                            key={`case-messages-${selectedCase.id}-${msg.message_id}`}
                            message={msg}
                            getMessageFrom={getMessageFrom}
                            caseId={selectedCase.id}
                            onMessageViewed={onMessageViewed}
                          />
                      ))}

                      {shouldAllowReply && (
                        <tr>
                          <td>
                            {getNewMessageFrom()}{" "}
                            <select
                              name="to"
                              id="to"
                              className="form-select mt-2"
                              value={form.form.to}
                              onChange={(e) =>
                                form.onChange("to", e.target.value)
                              }
                              required
                            >
                              <option value="" selected disabled>
                                Select user
                              </option>
                              {users.map((user) => {
                                if (user.supporter) return null;

                                return (
                                  <option
                                    key={`to-user-${user.user || user.user_id}`}
                                    value={user.user || user.user_id}
                                  >
                                    {user.user || user.user_id}
                                  </option>
                                );
                              })}
                            </select>
                          </td>

                          <td colSpan={3}>
                            <textarea
                              name="message"
                              id="message"
                              className="form-control"
                              rows={2}
                              value={form.form.message}
                              onChange={(e) =>
                                form.onChange("message", e.target.value)
                              }
                              required
                            />

                            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap my-3">
                              <AttachFiles
                                value={form.form.files}
                                onChange={(files) =>
                                  form.onChange("files", files)
                                }
                              />

                              <button
                                className="btn btn-yellow"
                                disabled={
                                  !form.form.message || saving || !form.form.to
                                }
                                type="submit"
                              >
                                <i className="bi bi-send"></i>{" "}
                                {saving ? <LoadIndicator /> : "Send"}
                              </button>
                            </div>
                          </td>

                          <td></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </form>

            <div className="d-flex flex-row align-items-center justify-content-center">
              {selectedCase && !Boolean(selectedCase.closed) && (
                <button
                  className="btn btn-black me-3"
                  type="button"
                  onClick={handleCloseCase}
                >
                  Close
                </button>
              )}

              <button
                className="btn btn-yellow"
                onClick={() => setOpenAddUser(true)}
              >
                Add User
              </button>
            </div>
          </>
        )}

        {message && (
          <div className="container">
            <SingleMessageView
              messageId={Number(message)}
              handleClose={() => navigate(-1)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MessageCase;
