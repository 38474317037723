import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialized: false,
  applications: [],
};

export const softwareApplicationManagement = createSlice({
  name: "software_application_management",
  initialState,
  reducers: {
    initializeApplicationList: (state, action) => {
      state.applications = action.payload;
      state.initialized = true;
    },

    updateApplication: (state, action) => {
      state.applications = state.applications.map((item) => {
        if (item.sw_application_id === action.payload.sw_application_id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
    },

    addApplication: (state, action) => {
      state.applications = [...state.applications, action.payload];
    },

    removeApplication: (state, action) => {
      state.applications = state.applications.filter(
        (item) => item.sw_application_id !== action.payload.sw_application_id
      );
    },
  },
});

export const {
  initializeApplicationList,
  addApplication,
  updateApplication,
  removeApplication,
} = softwareApplicationManagement.actions;

export const selectSoftwareApplicationManagementData = (state) => ({
  ...state.software_application_management,
});

export default softwareApplicationManagement.reducer;
