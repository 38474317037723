import React, { useState } from "react";
import styles from "./SoftwareApplicationRecord.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { formatDDMMYYYYDash, useForm, useShortDisplay } from "shared/helpers";
import { useDispatch, useSelector } from "react-redux";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import SWApplicationService from "services/SWApplicationService";
import {
  addApplication,
  removeApplication,
  updateApplication,
} from "redux-store/features/softwareApplicationManagement";
import ErrorsComponent from "views/components/Errors";
import BrandService from "services/BrandService";
import Autocomplete from "views/components/Autocomplete";
import LockTimer from "views/components/LockTimer";
import WarningModal from "views/components/WarningModal";
import CategoryService from "services/CategoryService";

function SoftwareApplicationRecord() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const { state } = useLocation();
  const user = useSelector(selectUser);

  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const swApplicationService = new SWApplicationService(dispatch, token);

  const [brands, setBrands] = useState([]);
  const [brandInput, setBrandInput] = useState("");
  useEffect(() => {
    if (brandInput.toLowerCase() === "null") {
      setBrandInput("");
    }
  }, [brandInput]);

  const getBrands = async () => {
    try {
      const res = await BrandService.fetchAll(token, dispatch);

      setBrands(res.data.data);
    } catch (e) {}
  };

  const [application, setApplication] = useState(null);

  const navigateToSVM = () =>
    navigate("/database-records-management/svm", {
      state: { application: form },
    });

  const {
    form,
    onChange,
    handleServerError,
    validate,
    err: errors,
    set_err,
  } = useForm({
    application: "",
    end_category: null,
    name: "",
    brand: null,
  });

  useShortDisplay(() => set_err([]), 5000, errors.length > 0);

  const [editBtn, setEditBtn] = useState(false);

  const isChanged = () =>
    application &&
    (application.name !== form.name ||
      application.application !== form.application ||
      application.end_category !== form.end_category ||
      application.brand !== form.brand);

  const [showWaringModal, setShowWarningModal] = useState(false);

  const editHandler = () => {
    if (!editBtn) {
      setEditBtn(true);
    } else {
      if (isChanged()) {
        setShowWarningModal(true);
      } else {
        setEditBtn(false);
      }
    }
  };

  const createApplication = async () => {
    try {
      const res = await swApplicationService.create(form);

      dispatch(addApplication(res.data.data));

      if (stateFor === "new") {
        window.close();
      } else {
        navigate(-1);
      }
    } catch (error) {
      handleServerError(error);
    }
  };

  const updateSWApplication = async () => {
    try {
      const res = await swApplicationService.update(form);

      const application = res.data.data;

      dispatch(updateApplication(application));

      setEditBtn(false);

      setApplication(null);
      Object.keys(application).forEach((key) =>
        onChange(key, application[key])
      );
    } catch (error) {
      handleServerError(error);
    }
  };

  const [saving, setSaving] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate(["application", "name"])) return;

    setSaving(true);
    if (state?.for === "new" || stateFor === "new") {
      await createApplication();
    } else {
      if (editBtn) updateSWApplication();
    }

    setSaving(false);
  };

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (!form.sw_application_id) return;
    setDeleting(true);
    try {
      await swApplicationService.delete(form.sw_application_id);

      dispatch(removeApplication(form));

      navigate(-1);
    } catch (error) {
      console.log("Error", error);
      handleServerError(error);
    }
    setDeleting(false);
  };

  const unlockApplication = async () => {
    try {
      await swApplicationService.unlock();
    } catch (error) {}
  };

  const [categories, setCategories] = useState([]);
  const [categoryInput, setCategoryInput] = useState("");

  useEffect(() => {
    if (categoryInput.toLowerCase() === "null") {
      setCategoryInput("");
    }
  }, [categoryInput]);

  const getCategories = async () => {
    try {
      const res = await CategoryService.getEndCategories(dispatch, token);

      setCategories(res.data.data);
    } catch (error) {}
  };

  const [stateFor, setStateFor] = useState("");
  useEffect(() => {
    getBrands();
    getCategories();

    const search = new URLSearchParams(window.location.search);
    const ref = search.get("ref");

    setStateFor(ref || "");
    setEditBtn(ref === "new");

    return () => {
      unlockApplication();
    };
  }, []);

  const viewApplication = async (id) => {
    try {
      const res = await swApplicationService.view(id);

      setApplication(res.data.data);

      Object.keys(res.data.data).forEach((key) =>
        onChange(key, res.data.data[key])
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (editBtn && form.sw_application_id) {
      viewApplication(form.sw_application_id);
    } else {
      onChange("locked_by", null);
      onChange("locked_on", null);

      unlockApplication();
    }
  }, [editBtn]);

  useEffect(() => {
    if (state) {
      setEditBtn(state.for === "new");

      if (state.item) {
        Object.keys(state.item).forEach((key) =>
          onChange(key, state.item[key])
        );
      }
    }
  }, [state]);

  return (
    <>
      <WarningModal
        show={showWaringModal}
        text="Changes have not been saved, exit edit mode?"
        handleOk={() => {
          setEditBtn(false);

          if (application) {
            onChange("brand", application.brand);
            onChange("end_category", application.end_category);
            onChange("name", application.name);
            onChange("application", application.application);
          }

          setShowWarningModal(false);
        }}
        handleClose={() => setShowWarningModal(false)}
      />

      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center userLevel-Admin">
            <div className="col-sm-6">
              <div className="infomore">
                User Admin level: {user && user.admin && user.admin.permission}
              </div>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <div className={`${styles.arrow} mb-4`} onClick={goBack}>
            <img src="/assets/Archive/ArrowLeft.png" alt="" />
            <span>Go Back</span>
          </div>

          <div className={`${styles.heading} mb-4`}>
            <h2>Software Application Record</h2>

            {state?.for === "existing" && (
              <p onClick={navigateToSVM} className={styles.uses}>
                {/* Version Records: {form.uses || 0} */}
                Manage SW Version
              </p>
            )}

            {state?.for === "existing" ? (
              <div className={styles.alNum}>
                <LockTimer data={form} onComplete={() => navigate(-1)} />

                <div className={styles.topBtn}>
                  <button
                    onClick={editHandler}
                    disabled={form.locked_by && form.locked_by !== user.user_id}
                  >
                    {editBtn ? "Close" : "Edit"}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          {/*  */}

          {/* a row withoiut button*/}
          <form onSubmit={handleSubmit}>
            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Application ID</span>
              </div>

              <div className={styles.rowValue}>
                <span>{form.sw_application_id || "Null"}</span>
              </div>
            </div>

            {/** NAME */}
            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Name</span>
              </div>

              <div className={styles.rowValue}>
                {!editBtn ? (
                  <>{form.name || "Null"}</>
                ) : (
                  <input
                    className="form-control"
                    maxLength={30}
                    value={form.name}
                    onChange={(e) => onChange("name", e.target.value)}
                    required
                  />
                )}
              </div>
            </div>

            {/** APPLICATION */}
            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Application</span>
              </div>

              <div className={styles.rowValue}>
                {!editBtn ? (
                  <>{form.application || "Null"}</>
                ) : (
                  <input
                    className="form-control"
                    maxLength={100}
                    value={form.application}
                    onChange={(e) => onChange("application", e.target.value)}
                    required
                  />
                )}
              </div>
            </div>

            {/** CATEGORY */}
            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>End Category</span>
              </div>

              <div className={styles.rowValue}>
                {!editBtn ? (
                  <>{form.end_category_name || "Null"}</>
                ) : (
                  <Autocomplete
                    className={styles.customAutoComplete}
                    first={[
                      { category_id: "", name: "Null", verified_date: true },
                    ]}
                    suggestions={categories}
                    placeholder="Select a category"
                    input={categoryInput}
                    initialValue={form.end_category || ""}
                    setInput={setCategoryInput}
                    labelKey="name"
                    primaryKey={"category_id"}
                    setValue={(category) =>
                      onChange("end_category", category.category_id || null)
                    }
                    required={false}
                  />
                )}
              </div>
            </div>

            {/** BRAND */}
            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>HW Brand</span>
              </div>

              <div className={styles.rowValue}>
                {!editBtn ? (
                  <>{form.brand_name || "Null"}</>
                ) : (
                  <Autocomplete
                    className={styles.customAutoComplete}
                    first={[{ brand_id: "", brand: "Null" }]}
                    suggestions={brands}
                    placeholder="Select a brand"
                    input={brandInput}
                    initialValue={form.brand || ""}
                    setInput={setBrandInput}
                    labelKey="brand"
                    primaryKey={"brand_id"}
                    setValue={(brand) =>
                      onChange("brand", brand.brand_id || null)
                    }
                    required={false}
                  />
                )}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Owner </span>
              </div>

              <div className={styles.rowValue}>
                <span>{form.created_by_name || "Null"} </span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.rowLabel}>
                <span>Created Date</span>
              </div>

              <div className={styles.rowValue}>
                <span>
                  {form.created_at
                    ? formatDDMMYYYYDash(form.created_at)
                    : "Null"}
                </span>
              </div>
            </div>

            {/* a row with button*/}

            <div className={`${styles.btnContainer} mt-4`}>
              <button
                className={styles.delete}
                disabled={!form.can_delete || deleting || saving}
                onClick={handleDelete}
                type="button"
              >
                Delete
              </button>

              <div className={styles.rightBtn}>
                <button disabled={saving || deleting || !editBtn}>
                  Submit
                </button>
              </div>
            </div>
          </form>

          {errors.length > 0 && <ErrorsComponent errors={errors} />}
        </div>
      </div>
    </>
  );
}

export default SoftwareApplicationRecord;
