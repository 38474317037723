import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import SerialInstruction from "../serial-instructions/SerialInstruction";

function SelectSerialInstruction({ contextData, handleClose, onSelect }) {
  const data = useMemo(
    () => (contextData ? { ...contextData, handleClose, onSelect } : null),
    [contextData, handleClose, onSelect]
  );

  return (
    <Modal
      show={Boolean(contextData)}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      fullscreen
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ borderBottom: "none", backgroundColor: "black" }}
      ></Modal.Header>

      <Modal.Body style={{ backgroundColor: "black" }}>
        <SerialInstruction contextData={data} />
      </Modal.Body>
    </Modal>
  );
}

export default SelectSerialInstruction;
