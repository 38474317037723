import React from "react";
import { formatDDMMYYYYDash } from "shared/helpers";
import "./styles.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function FormTable({ data, columns, form, onChange, itemKey }) {
  return (
    <div className="qualy-form-table table-responsive">
      {data && data.length > 0 && (
        <table className="table table-responsive table-dark-theme">
          <thead>
            <tr>
              {columns.map((col, index) => {
                return (
                  <th scope="col" key={"col-p-" + index}>
                    {col.text}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={"data-row-p-" + index}>
                  {columns.map((col, indx) => {
                    if (col.type && col.type === "date")
                      return (
                        <td key={"data-p-" + indx}>
                          {formatDDMMYYYYDash(item[col.key])}
                        </td>
                      );

                    if (col.editable)
                      return (
                        <td key={"data-p-" + indx}>
                          <OverlayTrigger
                            key={"data-p-" + indx}
                            placement="top"
                            overlay={
                              <Tooltip id={"tooltip-" + "data-p-" + indx}>
                                {form[item[itemKey] + "__" + col.key] || ""}
                              </Tooltip>
                            }
                          >
                            <input
                              type="text"
                              required={col.required}
                              className="form-control"
                              value={form[item[itemKey] + "__" + col.key] || ""}
                              onChange={onChange}
                              name={item[itemKey] + "__" + col.key}
                            />
                          </OverlayTrigger>
                        </td>
                      );

                    return (
                      <td key={"data-p-" + indx}>{item[col.key] || "-"}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {data.length === 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 18,
            fontWeight: "600",
          }}
        >
          No record found
        </div>
      )}
    </div>
  );
}

export default FormTable;
