import React from "react";
import { Modal } from "react-bootstrap";
import CompanyManagement from "../company-management";

function NewCompany({ show, handleClose, closeModal }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      fullscreen
      centered
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", backgroundColor: "black" }}
      ></Modal.Header>

      <Modal.Body style={{ backgroundColor: "black", padding: 0 }}>
        <CompanyManagement closeModal={closeModal} />
      </Modal.Body>
    </Modal>
  );
}

export default NewCompany;
