import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import {
  selectBrandManagementData,
  setSelectedBrand,
  switchMode,
} from "redux-store/features/brandManagementSlice";
import BrandService from "services/BrandService";
import CompanyService from "services/CompanyService";
import Autocomplete from "views/components/Autocomplete";
import DescModal from "views/components/DescModal";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";
import EditBrand from "./edit";
import { handleServerError, modes } from "./helpers";
import ViewBrand from "./view";

function BrandManagement() {
  const navigate = useNavigate();
  const goBack = () => {
    if (mode != modes.view) {
      dispatch(switchMode(modes.view));
    } else {
      navigate(-1);
    }
  };

  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const { selectedBrand, mode } = useSelector(selectBrandManagementData);

  const [search, setSearch] = useState("");
  const [brands, setBrands] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [brandModels, setBrandModels] = useState([]);

  const [fetchingBrands, setFetchingBrands] = useState(false);
  const fetchBrands = async () => {
    setFetchingBrands(true);
    try {
      const res = await BrandService.fetchAll(token, dispatch);

      setBrands(res.data.data);
    } catch (error) {}
    setFetchingBrands(false);
  };

  const fetchCompanies = async () => {
    try {
      const res = await CompanyService.getAvailableCompanies(token, dispatch);

      setCompanies(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchBrands();
    fetchCompanies();

    return () => {
      dispatch(setSelectedBrand(null));
    };
  }, []);

  const fetchBrandModels = async () => {
    try {
      const res = await BrandService.getBrandModelRecordsByBrand(
        token,
        dispatch,
        selectedBrand.brand_id
      );

      setBrandModels(res.data.data);
    } catch (e) {}
  };

  useEffect(() => {
    if (selectedBrand) {
      // get brand models using this brand
      fetchBrandModels();
    }
  }, [selectedBrand?.brand_id]);

  const [open_brand_models, set_open_brand_modelss] = useState(false);

  const closeBrandModels = () => {
    set_open_brand_modelss(false);
  };

  const openBrandModels = () => {
    set_open_brand_modelss(true);
  };

  const setupEditMode = () => {
    dispatch(switchMode(modes.edit));
  };

  const setupNewMode = () => {
    dispatch(switchMode(modes.new));
  };

  const [errors, setErrors] = useState([]);

  const [loading, setLoading] = useState(false);
  const updateBrand = async () => {
    setErrors([]);
    setLoading(true);
    try {
      const data = { ...selectedBrand };

      if (data.brand_owner == "none") data.brand_owner = null;

      const res = await BrandService.updateBrand(token, dispatch, data);

      dispatch(setSelectedBrand(res.data.data));
      dispatch(switchMode(modes.view));
      fetchBrands();
    } catch (error) {
      handleServerError(error, setErrors);
    }
    setLoading(false);
  };

  const createBrand = async () => {
    setErrors([]);
    setLoading(true);
    try {
      const data = { ...selectedBrand };

      if (data.brand_owner == "none") data.brand_owner = null;
      data.company = data.brand_owner;

      const res = await BrandService.addBrandToDb(token, dispatch, data);

      dispatch(setSelectedBrand(res.data.data));
      dispatch(switchMode(modes.view));

      setBrands((prev) => [...prev, res.data.data]);
    } catch (error) {
      handleServerError(error, setErrors);
    }
    setLoading(false);
  };

  const [deleting, setDeleting] = useState(false);

  const deleteBrand = async () => {
    setErrors([]);
    setLoading(true);
    setDeleting(true);
    try {
      const res = await BrandService.deleteBrand(
        token,
        dispatch,
        selectedBrand.brand_id
      );

      dispatch(setSelectedBrand(null));
      dispatch(switchMode(modes.view));
      fetchBrands();
    } catch (error) {
      handleServerError(error, setErrors);
    }
    setLoading(false);
    setDeleting(false);
  };

  const onSubmit = (e) => {
    e?.preventDefault();

    switch (mode) {
      case modes.edit:
        updateBrand();
        break;

      case modes.new:
        createBrand();
        break;

      default:
        updateBrand();
        break;
    }
  };

  return (
    <>
      <DescModal
        show={open_brand_models}
        handleClose={closeBrandModels}
        children={
          <div>
            <h5>Brand Models</h5>

            <ul>
              {brandModels.map((item) => (
                <li key={"brand-models-available-" + item.brand_model_id}>
                  <p>
                    {item.model_name_des} {item.model_code}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        }
      />

      <div id="main-container">
        {fetchingBrands ? (
          <div className="container">
            <LoadIndicator size="lg" />
          </div>
        ) : (
          <div className="container">
            <div className="row mb-4 justify-content-center">
              <div className="col-sm-6">
                <div className="infomore">
                  User Admin level:{" "}
                  {user && user.admin && user.admin.permission}
                </div>
              </div>
              <div className="col-sm-6"></div>
            </div>

            <h2 className="mb-4">Professional Brand Management</h2>

            <div className="infoContentCenter card mb-4">
              <div className="row">
                {/** */}
                <div className="col-xl-8">
                  {mode != modes.new && (
                    <>
                      {/** BRAND SEARCH */}
                      <div className="row mb-4">
                        <div className="col-md-3">
                          <label className="form-label">Select Brand:</label>
                        </div>

                        <div className="col-md-9">
                          <Autocomplete
                            input={search}
                            setInput={setSearch}
                            suggestions={brands}
                            placeholder="Samsung"
                            primaryKey="brand_id"
                            labelKey="brand"
                            setValue={(brand) =>
                              dispatch(setSelectedBrand(brand))
                            }
                          />
                        </div>
                      </div>

                      {/** USES AND BRAND MODEL LIST */}
                      <div className="row mb-4">
                        <div className="col-md-3">
                          <label className="form-label">Uses:</label>
                        </div>

                        <div className="col-md-9">
                          <div className="row align-items-center">
                            <p>
                              {brandModels.length}{" "}
                              <a onClick={openBrandModels} className="infolink">
                                See details
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="mb-4">
                    <ErrorsComponent errors={errors} />
                  </div>

                  {mode === modes.view && (
                    <ViewBrand
                      selectedBrand={selectedBrand}
                      companies={companies}
                    />
                  )}

                  {(mode === modes.edit || mode === modes.new) && (
                    <EditBrand
                      selectedBrand={selectedBrand}
                      onSubmit={onSubmit}
                      companies={companies}
                    />
                  )}

                  <div className="btn-group-child mb-5">
                    <button
                      type="button"
                      className="btn btn-yellow me-3"
                      disabled={mode != modes.view || loading}
                      onClick={setupNewMode}
                    >
                      <i className="bi bi-plus-circle"></i> New Brand
                    </button>

                    <button
                      type="button"
                      className="btn btn-yellow me-3"
                      disabled={
                        mode === modes.edit ||
                        mode === modes.new ||
                        !selectedBrand ||
                        loading
                      }
                      onClick={setupEditMode}
                    >
                      <i className="bi bi-pencil"></i> Edit
                    </button>

                    <button
                      type="button"
                      className="btn btn-yellow me-3"
                      disabled={
                        mode != modes.view ||
                        !selectedBrand ||
                        brandModels.length > 0 ||
                        loading
                      }
                      onClick={deleteBrand}
                    >
                      <i className="bi bi-trash"></i>
                      {deleting ? <LoadIndicator /> : "Delete"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center">
              <button className="btn btn-black me-2" onClick={goBack}>
                Cancel
              </button>

              <button
                form="brand-edit-form"
                type="submits"
                className="btn btn-yellow"
                disabled={loading}
              >
                {loading ? <LoadIndicator /> : "Submit"}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BrandManagement;
