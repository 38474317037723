import { createSlice } from "@reduxjs/toolkit";
import { formatYYYYMMDD } from "shared/helpers";
import { modes } from "views/database-records-management/company-management/helpers";

const initialState = {
  mode: modes.view,
  selectedCompany: null,
  fromBrandModel: false,
};

export const companyManagement = createSlice({
  name: "company_management",
  initialState,
  reducers: {
    switchMode: (state, action) => {
      state.mode = action.payload;

      if (action.payload === modes.new) {
        state.selectedCompany = {
          company_id: null,
          company_name: "",
          country: "United Kingdom",
          address_line1: "",
          town: "",
          region: "",
          postal_ref: "",
          type: "Limited",
          public_location: 1,
          status: "Actively Trading",
          status_changed: "",
          contact_number: "",
          contact_email: "",
          website: "",
          start_date: formatYYYYMMDD(new Date().toISOString()),
          company_rep: null,
          created_by: null,
          verification_status: null,
          professional_id: null,
          professional_sales: null,
          verified_date: null,
        };
      }
    },

    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },

    updateSelectedCompany: (state, action) => {
      state.selectedCompany = {
        ...state.selectedCompany,
        [action.payload.key]: action.payload.value,
      };
    },

    fromBrandModel: (state, action) => {
      state.fromBrandModel = action.payload;
    },
  },
});

export const {
  setSelectedCompany,
  switchMode,
  fromBrandModel,
  updateSelectedCompany,
} = companyManagement.actions;

export const selectCompanyManagementData = (state) => ({
  ...state.company_management,
});

export default companyManagement.reducer;
