import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "redux-store/features/authSlice";
import DatabaseGlobalVariablesService from "services/DatatbaseGlobalVariablesService";
import { useForm, useShortDisplay } from "shared/helpers";
import ErrorsComponent from "views/components/Errors";
import LoadIndicator from "views/components/LoadIndicator";

const editableKeys = [
  "invite_limit",
  "invite_only",
  "invite_incentive",
  "sms_use_t",
  "report_comm_fixed",
  "report_comm_vari",
  "user_delete_delay",
  "user_response_limit",
  "personal_doc_delete_delay",
  "symptom_delay",
  "qlist_delete_period",
  "uap",
  "qap",
  "out_of_use_delay",
];

const GlobalVariables = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const service = new DatabaseGlobalVariablesService(token, dispatch);

  const goBack = () => navigate(-1);

  const [loading, setLoading] = useState(false);

  const { form, onChange, err, set_err, handleServerError } = useForm({
    invite_limit: "",
    invite_only: "",
    invite_incentive: "",
    sms_use_t: "",
    report_comm_fixed: "",
    report_comm_vari: "",
    user_delete_t: "",
    user_delete_delay: "",
    user_response_limit: "",
    personal_doc_delete_delay: "",
    symptom_delay: "",
    qlist_delete_period: "",
    uap: "",
    qap: "",
    out_of_use_delay: "",
  });

  useShortDisplay(() => set_err([]), 5000, err.length);

  const setUpForm = (data) => {
    if (data) {
      for (const key of Object.keys(data)) {
        if (editableKeys.includes(key)) {
          onChange(key, data[key]);
        }
      }
    }
  };

  const getDatabaseGlobalVariables = async () => {
    try {
      const res = await service.getDatabaseGlobalVariables();

      setUpForm(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getDatabaseGlobalVariables();
  }, []);

  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const payload = {};

      for (const key in form) {
        payload[key] = Number(form[key]);
      }

      const res = await service.updateDatabaseGlobalVariables(payload);

      setSuccess(res.data.message);

      setUpForm(res.data.data);
    } catch (error) {
      handleServerError(error);
    }
    setLoading(false);
  };

  return (
    <>
      <div id="main-container">
        <div className="container">
          <div className="row mb-4 justify-content-center">
            <div className="col-sm-6">
              <div className="infmore">
                User Admin Level: {user && user.admin && user.admin.permission}
              </div>
            </div>

            <div className="col-sm-6"></div>
          </div>

          <h2 className="mb-4">Database Global Variables</h2>

          <div className="infoContentCenter card mb-4">
            {/** */}
            <div className="row">
              <div className="col-lg-10 col-sm-12">
                {/**  */}
                <form id="globalVariablesForm" onSubmit={handleSubmit}>
                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        Submitting user invitation limit
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control me-3"
                        placeholder="10"
                        value={form.invite_limit}
                        onChange={(e) =>
                          onChange("invite_limit", e.target.valueAsNumber)
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        Restrict submitting user invite only
                      </label>
                    </div>

                    <div className="col-md-9">
                      <select
                        className="form-select"
                        value={form.invite_only}
                        onChange={(e) =>
                          onChange("invite_only", Number(e.target.value))
                        }
                        required
                      >
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </select>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        Submitting User Invite incentive (Cr)
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="3"
                        value={form.invite_incentive}
                        onChange={(e) =>
                          onChange("invite_incentive", e.target.valueAsNumber)
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        SMS Limit interval (Hours)
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control me-3"
                        placeholder="24"
                        value={form.sms_use_t}
                        onChange={(e) =>
                          onChange("sms_use_t", e.target.valueAsNumber)
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        Fixed report commission (C)
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control me-3"
                        placeholder="0.05"
                        value={form.report_comm_fixed}
                        onChange={(e) =>
                          onChange("report_comm_fixed", e.target.valueAsNumber)
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        Variable report commission (%)
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control me-3"
                        placeholder="10"
                        value={form.report_comm_vari}
                        onChange={(e) =>
                          onChange("report_comm_vari", e.target.valueAsNumber)
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        User Delete delay (Weeks)
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control me-3"
                        placeholder="60"
                        value={form.user_delete_delay}
                        onChange={(e) =>
                          onChange("user_delete_delay", e.target.valueAsNumber)
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        User response limit (Days)
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control me-3"
                        placeholder="60"
                        value={form.user_response_limit}
                        onChange={(e) =>
                          onChange(
                            "user_response_limit",
                            e.target.valueAsNumber
                          )
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        Personal document removal (Days)
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control me-3"
                        placeholder="60"
                        value={form.personal_doc_delete_delay}
                        onChange={(e) =>
                          onChange(
                            "personal_doc_delete_delay",
                            e.target.valueAsNumber
                          )
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        Symptom seen after use limit (days)
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control me-3"
                        placeholder="60"
                        value={form.symptom_delay}
                        onChange={(e) =>
                          onChange("symptom_delay", e.target.valueAsNumber)
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        Qlist delete delay (weeks)
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control me-3"
                        placeholder="52"
                        value={form.qlist_delete_period}
                        onChange={(e) =>
                          onChange(
                            "qlist_delete_period",
                            e.target.valueAsNumber
                          )
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        Unit assist portion (UAP)
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control me-3"
                        placeholder="0.1"
                        step={"0.1"}
                        max={1}
                        value={form.uap}
                        onChange={(e) =>
                          onChange("uap", e.target.valueAsNumber)
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        Quality Assist Portion (QAP)
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control me-3"
                        placeholder="0.5"
                        value={form.qap}
                        onChange={(e) =>
                          onChange("qap", e.target.valueAsNumber)
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <label className="form-label">
                        Out of use delay (weeks)
                      </label>
                    </div>

                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control me-3"
                        placeholder="4"
                        value={form.out_of_use_delay}
                        max={255}
                        onChange={(e) =>
                          onChange("out_of_use_delay", e.target.valueAsNumber)
                        }
                        required
                      />
                    </div>
                  </div>
                </form>
                {/** */}
              </div>
            </div>
          </div>

          <ErrorsComponent errors={err} />

          {success && (
            <ErrorsComponent variant="alert-success" errors={[success]} />
          )}

          <div className="mt-4 text-center">
            <button
              type="submit"
              disabled={loading}
              form="globalVariablesForm"
              className="btn btn-yellow me-2"
            >
              {loading ? <LoadIndicator /> : "Update"}
            </button>

            <button
              disabled={loading}
              type="button"
              className="btn btn-black"
              onClick={goBack}
            >
              Go back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalVariables;
