import { useMessageTypeManagement } from "hooks/useMessageTypeManagement";
import "./styles.css";
import { useSelector } from "react-redux";
import { selectUser } from "redux-store/features/authSlice";
import AutocompleteV2 from "views/components/AutocompleteV2";
import { selectMessageTypes } from "redux-store/features/messageTypeManagementSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { modes } from "./helper";
import { formatDDMMYYYYDash, handleError, useForm } from "shared/helpers";
import LoadIndicator from "views/components/LoadIndicator";
import ErrorsComponent from "views/components/Errors";

const MessageTypeManagement = () => {
  const controller = useMessageTypeManagement();

  const user = useSelector(selectUser);
  const messageTypes = useSelector(selectMessageTypes);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [selectedTypeId, setSelectedTypeId] = useState(null);
  const [mode, setMode] = useState(modes.view);
  const form = useForm();

  const handleChange = (e) => form.onChange(e.target.name, e.target.value);

  const cleanUp = () => {
    setSelectedTypeId(null);
    setMode(modes.view);
    setLoading(false);
    setErrors([]);
    form.reset();
  };

  useEffect(() => {
    if (messageTypes?.length === 0) {
      controller.httpGetMessageTypes();
    }
  }, [messageTypes?.length]);

  useEffect(() => {
    return cleanUp;
  }, []);

  const selectedType = useMemo(() => {
    if (selectedTypeId) {
      return (
        messageTypes.filter((e) => e.type_id === selectedTypeId)[0] || null
      );
    }
    return null;
  }, [selectedTypeId, messageTypes]);

  const getYesOrNo = useCallback((value) => {
    if (isNaN(Number(value))) return "Null";

    return Boolean(Number(value)) ? "Yes" : "No";
  }, []);

  const goToEdit = () => {
    if (!selectedType) return;
    Object.keys(selectedType).forEach((key) =>
      form.onChange(key, selectedType[key])
    );
    setMode("edit");
  };

  const goToCreate = () => {
    form.reset();
    setSelectedTypeId(null);
    setMode(modes.create);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;

    setErrors([]);
    setLoading(true);

    try {
      if (mode === modes.edit) {
        await controller.httpUpdateMessageType(form.form);
      } else {
        const type_id = await controller.httpCreateMessageType(form.form);
        setSelectedTypeId(type_id || null);
      }
      setMode(modes.view);
      form.reset();
    } catch (error) {
      handleError(error, setErrors);
    }

    setLoading(false);
  };

  return (
    <>
      <div id="main-container" className="qualy_message_type">
        <form className="container" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-lg-9">
              <div className="row mb-4 justify-content-center">
                <div className="col-sm-6">
                  <div className="infomore">
                    User Admin level:{" "}
                    {user && user.admin && user.admin.permission}
                  </div>
                </div>
                <div className="col-sm-6"></div>
              </div>

              <h2 className="mb-4">Message Type Management</h2>

              {/** Select Record */}
              <div className="row mb-4">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>Select Record:</span>
                  </div>
                </div>

                <div className={`col`}>
                  <AutocompleteV2
                    suggestions={messageTypes}
                    labels={["name"]}
                    primaryKey="type_id"
                    value={selectedTypeId || ""}
                    setValue={(type) => setSelectedTypeId(type.type_id)}
                    placeholder={"Select Record"}
                    sortKey={"name"}
                    disabled={mode !== "view"}
                  />
                </div>
              </div>

              {/** TYPE ID */}
              <div className="row mb-2">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>Type ID</span>
                  </div>
                </div>

                <div className={`col-auto qualy_message_type__topLabelVal`}>
                  {selectedType?.type_id || "Null"}
                </div>
              </div>

              {/** TYPE NAME */}
              <div className="row mb-2">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>Type Name</span>
                  </div>
                </div>

                <div className={`col qualy_message_type__topLabelVal`}>
                  {mode === "view" && (selectedType?.name || "Null")}
                  {mode !== "view" && (
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={form.form.name || ""}
                      onChange={handleChange}
                      maxLength={20}
                      required
                    />
                  )}
                </div>
              </div>

              {/** DESCRIPTION */}
              <div className="row mb-2">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>Description</span>
                  </div>
                </div>

                <div className={`col qualy_message_type__topLabelVal`}>
                  {mode === "view" && (selectedType?.description || "Null")}
                  {mode !== "view" && (
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      value={form.form.description || ""}
                      onChange={handleChange}
                      minLength={100}
                    />
                  )}
                </div>
              </div>

              {/** GUIDANCE */}
              <div className="row mb-2">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>Guideance</span>
                  </div>
                </div>

                <div className={`col qualy_message_type__topLabelVal`}>
                  {mode === "view" && (selectedType?.guidance || "Null")}
                  {mode !== "view" && (
                    <textarea
                      type="text"
                      className="form-control"
                      name="guidance"
                      value={form.form.guidance || ""}
                      onChange={handleChange}
                      maxLength={200}
                    />
                  )}
                </div>
              </div>

              {/** ALLOCATION */}
              <div className="row mb-2">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>Allocation</span>
                  </div>
                </div>

                <div className={`col qualy_message_type__topLabelVal`}>
                  {mode === modes.view && getYesOrNo(selectedType?.allocation)}
                  {mode !== modes.view && (
                    <select
                      name="allocation"
                      value={form.form.allocation}
                      className="form-select"
                      onChange={handleChange}
                      required
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  )}
                </div>
              </div>

              {/** USER REPLY */}
              <div className="row mb-2">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>User Reply</span>
                  </div>
                </div>

                <div className={`col qualy_message_type__topLabelVal`}>
                  {mode === modes.view && getYesOrNo(selectedType?.user_reply)}
                  {mode !== modes.view && (
                    <select
                      name="user_reply"
                      value={form.form.user_reply}
                      className="form-select"
                      onChange={handleChange}
                      required
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  )}
                </div>
              </div>

              {/** USER INIT */}
              <div className="row mb-2">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>User Initialisation</span>
                  </div>
                </div>

                <div className={`col qualy_message_type__topLabelVal`}>
                  {mode === modes.view && getYesOrNo(selectedType?.user_init)}
                  {mode !== modes.view && (
                    <select
                      name="user_init"
                      value={form.form.user_init}
                      className="form-select"
                      onChange={handleChange}
                      required
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  )}
                </div>
              </div>

              {/** ADMIN INIT */}
              <div className="row mb-2">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>Admin Initialisation</span>
                  </div>
                </div>

                <div className={`col qualy_message_type__topLabelVal`}>
                  {mode === modes.view && getYesOrNo(selectedType?.admin_init)}
                  {mode !== modes.view && (
                    <select
                      name="admin_init"
                      value={form.form.admin_init}
                      className="form-select"
                      onChange={handleChange}
                      required
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  )}
                </div>
              </div>

              {/** PROFESSIONAL INIT */}
              <div className="row mb-2">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>Professional Initialisation</span>
                  </div>
                </div>

                <div className={`col qualy_message_type__topLabelVal`}>
                  {mode === modes.view && getYesOrNo(selectedType?.prof_init)}
                  {mode !== modes.view && (
                    <select
                      name="prof_init"
                      value={form.form.prof_init}
                      className="form-select"
                      onChange={handleChange}
                      required
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  )}
                </div>
              </div>

              {/** REP INIT */}
              <div className="row mb-2">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>Company Rep Initialisation</span>
                  </div>
                </div>

                <div className={`col qualy_message_type__topLabelVal`}>
                  {mode === modes.view && getYesOrNo(selectedType?.rep_init)}
                  {mode !== modes.view && (
                    <select
                      name="rep_init"
                      value={form.form.rep_init}
                      className="form-select"
                      onChange={handleChange}
                      required
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  )}
                </div>
              </div>

              {/** FATE */}
              <div className="row mb-2">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>Fate</span>
                  </div>
                </div>

                <div className={`col qualy_message_type__topLabelVal`}>
                  {mode === modes.view && (selectedType?.fate || "Null")}
                  {mode !== modes.view && (
                    <select
                      name="fate"
                      className="form-select"
                      value={form.form.fate || ""}
                      onChange={handleChange}
                      required
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value={"Current"}>Current</option>
                      <option value={"Obsolete"}>Obsolete</option>
                    </select>
                  )}
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <div className={"qualy_message_type__topLabel"}>
                    <span>Last Updated</span>
                  </div>
                </div>

                <div className={`col-auto qualy_message_type__topLabelVal`}>
                  {selectedType?.updated_at
                    ? formatDDMMYYYYDash(selectedType.updated_at)
                    : "Null"}
                </div>
              </div>
            </div>
          </div>

          {errors.length > 0 && <ErrorsComponent errors={errors} />}

          <div className="d-flex flex-row justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-yellow me-3"
              disabled={mode !== modes.view || loading}
              onClick={goToCreate}
            >
              <i className="bi bi-plus-circle"></i> Create New
            </button>

            <button
              type="button"
              className="btn btn-yellow me-3"
              disabled={mode !== modes.view || loading || !selectedType}
              onClick={goToEdit}
            >
              <i className="bi bi-pencil"></i> Edit
            </button>

            <button
              type="submit"
              className="btn btn-yellow"
              disabled={mode === modes.view}
            >
              {loading ? <LoadIndicator /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default MessageTypeManagement;
