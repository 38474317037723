import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialized: false,
  symptoms: [],
  filter: {
    category: null,
    isCategoryEnd: null,
    aberration: null,
    status: null,
    text: null,
    symptom_id: null,
  },
};

export const symptomManagement = createSlice({
  name: "symptom_management",
  initialState,
  reducers: {
    initializeSymptomList: (state, action) => {
      state.symptoms = action.payload;
      state.initialized = true;
    },

    addSymptom: (state, action) => {
      state.symptoms = [...state.symptoms, action.payload];
    },

    updateSymptom: (state, action) => {
      state.symptoms = state.symptoms.map((item) => {
        if (item.symptom_id === action.payload.symptom_id) {
          return { ...item, ...action.payload };
        }

        return item;
      });
    },

    removeSymptom: (state, action) => {
      state.symptoms = state.symptoms.filter(
        (item) => item.symptom_id !== action.payload
      );
    },

    updateFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]:
          typeof action.payload.value === "undefined"
            ? null
            : action.payload.value,
      };
    },

    resetFilter: (state) => {
      state.filter = {
        aberration: null,
        category: null,
        status: null,
        text: null,
        symptom_id: null,
        isCategoryEnd: null,
      };
    },
  },
});

export const {
  initializeSymptomList,
  addSymptom,
  updateFilter,
  updateSymptom,
  removeSymptom,
} = symptomManagement.actions;

export const selectSymptomManagementData = (state) => ({
  ...state.symptom_management,
});

export default symptomManagement.reducer;
