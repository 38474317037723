import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },

    logout: (state) => {
      state.user = null;
      state.token = null;
    },

    verifyPhone: (state) => {
      state.user = { ...state.user, phone_verified: 1 };
    },

    updateProfilePic: (state, action) => {
      state.user = { ...state.user, photo: action.payload };
    },
  },
});

export const { login, logout, verifyPhone, updateProfilePic } =
  authSlice.actions;

export const selectUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;

export default authSlice.reducer;
