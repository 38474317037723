import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bugTypes: [],
  cases: [],
};

export const messageCasesSlice = createSlice({
  name: "messageCases",
  initialState,
  reducers: {
    setBugTypes: (state, action) => {
      const newTypes = action.payload?.filter(
        (e) => state.bugTypes.findIndex((x) => x.type_id === e.type_id) === -1
      );
      state.bugTypes = [...newTypes, ...state.bugTypes];
    },
    addBugType: (state, action) => {
      state.bugTypes = [action.payload, ...state.bugTypes];
    },
    setCases: (state, action) => {
      state.cases = action.payload || [];
    },
    addCase: (state, action) => {
      state.cases = [action.payload, ...state.cases];
    },
    updateCase: (state, action) => {
      state.cases = state.cases.map((e) => {
        if (e.id === action.payload.id) {
          return { ...e, ...action.payload };
        }
        return e;
      });
    },
  },
});

export const { setBugTypes, setCases, addCase, updateCase, addBugType } =
  messageCasesSlice.actions;

export const selectMessageCasesBugTypes = (state) =>
  state.messageCases.bugTypes;

export const selectMessageCases = (state) => state.messageCases.cases;

export default messageCasesSlice.reducer;
