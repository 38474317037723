import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateSelectedBrandModel } from "redux-store/features/brandModelManagement";

const str_pad_left = (string, pad, length) => {
  return (new Array(length + 1).join(pad) + string).slice(-length);
};

const Countdown = ({ initialValue = null, onFinish = () => {} }) => {
  const dispatch = useDispatch();

  const [seconds, setSeconds] = useState(initialValue);

  useEffect(() => {
    setSeconds(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timer =
      seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);

    if (seconds == 0) {
      onFinish();
    }
    return () => {
      clearInterval(timer);
    };
  }, [seconds]);

  useEffect(() => {
    dispatch(updateSelectedBrandModel({ key: "seconds_left", value: seconds }));
  }, [seconds]);

  if (!initialValue) return null;

  return seconds ? (
    <span>
      {str_pad_left(Math.floor(seconds / 60).toString(), "0", 2)}:
      {str_pad_left(
        (seconds - Math.floor(seconds / 60) * 60).toString(),
        "0",
        2
      )}
    </span>
  ) : null;
};

export default Countdown;
