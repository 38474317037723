import { axiosClient } from "shared/axiosInstance";

class ModelService {
  static getAllModels(token, dispatch, brand, category) {
    return axiosClient(dispatch, token).get(
      `/model?brand=${brand}&category=${category}`
    );
  }

  static getModels(token, dispatch) {
    return axiosClient(dispatch, token).get("/model/all");
  }

  static getAllModelsNoFilter(token, dispatch) {
    return axiosClient(dispatch, token).get("/model/all");
  }

  static addModelToDb(token, dispatch, payload) {
    return axiosClient(dispatch, token).post("/model", payload);
  }

  static addModelOnly(token, dispatch, payload) {
    return axiosClient(dispatch, token).post("/model/model_only", payload);
  }

  static getModelForCategory(token, dispatch, category) {
    return axiosClient(dispatch, token).get(`/model?category=${category}`);
  }

  static fetchById(token, dispatch, id) {
    return axiosClient(dispatch, token).get(`/model/single/${id}`);
  }

  static getBrandModelModelUses(token, dispatch, { brand_model, model }) {
    return axiosClient(dispatch, token).get(
      `/brand_model/model-uses?brand_model=${brand_model}&model=${model}`
    );
  }

  static updateModel(token, dispatch, payload) {
    return axiosClient(dispatch, token).post("/model/update", payload);
  }

  static newModelEntry(token, dispatch, payload) {
    return axiosClient(dispatch, token).post("/model/adminCreate", payload);
  }

  static deleteModel(token, dispatch, model_id) {
    return axiosClient(dispatch, token).delete(`/model?model_id=${model_id}`);
  }
}

export default ModelService;
